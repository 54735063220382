import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


const Raffles = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <section
      className="landing-raffles shared-container position-relative w-100 d-flex align-items-center justify-content-sm-between"
    >
      <img
        src="/assets/icons/main-s5-decor.png"
        alt="raffles-img-decor"
        className="landing-raffles-decor wide-max-hide"
      />

      <div className="landing-raffles-section ">
        <div className="landing-raffles-title-purple">
          <h1 className="landing-raffles-title big-headline">
            {t("landing.raffles.title1")}
          </h1>

          <img src="/assets/icons/purple-vector.svg" alt="purple-vector" />
        </div>

        <div className="landing-raffles-title-gold">
          <h1 className="landing-raffles-title big-headline">
            {t("landing.raffles.title2")}
          </h1>

          <img src="/assets/icons/gold-vector.svg" alt="gold-vector" />
        </div>

        <p className="landing-raffles-description description">
          {t("landing.raffles.text")}
        </p>

        <button className="shared-btn-primary landing-explore-btn" onClick={() => navigate("/raffles")}>
          {t("landing.raffles.btn")}
        </button>
      </div>

      <img src="/assets/images/raffles-image.png" alt="raffles-img" className="landing-raffles-img image" />
    </section>
  )
}
export default Raffles

import React, { FC, useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import RegionContext, { REGION } from "@context/Region"

import { Button, Spinner } from "reactstrap"

import { BonusCard, getBonusText, Promotions, TopBonusCard } from "@modules/bonuses"
import { TopBonus, useAllBonuses, useFeaturedBonuses, useTopBonuses } from "@modules/adminBonuses"

type RewardsProps = {
  useAlternativeBg?: boolean
  withPromotions?: boolean
  withAllBonuses?: boolean
  withScroll?: boolean
  withShowMoreBonuses?: boolean
  withBg?: boolean
}

const Rewards: FC<RewardsProps> = ({
  useAlternativeBg,
  withAllBonuses = false,
  withPromotions = false,
  withScroll = false,
  withBg = false,
  withShowMoreBonuses = false
}) => {
  const { t } = useTranslation()

  const region = useContext(RegionContext)

  const [activeRegion, setIsActiveRegion] = useState(region)
  const { bonuses: topBonuses, loading: topBonusesLoading } = useTopBonuses(activeRegion)
  const { bonuses: allBonuses, loading: allBonusesLoading } = useAllBonuses(withAllBonuses, activeRegion)
  const { bonuses: featuredBonuses, loading: featuredBonusesLoading } = useFeaturedBonuses(activeRegion)

  const [showMore, setShowMore] = useState<boolean>(false)


  const promoteBonus: TopBonus | null = topBonuses[0] || null

  const tabHandler = (tab: REGION) => {
    setIsActiveRegion(tab)
  }

  const handleScrollToTop = () => {
    window.location.href = "#rewards"
  }

  useEffect(() => {
    setIsActiveRegion(region)
  }, [region])

  return (
    <section className="bonuses-rewards-wrapper position-relative" id="rewards">
      {withBg &&
        <img
          src={useAlternativeBg ? "/assets/images/landing-rewards-decoration.png" : "/assets/images/bonuses-rewards-decoration.png"}
          alt="bg"
          className="bonuses-rewards-decoration wide-max-hide"
        />}

      <div className="shared-container bonuses-rewards">
        <h3 className="bonuses-rewards-title">{t("landing.rewards.title")}</h3>

        <h2 className="bonuses-rewards-subtitle">{t("landing.rewards.subtitle")}</h2>

        <div className="bonuses-rewards-tab-wrapper">
          <p className="shared-text white shared-text--small text-uppercase mb-0">{t("bonuses.region.title")}</p>

          <div className="bonuses-rewards-tab-container">
            {Object.values(REGION).map((value) => (
              <button
                className={classNames("admin-bonuses-tab ", { "admin-bonuses-tab-active": value === activeRegion })}
                name={value}
                key={value}
                onClick={() => {
                  tabHandler(value)
                }}
              >
                {value}
              </button>
            ))}
          </div>
        </div>

        {topBonusesLoading ? (
          <Spinner color="primary" className="ms-auto" />
        ) : !promoteBonus ? null : (
          <div className="bonuses-rewards-bonus-wrapper position-relative zindex-1 no-overflow">
            {promoteBonus.bannerUrl ? (
              <img className="bonuses-rewards-bonus-wrapper-background"
                   src={promoteBonus.bannerUrl} alt="promo-bg" />
            ) : (
              <>
                <img className="bonuses-rewards-bonus-wrapper-background wide-only"
                     src="/assets/images/Stake-Bonus-wide.png" alt="promo-bg" />
                <img className="bonuses-rewards-bonus-wrapper-background laptop-only "
                     src="/assets/images/Stake-Bonus-tablet.png" alt="promo-bg" />
                <img className="bonuses-rewards-bonus-wrapper-background tablet-only"
                     src="/assets/images/Stake-Bonus-tablet.png" alt="promo-bg" />
                <img className="bonuses-rewards-bonus-wrapper-background mobile-only"
                     src="/assets/images/Stake-Bonus-mob.png" alt="promo-bg" />
              </>
            )}

            <div className="bonuses-rewards-bonus bonuses-rewards-bonus--small position-relative zindex-1">
              <img
                className="bonuses-rewards-bonus-img cursor-pointer"
                src={promoteBonus?.imageUrl || "/assets/images/bonus-logo-mock-2.png"}
                alt={promoteBonus?.id}
                onClick={() => window.open(promoteBonus?.refUrl, "_blank")}
              />

              <p className="bonuses-rewards-bonus-name">{promoteBonus?.title}</p>

              <div className="w-100 d-flex flex-row align-items-stretch justify-content-between">
                <div className="bonuses-rewards-bonus-text">
                  {getBonusText(promoteBonus?.firstLineDescription)}
                </div>

                <div className="bonuses-rewards-bonus-text">
                  {getBonusText(promoteBonus?.secondLineDescription)}
                </div>
              </div>

              <div className="d-flex flex-column align-items-center position-relative w-100">
                <Button
                  onClick={() => window.open(promoteBonus?.refUrl, "_blank")}
                  color="primary"
                  className="bonuses-rewards-bonus-btn cursor-pointer"
                >
                  {t("landing.rewards.cta")}
                </Button>

                <span className="bonuses-rewards-bonus-btn-with-caption">{t("bonuses.bonus.termsApply")}</span>

                {promoteBonus?.hoverText === null ? null :
                  (
                    <span className="bonuses-rewards-bonus-btn-with-caption-tooltip">{promoteBonus?.hoverText}</span>
                  )}
              </div>
            </div>
          </div>
        )}

        {featuredBonusesLoading ? (
          <Spinner color="primary" className="ms-auto" />
        ) : (
          <div className={classNames("w-100 bonuses-rewards-bonus-list no-scrollbar", {
            "bonuses-rewards-bonus-list--horizontal": withScroll && !withAllBonuses
          })}
          >
            {featuredBonuses.map((bonus: TopBonus) => <TopBonusCard bonus={bonus} key={bonus.id} />)}
          </div>
        )}

        {withShowMoreBonuses ? (!withAllBonuses ? null : allBonusesLoading ? (
          <Spinner color="primary" className="wide-max-hide" />
        ) : (
          <>
            {showMore && (
              <div className="mt-3 bonuses-rewards-all-bonus shared-container w-100">
                {allBonuses.map((bonus) => <BonusCard bonus={bonus} key={bonus.id} />)}
              </div>
            )}

            {withShowMoreBonuses && allBonuses.length > 0 && (
              <>
                {!showMore ? (
                  <button className="bonuses-btn-show shared-btn-secondary" onClick={() => setShowMore(true)}>
                    {t("bonuses.btnMore")}
                  </button>
                ) : (
                  <button className="bonuses-btn-show bonuses-btn-less shared-btn-secondary" onClick={() => {
                    setShowMore(false)
                    handleScrollToTop()
                  }}>
                    {t("bonuses.btnLess")}
                  </button>
)}
              </>
            )}
          </>)) : (!withAllBonuses ? null : allBonusesLoading ? (
          <Spinner color="primary" className="wide-max-hide" />
        ) : (
          <>
            <div className="mt-3 bonuses-rewards-all-bonus shared-container w-100">
              {allBonuses.map((bonus) => <BonusCard bonus={bonus} key={bonus.id} />)}
            </div>
          </>)
        )}

        {withPromotions && <Promotions />}
      </div>
    </section>
  )
}

export default Rewards

import axios from 'network'

import { PaginationPayload } from "@hooks/usePlainSWR"

export const fetchActiveLeaderboard = async (provider: string) => {
  const res = await axios.get(`/leaderboard/active`, {
    params: {
      provider
    }
  })

  return {
    data: res?.data
  }
}

export const fetchLeaderboardById = async (id: string) => {
  const res = await axios.get(`/leaderboard/${id}`, {
  })

  return {
    data: res?.data
  }
}

export const fetchLeaderboardsHistory = async (payload: PaginationPayload) => {
  const {page, perPage} = payload
  const res = await axios.get('/leaderboard/ended', {
    params: {
      page,
      limit: perPage
    }
  })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const fetchUsersByLeaderboardId = async (payload: PaginationPayload, id: string) => {
  const {page, perPage} = payload
  const res = await axios.get(`/leaderboard/${id}/users`, {
    params: {
      id,
      page,
      limit: perPage
    }
  })

  return {
    total: res?.data?.length,
    data: res?.data
  }
}
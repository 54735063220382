import { WheelEvent } from 'react'

/**
 * @param amount - Number to be cut
 * @param decimalsCount - Max number of decimals
 * @example
 * amount = 0.70969252
 * decimalsCount = 3
 * result = 0.709
 * @returns A limited number of decimal
 */
export const showDecimalPlacesIfCents = (amount: number, decimalsCount: number = 2): number => {
  const divider = Math.pow(10, decimalsCount)

  const parsedAmount = Math.floor(amount * divider) / divider

  return parsedAmount % 1 !== 0 ? parsedAmount : amount
}

/**
 * @param amount - Amount of users
 * @example
 * amount = 2000
 * result = '2k'
 * @returns Shorted view of users amount
 */
export const prettifyUserAmount = (amount: number): string => {
  return amount > 1000 ? `${showDecimalPlacesIfCents(amount / 1000)}k` : amount.toString()
}

export const blockInvalidCharForInt = (e: any) => {
  if (['e', 'E', '+', '-', '.', ',', '"', "'", '^'].includes(e.key)) {
    e.preventDefault()
  }
}
export const blockInvalidCharForIntWithNegative = (e: any) => {
  if (['e', 'E', '+', '.', ',', '"', "'", '^'].includes(e.key)) {
    e.preventDefault()
  }
}
export const blockInvalidCharForFloat = (e: any) => {
  if (['e', 'E', '+', '-', ',', '"', "'", '^'].includes(e.key)) {
    e.preventDefault()
  }
}

export const numberInputOnWheelPreventChange = (e: WheelEvent): void => {
  // Prevent the input value change
  // @ts-ignore
  e?.target?.blur()

  // Prevent the page/container scrolling
  e.stopPropagation()

  // Refocus immediately, on the next tick (after the current function is done)
  setTimeout(() => {
    // @ts-ignore
    e?.target?.focus()
  }, 0)
}

export const INT_MAX_SIZE = 2147483647

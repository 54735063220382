import axios from "network"

import { PaginationPayload } from "@hooks/usePlainSWR"

import { PointCardsPayload } from "@modules/pointsStore"

export const fetchPointCards = async (payload: PointCardsPayload & PaginationPayload) => {
  const { orderBy, order, page, perPage, search } = payload

  const res = await axios.get("/point-shop/items", {
    params: {
      sortOrder: order,
      sortBy: orderBy,
      limit: perPage,
      page,
      search
    },
    withCredentials: true
  })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const fetchPointsStoreStatistic = () => axios.get("point-shop/dashboard")

export const createPurchaseRequest = (pointsShopItemId: number) => axios.post("/point-shop/purchases", {
  pointsShopItemId
}, { withCredentials: true })

export const updatePointCardsOrderRequest = (payload: { id: number, order: number }[]) => {

  const items = {
    items: payload
  }
  void axios.post("/point-shop/items/change-order", items, {
    withCredentials: true
  })
}


import React, { FC, useState } from "react"
import classNames from "classnames"

import { addCommasForBigNumbers } from "@utils/Strings"

import Pagination from "components/Pagination"
import { leaderboardRandomPrize } from "@modules/leaderboards"


type RandomWinnersTableProps = {
  total: number
  randomPrizes: leaderboardRandomPrize[],
  isActive: boolean | undefined,
  isRandom?: boolean
  leaderboardId?: string,
  refreshUsers?: () => void
}

const RandomWinnersTable: FC<RandomWinnersTableProps> = ({
  randomPrizes,
  isActive,
  isRandom
}) => {
  const [page, setPage] = useState<number>(1)
  const [perPage] = useState<number>(10)
  const startIndex = ((page - 1) * perPage)

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      {randomPrizes.slice(page === 1 ? 0 : startIndex, perPage + startIndex).map((winner, index) => {
        const place = index + 1 + (perPage * (page - 1))
          return (
            <div
              className={classNames("shared-table-container w-100 d-flex flex-row admin-current-leaderboard-table-row")}
              key={winner.id}
            >
              <div
                className="admin-current-leaderboard-table-cell d-flex position-relative admin-current-leaderboard-table-cell--user">
                <div className={classNames("admin-current-leaderboard-table-cell-place")}>
                  {place}
                </div>

                <div className="">
                  {winner.leaderboardUserDisplayName || `Username ${place}`}
                </div>
              </div>

              <div>
                <div
                  className={classNames("admin-current-leaderboard-table-cell admin-current-leaderboard-table-cell--wagered",
                    { "admin-current-leaderboard-table-cell-ended": !isActive })}
                >
                  <img src="/assets/icons/coin-small.svg" alt="coin" />

                  {winner.leaderboardUserDisplayName ? addCommasForBigNumbers(parseFloat(winner.leaderboardUser.wageredTotal)) : 0}
                </div>
              </div>

              <div>
                <div
                  className={classNames("admin-current-leaderboard-table-cell admin-current-leaderboard-table-cell--prize-blue",
                    { "admin-current-leaderboard-table-cell-ended": !isActive },
                    { "admin-current-leaderboard-table-cell--prize": !isActive && page === 1 && index <= 2 && !isRandom })}>
                  ${randomPrizes && randomPrizes.length > index ? randomPrizes[index].amount : 0}
                </div>
              </div>
            </div>
          )
        })}

      <div className="admin-current-leaderboard-table-pagination">
        <Pagination
          rowCount={Math.ceil(randomPrizes?.length / perPage) || 1}
          currentPage={page}
          onChangePage={setPage}
          rowsPerPage={1}
        />
      </div>
    </div>
  )
}

export default RandomWinnersTable
import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchAllCommentsBySlotId } from "@modules/slotsReviews/requests"
import { CommentEntity, SearchPayload } from "@modules/slotsReviews"
import { useEffect, useState } from "react"

export const useAllCommentsBySlotId = (id: string) => {
  const [filter, setFilter] = useState<SearchPayload>({})
  const [page] = useState(1)
  const [perPage, setPerPage] = useState(5)

  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWR(`/comments/${id}`, () => fetchAllCommentsBySlotId(id, {
    page,
    perPage
  }))

  const comments: CommentEntity[] = (data?.items || [])

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  return {
    list: comments,

    refresh,

    loading: !data && !error,

    total: data?.total || 0,

    perPage,
    setPerPage,

    onFiltered: (newFilter: Partial<SearchPayload>) => setFilter((prev) => ({ ...prev, ...newFilter })),
    filter
  }
}

import { useEffect, useState } from 'react'

import { usePlainSWRNoMount } from '@hooks/usePlainSWR'

import {
  AdminLootBoxTransactionSort,
  AdminLootBoxTransactionsFilterPayload,
  TABLE_ORDER,
  UserLootBoxItem,
  fetchAdminLootBoxTransactions
} from '@modules/adminLootBoxTransactions'

export const useAdminLootBoxTransactions = () => {
  const [filter, setFilter] = useState<AdminLootBoxTransactionsFilterPayload>({
    sortOrder: TABLE_ORDER.DESC,
    sortBy: AdminLootBoxTransactionSort.DATE,
    search: undefined,
    status: undefined
  })
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount('/loot-box/users', () =>
    fetchAdminLootBoxTransactions({ take: perPage, skip: (page - 1) * perPage, ...filter })
  )

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const onFiltered = (newFilter: Partial<AdminLootBoxTransactionsFilterPayload>) =>
    setFilter((prev) => ({ ...prev, ...newFilter }))

  return {
    list: (data?.data || []) as UserLootBoxItem[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 0,

    filter,
    onFiltered
  }
}

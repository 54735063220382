import React, { FC, useState } from "react"
import { Control, Controller, UseFormSetValue, useWatch } from "react-hook-form"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { Input, ModalBody } from "reactstrap"

import { FormPrize, LeaderboardForm } from "@modules/adminLeaderboard"
import { blockInvalidCharForInt, numberInputOnWheelPreventChange } from "@utils/Decimals"

type ManageLeaderboardModalPrizesProps = {
  watch: (s: string) => LeaderboardForm
  control: Control<LeaderboardForm, any>
  setValue: UseFormSetValue<LeaderboardForm>
  isEdit: boolean
}

enum PrizesStep {
  TIERED = "tiered",
  RANDOM = "random",
}

const ManageLeaderboardModalPrizes: FC<ManageLeaderboardModalPrizesProps> = ({ isEdit, setValue, control, watch }) => {
  const { t } = useTranslation()
  const [activeStep, setActiveStep] = useState<PrizesStep>(PrizesStep.TIERED)

  const tieredPrizes = useWatch({ control, name: "tieredPrizes" })
  const randomPrizes = useWatch({ control, name: "randomPrizes" })

  const handleAddTieredPrize = () => setValue("tieredPrizes", [
    ...tieredPrizes, {
      prizeNumber: tieredPrizes.length + 1,
      amount: undefined
    } as unknown as FormPrize
  ])
  const handleAddRandomPrize = () => setValue("randomPrizes", [
    ...randomPrizes, {
      prizeNumber: randomPrizes.length + 1,
      amount: undefined
    } as unknown as FormPrize
  ])
  const randomPrizeThreshold = watch("randomPrizeThreshold")
  return (
    <ModalBody className="shared-modal-body admin-leaderboards-create w-100 pt-0">
      <p className="mt-4 mb-0 admin-leaderboards-create-title">
        {t(isEdit ? "admin-leaderboard.createModal.editPrizes" : "admin-leaderboard.createModal.addPrizes")}
      </p>

      <div className="w-100 d-flex align-items-center justify-content-center mt-2">
        <button
          className={classNames("shared-tab", { "shared-tab-active": activeStep === PrizesStep.TIERED })}
          type="button"
          onClick={() => setActiveStep(PrizesStep.TIERED)}
        >
          {t("admin-leaderboard.createModal.tabTiered")}
        </button>

        <button
          className={classNames("shared-tab ms-4", { "shared-tab-active": activeStep === PrizesStep.RANDOM })}
          type="button"
          onClick={() => setActiveStep(PrizesStep.RANDOM)}
        >
          {t("admin-leaderboard.createModal.tabRandom")}
        </button>
      </div>

      {activeStep === PrizesStep.TIERED && (
        <Controller control={control} name="tieredPrizes" render={({ field }) => (
          <div className="mt-4 admin-leaderboards-create-prizes">
            {field.value.map((tierPrize) => (
              <div className="shared-input-wrapper admin-leaderboards-create-prizes-input">
                <label className="shared-input-label">
                  {t("admin-leaderboard.createModal.prize")} {tierPrize.prizeNumber}
                </label>

                <Input
                  value={tierPrize.amount}
                  onChange={(e) => field.onChange(field.value.map((f) => (f.prizeNumber === tierPrize.prizeNumber ? ({
                    prizeNumber: tierPrize.prizeNumber,
                    amount: e.target.value
                  }) : f)))}
                  className="shared-input"
                  placeholder="0"
                  min={0}
                  onWheel={numberInputOnWheelPreventChange}
                  onKeyDown={blockInvalidCharForInt}
                  cssModule={{ "form-control": " " }}
                  type="number"
                />

                {tierPrize.prizeNumber > 3 && tierPrize.prizeNumber === field.value.length && (
                  <button
                    type="button"
                    className="shared-input-after shared-btn p-0"
                    onClick={() => field.onChange(field.value.filter((f) => f.prizeNumber !== tierPrize.prizeNumber))}
                  >
                    <img src="/assets/icons/delete.svg" alt="d" />
                  </button>
                )}
              </div>
            ))}

            <button
              onClick={() => handleAddTieredPrize()}
              className="no-user-select p-2 admin-leaderboards-create-add-btn justify-content-center"
            >
              <img src="/assets/icons/plus-rounded.svg" alt="plus" className="me-2" />

              {t("admin-leaderboard.createModal.addPrize")}
            </button>
          </div>
        )}
        />
      )}

      {activeStep === PrizesStep.RANDOM && (
        <>
          <Controller control={control} name="randomPrizeThreshold" render={({ field }) => (
            <div className="mt-4 ms-auto me-auto shared-input-wrapper admin-leaderboards-create-content w-100">
              <label className="shared-input-label">
                {t("admin-leaderboard.createModal.threshold")}
              </label>

              <Input
                value={field.value}
                onChange={(e) => field.onChange(!e.target.value && e.target.value !== "0" ? undefined : e.target.value)}
                className="shared-input"
                placeholder="0"
                onWheel={numberInputOnWheelPreventChange}
                onKeyDown={blockInvalidCharForInt}
                cssModule={{ "form-control": " " }}
                type="number"
              />
            </div>
          )}
          />

          <Controller control={control} name="randomPrizes" render={({ field }) => (
            <div className="mt-4 admin-leaderboards-create-prizes">
              {field.value.map((randomPrize) => (
                <div className="shared-input-wrapper admin-leaderboards-create-prizes-input">
                  <label className="shared-input-label">
                    {t("admin-leaderboard.createModal.prize")} {randomPrize.prizeNumber}
                  </label>

                  <Input
                    value={randomPrize.amount}
                    onChange={(e) => field.onChange(field.value.map((f) => (f.prizeNumber === randomPrize.prizeNumber ? ({
                      prizeNumber: randomPrize.prizeNumber,
                      amount: e.target.value
                    }) : f)))}
                    className="shared-input"
                    placeholder="0"
                    onWheel={numberInputOnWheelPreventChange}
                    onKeyDown={blockInvalidCharForInt}
                    cssModule={{ "form-control": " " }}
                    type="number"
                  />

                  {randomPrize.prizeNumber === field.value.length && (
                    <button
                      type="button"
                      className="shared-input-after shared-btn p-0"
                      onClick={() => field.onChange(field.value.filter((f) => f.prizeNumber !== randomPrize.prizeNumber))}
                    >
                      <img src="/assets/icons/delete.svg" alt="d" />
                    </button>
                  )}
                </div>
              ))}
              <button
                disabled={!randomPrizeThreshold || +randomPrizeThreshold === 0}
                onClick={() => handleAddRandomPrize()}
                className="no-user-select p-2 admin-leaderboards-create-add-btn justify-content-center"
              >
                <img src="/assets/icons/plus-rounded.svg" alt="plus" className="me-2" />

                {t("admin-leaderboard.createModal.addPrize")}
              </button>
            </div>
          )}
          />
        </>
      )}
    </ModalBody>
  )
}

export default ManageLeaderboardModalPrizes

import { REGION } from "@context/Region"


export type Bonus = {
  title: string
  firstLineDescription: string
  secondLineDescription: string
  thirdLineDescription: string | null
  fourthLineDescription: string | null
  id: string
  imageUrl: string
  refUrl: string
  order: number
  isFeatured: boolean
  region: REGION
  createdAt: Date
  type: EBonusType
  updatedAt: Date
  bannerUrl: string | null
  hoverText: string
}

export enum EBonusType {
  TOP = "TOP",
  FEATURED = "FEATURED",
  REGULAR = "REGULAR"
}

export enum EModalBonus {
  STAKE = "stake",
  FEATURED = "featured",
  REGULAR = "regular",
}

export type TopBonus = Bonus & {}

import { useEffect, useState } from "react"

import { usePlainSWRNoMount } from "@hooks/usePlainSWR"

import { RAFFLE } from "constants/swr"

import { RaffleEntryEntity, RafflesPayload, fetchEntries, RaffleEntriesPayload } from "@modules/raffles"

export const useSelectedRaffleEntries = (id: number) => {
  const [filter, setFilter] = useState<RaffleEntriesPayload>({})

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(24)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount(`${RAFFLE}/${id}/entries`, () => fetchEntries({ ...filter, page, perPage }, id))

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const fillArray = (targetLength: number) => {
    const entriesCards: RaffleEntryEntity[] = [...(data?.items || [])]
    let number = data?.items.length === 0 ? 0 : data?.items.at(-1).entryNumber || 0

    while (entriesCards.length < targetLength) {
      entriesCards.push({
        userId: "",
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        isWinner: false,
        raffleId: 0,
        entryNumber: ++number,
        user: {
          displayName: "",
          id: "",
          entryNumber: 0,
          profileImageUrl: ""
        },
        id: 0
      })
    }

    return entriesCards as RaffleEntryEntity[]
  }
  const test: RaffleEntryEntity[] = [...(data?.items || [])]

  return {
    list: fillArray(perPage),
    clearList: test,
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 0,

    onFiltered: (newFilter: Partial<RafflesPayload>) => setFilter((prev) => ({ ...prev, ...newFilter })),
    filter
  }
}

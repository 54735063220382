import React, { FC } from "react"
import { formatDistanceStrict } from "date-fns"

import classNames from "classnames"

import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"
import { CommentEntity } from "@modules/slotsReviews"
import { useTranslation } from "react-i18next"
import UserAvatar from "@src/components/UserAvatar"

type CommentProps = {
  comment: CommentEntity
}

const Comment: FC<CommentProps> = ({ comment }) => {
  const { t } = useTranslation()

  return (
    <div className="slots-reviews-slot-comment-wrapper">
      <div className="slots-reviews-slot-comment-header">
        <div className="slots-reviews-slot-comment-user-info">
          <UserAvatar className="slots-reviews-slot-comment-img" userDisplayName={comment.user.displayName} userImageUrl={comment.user.profileImageUrl} size={30} />
          <div>
            <p className="slots-reviews-slot-comment-user-info-name">
              {comment.user.displayName}
            </p>

            <p className="slots-reviews-slot-comment-user-info-date">
              {formatDistanceStrict(new Date(comment?.createdAt), new Date())}
              &nbsp;{t("currentSlotPage.currentSlotInfo.commentTimeAgo")}
            </p>
          </div>
        </div>

        {/*<div className="d-flex">
          <button
            className={`slots-reviews-slot-comment-btn-like shared-text shared-text--extra-small ${comment.isLiked ? "text-color-primary" : "text-secondary-75"} font-weight-600`}>
            {comment.isLiked ? (
              <img src="/assets/icons/heart-primary.svg" alt="heart" />
            ) : (
              <img src="/assets/icons/heart-bold.svg" alt="heart" />
            )}
            {comment.likeCount}
          </button>

          <button className="slots-reviews-slot-comment-btn-reply ms-2">
            <img src="/assets/icons/arrow-back.svg" alt="arrow" />
          </button>
        </div>*/}
      </div>

      <div className="slots-reviews-slot-comment-body">
        <div className="slots-reviews-slot-comment-rating">
          {[...Array(5)].map((star, index) => {
            return (
              <div className="" key={`${index}${comment.id}`}>
                <StarIcon
                  className={classNames("", index <= comment.rate - 1 ? "text-yellow" : "text-secondary-25")} />
              </div>
            )
          })}
        </div>

        <p className="mb-0 mt-2">
          {comment.comment}
        </p>
      </div>
    </div>
  )
}

export default Comment
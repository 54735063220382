import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Provider } from "@modules/slotsReviews"
import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"
import { ReactComponent as DollarIcon } from "@src/assets/icons/dollar.svg"
import parse from "html-react-parser"
import { useNavigate } from "react-router-dom"

type ProviderDescriptionSectionProps = {
  provider: Provider
}

const ProviderDescriptionSection: FC<ProviderDescriptionSectionProps> = ({ provider }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <section className="slots-reviews-provider-about-line">
      <div className="slots-reviews-provider-about">
        <div className="text-editor-content w-100">
            {parse(provider.review)}
        </div>

        <div className="slots-reviews-provider-attributes">
          <div
            className="slots-reviews-provider-attributes-title shared-text shared-text--small text-secondary-100 font-weight-600 text-uppercase">
            {t("currentProvider.attributes.title")}
          </div>

          <div className="slots-reviews-provider-attributes-container">
            <div
              className="w-100 d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
            >
              <div>
                <img className="me-2" src="/assets/icons/refresh-arrow.svg" alt="arrow" />

                <span className="text-secondary-100 text-uppercase">
                {t("currentProvider.attributes.established")}
              </span>
              </div>

              <span className="text-primary">
              {provider.established}
            </span>
            </div>

            <div
              className="w-100 d-flex align-items-center justify-content-between  shared-text shared-text--small font-weight-600">
              <div>
                <img className="me-2" src="/assets/icons/line-chart.svg" alt="chart" />

                <span className="text-secondary-100 text-uppercase">
                {t("currentProvider.attributes.numberOfSlots")}
              </span>
              </div>

              <span className="text-primary">
              {provider.slotsNumber}
            </span>
            </div>

            <div
              className="w-100 d-flex align-items-center justify-content-between  shared-text shared-text--small font-weight-600">
              <div>
                <StarIcon className="text-secondary-50 me-2" />

                <span className="text-secondary-100 text-uppercase">
                {t("currentProvider.attributes.countires")}
              </span>
              </div>

              <span className="text-primary">
              {provider.countries}
            </span>
            </div>

            <div className="d-flex flex-column justify-content-center shared-text shared-text--small font-weight-600">
              <div>
                <DollarIcon className="text-secondary-50 me-2" />

                <span className="text-secondary-100 text-uppercase">
                {t("currentProvider.attributes.popularSlots")}
              </span>
              </div>


              <div className="text-primary text-uppercase mt-2">
                {provider.slots.length > 0 && provider.slots.slice(0, 5).map((slot) => (
                  <>
                    <span
                      className="slots-reviews-provider-attributes-link cursor-pointer"
                      onClick={() => navigate(`/slots-reviews/slot/${slot.id}`)}
                    >
                      {slot.name}
                    </span>
                  </>
                ))}
              </div>
            </div>

            <div className="shared-text shared-text--small font-weight-600">
              <img className="me-2" src="/assets/icons/ease.svg" alt="ease" />

              <span className="text-secondary-100 text-uppercase">
              {t("currentProvider.attributes.gameTypes")}
            </span>

              <div className="d-flex text-primary text-uppercase mt-2">
                {provider.gameTypes.map((type) => (
                  <>
                    <span className="slots-reviews-provider-attributes-link" key={type}>
                      {type}
                    </span>
                    &nbsp;
                  </>
                ))}
              </div>
            </div>

            <div className="shared-text shared-text--small font-weight-600">
              <img className="me-2" src="/assets/icons/visualization.svg" alt="visualization" />

              <span className="text-secondary-100 text-uppercase">
              {t("currentProvider.attributes.licenses")}
            </span>

              <span className="d-flex text-primary text-uppercase mt-2">
                {provider.licenses.map((item) => (
                  <>
                    <span className="slots-reviews-provider-attributes-link" key={item}>
                      {item}
                    </span>
                    &nbsp;
                  </>
                ))}
            </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ProviderDescriptionSection

import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap'

import useToast, { TOAST_TYPE } from '@hooks/useToast'

import { UserLootBoxItem, payUserLootBoxItem } from '@modules/adminLootBoxTransactions'
import { UserLootBoxItemStatus } from '@src/modules/lootBox'

type ApproveTransactionModalProps = {
  onClose: () => void
  isOpen: boolean
  transaction?: UserLootBoxItem
  onApply: () => void
}

const ApproveTransactionModal: FC<ApproveTransactionModalProps> = ({
  onApply,
  onClose,
  isOpen,
  transaction
}) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [loading, setLoading] = useState<boolean>(false)

  const handleApproveTransaction = async () => {
    if (!transaction) return

    setLoading(true)

    try {
      await payUserLootBoxItem({
        status: UserLootBoxItemStatus.APPROVED,
        userLootBoxItemId: transaction.id
      })

      toast('Successfully approved!', TOAST_TYPE.SUCCESS)

      onApply()
    } catch (e) {
      console.error(e)
      toast("Transaction wasn't approved. Please, check console", TOAST_TYPE.ERROR)
    }
    setLoading(false)
  }

  const handleClose = () => {
    onClose()
  }

  if (!transaction) return null

  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ 'modal-dialog': 'admin-leaderboards-modal' }}
      contentClassName='position-relative shared-modal'
      toggle={handleClose}
    >
      <button type='button' className='shared-btn shared-modal-close' onClick={handleClose}>
        <img src='/assets/icons/close.svg' alt='close' />
      </button>

      <ModalBody cssModule={{ 'modal-body': 'admin-leaderboards-modal-body-unban' }}>
        <div className='admin-leaderboards-modal-title'>
          {t('admin-loot-box-transactions.modal.approve.title')}
        </div>

        <p className='admin-leaderboards-modal-text'>
          {t('admin-loot-box-transactions.modal.approve.description')}
        </p>
      </ModalBody>

      <ModalFooter
        color='secondary'
        cssModule={{ 'modal-footer': 'admin-leaderboards-modal-footer' }}
        className='admin-loot-box-transactions-modal-update-footer'
      >
        <button
          type='button'
          className='shared-btn-secondary admin-leaderboards-modal-btn admin-loot-box-transactions-modal-update-footer-btn'
          onClick={handleClose}
          disabled={loading}
        >
          {t('admin-leaderboard.current.banModal.btnCancel')}
        </button>

        <Button
          cssModule={{ btn: 'profile-modal-ban-btn' }}
          onClick={handleApproveTransaction}
          disabled={loading}
          className='admin-loot-box-transactions-modal-update-footer-btn admin-loot-box-transactions-modal-cta-approve'
        >
          {t('admin-loot-box-transactions.actions.approve')}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default ApproveTransactionModal

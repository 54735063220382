// ** Pages
import { RouteObject } from 'react-router-dom'

import { router as landingRouter } from '@modules/landing'
import { router as bonusesRouter } from '@modules/bonuses'
import { router as adminRouter } from '@modules/admin'
import { router as leaderboardsRouter } from '@modules/leaderboards'
import { router as pointsStoreRouter } from '@modules/pointsStore'
import { router as bonusHuntRouter } from '@modules/bonusHunt'
import { router as rafflesRouter } from '@modules/raffles'
import { router as slotsReviewsRouter } from '@modules/slotsReviews'
import { router as dailyCaseRouter } from '@modules/dailyCase'

export interface RouteEntity {
  path?: string
  index?: boolean
  element?: JSX.Element
  meta?: any
  children?: RouteEntity[]
}

// ** Merge Routes
const Routes: RouteEntity[] = [
  ...bonusesRouter,
  ...adminRouter,
  ...leaderboardsRouter,
  ...pointsStoreRouter,
  ...bonusHuntRouter,
  ...rafflesRouter,
  ...slotsReviewsRouter,
  ...landingRouter,
  ...dailyCaseRouter
]

const getRoutes = () => {
  return Routes as RouteObject[]
}

export { Routes, getRoutes }

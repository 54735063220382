import { FC } from "react"
import classNames from "classnames"

import useKickOnlineStatus, { HADDZY_KICK_LINK } from "@hooks/useKickOnlineStatus"

import "./styles.scss"

type LiveIndicatorProps = {
  className?: string
}

const LiveIndicator: FC<LiveIndicatorProps> = ({ className = true }) => {
  const { isOnline } = useKickOnlineStatus()

  return (
    <a href={HADDZY_KICK_LINK} className={classNames("live-indicator-wrapper", className)} onClick={(e) => {
      if (!isOnline) {
        e.preventDefault()
      }
    }}>
      <div className={classNames("live-indicator", {
        active: isOnline
      })}>
        <div className="live-indicator-content" />
      </div>

      <p className="live-indicator-text m-0">live</p>
    </a>
  )
}

export default LiveIndicator

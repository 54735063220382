import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import EarnBonusesCard from "components/EarnBonusesCard"

type EarnBonusesProps = {
  isPointShop?: boolean
  title: string
  subtitle: string
}
const EarnBonuses: FC<EarnBonusesProps> = ({ title, subtitle, isPointShop }) => {
  const { t } = useTranslation()


  return (
    <section className="bonuses-earn-wrapper position-relative">
      <img
        src="/assets/images/earn-bonuses-bg.png"
        alt="earn-bonuses-bg"
        className="bonuses-earn-decoration laptop-max-hide"
      />

      <div className="bonuses-earn w-100 shared-container bonuses-earn-title-subtitle">
        <div className="bonuses-earn-title">
          <h3 className="bonuses-earn-title">{title}</h3>

          <h2 className={classNames("text-uppercase bonuses-earn-title-subtitle", {
            "bonuses-earn-title-subtitle-gold": isPointShop
          })}
          >
            {subtitle}
          </h2>
        </div>

        <div className="bonuses-earn-section w-100">
          <EarnBonusesCard
            title={t("bonuses.earnSection.cardTitle1")}
            subtitle={t("bonuses.earnSection.cardSubtitle1")}
            number="1"
            imgUrl="assets/icons/earn-kick.svg"
          />

          <EarnBonusesCard
            title={t("bonuses.earnSection.cardTitle2")}
            subtitle={t("bonuses.earnSection.cardSubtitle2")}
            number="2"
            imgUrl="assets/icons/earn-crown.svg"
          />

          <EarnBonusesCard
            title={t("bonuses.earnSection.cardTitle3")}
            subtitle={t("bonuses.earnSection.cardSubtitle3")}
            number="3"
            imgUrl="assets/icons/earn-lightning.svg"
          />
        </div>
      </div>
    </section>
  )
}
export default EarnBonuses

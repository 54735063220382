import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchCurrentProvider } from "@modules/slotsReviews/requests"
import { Provider, SearchPayload } from "@modules/slotsReviews"
import { useState } from "react"

export const useCurrentProvider = (id: string) => {
  const [filter, setFilter] = useState<SearchPayload>({})

  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWR(`/slots-reviews/providers/${id}`, () => fetchCurrentProvider(id, filter))

  const provider: Provider = data?.item

  return {
    provider,

    refresh,

    loading: !data && !error,

    total: data?.total || 0,

    onFiltered: (newFilter: Partial<SearchPayload>) => setFilter((prev) => ({ ...prev, ...newFilter })),
    filter
  }
}

import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


type HeroProps = {}

const Hero: FC<HeroProps> = ({}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const scrollToExplore = () => {
    window.location.href = "#rewards"
  }
  return (
    <section className="w-100 landing-hero-wrapper">
      <div className="shared-container position-relative landing-hero">
        <img src="/assets/images/landing-hero-decoration.png"
             alt="decoration"
             className="landing-hero-decoration wide-max-hide" />

        <div className="landing-hero-content">
          <h3 className="landing-hero-content-title">
            {t("landing.hero.title1")}&nbsp;

            <span>
              {t("landing.hero.title2")}&nbsp;
            </span>

            {t("landing.hero.title3")}
          </h3>

          <h1 className="landing-hero-content-subtitle">
            {t("landing.hero.title4")}
          </h1>

          <p className="landing-hero-content-text">
            {t("landing.hero.text")}
          </p>

          <button className="landing-hero-content-btn shared-btn-primary" onClick={() => navigate("/bonuses")}>
            {t("landing.hero.btn")}
          </button>
        </div>

        <img src="/assets/images/landing-hero.png" alt="decoration" className="landing-hero-img" />

      </div>

      <div className="landing-hero-scroll wide-max-hide" onClick={scrollToExplore}>
        <p>{t("landing.hero.scroll")}</p>

        <img src="/assets/icons/mouse.svg" alt="mouse" />
      </div>
    </section>
  )
}

export default Hero

import { lazy } from "react"

import { RouteEntity } from "@src/router/routes"

import "./views/bonus-hunt.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

const BonusHunt = lazy(() => import("./views/BonusHunt"))
const CurrentBonusHunt = lazy(() => import("./views/CurrentBonusHunt"))

export * from "./dto"

export * from "./useBonushuntStatistic"
export * from "./useBonusHuntTopStatistic"
export * from "./useBonusHunt"

export * from "./requests"

export { default as BonusHuntTopStatisticCard } from "./components/BonusHuntStatisticCard"
export { default as BonusHuntTopStatistic } from "./components/BonusHuntTopStatistic"
export { default as LiveStream } from "./components/LiveStream"
export { default as BonusHuntStatistic } from "./components/BonusHuntStatistic"
export { default as BonusHuntTable } from "./components/BonusHuntTable"

export const router: RouteEntity[] = [
  {
    path: "/bonus-hunt",
    element: <BonusHunt />
  },
  {
    path: "/bonus-hunt/:id",
    element: <CurrentBonusHunt />
  }
]

export const locales = {
  en,
  ru
}

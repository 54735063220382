import { lazy } from 'react'

import { RouteEntity } from '@src/router/routes'

import './views/admin.scss'

import en from './locales/en.json'
import ru from './locales/ru.json'

import {
  AdminFutureLeaderboards,
  AdminLeaderboard,
  AdminCurrentLeaderboard
} from '@modules/adminLeaderboard'
import { AdminUsers } from '@modules/adminUsers'
import { AdminBonuses } from '@modules/adminBonuses'
import { AdminPointsStore } from '@modules/adminPointsStore'
import { AdminTransactions } from '@modules/adminTransactions'
import { AdminCurrentRaffle, AdminActiveRaffles, AdminEndedRaffles } from '@modules/adminRaffles'
import {
  AdminSlotsReviews,
  AddNewSlotReview,
  AdminProvidersReviews,
  AddNewProviderReview,
  AdminCommentReviewPage
} from '@modules/adminSlotsReviews'
import { AdminLootBoxes, AdminLootBox, AdminLootBoxItems } from '@modules/adminLootBox'
import { AdminLootBoxTransactions } from '@modules/adminLootBoxTransactions'

const Admin = lazy(() => import('./views/Admin'))

export { default as AsideMenu } from './components/AsideMenu'

export const nestedRouter: RouteEntity[] = [
  {
    path: 'comments',
    element: <AdminCommentReviewPage />
  },
  {
    path: 'providers/provider/:id',
    element: <AddNewProviderReview />
  },
  {
    path: 'providers/provider/create',
    element: <AddNewProviderReview />
  },
  {
    path: 'providers',
    element: <AdminProvidersReviews />
  },
  {
    path: 'slots-reviews/slot/:id',
    element: <AddNewSlotReview />
  },
  {
    path: 'slots-reviews/slot/create',
    element: <AddNewSlotReview />
  },
  {
    path: 'slots-reviews',
    element: <AdminSlotsReviews />
  },
  {
    path: 'transactions',
    element: <AdminTransactions />
  },
  {
    path: 'points-store',
    element: <AdminPointsStore />
  },
  {
    path: 'loot-boxes',
    element: <AdminLootBoxes />
  },
  {
    path: 'loot-boxes/:id',
    element: <AdminLootBox />
  },
  {
    path: 'loot-box-items',
    element: <AdminLootBoxItems />
  },
  {
    path: 'loot-box-transactions',
    element: <AdminLootBoxTransactions />
  },
  {
    path: 'raffles/:id',
    element: <AdminCurrentRaffle />
  },
  {
    path: 'raffles/ended',
    element: <AdminEndedRaffles />
  },
  {
    path: 'raffles',
    element: <AdminActiveRaffles />
  },
  {
    path: 'leaderboards/:id',
    element: <AdminCurrentLeaderboard />
  },
  {
    path: 'leaderboards/future',
    element: <AdminFutureLeaderboards />
  },
  {
    path: 'leaderboards',
    element: <AdminLeaderboard />
  },
  {
    path: 'users',
    element: <AdminUsers />
  },
  {
    path: 'bonuses',
    element: <AdminBonuses />
  }
]

export const router: RouteEntity[] = [
  {
    path: '/admin/*',
    element: <Admin />,
    children: nestedRouter
  }
]

export const locales = {
  en,
  ru
}

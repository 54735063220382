import * as yup from "yup"

export type SlotReviewForm = {
  name: string
  providerId: string
  rtp: string
  volatility: string
  maxPotential: string
  mixMaxBet: string
  paylines: string
  reels: string
  releaseDate: string
  personalWin: string
  personalMulti: string
  imageUrl: any
  adminRate: number
  review: string
}

export const setupSlotReviewFormSchema = () => yup.object().shape({
  name: yup.string().required(),
  providerId: yup.string().required(),
  rtp: yup.string().required(),
  volatility: yup.string().required(),
  maxPotential: yup.string().required(),
  mixMaxBet: yup.string().required(),
  paylines: yup.string().required(),
  reels: yup.string().required(),
  releaseDate: yup.string().required(),
  personalWin: yup.string().required(),
  personalMulti: yup.string().required(),
  imageUrl: yup.mixed().required(),
  adminRate: yup.number().required(),
  review: yup.string().required()
})

export type ProviderForm = {
  name: string
  established: number
  countries: number
  slotsNumber: number
  gameTypes: string
  licenses: string
  imageUrl: any
  review: string
}

export const setupProviderFormSchema = () => yup.object().shape({
  name: yup.string().required(),
  established: yup.number().required(),
  countries: yup.number().required(),
  slotsNumber: yup.number().required(),
  gameTypes: yup.string().required(),
  licenses: yup.string().required(),
  imageUrl: yup.mixed().required(),
  review: yup.string().required()
})

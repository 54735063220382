import React, { FC, useEffect, useState } from "react"
import { Button, Input, InputGroup, InputGroupText } from "reactstrap"
import classNames from "classnames"

type WalletInputProps = {
  type: string,
  address: string,
  img: string
  onSubmit: (v: string | null) => void
  placeholder?: string
}

const WalletInput: FC<WalletInputProps> = ({ onSubmit, address, type, img, placeholder }) => {
  const [isEdit, setEditBtn] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string>(address)
  const handleEdit = () => {
    if (isEdit) {
      onSubmit(inputValue ? inputValue : null)
    }
    setEditBtn(!isEdit)
  }

  useEffect(() => {
    setInputValue(address)
  }, [address])

  return (
    <InputGroup cssModule={{ "input-group": "profile-modal-crypto-wallet" }}>
      <div
        className={classNames("profile-modal-crypto-wallet-input-container d-flex w-100", { "profile-modal-crypto-wallet-input-container-edit": isEdit })}
      >
        <InputGroupText cssModule={{"input-group-text": "profile-modal-crypto-wallet-text"}}>
          <img src={img} alt={type} />
          {type}
        </InputGroupText>

        <Input
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          cssModule={{ "form-control": "profile-modal-crypto-wallet-input" }}
          disabled={!isEdit}
          placeholder={placeholder}
        />
      </div>

      <Button
        className={classNames("", { "profile-modal-crypto-wallet-btn-edit": isEdit })}
        cssModule={{ btn: "profile-modal-crypto-wallet-btn" }}
        onClick={handleEdit}
      >

        {isEdit ? "SAVE" : <img src="/assets/icons/edit.svg" alt="edit-icon" />}
      </Button>
    </InputGroup>
  )
}

export default WalletInput

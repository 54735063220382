import React from "react"
import { useTranslation } from "react-i18next"


const About = () => {
  const { t } = useTranslation()

  return (
    <section className="landing-about shared-container tablet-max-hide">
      <div className="about-img image">
        <img src="/assets/icons/enjoy-img.png" alt="enjoy-img" />
      </div>

      <div className="landing-about-section">
        <h2 className="landing-about-section-title mid-headline">
          {t("landing.about.title1")}&nbsp;

          <span className="mid-headline-primary">
            {t("landing.about.title2")}?
          </span>
        </h2>

        <p className="landing-about-section-description description">
          Who is Haddzy you may ask? Haddzy started his gambling streams a little over three years ago but that's not
          where the streaming journey began. Starting from the gaming side of streaming 5 years ago his rise on the
          unfamiliar Facebook Gaming platform playing Call Of Duty then transitioning to Casino content there was a
          rapid rise in popularity on the platform. In a little under three years has become a highly respected casino
          streamer within the industry and back to back SIGMA Casino Streamer of the year award winner. Now you can
          find Haddzy live 5 to 6 days a week on Kick & Facebook Gaming. Haddzy also gives back to his community with
          instream rewards and giveaways that can be found here on&nbsp;
          <a href="https://www.haddzy.com" target="_blank">Haddzy.com</a>.
        </p>

        {/* <button className="shared-btn-primary">
          {t("landing.about.btn")}
        </button> */}
      </div>
    </section>
  )
}
export default About

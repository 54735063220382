import React from "react"
import { useTranslation } from "react-i18next"
import LeaderboardTable from "components/LeaderboardTable"
import { useLeaderboard } from "../useLeaderboard"
import { endOfMonth, format } from "date-fns"
import { useParams } from "react-router-dom"

const LeaderboardSection = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useLeaderboard(id!)

  return (
    <section className="current-leaderboard-section w-100 position-relative">
      <div className="current-leaderboard-section-title ">
        <img src="/assets/icons/hourglass.svg" alt="title-img" />

        <h2>{t("leaderboards.leaderboard-section.title")}{loading ? "" : `${format(endOfMonth(new Date(data.year, data.month)), "MM.dd.yyyy")}`}</h2>
      </div>

      <div className="current-leaderboard-section-table-wrapper">
        <LeaderboardTable />
      </div>
    </section>
  )
}
export default LeaderboardSection

import { useEffect, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import {
  fetchActiveLeaderboard, fetchLeaderboardById,
  fetchLeaderboardsHistory
} from "./requests"
import { ActiveLeaderboard, Leaderboard } from "./dto"


export const useLeaderboard = (id?: string) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const [provider, setProvider] = useState("STAKE")

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(id ? `/leaderboard/history/${id}` : `/leaderboard/active`, () => (id ? fetchLeaderboardById(id) : fetchActiveLeaderboard(provider)))

  return {
    data: data as ActiveLeaderboard,
    error,

    refresh,
    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    provider,
    onChangeProvider: setProvider
  }
}

export const useLeaderboardHistory = (initialPerPage: number) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(initialPerPage)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR("/leaderboard/ended", () =>
    fetchLeaderboardsHistory({ page, perPage })
  )

  useEffect(() => {
    refresh()
  }, [page, perPage])

  return {
    list: (data?.items || []) as Leaderboard[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 1

  }
}

import axios from "network"

import { PaginationPayload } from "@hooks/usePlainSWR"

import { AdminPointTransactionsFilterPayload, AdminPointTransactionStatus } from "@modules/adminTransactions/dto"

export const fetchAdminPointTransactions = async (payload: AdminPointTransactionsFilterPayload & PaginationPayload) => {
  const params = {
    ...payload,
    skip: payload.page * payload.perPage,
    take: payload.perPage
  }

  if (params.status === AdminPointTransactionStatus.ALL) {
    delete params.status
  }
  const res = await axios.get("/point-shop/purchases", {
    params,
    withCredentials: true
  })

  return {
    total: res?.data?.length,
    data: res?.data
  }
}

export const fetchUserById = async (id: string) => {
  const res = await axios.get(`/users/${id}`, {
    withCredentials: true,
    params: { id }
  })

  return {
    data: res?.data
  }
}

export const updateTransaction = (id: number, status: AdminPointTransactionStatus, comment?: string) => axios.patch(`/point-shop/purchases/${id}`, {
  status,
  rejectionReason: comment
}, { withCredentials: true })

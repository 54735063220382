import React, { FC } from "react"

type SectionTitleProps = {
  imgUrl: string,
  text: string
}

const SectionTitle: FC<SectionTitleProps> = ({ imgUrl, text }) => {

  return (
    <div className="leaderboard-section-title">
      <img src={imgUrl} alt="title-img" />

      <h2>{text}</h2>
    </div>
  )
}
export default SectionTitle

import { FC } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { ReactComponent as DiscordIcon } from "@src/assets/icons/discord.svg"
import { ReactComponent as TwitterIcon } from "@src/assets/icons/twitter.svg"
import { ReactComponent as YoutubeIcon } from "@src/assets/icons/youtube.svg"
import { ReactComponent as KickIcon } from "@src/assets/icons/kick.svg"

import useKickOnlineStatus, { HADDZY_JR_KICK_LINK, HADDZY_KICK_LINK } from "@hooks/useKickOnlineStatus"

type CreatorsProps = {}

const Creators: FC<CreatorsProps> = ({}) => {
  const { t } = useTranslation()
  const { data } = useKickOnlineStatus()

  return (
    <section className="landing-creators">
      <img className="landing-creators-bg" src="/assets/images/creators-bg.png" alt="bg" />

      <div className="shared-container">
        <div className="landing-creators-title-wrapper">
          <img src="/assets/icons/creators-icon.svg" alt="logo" />

          <div className="landing-creators-title-text-wrapper">
            <h3 className="landing-creators-title-text">
              {t("landing.creators.title1")}
            </h3>

            <h3 className="landing-creators-title-text">
              {t("landing.creators.title2")}
            </h3>
          </div>

          <p className="shared-text text-secondary-75 mt-2 mb-0 tablet-max-hide">
            {t("landing.creators.subtitle")}
          </p>
        </div>

        <div className="landing-creators-list">
          <div className="landing-creators-card">
            <img src="/assets/images/creators-card-bg.png" alt="bg" />

            {!!data?.haddzyStatus && (
              <div  className="live-indicator-wrapper landing-creators-card-live">
                <div className="live-indicator active">
                  <div className="live-indicator-content" />
                </div>

                <p className="live-indicator-text m-0">live</p>
              </div>
            )}

            <div className="landing-creators-card-content">
              <img className="landing-creators-card-content-img" src="/assets/images/logo.svg" alt="logo" />

              <p className="landing-creators-card-content-name">HADDZY</p>

              <p className="shared-text text-secondary-75 mt-3 m-b-0">Stay up to date</p>

              <div className="landing-creators-card-content-socials">
                <a href="https://discord.gg/haddzy" target="_blank"
                   className="landing-creators-card-content-socials-link">
                  <DiscordIcon className="landing-creators-card-content-socials-link-icon" />
                </a>

                <a href="https://www.youtube.com/channel/UCOKNEzhIAvZDaP805jUkGEQ" target="_blank"
                   className="landing-creators-card-content-socials-link">
                  <YoutubeIcon className="landing-creators-card-content-socials-link-icon" />
                </a>

                <a href="https://twitter.com/Haddzy_" target="_blank"
                   className="landing-creators-card-content-socials-link">
                  <TwitterIcon className="landing-creators-card-content-socials-link-icon" />
                </a>

                <a
                  href={HADDZY_KICK_LINK}
                  target="_blank"
                  className={classNames("landing-creators-card-content-socials-link", {
                    active: !!data?.haddzyStatus
                  })}
                >
                  <KickIcon className="landing-creators-card-content-socials-link-icon" />
                </a>
              </div>
            </div>
          </div>

          <div className="landing-creators-card">
            <img src="/assets/images/creators-card-bg.png" alt="bg" />

            {!!data?.haddzyJrStatus && (
              <div  className="live-indicator-wrapper landing-creators-card-live">
                <div className="live-indicator active">
                  <div className="live-indicator-content" />
                </div>

                <p className="live-indicator-text m-0">live</p>
              </div>
            )}

            <div className="landing-creators-card-content">
              <img className="landing-creators-card-content-img" src="/assets/images/haddzy-jr-logo.png" alt="logo" />

              <p className="landing-creators-card-content-name">HADDZYJR</p>

              <p className="shared-text text-secondary-75 mt-3 m-b-0">Stay up to date</p>

              <div className="landing-creators-card-content-socials">
                <a href="https://discord.gg/SRFewrEZaD" target="_blank"
                   className="landing-creators-card-content-socials-link">
                  <DiscordIcon className="landing-creators-card-content-socials-link-icon" />
                </a>

                <a href="https://twitter.com/haddzyjr" target="_blank"
                   className="landing-creators-card-content-socials-link">
                  <TwitterIcon className="landing-creators-card-content-socials-link-icon" />
                </a>

                <a
                  href={HADDZY_JR_KICK_LINK}
                  target="_blank"
                  className={classNames("landing-creators-card-content-socials-link", {
                    active: !!data?.haddzyJrStatus
                  })}
                >
                  <KickIcon className="landing-creators-card-content-socials-link-icon" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Creators

import { usePlainSWR } from "@hooks/usePlainSWR"

import { PointsStoreStatisticEntity, fetchPointsStoreStatistic } from "@modules/pointsStore"

export const usePointsStoreStatistic = () => {
  const { data, mutate, error, isValidating } = usePlainSWR(`/point-shop/dashboard`, fetchPointsStoreStatistic)

  return {
    statistic: data as PointsStoreStatisticEntity,
    mutate,
    loading: !data && !error,
    isValidating,
    error
  }
}

export default usePointsStoreStatistic

import axios from 'network'

import { PaginationPayload } from "@hooks/usePlainSWR"

export const fetchFutureLeaderboards = async (payload: PaginationPayload) => {
  const { page, perPage } = payload
  const res = await axios.get('leaderboard/admin/future-list', {
    params: {
      page,
      limit: perPage
    },
    withCredentials: true
  })

  return {
    total: res?.data?.length,
    data: res?.data?.items
  }
}

export const fetchAdminCurrentLeaderboards = async (id: string) => {
  const res = await axios.get(`leaderboard/admin/${id}`, {
    withCredentials: true
  })

  return {
    data: res?.data
  }
}

export const fetchAdminLeaderboards = async (payload: PaginationPayload) => {
  const { page, perPage } = payload
  const res = await axios.get('leaderboard/admin/list', {
    params: {
      page,
      limit: perPage
    },
    withCredentials: true
  })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const fetchUsersByLeaderboardId = async (payload: PaginationPayload, id: string) => {
  const { page, perPage } = payload
  const res = await axios.get(`leaderboard/admin/${id}/users`, {
    params: {
      page,
      limit: perPage
    },
    withCredentials: true
  })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}


import React, { FC } from "react"
import { Control, Controller, UseFormSetValue } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { ModalBody } from "reactstrap"

import { LeaderboardForm } from "@modules/adminLeaderboard"
import { Option } from "@utils/Select"
import ModalInput from "@src/components/ModalInput"
import SelectInput from "@src/components/SelectInput"
import { blockInvalidCharForInt } from "@utils/Decimals"


export type ManageLeaderboardModalSetupHandle = {
  setDuration: (duration: string) => void
}

type ManageLeaderboardModalSetupProps = {
  isEdit: boolean
  control: Control<LeaderboardForm, any>
  setValue: UseFormSetValue<LeaderboardForm>
  onClose: () => void
  onApply: () => void
}

const monthOptions: Option[] = [
  { label: "JANUARY", value: "1" },
  { label: "FEBRUARY", value: "2" },
  { label: "MARCH", value: "3" },
  { label: "APRIL", value: "4" },
  { label: "MAY", value: "5" },
  { label: "JUNE", value: "6" },
  { label: "JULY", value: "7" },
  { label: "AUGUST", value: "8" },
  { label: "SEPTEMBER", value: "9" },
  { label: "OCTOBER", value: "10" },
  { label: "NOVEMBER", value: "11" },
  { label: "DECEMBER", value: "12" }
]

const yearOptions: Option[] = [
  { label: "2023", value: "2023" },
  { label: "2024", value: "2024" },
  { label: "2025", value: "2025" },
  { label: "2026", value: "2026" }
]

const providerOptions: Option[] = [{ label: "STAKE", value: "STAKE" }]


const ManageLeaderboardModalSetup: FC<ManageLeaderboardModalSetupProps> = ({ control, isEdit}) => {
  const { t } = useTranslation()

  return (
    <ModalBody className="shared-modal-body admin-leaderboards-create">
      <p className="mt-2 mb-0 admin-leaderboards-create-title admin-leaderboards-create-title--small">
        {isEdit ? t("admin-leaderboard.createModal.editSetup") : t("admin-leaderboard.createModal.setup")}
      </p>

      <div className="admin-leaderboards-create-date-wrapper">
        <Controller
          control={control}
          name="month"
          render={({ field }) => (
            <SelectInput
              options={monthOptions}
              onChange={(s) => field.onChange(s)}
              value={field.value.toString()}
              title="CHOOSE MONTH"
            />
          )}
        />

        <Controller
          control={control}
          name="year"
          render={({ field }) => (
            <SelectInput
              options={yearOptions}
              onChange={(s) => field.onChange(s)}
              value={field.value.toString()}
              title="CHOOSE YEAR"
            />
          )}
        />
      </div>

      <div className="admin-leaderboards-create-date-wrapper">
        <Controller
          control={control}
          name="provider"
          render={({ field }) => (
            <SelectInput
              options={providerOptions}
              onChange={(s) => field.onChange(s)}
              value={field.value}
              title="CHOOSE PROVIDER"
            />
          )}
        />
      </div>

      <Controller
        control={control}
        name="maxPublicUsersCount"
        render={({ field }) => (
          <ModalInput
            value={field.value}
            onChange={field.onChange}
            onKeyDown={blockInvalidCharForInt}
            type="number"
            className="mt-4"
            label="MAX PUBLIC USERS"
          />
        )}
      />

    </ModalBody>
  )
}

export default ManageLeaderboardModalSetup

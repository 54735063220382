import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import JsonView from "react18-json-view"

import { Modal, Spinner } from "reactstrap"

import CopyButton from "components/CopyButton"

import { useRaffleProof } from "@modules/raffles"

type RaffleProofModalProps = {
  id: number
  isOpen: boolean
  onClose: () => void
}

const RaffleProofModal: FC<RaffleProofModalProps> = ({ onClose, isOpen, id }) => {
  const { t } = useTranslation()
  const { data, loading } = useRaffleProof(id!)

  if (loading || !data) {
    return (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        contentClassName="profile-modal"
        className="profile-modal-container"
        backdrop="static"
        centered
      >
        <div className="w-100 d-flex align-items-center justify-content-center">
          <Spinner className="text-primary" />
        </div>
      </Modal>
    )
  }
  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close rounded" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <div className="instruction-modal-body">
        <h2 className="instruction-modal-title">{t("raffles.proof.title")}</h2>

        <h3 className="instruction-modal-subtitle">{t("raffles.proof.result")}</h3>

        <a className="text-daylight" href="https://api.random.org/signatures/form" target="_blank">
          Random.org Result Verification Page.
        </a>

        <div className="w-100 d-flex flex-row align-items-center justify-content-between">
          <h3 className="instruction-modal-subtitle">{t("raffles.proof.codeSnippet")}</h3>

          <CopyButton copyText={data?.proofBody} />
        </div>

        <JsonView src={JSON.parse(data?.proofBody)} />

        <div className="w-100 d-flex flex-row align-items-center justify-content-between">
          <h3 className="instruction-modal-subtitle">{t("raffles.proof.signature")}</h3>

          <CopyButton copyText={data?.proofSignature} />
        </div>

        <div className="instruction-modal-signature m-0">
          {data?.proofSignature}
        </div>
      </div>
    </Modal>
  )
}

export default RaffleProofModal

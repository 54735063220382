import React, { FC, useState } from "react"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { Button, Collapse as ReactstrapCollapse } from "reactstrap"

import "./styles.scss"

type AsideCollapseProps = {
  title: string
  links: { text: string, url: string }[]
  active: string
  navHandler: (event: React.MouseEvent<HTMLAnchorElement>) => void
}

const AsideCollapse: FC<AsideCollapseProps> = ({ title, links, active, navHandler }) => {
  const activeDropdown = links.map(item => active.includes(item.url)).includes(true)

  const [isOpen, setIsOpen] = useState(activeDropdown)

  const toggle = () => setIsOpen(!isOpen)

  return (

    <div className="aside-collapse">
      <div className="aside-collapse-header d-flex w-100 justify-content-between" onClick={toggle}>
        <Button
          className={classNames("aside-collapse-trigger", {
            active: isOpen
          })}
          color="transparent"
          cssModule={{ btn: "aside-collapse-trigger" }}
        >
          {title}
        </Button>

        <div className="aside-collapse-img">
          <img src="/assets/icons/dropdown-arrow.svg" alt="icon" className={classNames("", {
            "aside-collapse-active": isOpen
          })} />
        </div>
      </div>

      <ReactstrapCollapse isOpen={isOpen} className="aside-collapse-content">
        {links.map(({ text, url }) => (
          <Link
            to={url}
            type={url}
            onClick={navHandler}
            key={url}
            className={classNames("aside-collapse-nav-link", { "aside-collapse-nav-link-active": url === active })}
          >
            {text}
          </Link>
        ))}
      </ReactstrapCollapse>
    </div>
  )
}

export default AsideCollapse

import React, { Suspense } from "react"
import { BrowserRouter } from "react-router-dom"
import ReactDOM from "react-dom/client"
import { ToastContainer } from "react-toastify"

import "react-toastify/dist/ReactToastify.css"
import "flatpickr/dist/themes/dark.css"
import "react18-json-view/src/style.css"

import "mock"

import { Ability } from "@casl/ability"

// ** Locales
import "./i18n/index"

import "./styles/index.scss"
import "@src/components/TextEditor/styles.scss"
import reportWebVitals from "./reportWebVitals"

import { AbilityContext } from "@context/Can"
import AuthContext, { context } from "@context/Auth"

import AppHooks from "./router/AppHooks"
import { Spinner } from "reactstrap"
import ScrollToTop from "@src/components/ScrollToTop"

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
)
root.render(
  <>
    <ToastContainer />

    <Suspense fallback={(
      <div className="background-light w-100 vh-100 d-flex align-items-center justify-content-center">
        <Spinner color="primary" />
      </div>
    )}>

      <BrowserRouter>
        <ScrollToTop />
        <AuthContext.Provider value={context}>
          <AbilityContext.Provider value={new Ability()}>
            <AppHooks />
          </AbilityContext.Provider>
        </AuthContext.Provider>
      </BrowserRouter>
    </Suspense>
  </>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { FC } from "react"

import "./styles.scss"

type EarnBonusesCardProps = {
  title: string
  imgUrl: string
  subtitle: string
  number: string
}

const EarnBonusesCard: FC<EarnBonusesCardProps> = ({ title, subtitle, number, imgUrl }) => {
  return (
    <div className="earn-bonus-card d-flex flex-column justify-content-end">
      <div className="d-flex position-relative align-items-center justify-content-between flex-column">
        <div className="d-flex align-items-center justify-content-center position-relative">
          <img src={imgUrl} alt="" />
        </div>

        <h5>{subtitle}</h5>

        <h4>{title}</h4>

        <div className="earn-bonus-card-number">{number}</div>
      </div>
    </div>
  )
}

export default EarnBonusesCard

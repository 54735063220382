import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { Modal, ModalBody, ModalFooter } from "reactstrap"

import { User } from "@hooks/useUser"
import { banUserRequest, unbanUserRequest } from "@modules/adminUsers"
import useToast, { TOAST_TYPE } from "@hooks/useToast"

type BanUserModalProps = {
  onClose: () => void,
  isOpen: boolean,
  user?: User
  onApply: () => void
}

const BanUserModal: FC<BanUserModalProps> = ({ onApply, onClose, isOpen, user }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [banReason, setBanReason] = useState<string>("")

  const onBanUser = async () => {
    if (!user) return

    await banUserRequest(user.id, banReason)

    toast("Successfully banned!", TOAST_TYPE.SUCCESS)

    setBanReason("")

    onApply()
  }
  const onUnbanUser = async () => {
    if (!user) return

    await unbanUserRequest(user.id)

    toast("Successfully unbanned!", TOAST_TYPE.SUCCESS)

    onApply()
  }

  const handleClose = () => {
    setBanReason("")

    onClose()
  }

  if (!user) return null

  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "admin-leaderboards-modal" }}
      contentClassName="position-relative shared-modal"
      toggle={handleClose}
    >
      <button type="button" className="shared-btn shared-modal-close" onClick={handleClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      {user.isBanned ? (
        <ModalBody cssModule={{ "modal-body": "admin-leaderboards-modal-body-unban" }}>
          <div
            className="admin-leaderboards-modal-title">{t("admin-leaderboard.current.unbanModal.title")} {user.displayName}?
          </div>

          <p className="admin-leaderboards-modal-text">
            {t("admin-leaderboard.current.unbanModal.text")}
          </p>
        </ModalBody>
      ) : (
        <ModalBody cssModule={{ "modal-body": "admin-leaderboards-modal-body" }}>
          <div
            className="admin-leaderboards-modal-title">{t("admin-leaderboard.current.banModal.title")} {user.displayName}?
          </div>

          <p className="admin-leaderboards-modal-text">
            {t("admin-leaderboard.current.banModal.text")}
          </p>

          <div className="admin-leaderboards-modal-title-area">
            {t("admin-leaderboard.current.banModal.areaTitle")}
          </div>

          <textarea
            onChange={(e) => setBanReason(e.target.value)}
            value={banReason}
            placeholder={t("admin-leaderboard.current.banModal.areaPlaceholder")}
          />
        </ModalBody>
      )}

      <ModalFooter color="secondary" cssModule={{ "modal-footer": "admin-leaderboards-modal-footer" }}>
        <button
          type="button"
          className="shared-btn-secondary admin-leaderboards-modal-btn"
          onClick={handleClose}
        >
          {t("admin-leaderboard.current.banModal.btnCancel")}
        </button>

        {user.isBanned ? (
          <button
            type="button"
            className="points-confirm-btn shared-btn-primary admin-leaderboards-modal-btn"
            onClick={onUnbanUser}
          >
            {t("admin-leaderboard.current.unbanModal.btnUnban")}
          </button>
        ) : (
          <button
            type="button"
            className="points-confirm-btn shared-btn-primary admin-leaderboards-modal-btn"
            onClick={onBanUser}
          >
            {t("admin-leaderboard.current.banModal.btnBan")}
          </button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default BanUserModal

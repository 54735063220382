import React, { FC } from "react"

import { Modal } from "reactstrap"

import {
  Bonus,
  EModalBonus,
  FeaturedBonusModal,
  RegularBonusModal,
  StakeBonusModal
} from "@modules/adminBonuses"
import { REGION } from "@context/Region"

type ManageBonusModalProps = {
  isOpen: boolean
  onClose: () => void
  onApply: () => void
  bonus?: Bonus
  region: REGION
  type: EModalBonus | null
}

const ManageBonusModal: FC<ManageBonusModalProps> = ({ region, type, isOpen, onApply, onClose, bonus }) => {
  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper small"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close rounded" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      {type === EModalBonus.STAKE && <StakeBonusModal bonus={bonus} region={region} onApply={onApply} />}

      {type === EModalBonus.FEATURED && <FeaturedBonusModal bonus={bonus} region={region} onApply={onApply} />}

      {type === EModalBonus.REGULAR && <RegularBonusModal bonus={bonus} region={region} onApply={onApply} />}
    </Modal>
  )
}

export default ManageBonusModal

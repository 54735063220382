import { usePlainSWR } from "@hooks/usePlainSWR"

import { useEffect } from "react"
import axios from "network"

const getIsKickOnlineRequest = () => axios.get("/stats/kick-status")

export const HADDZY_KICK_LINK = 'https://kick.com/haddzy'
export const HADDZY_JR_KICK_LINK = 'https://kick.com/haddzyjr'

export const STREAM_HADDZY_KICK_LINK = 'https://player.kick.com/haddzy'
export const STREAM_HADDZY_JR_KICK_LINK = 'https://player.kick.com/haddzyjr'

export type KickStatus = {
  haddzyStatus: boolean,
  haddzyJrStatus: boolean
}

export const useKickOnlineStatus = () => {
  const { data, mutate, error, isValidating } = usePlainSWR(`/stats/is-online`, getIsKickOnlineRequest)

  useEffect(() => {
    const i = setInterval(() => mutate(), 120000)
    return () => clearInterval(i)
  }, [])

  const isOnline = !!data?.haddzyStatus || !!data?.haddzyJrStatus

  return {
    data: data as KickStatus,
    isOnline,
    mutate,
    loading: !data && !error,
    isValidating,
    error
  }
}

export default useKickOnlineStatus

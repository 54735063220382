import React, { FC } from "react"

import { Control, Controller } from "react-hook-form"

import ModalInput from "components/ModalInput"
import ImageUploader from "components/ImageUploader"

import { ProviderForm } from "@modules/adminSlotsReviews"
import { useTranslation } from "react-i18next"
import { DIGITS_ONLY_REGEXP } from "@utils/Validation"
import { InputType } from "reactstrap/types/lib/Input"

type ProviderReviewFormProps = {
  control: Control<ProviderForm, any>
}

const ProviderReviewForm: FC<ProviderReviewFormProps> = ({control}) => {
  const { t } = useTranslation()

  const descriptions: { key: keyof ProviderForm, label: string, placeholder: string, type: InputType }[] = [
    {
      key: "established",
      label: t("adminProvidersReviews.addProviderReview.established"),
      placeholder: t("adminProvidersReviews.addProviderReview.establishedPlaceholder"),
      type: "number"
    },
    {
      key: "countries",
      label: t("adminProvidersReviews.addProviderReview.countries"),
      placeholder: t("adminProvidersReviews.addProviderReview.countriesPlaceholder"),
      type: "number"
    },
    {
      key: "slotsNumber",
      label: t("adminProvidersReviews.addProviderReview.numberOfSlots"),
      placeholder: t("adminProvidersReviews.addProviderReview.numberOfSlotsPlaceholder"),
      type: "number"
    },
    {
      key: "gameTypes",
      label: t("adminProvidersReviews.addProviderReview.gameTypes"),
      placeholder: t("adminProvidersReviews.addProviderReview.gameTypesPlaceholder"),
      type: "text"
    },
    {
      key: "licenses",
      label: t("adminProvidersReviews.addProviderReview.licenses"),
      placeholder: t("adminProvidersReviews.addProviderReview.licencesPlaceholder"),
      type: "text"
    }
  ]
  return (
    <>
        <div className="admin-slots-reviews-add-slot-form-content">
          <div className="admin-slots-reviews-add-slot-form-content-block">
            <h2 className="shared-text shared-text--small text-headline-white font-weight-600 text-uppercase">
              {t("adminSlotsReviews.addSlotReview.mainInfo")}
            </h2>

            <Controller
              control={control}
              name="name"
              render={({ field }) => (
                <ModalInput
                  value={field.value}
                  onChange={field.onChange}
                  label={t("adminProvidersReviews.addProviderReview.name")}
                  className="admin-slots-reviews-add-slot-form-space"
                  placeholder={t("adminProvidersReviews.addProviderReview.namePlaceholder")}
                />
              )}
            />

            <p className="admin-bonuses-modal-text admin-bonuses-modal-space">
              {t("admin-raffles.createNewRaffleModal.logo")}
            </p>

            <Controller
              control={control}
              name="imageUrl"
              render={({ field }) => (
                <ImageUploader
                  className="admin-slots-reviews-add-slot-form-space"
                  url={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>

          <div className="admin-slots-reviews-add-slot-form-content-block">
            <h2 className="shared-text shared-text--small text-headline-white font-weight-600 text-uppercase">
              {t("adminProvidersReviews.addProviderReview.attributes")}
            </h2>

            {descriptions.map(({ key, label, placeholder, type }) => (
              <Controller
                control={control}
                name={key}
                key={key}
                render={({ field }) => (
                  <ModalInput
                    type={type}
                    value={field.value}
                    onChange={(val) => {
                      if (!DIGITS_ONLY_REGEXP.test(val) && type === 'number') {
                        return
                      }
                      field.onChange(val)
                    }}
                    label={label}
                    className="admin-slots-reviews-add-slot-form-space"
                    placeholder={placeholder}
                  />
                )}
              />
            ))}
          </div>
        </div>
    </>
  )
}

export default ProviderReviewForm

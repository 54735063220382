import React, { useRef } from "react"
import { useTranslation } from "react-i18next"

import Collapse, { CollapseRef } from "@src/components/Collapse"

enum COLLAPSE_BLOCKS {
  BLOCK_1 = 1,
  BLOCK_2 = 2,
  BLOCK_3 = 3
}

const Faq = () => {
  const { t } = useTranslation()

  const collapse1Ref = useRef<CollapseRef>(null)
  const collapse2Ref = useRef<CollapseRef>(null)
  const collapse3Ref = useRef<CollapseRef>(null)

  const list = [
    { key: COLLAPSE_BLOCKS.BLOCK_1, ref: collapse1Ref },
    { key: COLLAPSE_BLOCKS.BLOCK_2, ref: collapse2Ref },
    { key: COLLAPSE_BLOCKS.BLOCK_3, ref: collapse3Ref }
  ]

  const handleOpenedBlock = (key: COLLAPSE_BLOCKS) => {
    list.filter((item) => item.key !== key).forEach((item) => {
      item.ref.current?.setIsOpen(false)
    })
  }

  return (
    <section className="landing-faq shared-container w-100 d-flex flex-column align-items-center">
      <div className="landing-faq-title">
        <h3 className="small-headline">{t("landing.faq.title1")}</h3>

        <h3 className="small-headline small-headline-primary">&nbsp;{t("landing.faq.title2")}</h3>
      </div>

      <div className="landing-faq-content ">
        <Collapse
          onOpen={() => handleOpenedBlock(COLLAPSE_BLOCKS.BLOCK_1)}
          ref={collapse1Ref}
          title="HOW DO I EARN POINTS"
        >
          <p>
            You can earn points in the following ways:
            <br />

            <p>
              <b>1. Interact with the stream and get rewarded:</b>
              <br />
              <p className="m-0 ps-3 mt-2">
                Tune in to our exciting streams and follow to start accumulating points. The more you interact, the more
                points you'll earn. So chat it up, follow the channel, and participate in giveaways for even more
                points!
              </p>
            </p>


            <p>
              <b>2. Special raffles on stream:</b>
              <br />
              <p className="m-0 ps-3 mt-2">
                We'll be hosting special raffles on our stream. That's right, you have multiple chances to win prizes
                just
                by tuning in and watching the fun.
              </p>
            </p>

            <p>
              <b>3. Earn money from your favorite streamers big wins:</b>
              <br />
              <p className="m-0 ps-3 mt-2">
                When your streamer hits x100, you get paid! It's a great way to support your favorite streamers and make
                some points at the same time.
              </p>
            </p>

            Any questions? Hit up our support team!
            <br />
            <br />
            Happy earning!
          </p>
        </Collapse>

        <Collapse
          onOpen={() => handleOpenedBlock(COLLAPSE_BLOCKS.BLOCK_2)}
          ref={collapse2Ref}
          title="HOW TO SPEND POINTS"
        >
          <p>
            Looking for ways to use your coins? Here are some exciting options!
            <br />
            <br />
            You can indulge yourself by purchasing unique prizes in our <a href="/points-store">Points Shop</a>,
            including physical items,
            discounts, and more.
            <br />
            <br />

            Or, if you're feeling lucky, why not join our exclusive <a href="/raffles">Raffles</a>? Either way, there's
            always something
            rewarding waiting for you!

            <br />
            <br />
            If you have any questions or encounter any issues, please contact our support team for assistance.

            <br />
            <br />
            Happy spending!
          </p>
        </Collapse>

        <Collapse
          onOpen={() => handleOpenedBlock(COLLAPSE_BLOCKS.BLOCK_3)}
          ref={collapse3Ref}
          title="HOW TO PARTICIPATE IN RAFFLES"
        >
          <p>
            To participate in our exciting raffles, follow these steps:
            <br />

            <p>
              <b>1. Visit the Raffles Page:</b>
              <br />
              <p className="m-0 ps-3 mt-2">
                Navigate to our <a href="/raffles">Raffles page</a> to view the available raffles.
              </p>
            </p>


            <p>
              <b>2. Account Requirements:</b>
              <br />
              <p className="m-0 ps-3 mt-2">
                All raffles require you to have an account on our platform.
              </p>
            </p>

            <p>
              <b>3. Choose a Raffle:</b>
              <br />
              <p className="m-0 ps-3 mt-2">
                Browse through the list of raffles and choose the one you'd like to enter. We offer both free and
                non-free raffles, giving everyone a chance to win!
              </p>
            </p>

            <p>
              <b>4. Participate:</b>
              <br />
              <p className="m-0 ps-3 mt-2">
                Once you've selected a raffle, click the "Join" button to enter. You'll be included in the raffle for a
                chance to win fantastic prizes!
              </p>
            </p>

            Whether you're interested in free or non-free raffles, our platform offers opportunities for everyone to
            participate and win exciting rewards. Visit our <a href="/raffles">Raffles page</a> now to get started!
            <br />
            <br />

            If you have any questions or need further assistance, please feel free to contact our support team .
            <br />
            <br />
            Happy earning!
          </p>
        </Collapse>
      </div>
    </section>
  )
}
export default Faq

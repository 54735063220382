import React from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"


const PointStore = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <section
      className="landing-point-store shared-container w-100 d-flex align-items-center justify-content-sm-between"
    >
      <div className="landing-point-store-section">
        <h1 className="landing-point-store-title big-headline">
          {t("landing.point-store.title1")}

          <img src="/assets/icons/Coin.svg" alt="coin" />

          {t("landing.point-store.title2")}
        </h1>

        <h1 className="landing-point-store-title big-headline text-primary">
          {t("landing.point-store.title3")}
        </h1>

        <p className="landing-point-store-description description">
          {t("landing.point-store.text")}
        </p>

        <button className="shared-btn-primary" onClick={() => navigate("/points-store")}>
          {t("landing.point-store.btn")}
        </button>
      </div>

      <img src="/assets/icons/main-s6-img.png" alt="point-stor-img" className="landing-point-store-img image" />
    </section>

  )
}
export default PointStore

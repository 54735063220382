import axios from 'network'

export const fetchBonusHuntTopStatistic = (id: string) => axios.get(`/bonus-hunt/hunts/${id}`
)

export const fetchBonusHuntStatistic = () => axios.get("/bonus-hunt/stats")

export const fetchBonusHunt = async () => {
  const res = await axios.get('/bonus-hunt/hunts')

  return {
    total: res?.data?.length,
    data: res?.data
  }
}

import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import translationEN from './locales/en/translation.json'
import translationRU from './locales/ru/translation.json'

import { locales as landingLocales } from '@modules/landing'
import { locales as bonusesLocales } from '@modules/bonuses'
import { locales as adminUsersLocales } from '@modules/adminUsers'
import { locales as adminLeaderboardLocales } from '@modules/adminLeaderboard'
import { locales as leaderboardsLocales } from '@modules/leaderboards'
import { locales as adminBonusesLocales } from '@modules/adminBonuses'
import { locales as pointsStoreLocales } from '@modules/pointsStore'
import { locales as adminPointsStoreLocales } from '@modules/adminPointsStore'
import { locales as bonusHuntLocales } from '@modules/bonusHunt'
import { locales as adminTransactionsLocales } from '@modules/adminTransactions'
import { locales as rafflesLocales } from '@modules/raffles'
import { locales as adminRafflesLocales } from '@modules/adminRaffles'
import { locales as slotsReviewsLocales } from '@modules/slotsReviews'
import { locales as adminSlotsReviewsLocales } from '@modules/adminSlotsReviews'
import { locales as adminLootBoxLocales } from '@modules/adminLootBox'
import { locales as adminLootBoxTransactionsLocales } from '@modules/adminLootBoxTransactions'
import { locales as dailyCaseLocales } from '@modules/dailyCase'

export const typesafeTranslation = (path: string): string => {
  const translation = i18n.t(path)

  if (translation === '') {
    return path
  }

  return translation
}

const resources = {
  en: {
    translation: {
      ...translationEN,
      ...landingLocales.en,
      ...adminUsersLocales.en,
      ...bonusesLocales.en,
      ...leaderboardsLocales.en,
      ...adminLeaderboardLocales.en,
      ...adminBonusesLocales.en,
      ...pointsStoreLocales.en,
      ...adminPointsStoreLocales.en,
      ...adminTransactionsLocales.en,
      ...bonusHuntLocales.en,
      ...rafflesLocales.en,
      ...adminRafflesLocales.en,
      ...slotsReviewsLocales.en,
      ...adminSlotsReviewsLocales.en,
      ...adminLootBoxLocales.en,
      ...adminLootBoxTransactionsLocales.en,
      ...dailyCaseLocales.en
    }
  },
  ru: {
    translation: translationRU,
    ...landingLocales.ru,
    ...adminUsersLocales.ru,
    ...bonusesLocales.ru,
    ...leaderboardsLocales.ru,
    ...adminLeaderboardLocales.ru,
    ...adminBonusesLocales.ru,
    ...pointsStoreLocales.ru,
    ...adminPointsStoreLocales.ru,
    ...adminTransactionsLocales.ru,
    ...bonusHuntLocales.ru,
    ...rafflesLocales.ru,
    ...adminRafflesLocales.ru,
    ...slotsReviewsLocales.ru,
    ...adminSlotsReviewsLocales.ru,
    ...adminLootBoxLocales.ru,
    ...adminLootBoxTransactionsLocales.ru,
    ...dailyCaseLocales.ru
  }
}

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    lng: 'en',
    supportedLngs: ['en'],
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    resources
  })

export default i18n

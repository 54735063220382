import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchAllSlots, updateSlotsOrderRequest } from "@modules/slotsReviews/requests"
import { SearchPayload, SlotCard } from "@modules/slotsReviews"
import { useEffect, useState } from "react"

export const useAllSlots = (maxItems?: number, providerId?: string) => {
  const [filter, setFilter] = useState<SearchPayload>({})
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(maxItems || 5)

  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWR(`/slots-reviews/all-slots/${providerId}`, () => fetchAllSlots({
    ...filter,
    page,
    perPage
  }, providerId))

  const SlotCards: SlotCard[] = (data?.items || [])

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const saveAllSlotsNewOrder = async (items: SlotCard[]) => {
    const prevOrders: number[] = SlotCards.map(({ order }) => order)
    const payload: { order: number, id: string }[] = items.map(({ id }, index) => ({ id, order: prevOrders[index] }))

    await updateSlotsOrderRequest(payload)
  }

  return {
    list: SlotCards,

    refresh,

    loading: !data && !error,

    total: data?.total || 0,

    perPage,
    setPerPage,

    page,
    setPage,

    saveAllSlotsNewOrder,

    onFiltered: (newFilter: Partial<SearchPayload>) => setFilter((prev) => ({ ...prev, ...newFilter })),
    filter
  }
}

import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import parse from "html-react-parser"
import { format } from "date-fns"
import { useParams } from "react-router-dom"

import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"
import { ReactComponent as DollarIcon } from "@src/assets/icons/dollar.svg"

import { CommentsSection, SlotInfo, useAllCommentsBySlotId } from "@modules/slotsReviews"

type CurrentSlotDescriptionProps = {
  slot: SlotInfo
  onLeaveComment: (b: string) => void
  isOpen: boolean
  loading: boolean
  isCommented: boolean
}

const CurrentSlotDescription: FC<CurrentSlotDescriptionProps> = ({ slot, onLeaveComment, isOpen, loading, isCommented }) => {
  const { id } = useParams()
  const { t } = useTranslation()

  const { list, perPage, setPerPage, total } = useAllCommentsBySlotId(id!)

  return (
    <section className="slots-reviews-slot-info-description-wrapper">
      <div className="w-100">
        <div className="slots-reviews-slot-info-description">
          <div className="text-editor-content w-100">
            {parse(slot.review)}
          </div>
        </div>

        <CommentsSection
          comments={list}
          onLeaveComment={onLeaveComment}
          isOpen={isOpen}
          perPage={perPage}
          setPerPage={setPerPage}
          isCommented={isCommented}
          total={total}
        />
      </div>

      <div className="slots-reviews-slot-info-description-info">
        <div className="slots-reviews-slot-info-description-info-statistic">
          <div
            className="slots-reviews-provider-attributes-title shared-text shared-text--small text-secondary-100 font-weight-600 text-uppercase">
            {t("currentSlotPage.currentSlotInfo.info.title")}
          </div>

          <div className="slots-reviews-provider-attributes-container">
            <div
              className="w-100 d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
            >
              <div className="d-flex">
                <img className="me-2" src="/assets/icons/refresh-arrow.svg" alt="arrow" />

                <div className="text-secondary-100 text-uppercase">
                  {t("currentSlotPage.currentSlotInfo.info.rtp")}
                </div>
              </div>

              <div className="text-primary">
                {slot.rtp}
              </div>
            </div>

            <div
              className="w-100 d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
            >
              <div className="d-flex">
                <img className="me-2" src="/assets/icons/line-chart.svg" alt="chart" />

                <div className="text-secondary-100 text-uppercase">
                  {t("currentSlotPage.currentSlotInfo.info.volatility")}
                </div>
              </div>

              <div className="text-primary text-uppercase">
                {slot.volatility}
              </div>
            </div>

            <div
              className="w-100 d-flex align-items-center justify-content-between  shared-text shared-text--small font-weight-600"
            >
              <div className="d-flex">
                <StarIcon className="text-secondary-50 me-2" />

                <div className="text-secondary-100 text-uppercase">
                  {t("currentSlotPage.currentSlotInfo.info.maxPotential")}
                </div>
              </div>

              <div className="text-primary">
                {slot.maxPotential}
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
            >
              <div className="d-flex">
                <DollarIcon className="text-secondary-50 me-2" />

                <div className="text-secondary-100 text-uppercase">
                  {t("currentSlotPage.currentSlotInfo.info.minMaxBet")}
                </div>
              </div>


              <div className="text-primary text-uppercase">
                {slot.mixMaxBet}
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
            >
              <div className="d-flex">
                <img className="me-2" src="/assets/icons/ease.svg" alt="ease" />

                <div className="text-secondary-100 text-uppercase">
                  {t("currentSlotPage.currentSlotInfo.info.payLines")}
                </div>
              </div>

              <div className="d-flex text-primary text-uppercase">
                {slot.paylines}
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
            >
              <div className="d-flex">
                <img className="me-2" src="/assets/icons/visualization.svg" alt="visualization" />

                <div className="text-secondary-100 text-uppercase">
                  {t("currentSlotPage.currentSlotInfo.info.reels")}
                </div>
              </div>

              <div className="d-flex text-primary text-uppercase">
                {slot.reels}
              </div>
            </div>

            <div
              className="d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
            >
              <div className="d-flex">
                <img className="me-2" src="/assets/icons/calendar.svg" alt="calendar" />

                <div className="text-secondary-100 text-uppercase">
                  {t("currentSlotPage.currentSlotInfo.info.released")}
                </div>
              </div>

              <div className="d-flex text-primary text-uppercase">
                {!loading && (format(new Date(slot?.releaseDate), "dd MMM yyyy") || format(new Date(), "dd/MM/yyyy"))}
              </div>
            </div>
          </div>
        </div>

        <div>
          <div className="slots-reviews-slot-info-description-info-statistic-best">
            <div
              className="slots-reviews-provider-attributes-title shared-text shared-text--small text-secondary-100 font-weight-600 text-uppercase"
            >
              {t("currentSlotPage.currentSlotInfo.best.title")}
            </div>

            <div className="slots-reviews-provider-attributes-container">
              <div
                className="w-100 d-flex align-items-center justify-content-between shared-text shared-text--small font-weight-600"
              >
                <div className="d-flex">
                  <img className="me-2" src="/assets/icons/trophy.svg" alt="trophy" />

                  <div className="text-secondary-100 text-uppercase">
                    {t("currentSlotPage.currentSlotInfo.best.win")}
                  </div>
                </div>

                <div className="text-primary">
                  {slot.personalWin}
                </div>
              </div>

              <div
                className="w-100 d-flex align-items-center justify-content-between  shared-text shared-text--small font-weight-600"
              >
                <div className="d-flex">
                  <img className="me-2" src="/assets/icons/x.svg" alt="x" />

                  <div className="text-secondary-100 text-uppercase">
                    {t("currentSlotPage.currentSlotInfo.best.x")}
                  </div>
                </div>

                <div className="text-primary">
                  {slot.personalMulti}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CurrentSlotDescription

import { FC } from "react"
import { useTranslation } from "react-i18next"

import useKickOnlineStatus, { STREAM_HADDZY_JR_KICK_LINK, STREAM_HADDZY_KICK_LINK } from "@hooks/useKickOnlineStatus"

type LiveStreamProps = {}

const LiveStream: FC<LiveStreamProps> = ({}) => {
  const { t } = useTranslation()

  const { isOnline, data } = useKickOnlineStatus()

  return (
    <section
      className="bonus-hunt-stream-wrapper w-100 position-relative"
    >
      <img src="/assets/images/bonus-hunt-stream-bg.png" alt="bg" className="bonus-hunt-stream-bg" />

      <div className="bonus-hunt-stream d-flex flex-column justify-content-start align-items-center">
        <img src="/assets/icons/stream-cta.png" alt="stream" className="bonus-hunt-stream-cta" />

        <h3 className="bonus-hunt-stream-title">
          {t("bonus-hunt.stream.title1")}&nbsp;<span className="text-inherit">{t("bonus-hunt.stream.title2")}</span>
        </h3>

        <p className="shared-text shared-text--small white">{t("bonus-hunt.stream.subtitle")}</p>

        <div className="bonus-hunt-stream-content">
          {isOnline ? (
            <iframe
              src={!!data?.haddzyStatus ? STREAM_HADDZY_KICK_LINK : STREAM_HADDZY_JR_KICK_LINK}
              style={{ width: "100%", borderRadius: "10px", aspectRatio: "16/9" }}
            />
          ) : (
            <div className="bonus-hunt-stream-content-placeholder">
              <h4>{t("bonus-hunt.stream.placeholder1")}&nbsp;<span
                className="text-inherit text-primary">{t("bonus-hunt.stream.placeholder2")}</span></h4>
            </div>
          )}
        </div>
      </div>
    </section>
  )
}

export default LiveStream

import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { ReactComponent as ExclamationMark } from "assets/icons/attention-sign.svg"

import { PointCard } from "@modules/pointsStore"
import { useCurrentUser } from "@hooks/useUser"

type PointsStoreCardProps = {
  card: PointCard
  onSelect: (c: PointCard) => void
  showControl?: boolean
  onDelete?: (c: PointCard) => void
  onEdit?: (c: PointCard) => void
  toggleModal?: () => void
  setShowLoginModal?: (b: boolean) => void
}

const PointsStoreCard: FC<PointsStoreCardProps> = ({
  card,
  onSelect,
  showControl,
  onDelete,
  onEdit,
  toggleModal,
  setShowLoginModal
}) => {
  const { user } = useCurrentUser()
  const { t } = useTranslation()

  return (
    <div className="points-store-card">
      <div className="w-100 d-flex align-items-center">
        <ExclamationMark
          className={classNames("exclamation-mark", { "exclamation-mark-gold": card.quantity <= 5 })}
        />

        {card.quantity === 0 ? (
          <span className="points-store-text points-store-text--middle">
            {t("pointsStore.card.noItemsLeft")}
          </span>
        ) : (
          <span className="points-store-text points-store-text--middle">
            {card.quantity}&nbsp;{t("pointsStore.card.itemsLeft")}
          </span>
        )}
      </div>

      <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-between">
        <img className="points-store-card-img" src={card.imageUrl} alt="itemImg" />

        <div className="d-flex flex-column align-items-center">
          <p className="points-store-card-name points-store-text text-center m-0">{card.title}</p>

          <span className="points-store-card-caption points-store-text gray points-store-text--small">
            <div className="text-truncate text-center cursor-pointer">{card.description}</div>

            <span className="points-store-card-caption-tooltip">{card.description}</span>
          </span>
        </div>

        <div className="points-store-card-footer-wrapper">
          <div className="points-store-card-price points-store-text white">
            <img src="/assets/icons/coin-small.svg" alt="coin" />
            {card.price}
          </div>
          {!showControl ? (
            card.quantity === 0 ? (
              <button className="points-store-card-btn shared-btn-primary" disabled>
                {t("pointsStore.card.btnSoldOut")}
              </button>
            ) : (
              <button
                className="points-store-card-btn shared-btn-primary"
                onClick={() => (user ? onSelect(card) : setShowLoginModal && setShowLoginModal(true))}
              >
                {t("pointsStore.card.btnBuy")}
              </button>
            )) : (
            <div className="points-store-card-cta-wrapper">
              <button
                className="points-store-card-cta shared-text white shared-text--extra-small"
                onClick={() => {
                  if (onEdit && toggleModal) {
                    onEdit(card)
                    toggleModal()
                  }
                }}
              >
                {t("pointsStore.ctaBtnEdit")}
              </button>

              <button
                className="points-store-card-cta points-store-card-cta-delete"
                onClick={() => onDelete && onDelete(card)}
              >
                <img src="/assets/icons/delete.svg" alt="delete" />
              </button>
            </div>
          )}

        </div>
      </div>
    </div>
  )
}

export default PointsStoreCard

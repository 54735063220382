import { FC } from "react"

import { numberWithCommas } from "@utils/Strings"

import "./styles.scss"

type LeaderboardTableRowProps = {
  place: number
  name: string
  total: number
  current: number
};

const LeaderboardTableRow: FC<LeaderboardTableRowProps> = ({ place, name, total, current }) => {

  return (
    <div className="leaderboard-table-row">
      <div className="leaderboard-table-left-side">
        <div className="position-relative">
          <img src="/assets/icons/rectangle.svg" alt="rectangle" />

          <span className="leaderboard-table-place">
            {place}
          </span>
        </div>

        <p className="width-100">
          {name}
        </p>
      </div>

      <div className="leaderboard-table-center width-100">
        ${numberWithCommas(total)}
      </div>

      <div className="leaderboard-table-right-side width-100 text-end">
        ${numberWithCommas(current)}
      </div>
    </div>
  )
}

export default LeaderboardTableRow

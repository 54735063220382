import { lazy } from "react"

import "./views/admin-slots-reviews.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export const AdminSlotsReviews = lazy(() => import("./views/AdminSlotsReviews"))
export const AdminProvidersReviews = lazy(() => import("./views/AdminProvidersReviews"))
export const AddNewSlotReview = lazy(() => import("./views/AddNewSlotReview"))
export const AddNewProviderReview = lazy(() => import("./views/AddNewProviderReview"))
export const AdminCommentReviewPage = lazy(() => import("./views/AdminCommentReviewPage"))

export * from "./dto"

export * from "./requests"

export * from "./useComments"

export * from "./helpers/form"

export { default as SlotReviewBaseForm } from "./components/SlotReviewBaseForm"
export { default as ProviderReviewForm } from "./components/ProviderReviewForm"
export { default as ExitReview } from "./components/ExitReview"
export { default as ReviewComment } from "./components/ReviewComment"
export { default as SlotsReorder } from "./components/SlotsReorder"
export { default as ProvidersReorder } from "./components/ProvidersReorder"
export { default as ConfirmDeleteEntity } from "./components/ConfirmDeleteEntity"


export const locales = {
  en,
  ru
}

import { lazy } from "react"
import { RouteEntity } from "@src/router/routes"

import "./views/slots-reviews.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"


const SlotsReviews = lazy(() => import("./views/SlotsReviews"))
const CurrentProvider = lazy(() => import("./views/CurrentProvider"))
const Providers = lazy(() => import("./views/Providers"))
const CurrentSlotPage = lazy(() => import("./views/CurrentSlotPage"))

export * from "./dto"

export * from "./useTopPicks"
export * from "./useFeaturedProviders"
export * from "./useCurrentProvider"
export * from "./useAllSlots"
export * from "./useSlotById"
export * from "./useAllCommentsBySlotId"
export * from "./useTopSlots"


export * from "./requests"

export { default as TopPicks } from "./components/TopPicks"
export { default as FeaturedProviders } from "./components/FeaturedProviders"
export { default as ProvidersCard } from "./components/ProvidersCard"
export { default as AllSlotsSection } from "./components/AllSlotsSection"
export { default as SlotsCard } from "./components/SlotsCard"
export { default as TopSlotCard } from "./components/TopSlotCard"
export { default as ProvidersList } from "./components/ProvidersList"
export { default as ProviderDescriptionSection } from "./components/ProviderDescriptionSection"
export { default as CurrentSlotInfo } from "./components/CurrentSlotInfo"
export { default as CurrentSlotDescription } from "./components/CurrentSlotDescription"
export { default as CommentsSection } from "./components/CommentsSection"
export { default as Comment } from "./components/Comment"
export { default as RateSlotModal } from "./components/RateSlotModal"

export const router: RouteEntity[] = [
  {
    path: "/slots-reviews/slot/:id",
    element: <CurrentSlotPage />
  },
  {
    path: "/slots-reviews/providers/:id",
    element: <CurrentProvider />
  },
  {
    path: "/slots-reviews/providers",
    element: <Providers />
  },
  {
    path: "/slots-reviews",
    element: <SlotsReviews />
  }
]

export const locales = {
  en,
  ru
}

import React, { FC, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"
import { compareAsc, format } from "date-fns"
import { mutate } from "swr"

import { RAFFLE } from "constants/swr"

import { addCommasForBigNumbers } from "@utils/Strings"

import SearchInput from "components/SearchInput/SearchInput"
import Pagination from "components/Pagination"
import { TableCell, TableHeaderCell } from "components/Table"

import { RaffleCardEntity, useSelectedRaffleEntries } from "@modules/raffles"
import { RerollRaffleWinnerModal } from "@modules/adminRaffles"

type AdminRaffleEntriesProps = {
  raffle: RaffleCardEntity
}

const AdminRaffleEntries: FC<AdminRaffleEntriesProps> = ({ raffle }) => {
  const { t } = useTranslation()

  const {
    clearList,
    loading,
    page,
    refresh,
    perPage,
    total,
    filter,
    onFiltered,
    onChangePage
  } = useSelectedRaffleEntries(raffle.id)

  const isRaffleEnded = useMemo(() => (compareAsc(new Date(), new Date(raffle.deadline)) === 1), [raffle])

  const [showRerollModal, setShowRerollModal] = useState<boolean>(false)

  return (
    <section className="w-100 align-items-start mb-5">
      <div className="admin-raffles-search-wrapper">
        <div className="w-100">
          <SearchInput
            onChange={(s) => onFiltered({ search: s })}
            value={filter?.search}
          />
        </div>

        {isRaffleEnded && (
          <div className="admin-raffles-cta-wrapper">
            <button
              className="admin-raffles-cta-btn-add shared-btn-primary"
              onClick={() => setShowRerollModal(true)}
            >
              <img src="/assets/icons/dice.svg" alt="plus" className="me-2" />

              {t("admin-raffles.entries.reroll")}
            </button>
          </div>
        )}

      </div>

      {loading ? (
        <Spinner color="primary" />
      ) : (
        clearList.length === 0 ? (
          <div className="raffles-list-stub mt-4">
            <p className="raffles-list-stub-text">
              {t("admin-raffles.entries.empty")}
            </p>
          </div>
        ) : (
          <div className="admin-users-table w-100 d-flex flex-column mt-3">
            <div className="w-100 d-flex flex-row">
              <TableHeaderCell text={t("admin-raffles.entries.user")} />

              <TableHeaderCell
                text={t("admin-raffles.entries.dateEntered")}
              />

              <TableHeaderCell
                text={t("admin-raffles.entries.ticket")}
              />
            </div>

            {clearList.map((entry) => (
              <div className="w-100 d-flex flex-row admin-transactions-table-row admin-raffles-entries-table-row"
                   key={entry.id}>
                <TableCell className="admin-raffles-ended-table-cell-wrapper border-0">
                  <div className="admin-raffles-ended-table-cell">
                    {entry.user.displayName}
                  </div>
                </TableCell>

                <TableCell className="admin-raffles-ended-table-cell-wrapper border-0">
                  <div className="admin-raffles-ended-table-cell">
                    {format(new Date(entry.createdAt), "dd.MM.yyyy")}
                  </div>
                </TableCell>

                <TableCell className="admin-raffles-ended-table-cell-wrapper border-0">
                  <div className="admin-raffles-ended-table-cell">
                    <img src="/assets/images/ticket.svg" alt="ticket" className="me-2" />

                    {addCommasForBigNumbers(entry.entryNumber)}
                  </div>
                </TableCell>
              </div>
            ))}
          </div>
        )
      )}

      <div className="admin-raffles-pagination">
        <Pagination
          rowCount={Math.ceil(total / perPage) || 1}
          currentPage={page}
          onChangePage={onChangePage}
          rowsPerPage={1}
        />
      </div>

      <RerollRaffleWinnerModal
        raffle={raffle}
        onClose={() => setShowRerollModal(false)}
        isOpen={showRerollModal}
        onApply={() => {
          setShowRerollModal(false)

          refresh()

          mutate(`${RAFFLE}/${raffle.id}`)
        }}
      />
    </section>
  )
}

export default AdminRaffleEntries

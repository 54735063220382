import mock, { throttling } from "../mock"

mock.onGet("/history", { params: { id: "11111" } }).reply(async () => {
  await throttling()

  return [
    200,
    {
      id: "11111",
      start: "2023-09-15T19:00:52Z",
      end: "2023-09-18T19:00:52Z",
      randomWinners: [
        {
          id: "66666",
          displaydisplayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "131,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "121,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "101,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        }
      ],
      randomWinnersCount: 8,
      members: [
        {
          id: "11111",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "3,565,560.34",
          prize: "30,000"
        },
        {
          id: "22222",
          displayName: "Southside25",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "841,672.75",
          prize: "15,000"
        },
        {
          id: "33333",
          displayName: "Southside31",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "641,672.75",
          prize: "12,500"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        }
      ]
    }
  ]
})
mock.onGet("/leaderboards").reply(async () => {
  await throttling()

  return [
    200,
    {
      id: "22222",
      start: "2023-09-15T19:00:52Z",
      end: "2023-09-30T19:00:52Z",
      randomWinner: [],
      randomWinnersCount: 5,
      members: [
        {
          id: "11111",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "3,565,560.34",
          prize: "30,000"
        },
        {
          id: "22222",
          displayName: "Southside2",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "841,672.75",
          prize: "15,000"
        },
        {
          id: "33333",
          displayName: "Southside3",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "641,672.75",
          prize: "12,500"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        }
      ]
    }
  ]
})
mock.onGet("/leaderboards/future").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: "22222",
        start: "2023-10-15T19:00:52Z",
        end: "2023-10-16T19:00:52Z",
        randomWinner: [],
        randomWinnersCount: 5,
        members: []
      }, {
      id: "2",
      start: "2023-10-16T19:00:52Z",
      end: "2023-10-18T19:00:52Z",
      randomWinner: [],
      randomWinnersCount: 5,
      members: []
    }, {
      id: "3",
      start: "2023-10-19T19:00:52Z",
      end: "2023-10-25T19:00:52Z",
      randomWinner: [],
      randomWinnersCount: 5,
      members: []
    }, {
      id: "4",
      start: "2023-10-26T19:00:52Z",
      end: "2023-10-29T19:00:52Z",
      randomWinner: [],
      randomWinnersCount: 5,
      members: []
    }, {
      id: "5",
      start: "2023-10-30T19:00:52Z",
      end: "2023-11-04T19:00:52Z",
      randomWinner: [],
      randomWinnersCount: 5,
      members: []
    }, {
      id: "6",
      start: "2023-11-05T19:00:52Z",
      end: "2023-11-25T19:00:52Z",
      randomWinner: [],
      randomWinnersCount: 5,
      members: []
    }
    ]
  ]
})

mock.onGet("/history").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside1",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        },
        randomWinner: [
          {
            id: "1111123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "222222123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "33333123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "44444123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "5555123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          }
        ]
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside2",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside3",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      }
    ]
  ]
})

mock.onGet("/leaderboards-admin").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: "12121",
        isActive: true,
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside1",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        },
        randomWinner: [
          {
            id: "1111123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "222222123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "33333123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "44444123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          },
          {
            id: "5555123041",
            displayName: "Southside1",
            balance: 53210,
            pointsSpended: 53210,
            isAdmin: true,
            profileImageUrl: "/assets/images/mock-avatar.png",
            joined: new Date(),
            email: "808mafia@gmail.com",
            btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
            isBanned: false
          }
        ]
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside2",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside3",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      },
      {
        id: "11111",
        start: "2023-09-15T19:00:52Z",
        end: "2023-09-18T19:00:52Z",
        winner: {
          id: "123041",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        }
      }
    ]
  ]
})

mock.onGet("/leaderboards-admin/:id", { params: { id: "12121" } }).reply(async () => {
  await throttling()

  return [
    200,
    {
      id: "12121",
      start: "2023-09-15T19:00:52Z",
      end: "2023-10-18T19:00:52Z",
      isActive: true,
      randomWinners: [
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "131,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "121,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "101,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        }
      ],
      randomWinnersCount: 8,
      members: [
        {
          id: "11111",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "3,565,560.34",
          prize: "30,000"
        },
        {
          id: "22222",
          displayName: "Southside25",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "841,672.75",
          prize: "15,000"
        },
        {
          id: "33333",
          displayName: "Southside31",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "641,672.75",
          prize: "12,500"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: true,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        }
      ]
    }
  ]
})

mock.onGet("/leaderboards-admin/:id", { params: { id: "11111" } }).reply(async () => {
  await throttling()

  return [
    200,
    {
      id: "11111",
      start: "2023-09-15T19:00:52Z",
      end: "2023-10-18T19:00:52Z",
      randomWinners: [
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "131,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "121,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "101,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "111,672.75",
          prize: "5,304.39"
        }
      ],
      randomWinnersCount: 8,
      members: [
        {
          id: "11111",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "3,565,560.34",
          prize: "30,000"
        },
        {
          id: "22222",
          displayName: "Southside25",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "841,672.75",
          prize: "15,000"
        },
        {
          id: "33333",
          displayName: "Southside31",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "641,672.75",
          prize: "12,500"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: true,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        }
      ],
      membersTired: [
        {
          id: "11111",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "3,565,560.34",
          prize: "30,000"
        },
        {
          id: "22222",
          displayName: "Southside25",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "841,672.75",
          prize: "15,000"
        },
        {
          id: "33333",
          displayName: "Southside31",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "641,672.75",
          prize: "12,500"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "44444",
          displayName: "Southside4",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "55555",
          displayName: "Southside5",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "66666",
          displayName: "Southside6",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "77777",
          displayName: "Southside7",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "88888",
          displayName: "Southside8",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "99999",
          displayName: "Southside9",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1010101010",
          displayName: "Southside10",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1111111111",
          displayName: "Southside11",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1212121212",
          displayName: "Southside12",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1313131313",
          displayName: "Southside13",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1414141414",
          displayName: "Southside14",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        },
        {
          id: "1515151515",
          displayName: "Southside15",
          balance: 53210,
          pointsSpended: 53210,
          isAdmin: true,
          profileImageUrl: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false,
          wagered: "141,672.75",
          prize: "5,304.39"
        }
      ]
    }
  ]
})

import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap"

import "./styles.scss"

type LoginModalProps = {
  isOpen: boolean
  onClose: () => void
}

const LoginModal: FC<LoginModalProps> = ({
  isOpen, onClose
}) => {
  const { t } = useTranslation()

  const onLogin = () => window.open(`${process.env.REACT_APP_API_URL}/auth/google`, "_self")

  const [termsApplied, setTermsApplied] = useState<boolean>(false)

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper login-modal-mobile login-modal-mobile-dialog"
      contentClassName="login-modal-mobile position-relative shared-modal"
      toggle={onClose}
    >
      <div className="d-flex flex-column align-items-start justify-content-center shared-modal-content login-modal-mobile-content">
        <button type="button" className="shared-btn shared-modal-close" onClick={onClose}>
          <img src="/assets/icons/close.svg" alt="close" />
        </button>

        <ModalBody className="shared-modal-body">
          <h4 className="shared-modal-title w-100 mb-0">{t("login.modal.title")}</h4>

          <p className="shared-modal-text shared-text mb-0 text-center login-modal-text">{t("login.modal.text")}</p>

          <div className="d-flex flex-row align-items-start justify-content-start login-modal-check-wrapper">
            <FormGroup check onClick={() => setTermsApplied(!termsApplied)}>
              <Input
                id="checkbox2"
                type="checkbox"
                onChange={() => null}
                checked={termsApplied}
                className="shared-modal-check-input cursor-pointer"
              />
              <Label check className="shared-modal-check login-modal-check user-select-none cursor-pointer">
                {t("login.modal.confirmText")} <span>{t("login.modal.terms")}</span>
              </Label>
            </FormGroup>
          </div>
        </ModalBody>

        <ModalFooter color="secondary" className="shared-modal-footer justify-content-between border-0 w-100">
          <button
            type="button"
            disabled={!termsApplied}
            className="login-modal-button"
            onClick={onLogin}
          >
            <img src="/assets/icons/google.png" alt="google" />{t("login.modal.btn")}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default LoginModal

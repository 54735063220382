import { FC, ReactNode, useEffect } from "react"
import { useLocation } from "react-router-dom"

type ScrollToTopProps = {
  children?: ReactNode,
}
const ScrollToTop: FC<ScrollToTopProps> = ({ children }) => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }, [pathname])

  return <>{children}</>
}

export default ScrollToTop
import * as yup from "yup"

export type StakeBonusForm = {
  title: string
  firstLineDescription: string
  secondLineDescription: string
  thirdLineDescription: string | null
  fourthLineDescription: string | null
  imageUrl: any
  bannerUrl?: any
  refUrl: string
}

export const setupStakeBonusFormSchema = () => yup.object().shape({
  title: yup.string().required(),
  firstLineDescription: yup.string().required(),
  secondLineDescription: yup.string().required(),
  thirdLineDescription: yup.string().required().nullable(),
  fourthLineDescription: yup.string().required().nullable(),
  imageUrl: yup.mixed().required(),
  bannerUrl: yup.mixed(),
  refUrl: yup.string().required()
})

export type FeaturedBonusForm = {
  title: string
  firstLineDescription: string
  secondLineDescription: string
  thirdLineDescription: string | null
  fourthLineDescription: string | null
  imageUrl: any
  refUrl: string
}

export const setupFeaturedBonusFormSchema = () => yup.object().shape({
  title: yup.string().required(),
  firstLineDescription: yup.string().required(),
  secondLineDescription: yup.string().required(),
  thirdLineDescription: yup.string().required().nullable(),
  fourthLineDescription: yup.string().required().nullable(),
  imageUrl: yup.mixed().required(),
  refUrl: yup.string().required()
})
export type RegularBonusForm = {
  firstLineDescription: string
  secondLineDescription: string
  thirdLineDescription: string
  fourthLineDescription: string
  imageUrl: any
  refUrl: string
}

export const setupRegularBonusFormSchema = () => yup.object().shape({
  firstLineDescription: yup.string().required(),
  secondLineDescription: yup.string().required(),
  thirdLineDescription: yup.string().required(),
  fourthLineDescription: yup.string().required(),
  imageUrl: yup.mixed().required(),
  refUrl: yup.string().required()
})

export type PointsStoreCardForm = {
  title: string
  description: string
  quantity: number | null
  stockQuantity: number
  price: number | null
  price_dollars: number | null
  userCooldown: number | null
  imageUrl: any
}

export const setupPointsStoreCardFormSchema = () => yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  quantity: yup.number().required().nullable(),
  stockQuantity: yup.number().required(),
  price: yup.number().required().nullable(),
  price_dollars: yup.number().required().nullable(),
  userCooldown: yup.number().required().nullable(),
  imageUrl: yup.mixed().required()
})

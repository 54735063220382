import React from "react"
import { useTranslation } from "react-i18next"

import { useLeaderboard, SectionTitle } from "modules/leaderboards"
import { useParams } from "react-router-dom"

const RandomWinners = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useLeaderboard(id!)

  if (!data?.leaderboardRandomPrize || data?.leaderboardRandomPrize?.length === 0) return null

  return (
    <section className="leaderboard-random-winners-wrapper">
      <div className="tablet-max-hide">
        <SectionTitle
          imgUrl="/assets/icons/clover.svg"
          text={loading ? "" : `${data.leaderboardRandomPrize.length} ${t("leaderboards.random-winners.title1")}${data.leaderboardRandomPrize[0].amount} ${t("leaderboards.random-winners.title2")}`}
        />
      </div>

      <div className="leaderboard-random-winners-container">
        <div className="leaderboard-random-winners-container-side">
          <p>{t("leaderboards.random-winners.text")}</p>

          <div className="leaderboard-random-winners-container-side-text">
            <img src="/assets/icons/dollar-icon.svg" alt="dollar-icon" />

            <h3 className="leaderboard-random-winners-text">
              {!loading && data.randomPrizeThreshold}
            </h3>
          </div>

          <p>{t("leaderboards.random-winners.text1")}</p>
        </div>

        <div className="leaderboard-random-winners-container-center">
          <img src="/assets/images/random-winners.svg" alt="random-winners" />

          <h3>
            {t("leaderboards.random-winners.title-center")}&nbsp;

            <img
              src="/assets/icons/dollar-icon.svg" alt="dollar-icon"
              className="leaderboard-random-winners-container-center-icon"
            />

            <span className="leaderboard-random-winners-text-gold">
              &nbsp;{!loading && data.leaderboardRandomPrize[0].amount * data.leaderboardRandomPrize.length}
            </span>
          </h3>
        </div>

        <div className="leaderboard-random-winners-container-side">
          <div
            className="leaderboard-random-winners-container-side-text leaderboard-random-winners-container-side-users"
          >
            <h3 className="leaderboard-random-winners-text leaderboard-random-winners-text-gold leaderboard-random-winners-text-mobile">
              {!loading && data.leaderboardRandomPrize.length}
            </h3>
            &nbsp;
            <h3 className="leaderboard-random-winners-text leaderboard-random-winners-text-mobile">
              {t("leaderboards.random-winners.title-side1")}
            </h3>
          </div>

          <p className="leaderboard-random-winners-container-side-users-text">
            {t("leaderboards.random-winners.text2")}
            {!loading && data.leaderboardRandomPrize[0].amount}&nbsp;
            {t("leaderboards.random-winners.text3")}
          </p>
        </div>
      </div>
    </section>
  )
}
export default RandomWinners

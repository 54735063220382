import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import { AdminPointTransaction, AdminPointTransactionStatus, updateTransaction } from "@modules/adminTransactions"

type RejectTransactionModalProps = {
  onClose: () => void,
  isOpen: boolean,
  transaction?: AdminPointTransaction
  onApply: () => void
}

const RejectTransactionModal: FC<RejectTransactionModalProps> = ({ onApply, onClose, isOpen, transaction }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [banReason, setBanReason] = useState<string>("")

  const onRejectTransaction = async () => {
    if (!transaction) return

    await updateTransaction(transaction.id, AdminPointTransactionStatus.REJECTED, banReason)

    toast("Successfully rejected!", TOAST_TYPE.SUCCESS)

    setBanReason("")

    onApply()
  }

  const handleClose = () => {
    setBanReason("")

    onClose()
  }

  if (!transaction) return null

  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "admin-leaderboards-modal" }}
      contentClassName="position-relative shared-modal"
      toggle={handleClose}
    >
      <button type="button" className="shared-btn shared-modal-close" onClick={handleClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <ModalBody cssModule={{ "modal-body": "admin-leaderboards-modal-body" }}>
        <div className="admin-leaderboards-modal-title">
          {t("admin-transactions.modal.reject.title")}
        </div>

        <p className="admin-leaderboards-modal-text">
          {t("admin-transactions.modal.reject.description")}
        </p>

        <div className="admin-leaderboards-modal-title-area">
          {t("admin-transactions.modal.reject.label")}
        </div>

        <textarea
          onChange={(e) => setBanReason(e.target.value)}
          value={banReason}
          placeholder={t("admin-transactions.modal.reject.placeholder")}
        />
      </ModalBody>

      <ModalFooter color="secondary" cssModule={{ "modal-footer": "admin-leaderboards-modal-footer" }}>
        <button
          type="button"
          className="shared-btn-secondary admin-leaderboards-modal-btn admin-transactions-modal-update-footer-btn"
          onClick={handleClose}
        >
          {t("admin-leaderboard.current.banModal.btnCancel")}
        </button>

        <Button
          cssModule={{ btn: "profile-modal-ban-btn" }}
          onClick={onRejectTransaction}
          className="admin-transactions-modal-update-footer-btn admin-transactions-modal-cta-reject"
        >
          {t("admin-transactions.actions.reject")}
        </Button>
      </ModalFooter>
    </Modal>
  )
}

export default RejectTransactionModal

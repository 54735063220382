import React, { FC } from "react"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import Countdown from "@src/components/Countdown"
import { addCommasForBigNumbers } from "@utils/Strings"
import { ReactComponent as HourglassIcon } from "assets/icons/hourglass.svg"

import { RaffleCardEntity, RAFFLES_STATUS } from "@modules/raffles"
import { useNavigate } from "react-router-dom"
import { User } from "@hooks/useUser"

type RaffleCardProps = {
  card: RaffleCardEntity
  status: RAFFLES_STATUS
  onSubmit?: (c: RaffleCardEntity) => void
  triggerLoginModal?: () => void
  user?: User
  showControl?: boolean
  onEdit?: (c: RaffleCardEntity) => void
  toggleModal?: () => void
}

const RaffleCard: FC<RaffleCardProps> = ({
  card,
  status,
  onSubmit,
  triggerLoginModal,
  user,
  showControl,
  onEdit,
  toggleModal
}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div className="overflow-hidden">
      <div className="raffles-card raffles-card-border position-relative">
        <img src={card.imageUrl} alt="" className="raffles-card-img cursor-pointer"
             onClick={() => (navigate(`/raffles/${card.id}`))} />

        <div className="d-flex flex-column position-relative cursor-pointer"
             onClick={() => navigate(`/raffles/${card.id}`)}>
          <p className="raffles-card-title text-truncate">{card.title}</p>

          {card.description ? <p className="raffles-card-description text-truncate">{card.description}</p> : null}
        </div>

        <div className="raffles-card-entries">
          <div className="raffles-card-entries-title">
            <img src="/assets/icons/users-icon.svg" alt="users-icon" />
            {t("raffles.card.entries")}
          </div>

          <span className={classNames("shared-text shared-text--small font-weight-600",
            { "text-secondary-75": status === RAFFLES_STATUS.ENDED })}
          >
          {card.maxEntries === 0 ?
            (card.entriesCount)
            : (
              <>
                {card.entriesCount}
                <span className="shared-text shared-text--small font-weight-600 text-secondary-40">
                   &nbsp;/&nbsp;{card.maxEntries}
                </span>
              </>
            )}
        </span>
        </div>

        <div className="raffles-card-countdown-wrapper">
          {status === RAFFLES_STATUS.ACTIVE ? null
            : (
              <div className="shared-text shared-text--small text-secondary-40 text-uppercase">
                {t("raffles.card.endedOn")}
              </div>
            )}

          <div className="raffles-card-countdown">
          <span className="raffles-card-countdown-hourglass">
            <HourglassIcon className={classNames("",
              { "raffles-card-countdown-hourglass-primary": status === RAFFLES_STATUS.ACTIVE },
              { "raffles-card-countdown-hourglass-secondary": status === RAFFLES_STATUS.ENDED })}
            />
          </span>

            {status === RAFFLES_STATUS.ACTIVE ?
              (<Countdown date={card.deadline?.toString()} className="countdown-raffle-card" timeType />
              ) : (
                <div className="shared-text shared-text--small text-headline-white">
                  {format(new Date(card.deadline), "dd.MM.yyyy")}
                </div>
              )}
          </div>
        </div>

        {showControl ? (
          <div className="admin-raffles-cta">
            <button className="admin-raffles-cta-btn-view shared-btn-primary w-100" onClick={() => navigate(`/admin/raffles/${card.id}`)}>
              {t("raffles.cta.view")}
            </button>

            <button className="admin-raffles-cta-btn-edit shared-btn-secondary w-100"
                    onClick={() => {
                      if (onEdit && toggleModal) {
                        onEdit(card)
                        toggleModal()
                      }
                    }}>
              {t("raffles.cta.edit")}
            </button>
          </div>
        ) : (
          status === RAFFLES_STATUS.ACTIVE ? (
            <button
              className="raffles-card-btn shared-btn-primary w-100"
              disabled={card.maxEntries > 0 && card.maxEntries === card.entriesCount}
              onClick={user ? () => onSubmit && onSubmit(card) : () => triggerLoginModal && triggerLoginModal()}
            >
              {card.entryCost === 0 ? t("raffles.card.btnJoinForFree")
                : (
                  <>
                    {t("raffles.card.btnJoin")}&nbsp;
                    <img src="/assets/icons/coin-small.svg" alt="coin" className="raffles-card-btn-img" />&nbsp;
                    {addCommasForBigNumbers(card.entryCost)}
                  </>)
              }
            </button>
          ) : (
            <button
              className="raffles-card-btn-ended shared-text shared-text--small text-headline-white text-uppercase font-weight-600"
              onClick={() => navigate(`/raffles/${card.id}`)}
            >
              {t("raffles.card.btnExplore")}
            </button>
          ))}
      </div>
    </div>
  )
}

export default RaffleCard

import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"

import { JoinRaffleModal, RaffleCardEntity, RAFFLES_STATUS, RafflesCard, useRaffles } from "@modules/raffles"
import { useCurrentUser } from "@hooks/useUser"
import LoginModal from "@src/components/LoginModal"

type CurrentRafflesProps = {}

const CurrentRaffles: FC<CurrentRafflesProps> = ({}) => {
  const { t } = useTranslation()
  const status = RAFFLES_STATUS.ACTIVE

  const { user } = useCurrentUser()
  const { list, loading, refresh } = useRaffles(status, false)

  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)
  const [currentCard, setCurrentCard] = useState<RaffleCardEntity | null>(null)

  const handleJoinRaffle = () => {
    setCurrentCard(null)
    refresh()
  }

  return (
    <section className="raffles-section raffles-section-current shared-container w-100">
      <div className="raffles-section-header">
        <div className="raffles-title-wrapper">
          <img src="/assets/images/tickets.png" alt="tickets" className="raffles-title-img" />

          <div className="raffles-title-container">
            <p className="raffles-title">
              {t("raffles.currentRaffles")}
            </p>

            <p className="shared-text shared-text--small font-weight-600 text-secondary-75 m-0">
              {t("raffles.currentRafflesDescription")}
            </p>
          </div>
        </div>

        <div className="raffles-title-trust">
          <img src="/assets/icons/shield.svg" alt="shield" />

          <div className="raffles-title-trust-text">{t("raffles.trustBlockText")}</div>
        </div>
      </div>

      <div className="raffles-list-wrapper">
        <div className="raffles-list">
          {loading ? (
            <div className="raffles-list-stub-wrapper">
              <Spinner color="primary" />
            </div>
          ) : (
            list.length === 0 ? (
              <div className="raffles-list-stub">
                <p className="raffles-list-stub-text">
                  {t("raffles.stubCurrent")}
                </p>
              </div>
            ) : (
              list.map(card => (
                <RafflesCard
                  card={card}
                  key={card.id}
                  status={status}
                  onSubmit={setCurrentCard}
                  user={user}
                  triggerLoginModal={() => setShowLoginModal(true)}
                />
              )))
          )}
        </div>
      </div>

      <JoinRaffleModal
        isOpen={!!currentCard}
        item={currentCard}
        onClose={() => setCurrentCard(null)}
        onApply={handleJoinRaffle}
      />

      <LoginModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)} />
    </section>
  )
}

export default CurrentRaffles

import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "reactstrap"

import { getBonusText } from "@modules/bonuses"

import { Bonus } from "@modules/adminBonuses"

type BonusCardProps = {
  bonus: Bonus
  onDeleteItem?: (b: Bonus) => void
  onEditItem?: (b: Bonus) => void
  showControl?: boolean
}

const BonusCard: FC<BonusCardProps> = ({ bonus, showControl = false, onDeleteItem, onEditItem }) => {
  const { t } = useTranslation()
  return (
    <div
      className="bonuses-rewards-bonus--horizontal bonuses-rewards-bonus--bordered position-relative"
      key={bonus.id}
    >
      {!showControl ? null : (
        <div className="admin-bonuses-btn-group">
          <button className="admin-bonuses-btn " onClick={() => onEditItem && onEditItem(bonus)}>
            <img className="admin-bonuses-btn-edit" src="/assets/icons/edit-2.svg" alt="edit" />
          </button>

          <button
            className="admin-bonuses-btn admin-bonuses-btn-delete"
            onClick={() => onDeleteItem && onDeleteItem(bonus)}
          >
            <img className="admin-bonuses-btn-delete" src="/assets/icons/delete.svg" alt="delete" />
          </button>
        </div>
      )}
      <div className="bonuses-rewards-bonus-img-wrapper small">
        <img
          src={bonus.imageUrl || "/assets/images/bonus-logo-mock-small.png"}
          alt=""
          onClick={() => window.open(bonus.refUrl, "_blank")}
          className="bonuses-rewards-bonus-img medium cursor-pointer"
        />
      </div>

      <div className="bonuses-rewards-bonus-tips">
        <div className="bonuses-rewards-bonus-text bonuses-rewards-bonus-tips-text m-0">
          {getBonusText(bonus.firstLineDescription)}
        </div>

        <div className="bonuses-rewards-bonus-text bonuses-rewards-bonus-tips-text m-0">
          {getBonusText(bonus.secondLineDescription)}
        </div>

        {bonus.thirdLineDescription && (
          <div className="bonuses-rewards-bonus-text bonuses-rewards-bonus-tips-text m-0">
            {getBonusText(bonus.thirdLineDescription)}
          </div>
        )}

        {bonus.fourthLineDescription && (
          <div className="bonuses-rewards-bonus-text bonuses-rewards-bonus-tips-text m-0">
            {getBonusText(bonus.fourthLineDescription)}
          </div>
        )}
      </div>

      <div className="d-flex align-items-center flex-column bonuses-rewards-bonus-btn small position-relative margin">
        <Button
          onClick={() => window.open(bonus.refUrl, "_blank")}
          color="primary"
          className="bonuses-rewards-bonus-btn small cursor-pointer h-100"
        >
          {t("bonuses.bonus.cta")}
        </Button>

        <span
          className="bonuses-rewards-bonus-btn-with-caption bonuses-rewards-bonus--horizontal-caption position-absolute">{t("bonuses.bonus.termsApply")}</span>

        {bonus.hoverText === null ? null : <span
          className="bonuses-rewards-bonus-btn-with-caption-tooltip bonuses-rewards-bonus-btn-with-caption--horizontal">{bonus.hoverText}</span>}
      </div>
    </div>
  )
}

export default BonusCard

import React, { FC, useState } from "react"

import { Button, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"

import axios from "network"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import { useTranslation } from "react-i18next"
import { User } from "@hooks/useUser"

type ChangeUsernameModalProps = {
  user: User
  onClose: () => void
  onSubmit: () => void
}

const setNewUsername = (id: string, displayName: string) => axios.patch(`/users/${id}`, {
  displayName
}, {
  withCredentials: true
})

const ChangeUsernameModal: FC<ChangeUsernameModalProps> = ({ onClose, onSubmit, user }) => {
  const { t } = useTranslation()
  const toast = useToast()
const inputMaxLength = 32
  const [loading, setLoading] = useState<boolean>(false)
  const [username, setUsername] = useState<string>(user.displayName)

  const handleOnSubmit = async () => {
    if (username.length > inputMaxLength) {
      toast("Username must be less than 32 characters", TOAST_TYPE.ERROR)

      return
    }
    if (username.length === 0) {
      toast("Username cannot be empty", TOAST_TYPE.ERROR)

      return
    }
    setLoading(true)
    try {
      await setNewUsername(user.id, username)
      toast("Username successfully changed", TOAST_TYPE.SUCCESS)

      onSubmit()
    } catch (e: any) {
      const error = e.response.data.message
      if (error === "User name already exists!") {
        toast("This username is already exists!", TOAST_TYPE.ERROR)
      } else {
        toast("Something went wrong!", TOAST_TYPE.ERROR)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Modal
      isOpen
      toggle={onClose}
      cssModule={{ "modal-dialog": "change-username-modal" }}
      contentClassName="content"
      fade={false}
    >
      <ModalHeader cssModule={{ "modal-header": "change-username-modal-title" }}>
        {t("profile.changeUsernameModal.title")}
      </ModalHeader>

      <ModalBody className="change-username-modal-text">
        <InputGroup className="position-relative" cssModule={{ "input-group": "none" }}>
          <label htmlFor="username" className="change-username-modal-text-label">
            {t("profile.changeUsernameModal.label")}
          </label>

          <Input
            name="username"
            value={username}
            type="text"
            onChange={e => setUsername(e.target.value)}
            cssModule={{ "form-control": "change-username-modal-text-input" }}
            maxLength={inputMaxLength}
          />

          <div
            className="change-username-modal-text-input-char-counter shared-text shared-text--extra-small text-secondary-40"
          >
            {username.length}/{inputMaxLength}
          </div>
        </InputGroup>
      </ModalBody>

      <ModalFooter color="secondary" className="change-username-modal-footer">
        <Button disabled={loading} cssModule={{ btn: "change-username-modal-cancel" }} onClick={onClose}>
          {t("profile.changeUsernameModal.btnCancel")}
        </Button>

        <button disabled={loading} type="button" className="change-username-modal-confirm-btn shared-btn-primary"
                onClick={handleOnSubmit}>
          {loading ? <Spinner size="sm" color="secondary" /> : t("profile.changeUsernameModal.btnConfirm")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default ChangeUsernameModal

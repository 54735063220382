import { FC, useRef, useState } from "react"
import { Button, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import classNames from "classnames"

import { useCurrentUser, logout } from "@hooks/useUser"
import { useOnClickOutside } from "@hooks/useOnClickOutside"

import { addCommasForBigNumbers } from "@utils/Strings"

import ProfileModal from "@src/components/ProfileModal"
import LoginModal from "@src/components/LoginModal"

import "./styles.scss"
import UserAvatar from "@src/components/UserAvatar"

type HeaderUserProps = {}

export const HeaderUser: FC<HeaderUserProps> = ({}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const menuRef = useRef(null)

  const { user, loading } = useCurrentUser()

  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false)

  const onLogin = () => setShowLoginModal(true)

  const [showProfileModal, setShowProfileModal] = useState(false)

  const toggleProfileModal = () => setShowProfileModal(!showProfileModal)

  const toggleMenu = () => setMenuIsOpen(!menuIsOpen)

  useOnClickOutside(menuRef, () => {
    if (menuIsOpen) setTimeout(() => setMenuIsOpen(false), 50)
  })

  const handleLogout = async () => {
    await logout()

    window.open("/", "_self")
  }

  return !user ? (
    <>
      <Button
        color="primary"
        size="lg"
        className="header-user-login shared-text shared-text--small font-weight-600 ms-auto text-uppercase wide-max-hide"
        onClick={onLogin}
      >
        {t("auth.login")}
      </Button>
      <LoginModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)} />
    </>
  ) : loading ? <Spinner color="primary" className="ms-auto wide-max-hide" /> : (
    <div className="header-user ms-auto wide-max-hide">
      <UserAvatar
        userDisplayName={user.displayName}
        userImageUrl={user.profileImageUrl}
        className="header-user-avatar"
        onClick={toggleProfileModal}
        size={48}
      />

      {showProfileModal && (
        <div className="position-relative">
          <ProfileModal modal={showProfileModal} setModal={setShowProfileModal} />
        </div>
      )}

      <div className="d-flex flex-column">
        <p className="header-user-name text-uppercase text-truncate">{user?.displayName}</p>

        <div className="header-user-balance w-100" onClick={logout}>
          <img src="/assets/icons/coin-small.svg" alt="coin" />

          <p className="m-0 d-inline">{addCommasForBigNumbers(user.pointsBalance)}</p>
        </div>
      </div>

      <button className="header-user-menu-btn position-relative" onClick={toggleMenu} ref={menuRef}>
        <img className={classNames("header-user-menu-btn-img", { "header-user-menu-btn-img-active": menuIsOpen })}
             src="/assets/icons/arrow.svg" alt="arrow" />

        <ul
          className={classNames("header-user-menu", {
            "header-user-menu-active": menuIsOpen
          })}
        >
          {user.isAdmin &&
            <li onClick={() => navigate("/admin/users")}>
              {t("header.dropdown.item1")}
            </li>
          }

          <li onClick={() => setShowProfileModal(true)}>
            {t("header.dropdown.item2")}
          </li>

          <li onClick={handleLogout}>{t("header.dropdown.item3")}</li>
        </ul>
      </button>
    </div>
  )
}

import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Modal, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import axios from 'network'
import { LootBox } from '@src/modules/lootBox'

type ConfirmDeleteModalProps = {
  isOpen: boolean
  action: () => Promise<void>
  onClose: () => void
  onApply: () => void
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  isOpen,
  onApply,
  onClose,
  action
}) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)

  const onDelete = async () => {
    setLoading(true)

    if (action) {
      await action()
    }
    setLoading(false)
    onApply()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      className='shared-modal-wrapper'
      contentClassName='position-relative shared-modal'
      toggle={onClose}
    >
      <div className='d-flex flex-column align-items-start justify-content-center shared-modal-content'>
        <button
          type='button'
          className='shared-btn shared-modal-close'
          onClick={onClose}
        >
          <img src='/assets/icons/close.svg' alt='close' />
        </button>

        <ModalBody className='shared-modal-body'>
          <h4 className='shared-modal-title w-100 mb-0'>
            {t('admin-loot-boxes.deleteModal.title')}
          </h4>

          <p className='shared-modal-text shared-text mb-0 text-center'>
            {t('admin-loot-boxes.deleteModal.text')}
          </p>
        </ModalBody>

        <ModalFooter
          color='secondary'
          className='shared-modal-footer justify-content-between border-0'
        >
          <button
            type='button'
            disabled={loading}
            className='rounded-0 shared-modal-btn shared-btn-secondary'
            onClick={onClose}
          >
            {t('admin-loot-boxes.deleteModal.cancel')}
          </button>

          <button
            type='button'
            disabled={loading}
            className='rounded-1 shared-modal-btn shared-btn-primary'
            onClick={onDelete}
          >
            {loading && <Spinner size='sm' className='me-1' />}

            {t('admin-loot-boxes.deleteModal.delete')}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteModal

import { FC } from "react"
import { useTranslation } from "react-i18next"

import classNames from "classnames"

import { ReactComponent as HaloIcon } from "@src/assets/icons/halo.svg"
import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg"
import { ReactComponent as YoutubeIcon } from "assets/icons/youtube.svg"
import { ReactComponent as KickIcon } from "assets/icons/kick.svg"
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg"

import "./styles.scss"

type SocialCardProps = {
  title: string
  subtitle: string
  link: string
}

const SocialCard: FC<SocialCardProps> = ({ title, subtitle, link }) => {
  const { t } = useTranslation()
  const icons = () => {
    switch (title.toLowerCase()) {
      case "facebook":
        return <FacebookIcon className="custom-social-card-icon" />
      case "kick":
        return <KickIcon className="custom-social-card-icon" />
      case "youtube":
        return <YoutubeIcon className="custom-social-card-icon" />
      case "instagram":
        return <InstagramIcon className="custom-social-card-icon" />
    }
  }
  return (
    <div className="custom-social-card d-flex flex-column justify-content-end">
      <div className="d-flex align-items-center justify-content-between flex-column">
        <div className="d-flex align-items-center justify-content-center position-relative">
          <HaloIcon className={classNames(`custom-social-card-halo ${title.toLowerCase()}`)} />

          {icons()}
        </div>

        <h4>{title}</h4>

        <h5>{subtitle}</h5>
      </div>

      <div className="custom-social-card-footer justify-content-center">
        <a href={link} color="primary" className="custom-social-card-link shared-btn-primary">
          {t("socials.btnExplore")}
        </a>
      </div>
    </div>
  )
}

export default SocialCard

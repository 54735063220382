import { useEffect, useState } from "react"

import { usePlainSWRNoMount } from "@hooks/usePlainSWR"

import {
  AdminPointTransaction,
  AdminPointTransactionsFilterPayload,
  AdminPointTransactionSort,
  AdminPointTransactionStatus,
  fetchAdminPointTransactions,
  TABLE_ORDER
} from "@modules/adminTransactions"

export const useAdminPointTransactions = () => {
  const [filter, setFilter] = useState<AdminPointTransactionsFilterPayload>({
    sortOrder: TABLE_ORDER.DESC,
    sortBy: AdminPointTransactionSort.DATE,
    status: AdminPointTransactionStatus.ALL
  })
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount("/admin-point-transactions", () =>
    fetchAdminPointTransactions({ page, perPage, ...filter })
  )

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const onFiltered = (newFilter: Partial<AdminPointTransactionsFilterPayload>) => setFilter((prev) => ({ ...prev, ...newFilter }))

  return {
    list: (data?.items || []) as AdminPointTransaction[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 0,

    filter,
    onFiltered
  }
}

import React, { FC, useEffect, useState } from "react"
import { Modal } from "reactstrap"
import { useTranslation } from "react-i18next"

import { ReactComponent as KickIcon } from "assets/icons/kick.svg"

import "./styles.scss"
import axios from "@src/network"
import useToast, { TOAST_TYPE } from "@hooks/useToast"
import { HADDZY_KICK_LINK } from "@hooks/useKickOnlineStatus"

type ConnectKickModalProps = {
  isOpen: boolean
  handleClose: () => void
}

type VerificationCode = {
  code: string
  expire: string
  result: number
}

const ConnectKickModal: FC<ConnectKickModalProps> = ({ isOpen, handleClose }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [verificationCode, setVerificationCode] = useState<VerificationCode>()

  const getVerificationCode = async () => {
    const res = await axios.get(`/users/connection/code?platform=kick`, {
      headers: { "x-api-key": 12345 },
      withCredentials: true
    })

    return {
      data: res.data
    }
  }

  useEffect(() => {
    if (isOpen) {
      getVerificationCode().then((res) => setVerificationCode(res.data))
    }
  }, [isOpen])

  const handleOnCopy = (copyText: string) => {
    navigator.clipboard.writeText(copyText)
    toast(t("copy.success"), TOAST_TYPE.SUCCESS)
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "connect-kick-modal" }}
      contentClassName="connect-kick-modal-body position-relative shared-modal"
      toggle={handleClose}
    >
      <button type="button" className="connect-kick-modal-close shared-btn shared-modal-close" onClick={() => {
        handleClose()
      }}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <div className="connect-kick-modal-title">
        <KickIcon className="connect-kick-modal-icon" />

        <p>{t("profile.connectionSection.kickModal.title")}</p>
      </div>

      <div className="connect-kick-modal-content">
        <div className="d-flex w-100 flex-column align-items-center">
          <p className="connect-kick-modal-text">
            {t("profile.connectionSection.kickModal.yourVerificationCode")}
          </p>

          <button className="connect-kick-modal-verification" onClick={() => handleOnCopy(verificationCode?.code!)}>
            {verificationCode?.code}

            <img src="/assets/icons/copy-alternative.svg" alt="copy" />
          </button>
        </div>

        <div className="d-flex w-100 ps-4">
          <ol className="mb-0 p-0">
            <li className="connect-kick-modal-text">
              {t("profile.connectionSection.kickModal.firstStep1")}&nbsp;
              <a className="connect-kick-modal-instructions-link" target="_blank" href={HADDZY_KICK_LINK}>
                {t("profile.connectionSection.kickModal.firstStep2")}
              </a>
            </li>

            <li className="connect-kick-modal-text">
              {t("profile.connectionSection.kickModal.secondStep")}
            </li>
          </ol>
        </div>

        <div className="d-flex w-100 flex-column align-items-center">
          <button
            className="connect-kick-modal-verification"
            onClick={() => handleOnCopy(`!connect ${verificationCode?.code}`)}
          >
            {`!connect ${verificationCode?.code}`}

            <img src="/assets/icons/copy-alternative.svg" alt="copy" />
          </button>
        </div>

        <div className="d-flex w-100 justify-content-center">
          <button className="connect-kick-modal-cancel shared-btn-secondary" onClick={handleClose}>
            {t("profile.connectionSection.kickModal.btnCancel")}
          </button>
        </div>
      </div>
    </Modal>
  )
}

export default ConnectKickModal

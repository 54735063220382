import { RouteEntity } from "@src/router/routes"

import "./views/bonuses.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

import Bonuses from "./views/Bonuses"

export * from "./dto"

export * from "./requests"
export * from "./helpers"

export { default as Rewards } from "./components/Rewards"
export { default as Promotions } from "./components/Promotions"
export { default as BonusCard } from "./components/BonusCard"
export { default as TopBonusCard } from "./components/TopBonusCard"
export { default as EarnBonuses } from "./components/EarnBonuses"

export const router: RouteEntity[] = [
  {
    path: "/bonuses",
    element: <Bonuses />
  }
]

export const locales = {
  en,
  ru
}

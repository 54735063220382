import React from "react"

import Pagination from "components/Pagination"
import LeaderboardHistoryCard from "components/LeaderboardHistoryCard"

import { useAdminLeaderboard } from "@modules/adminLeaderboard"
import { Spinner } from "reactstrap"
import { getImageUrlByIdAndIndex } from "@modules/leaderboards"
import { endOfMonth, isThisMonth } from "date-fns"

const LeaderboardsList = () => {
  const { list, total, page, perPage, onChangePage, loading } = useAdminLeaderboard()

  return (
    <section className="admin-leaderboards-content d-flex w-100 flex-column align-items-center justify-content-center">
      <div className="admin-leaderboards-list">
        {loading ? <Spinner color="primary" /> :
          list.map((leaderboard, index) => (
            <LeaderboardHistoryCard
              start={new Date(parseInt(leaderboard.year), parseInt(leaderboard.month) - 1).toString()}
              ended={endOfMonth(new Date(parseInt(leaderboard.year), parseInt(leaderboard.month) - 1)).toString()}
              imgUrl={getImageUrlByIdAndIndex(leaderboard.leaderboardUser[0]?.id || '', index)}
              name={leaderboard.leaderboardUser[0]?.displayName || ''}
              isHistoryPage
              id={leaderboard.id}
              isAdminPage
              isActive={isThisMonth(new Date(parseInt(leaderboard.year), parseInt(leaderboard.month) - 1))}
              key={leaderboard.id + index}
              prize={leaderboard.leaderboardTieredPrize[0].amount}
            />
          ))}
      </div>

      <div className="admin-leaderboards-pagination">
        <Pagination
          rowCount={Math.ceil(total / perPage)}
          currentPage={page + 1}
          onChangePage={(page) => onChangePage(page - 1)}
          rowsPerPage={1}
        />
      </div>
    </section>
  )
}

export default LeaderboardsList

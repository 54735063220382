import { hashToInteger } from "@utils/Strings"

const imageUrlsFirst = [
  "/assets/images/avatars/animoji-0.png",
  "/assets/images/avatars/animoji-1.png",
  "/assets/images/avatars/animoji-2.png",
  "/assets/images/avatars/animoji-3.png",
  "/assets/images/avatars/animoji-4.png",
  "/assets/images/avatars/animoji-5.png",
  "/assets/images/avatars/animoji-6.png",
  "/assets/images/avatars/animoji-7.png",
  "/assets/images/avatars/animoji-8.png",
  "/assets/images/avatars/animoji-9.png",
  "/assets/images/avatars/animoji-10.png"
]
const imageUrlsSecond = [
  "/assets/images/avatars/animoji-11.png",
  "/assets/images/avatars/animoji-12.png",
  "/assets/images/avatars/animoji-13.png",
  "/assets/images/avatars/animoji-14.png",
  "/assets/images/avatars/animoji-15.png",
  "/assets/images/avatars/animoji-16.png",
  "/assets/images/avatars/animoji-17.png",
  "/assets/images/avatars/animoji-18.png",
  "/assets/images/avatars/animoji-19.png",
  "/assets/images/avatars/animoji-20.png"
]
const imageUrlsThird = [
  "/assets/images/avatars/animoji-21.png",
  "/assets/images/avatars/animoji-22.png",
  "/assets/images/avatars/animoji-23.png",
  "/assets/images/avatars/animoji-24.png",
  "/assets/images/avatars/animoji-25.png",
  "/assets/images/avatars/animoji-26.png",
  "/assets/images/avatars/animoji-27.png",
  "/assets/images/avatars/animoji-28.png",
  "/assets/images/avatars/animoji-29.png",
  "/assets/images/avatars/animoji-30.png"
]

export const getImageUrlByIdAndIndex = (id: string = "", index: number) => {
  switch (index) {
    case 0 :
      return imageUrlsFirst[hashToInteger(id, 2) % 11]
    case 1 :
      return imageUrlsSecond[hashToInteger(id, 2) % 10]
    case 2 :
      return imageUrlsThird[hashToInteger(id, 2) % 10]
    default :
      return imageUrlsFirst[hashToInteger(id, 2) % 11]
  }
}

import { useEffect, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchUsersByLeaderboardId, LeaderboardUser } from "@modules/leaderboards"

export const useUsersByLeaderboardId = (id?: string) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`/leaderboard/${id}/users`, () =>
    (id ? fetchUsersByLeaderboardId({ page, perPage }, id)
      : null)
  )

  useEffect(() => {
    refresh()
  }, [page, perPage])

  return {
    list: (data?.items || []) as LeaderboardUser[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.totalCount || 1

  }
}

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { ReactComponent as ExclamationMark } from 'assets/icons/attention-sign.svg'

import { useCurrentUser } from '@hooks/useUser'
import { LootBox } from '@modules/lootBox'

type AdminLootBoxCardProps = {
  card: LootBox
  onDelete?: (item: LootBox) => void
  onEdit?: (item: LootBox) => void
}

const AdminLootBoxCard: FC<AdminLootBoxCardProps> = ({
  card,
  onDelete,
  onEdit
}) => {
  const { user } = useCurrentUser()
  const { t } = useTranslation()

  return (
    <div className='loot-boxes-card'>
      <div className='loot-boxes-card-label-id loot-boxes-text'>#{card.id}</div>
      <div className='w-100 h-100 d-flex flex-column align-items-center justify-content-between'>
        <img
          className='loot-boxes-card-img'
          src={card.imageUrl}
          alt='itemImg'
        />
        <div className='d-flex flex-column align-items-center'>
          <p className='loot-boxes-card-name loot-boxes-text text-center m-0'>
            {card.title}
          </p>

          <span className='loot-boxes-card-caption loot-boxes-text gray loot-boxes-text--small'>
            <div className='text-truncate text-center cursor-pointer'>
              {card.description}
            </div>

            <span className='loot-boxes-card-caption-tooltip'>
              {card.description}
            </span>
          </span>
        </div>

        <div className='loot-boxes-card-footer-wrapper'>
          <div className='loot-boxes-card-price loot-boxes-text white'>
            <div
              className={classNames('admin-loot-boxes-status-text', {
                'text-green': card.enabled === true,
                'text-ban-red': card.enabled === false
              })}
            >
              {card.enabled
                ? t('admin-loot-boxes.status.active')
                : t('admin-loot-boxes.status.inactive')}
            </div>
          </div>

          <div className='loot-boxes-card-cta-wrapper'>
            <button
              className='loot-boxes-card-cta shared-text white shared-text--extra-small'
              onClick={() => onEdit && onEdit(card)}
            >
              {t('pointsStore.ctaBtnEdit')}
            </button>

            <button
              className='loot-boxes-card-cta loot-boxes-card-cta-delete'
              onClick={() => onDelete && onDelete(card)}
            >
              <img src='/assets/icons/delete.svg' alt='delete' />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLootBoxCard

import React, { FC } from "react"
import { Spinner } from "reactstrap"
import { ProvidersCard, useFeaturedProviders } from "@modules/slotsReviews"
import { useTranslation } from "react-i18next"
import SearchInput from "@src/components/SearchInput/SearchInput"
import { useNavigate } from "react-router-dom"

type ProvidersListProps = {}

const ProvidersList: FC<ProvidersListProps> = ({}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { list, loading, onFiltered, filter, perPage, total, setPerPage } = useFeaturedProviders(12)

  const handleScrollToTop = () => {
    window.location.href = "#header"
  }
  return (
    <section className="slots-reviews-providers">
      <div
        className="slots-reviews-slot-back-wrapper shared-text shared-text--small text-headline-white font-weight-600 text-uppercase p-0"
      >
        <button className="slots-reviews-go-back" onClick={() => navigate("/slots-reviews")}>
          <img src="/assets/icons/back.svg" alt="back" />
        </button>

        {t("currentSlotPage.backToReviews")}
      </div>

      <div className="w-100 d-flex align-items-center justify-content-between mt-2">
        <p className="shared-text text-secondary-100 font-weight-700 text-uppercase m-0">
          {t("providersPage.title")}
        </p>

        <div className="slots-reviews-providers-search">
          <SearchInput
            onChange={(s) => onFiltered({ search: s })}
            value={filter?.search}
          />
        </div>
      </div>

      <div className="slots-reviews-providers-list flex-wrap">
        {loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          list.length === 0 ? (
            <div className="slots-reviews-providers-list-stub shared-stub">
              <p className="shared-stub-text">
                {t("currentProvider.stub")}
              </p>
            </div>
          ) : (
            list.map((card) => (
              <ProvidersCard card={card} key={card.id} withFooter />
            ))))}
      </div>

      {perPage < total && (
      <div className="slots-reviews-providers-btn">
        <button
          className="slots-reviews-btn shared-btn-secondary"
          onClick={() => setPerPage(total)}
        >
          {t("providersPage.btn")}
        </button>
      </div>
      )}
      {perPage >= total && total > 12 && (
        <div className="slots-reviews-providers-btn">
          <button
            className="slots-reviews-btn--default shared-btn-secondary"
            onClick={() => {
              setPerPage(12)
              handleScrollToTop()
            }}
          >
            {t("providersPage.showLess")}
          </button>
        </div>
      )}
    </section>
  )
}

export default ProvidersList
import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import { mutate } from "swr"
import classNames from "classnames"

import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap"

import UserAvatar from "components/UserAvatar"

import {
  AdminPointTransaction,
  AdminPointTransactionStatus,
  useUserById,
  RejectTransactionModal, ApproveTransactionModal
} from "@modules/adminTransactions"

import { addCommasForBigNumbers } from "@utils/Strings"

type ReviewTransactionProps = {
  transaction?: AdminPointTransaction
  isOpen: boolean
  onClose: () => void
  onApply: () => void
}

const ReviewTransaction: FC<ReviewTransactionProps> = ({ onClose, transaction, isOpen }) => {
  const { t } = useTranslation()

  const { user: userInfo } = useUserById(transaction?.userId!)

  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showApproveModal, setShowApproveModal] = useState(false)

  const ApplyButton = () => (
    <Button
      cssModule={{ btn: "profile-modal-ban-btn" }}
      onClick={() => setShowApproveModal(true)}
      className="flex-cell admin-transactions-modal-cta-approve"
    >
      {t("admin-transactions.actions.approve")}
    </Button>
  )
  const RejectButton = () => (
    <Button
      cssModule={{ btn: "profile-modal-ban-btn" }}
      onClick={() => setShowRejectModal(true)}
      className="flex-cell admin-transactions-modal-cta-reject"
    >
      {t("admin-transactions.actions.reject")}
    </Button>
  )

  const getCtaButtons = () => {
    switch (transaction?.status) {
      case AdminPointTransactionStatus.PENDING:
        return (
          <div className="admin-transactions-modal-cta-wrapper">
            <RejectButton />

            <ApplyButton />
          </div>
        )
      case AdminPointTransactionStatus.APPROVED:
        return <RejectButton />
      case AdminPointTransactionStatus.REJECTED:
        return <ApplyButton />
    }
  }


  const closeBtn = (
    <button className="profile-modal-close" onClick={onClose} type="button">
      <img src="/assets/icons/close.svg" alt="close" />
    </button>
  )

  if (!userInfo) return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      contentClassName="profile-modal"
      className="profile-modal-container"
      backdrop="static"
      centered
    >
      <div className="w-100 d-flex align-items-center justify-content-center">
        <Spinner className="text-primary" />
      </div>
    </Modal>
  )

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        contentClassName="profile-modal"
        className="profile-modal-container"
        backdrop="static"
        centered
      >
        <ModalHeader
          close={closeBtn}
          cssModule={{ "modal-header": "profile-modal-header" }}
        >
          {t("profile.profile")}
        </ModalHeader>

        <ModalBody className="p-0">
          <div className="profile-modal-wrapper mt-4">
            <div className="profile-modal-user">
              <UserAvatar userDisplayName={userInfo.displayName} userImageUrl={userInfo.profileImageUrl} />

              <div className="d-flex flex-column admin-transactions-modal-content">
                <div className="profile-modal-user-name-container align-items-center">
                  <p className="admin-transactions-modal-content-name shared-text m-0">{userInfo.displayName}</p>

                  <span className="d-flex align-items-center justify-content-between shared-text faded-text ms-auto">
                    Status:
                    <span className={classNames("profile-modal-user-status shared-text", {
                      "profile-modal-user-status-pending": transaction?.status === AdminPointTransactionStatus.PENDING,
                      "profile-modal-user-status-approved": transaction?.status === AdminPointTransactionStatus.APPROVED,
                      "profile-modal-user-status-rejected": transaction?.status === AdminPointTransactionStatus.REJECTED
                    })}>
                      {transaction?.status}
                    </span>
                  </span>
                </div>

                <div className="profile-modal-user-info">
                  <p>{t("profile.joined")}: {format(new Date(userInfo.createdAt), "MM.dd.yyyy")}</p>

                  <p>{t("profile.email")}: {userInfo.email}</p>
                </div>

                <span className="profile-modal-user-balance text-nowrap width-fit-content">
                  <img src="/assets/icons/Coin.svg" alt="" />

                  {t("profile.points")}: {userInfo.pointsBalance || 0}
                </span>
              </div>
            </div>

            <div className="profile-modal-statistics">
              <div className="profile-modal-statistics-title">
                <img src="/assets/icons/statistics.svg" alt="statistics-icon" />

                <p>{t("profile.statistics")}</p>
              </div>

              <div className="d-flex w-100 align-items-center justify-content-center">
                <div className="profile-modal-statistics-container w-100">

                  <div className="profile-modal-statistics-block w-100">
                    <p className="profile-modal-statistics-block-title">{t("profile.raffles-won")}</p>

                    <img src="/assets/icons/Coin.svg" alt="coin" />

                    <p className="profile-modal-statistics-block-balance">{userInfo.rafflesWon || 0}</p>
                  </div>

                  <div className="profile-modal-statistics-block w-100">
                    <p className="profile-modal-statistics-block-title">{t("profile.points-spent")}</p>

                    <img src="/assets/icons/Coin.svg" alt="coin" />

                    <p className="profile-modal-statistics-block-balance">{userInfo.pointsSpent || 0}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="admin-transactions-modal-item d-flex flex-row align-items-center justify-content-start">
              <div className="admin-transactions-modal-item-image-wrapper">
                <img
                  src={transaction?.pointsShopItem.imageUrl}
                  alt="item"
                  className="admin-transactions-modal-item-image"
                />
              </div>

              <div
                className="admin-transactions-modal-item-content d-flex flex-column align-items-start justify-content-start"
              >
                <h6 className="admin-transactions-modal-item-title m-0">{transaction?.pointsShopItem.title}</h6>

                <p className="admin-transactions-modal-item-description text-secondary-75 shared-text m-0">
                  {transaction?.pointsShopItem.description}
                </p>

                <div
                  className="admin-transactions-modal-item-price w-100 d-flex flex-row align-items-center justify-content-start"
                >
                  <span className="text-secondary-75 shared-text">{t("admin-transactions.modal.price")}:</span>

                  <div className="admin-transactions-modal-item-price-cell">
                    <img src="/assets/icons/coin-alternative.svg" alt="coin" />

                    {addCommasForBigNumbers(transaction?.pointsShopItem.price)}
                  </div>
                </div>
              </div>
            </div>

            {transaction?.status === AdminPointTransactionStatus.REJECTED && (
              <div className="admin-transactions-modal-reason">
                <p>{t("admin-transactions.modal.rejectReason")}</p>

                <div className="admin-transactions-modal-reason-block">
                  {transaction.rejectionReason}
                </div>
              </div>
            )}

            {getCtaButtons()}

            <RejectTransactionModal
              transaction={transaction}
              onClose={() => setShowRejectModal(false)}
              isOpen={showRejectModal}
              onApply={() => {
                mutate("/admin-point-transactions")

                setShowRejectModal(false)
              }}
            />

            <ApproveTransactionModal
              transaction={transaction}
              onClose={() => setShowApproveModal(false)}
              isOpen={showApproveModal}
              onApply={() => {
                mutate("/admin-point-transactions")

                setShowApproveModal(false)
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ReviewTransaction

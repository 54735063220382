import { useEffect } from "react"

import { usePlainSWRNoMount } from "@hooks/usePlainSWR"

import { Bonus, updateItemOrderRequest, getAllBonuses } from "@modules/adminBonuses"
import { REGION } from "@context/Region"

export const useAllBonuses = (withAllBonuses: boolean = false, region?: REGION) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount("/all-bonuses", () => (withAllBonuses && region ? getAllBonuses(region) : null))

  const bonuses: Bonus[] = (data || [])

  useEffect(() => {
    refresh()
  }, [region])

  const saveAllBonusesNewOrder = async (items: Bonus[]) => {
    const oldOrders: number[] = bonuses.map(({ order }) => order)
    const payload: { order: number, id: string }[] = items.map(({ id }, index) => ({ id, order: oldOrders[index] }))

    await updateItemOrderRequest(payload)

    // await refresh()
  }

  return {
    bonuses,
    loading: !error && !data,
    error,
    refresh,
    isValidating,

    saveAllBonusesNewOrder
  }
}

export default useAllBonuses

import React, { FC, useState } from "react"

import { Button, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"

import axios from "network"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import { blockInvalidCharForInt } from "@utils/Decimals"
import { useTranslation } from "react-i18next"

type TogglePointsByIdModalProps = {
  onClose: () => void
  onSubmit: () => void
  defaultId: string
}

const setPointsById = (id: string, newBalance: number) => axios.put(`/users/${id}/points`, {
  newBalance
}, {
  withCredentials: true
})

const TogglePointsByIdModal: FC<TogglePointsByIdModalProps> = ({ onClose, onSubmit, defaultId }) => {
  const {t} = useTranslation()
  const toast = useToast()

  const [loading, setLoading] = useState<boolean>(false)
  const [amount, setAmount] = useState<string>("")

  const handleOnSubmit = async () => {
    if (parseInt(amount) < 0) {
      toast("The value should be positive", TOAST_TYPE.ERROR)

      return
    }
    setLoading(true)
    try {
      await setPointsById(defaultId, parseInt(amount))

      toast("Balance successfully updated!", TOAST_TYPE.SUCCESS)

      onSubmit()
    } catch (e) {
      toast("Something went wrong!", TOAST_TYPE.ERROR)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="position-relative">
      <Modal
        isOpen
        toggle={onClose}
        cssModule={{ "modal-dialog": "points" }}
        contentClassName="content"
        fade={false}
      >
        <ModalHeader cssModule={{ "modal-header": "points-title" }}>
          {t("profile.togglePointsByIdModal.title")}
        </ModalHeader>

        <ModalBody className="points-text">
          <InputGroup className="position-relative" cssModule={{ "input-group": "none" }}>
            <label htmlFor="points" className="points-text-label">
              {t("profile.togglePointsByIdModal.label")}
            </label>

            <Input
              name="points"
              value={amount}
              onKeyDown={blockInvalidCharForInt}
              type="number"
              min={0}
              placeholder="Points"
              onChange={e => setAmount(e.target.value)}
              cssModule={{ "form-control": "points-text-input" }}
            />
          </InputGroup>
        </ModalBody>

        <ModalFooter color="secondary" className="points-footer">
          <Button disabled={loading} cssModule={{ btn: "points-cancel" }} onClick={onClose}>
            {t("profile.togglePointsByIdModal.btnCancel")}
          </Button>

          <button disabled={loading} type="button" className="points-confirm-btn shared-btn-primary" onClick={handleOnSubmit}>
            {loading ? <Spinner size="sm" color="white" /> : t("profile.togglePointsByIdModal.btnConfirm")}
          </button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default TogglePointsByIdModal

import React, { FC, useState } from "react"
import Pagination from "components/Pagination"

import classNames from "classnames"
import { useTranslation } from "react-i18next"
import { AdminLeaderboardUsers, BanUserInLeaderboard, UnbanUserInLeaderboard } from "@modules/adminLeaderboard"
import { leaderboardTieredPrize } from "@modules/leaderboards"
import { addCommasForBigNumbers } from "@utils/Strings"


type AdminLeaderboardTableProps = {
  total: number
  tiredPrizes?: leaderboardTieredPrize[],
  data: AdminLeaderboardUsers[],
  isActive: boolean | undefined,
  page: number,
  perPage: number,
  setPage: (page: number) => void
  leaderboardId?: string,
  refreshUsers?: () => void
}
const AdminLeaderboardTable: FC<AdminLeaderboardTableProps> = ({
  tiredPrizes,
  data,
  isActive,
  setPage,
  perPage,
  page,
  leaderboardId,
  refreshUsers,
  total
}) => {
  const { t } = useTranslation()
  const [userToUnban, setUserToUnban] = useState<AdminLeaderboardUsers | null>(null)
  const [userToBan, setUserToBan] = useState<AdminLeaderboardUsers | null>(null)
  const handleBanUser = () => {
    setUserToBan(null)

    if (refreshUsers) {
      refreshUsers()
    }
  }

  const handleUnbanUser = () => {
    setUserToUnban(null)

    if (refreshUsers) {
      refreshUsers()
    }
  }

  return (
    <div className="w-100 d-flex flex-column align-items-center">
      {data.map((user, index) => {
          const place = index + 1 + (perPage * (page - 1))
          return (
            <div className={classNames("shared-table-container w-100 d-flex flex-row admin-current-leaderboard-table-row",
              { "admin-current-leaderboard-table-row-banned": user.banned },
                { "admin-current-leaderboard-table-row-first": place === 1 },
                { "admin-current-leaderboard-table-row-second": place === 2 },
                { "admin-current-leaderboard-table-row-third": place === 3 })} key={user.id}
            >
              <div className="admin-current-leaderboard-table-cell d-flex position-relative admin-current-leaderboard-table-cell--user">
                <div className={classNames("admin-current-leaderboard-table-cell-place",
                  { "admin-current-leaderboard-table-cell-place-first": place === 1 },
                  { "admin-current-leaderboard-table-cell-place-second": place === 2 },
                  { "admin-current-leaderboard-table-cell-place-third": place === 3 },
                  { "admin-current-leaderboard-table-cell-place-banned": user.banned }
                )}
                >
                  {place}
                </div>

                <div className="">
                  {user.displayName}
                </div>
              </div>

              <div>
                <div className={classNames("d-flex justify-content-center admin-current-leaderboard-table-cell admin-current-leaderboard-table-cell--wagered",
                  { "admin-current-leaderboard-table-cell-ended": !isActive })}
                >
                  <img src="/assets/icons/coin-small.svg" alt="coin" />

                  {addCommasForBigNumbers(parseFloat(user.wageredTotal))}
                </div>
              </div>

              <div>
                <div className={classNames("d-flex justify-content-center admin-current-leaderboard-table-cell admin-current-leaderboard-table-cell--prize",
                    { "admin-current-leaderboard-table-cell-ended": !isActive })}
                >
                  ${tiredPrizes && tiredPrizes.length >= place ? tiredPrizes[place - 1].amount : 0}
                </div>
              </div>

              {isActive ? <div>
                <div
                  className="admin-current-leaderboard-table-cell--ban admin-current-leaderboard-table-cell d-flex align-items-end">
                  <button
                    type="button"
                    className="admin-current-leaderboard-ban-btn"
                    onClick={() => (user.banned ? setUserToUnban(user) : setUserToBan(user))}
                  >
                    {user.banned ? t("admin-leaderboard.current.btnUnban") : t("admin-leaderboard.current.btnBan")}
                  </button>
                </div>
              </div> : null}
            </div>
          )
        })}


      <div className="admin-current-leaderboard-table-pagination">
        <Pagination
          rowCount={Math.ceil(total / perPage) || 1}
          currentPage={page}
          onChangePage={setPage}
          rowsPerPage={1}
        />
      </div>

      <BanUserInLeaderboard
        isOpen={!!userToBan}
        leaderboardId={leaderboardId}
        user={userToBan}
        onClose={() => setUserToBan(null)}
        onApply={handleBanUser}
      />

      <UnbanUserInLeaderboard
        isOpen={!!userToUnban}
        leaderboardId={leaderboardId}
        user={userToUnban}
        onClose={() => setUserToUnban(null)}
        onApply={handleUnbanUser}
      />
    </div>
  )
}

export default AdminLeaderboardTable

import { FC, ReactElement } from "react"

type BonusHuntTopStatisticCardProps = {
  value: any
  children: ReactElement
  topStatistic?: boolean
}

const BonusHuntStatisticCard: FC<BonusHuntTopStatisticCardProps> = ({ value, children, topStatistic }) => {
  return (
    <div className={`bonus-hunt-statistic${topStatistic ? "-top" : ""}-card`}>
      <div className="bonus-hunt-statistic-value">
        {value}
      </div>

      <div className="bonus-hunt-statistic-text shared-text white shared-text--extra-small text-uppercase">
        {children}
      </div>
    </div>
  )
}

export default BonusHuntStatisticCard

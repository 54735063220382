import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { RaffleWinner, WinnerCard } from "@modules/raffles"

type RaffleWinnersProps = {
  winners: RaffleWinner[]
}

const RaffleWinners: FC<RaffleWinnersProps> = ({ winners }) => {
  const { t } = useTranslation()

  if (!winners) {
    return null
  }

  return (
    <section className="">
      <h3 className="selected-raffle-entries-card-winner-title text-center">
        {t("raffles.selectedRaffle.winners.title")}
      </h3>

      <div className="selected-raffle-entries-card-winner-wrapper">
        {winners.map((winner) => (
          <WinnerCard winner={winner} key={winner.id + winner.displayName} showEntriesCount />
        ))}
      </div>
    </section>
  )
}

export default RaffleWinners

import { FC } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import classNames from "classnames"

import { ReactComponent as DiscordIcon } from "@src/assets/icons/discord.svg"
import { ReactComponent as TwitterIcon } from "@src/assets/icons/twitter.svg"
import { ReactComponent as YoutubeIcon } from "@src/assets/icons/youtube.svg"

import "./styles.scss"

type FooterProps = {
  className?: string
}

const Footer: FC<FooterProps> = ({ className }) => {
  const { t } = useTranslation()

  return (
    <footer className={classNames("custom-footer-wrapper shared-container", className)}>
      <div className="custom-footer">
        <div className="custom-footer-content">
          <div className="custom-footer-content-title d-flex flex-column">
            <div className="d-flex flex-row align-items-center justify-content-start">
              <img src="/assets/images/logo.svg" alt="logo" className="custom-footer-logo" />

              <p className="custom-footer-logo-text mb-0">{t("footer.title")}</p>
            </div>

            <p className="custom-footer-text shared-text mb-0">
              {t("footer.description1")}
            </p>
          </div>

          <div className="custom-footer-content-explore d-flex flex-column">
            <p className="custom-footer-title">{t("footer.links.explore")}</p>

            <Link to="/bonuses" className="custom-footer-link">{t("footer.links.bonuses")}</Link>

            {/* <Link to="/leaderboards" className="custom-footer-link">{t("footer.links.leaderboard")}</Link> */}

            <Link to="/points-store" className="custom-footer-link">{t("footer.links.pointsStore")}</Link>

            <Link to="/raffles" className="custom-footer-link">{t("footer.links.raffles")}</Link>

            <Link to="/bonus-hunt" className="custom-footer-link">{t("footer.links.bonusHunt")}</Link>
          </div>

          <div className="custom-footer-content-about d-flex flex-column">
            <div>
              <p className="custom-footer-title">{t("footer.links.about")}</p>

              {/* <Link to="/" className="custom-footer-link">{t("footer.links.contact")}</Link> */}

              <Link to="https://www.begambleaware.org/" target="_blank" className="custom-footer-link">
                {t("footer.links.gamblingHelp")}
              </Link>

              <Link to="/faq" className="custom-footer-link">FAQ</Link>

              <Link to="/terms" className="custom-footer-link">{t("footer.links.termsOfService")}</Link>

              <Link to="/privacy" className="custom-footer-link">{t("footer.links.privacyPolicy")}</Link>
            </div>
          </div>

          <div className="custom-footer-content-socials d-flex flex-column">
            <p className="custom-footer-title">{t("footer.links.stayUpdated")}</p>

            <div className="d-flex flex-row">
              <a href="https://discord.gg/haddzy" target="_blank" className="custom-footer-social">
                <DiscordIcon />
              </a>

              <a href="https://www.youtube.com/channel/UCOKNEzhIAvZDaP805jUkGEQ" target="_blank"
                 className="custom-footer-social mx-2">
                <YoutubeIcon />
              </a>

              <a href="https://twitter.com/Haddzy_" target="_blank" className="custom-footer-social">
                <TwitterIcon />
              </a>
            </div>
          </div>
        </div>

        <hr className="custom-footer-separator" />

        <p className="custom-footer-caption shared-text ">
          {t("footer.description2")}
        </p>
      </div>
    </footer>
  )
}

export default Footer

import { FC } from "react"
import "./styles.scss"
import classNames from "classnames"

type SwitchProps = {
  id: string
  isOn: boolean
  handleToggle: () => void
  isDisabled?: boolean
}

const Switch: FC<SwitchProps> = ({ isOn, handleToggle, id, isDisabled }) => {
  return (
    <>
      <input
        onChange={() => handleToggle()}
        className={classNames("react-switch-checkbox", { "react-switch-checkbox-checked": isOn })}
        id={id}
        type="checkbox"
        disabled={isDisabled}
      />

      <label className={classNames("react-switch-label", { "react-switch-label-off": !isOn })} htmlFor={id}>
        <span className="react-switch-button" />
      </label>
    </>
  )
}

export default Switch

import mock, { throttling } from "../mock"

mock.onGet("/slots-reviews/top").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: 11,
        title: "Wanted Dead or W...",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Gates of Olympus",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Angry Fruits",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 4
      },
      {
        id: 11,
        title: "Sugar Rush",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 4
      },
      {
        id: 11,
        title: "Book of Shadows",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      }
    ]
  ]
})

mock.onGet("/slots-reviews/featured-providers").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Nolimit City"
      },
      {
        id: 115,
        imgUrl: "/assets/images/pragmatic.png",
        name: "Pragmatic play"
      },
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Nolimit City"
      },
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Pragmatic play"
      },
      {
        id: 115,
        imgUrl: "/assets/images/pragmatic.png",
        name: "Pragmatic play"
      },
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Nolimit City"
      },
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Nolimit City"
      },
      {
        id: 115,
        imgUrl: "/assets/images/pragmatic.png",
        name: "Pragmatic play"
      },
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Nolimit City"
      },
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Pragmatic play"
      },
      {
        id: 115,
        imgUrl: "/assets/images/pragmatic.png",
        name: "Pragmatic play"
      },
      {
        id: 115,
        imgUrl: "/assets/images/nolimit.png",
        name: "Nolimit City"
      }
    ]
  ]
})

mock.onGet("/slots-reviews/all-slots").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: 11,
        title: "Wanted Dead or Wild",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Gates of Olympus",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Angry Fruits",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 4
      },
      {
        id: 11,
        title: "Sugar Rush",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 4
      },
      {
        id: 11,
        title: "Book of Shadows",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Wanted Dead or W...",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Gates of Olympus",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Angry Fruits",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 4
      },
      {
        id: 11,
        title: "Sugar Rush",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 4
      },
      {
        id: 11,
        title: "Book of Shadows",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      },
      {
        id: 11,
        title: "Sugar Rush",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 4
      },
      {
        id: 11,
        title: "Book of Shadows",
        subtitle: "Hacksaw Gaming",
        imgUrl: "/assets/images/slot-img.png",
        link: "",
        rating: 5
      }
    ]
  ]
})

mock.onGet("/slots-reviews/providers/115", { params: { id: "115" } }).reply(async () => {
  await throttling()

  return [
    200,
    {
      id: 115,
      imgUrl: "/assets/images/nolimit.png",
      name: "nolimit city",
      slots: [
        {
          id: 11,
          title: "Wanted Dead or Wild",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        },
        {
          id: 11,
          title: "Gates of Olympus",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        },
        {
          id: 11,
          title: "Angry Fruits",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 4
        },
        {
          id: 11,
          title: "Sugar Rush",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 4
        },
        {
          id: 11,
          title: "Book of Shadows",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        },
        {
          id: 11,
          title: "Wanted Dead or W...",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        }
      ],
      description: "Popiplay is a Scandinavian iGaming brand that presents players with unique game mechanics, big jackpots, engaging gameplay, and extraordinary bonus rounds. Founded in 2022, Popiplay is offering everything a modern slots player needs and wants!\n" +
        "All online casino games in Popiplay's portfolio are mobile-focused so players can enjoy any of them on the go! Also, the provider is supporting all fiat and crypto currencies on the market. The RTP values are adjustable, multiple languages supported, and there is a bonus buy option for all the players who want to skip the base game and get to the free spins immediately. One-of-a-kind slot features such as Dynamic Reels and Chance 2x & 3x are boosting the gaming experience.\n" +
        "Popiplay slots are there to provide the iGaming community with best online gambling experience possible! Great slot themes, amazing visuals and sound effects, and powerful and modern reels with huge max wins and highly entertaining gameplay are appealing to the players world wide.",
      attributes: {
        established: 2013,
        numberOfSlots: 109,
        countires: 49,
        popularSlots: [
          {
            title: "book of shadows",
            link: ""
          },
          {
            title: "king of abobus",
            link: ""
          },
          {
            title: "sugar rush",
            link: ""
          }
        ],
        gameTypes: ["video slots", "table games"],
        licenses: {
          text: "Curacao eGaming",
          link: ""
        }
      }
    }
  ]
})

mock.onGet("/slots-reviews/slot/11", { params: { id: "11" } }).reply(async () => {
  await throttling()

  return [
    200,
    {
      id: 11,
      imgUrl: "/assets/images/slot-img.png",
      title: "Book of Shadows",
      subtitle: "Hacksaw Gaming",
      link: "",
      rating: 5,
      ratingUsers: 4,
      usersVoted: 112,
      about: "Get your horses saddled up and revolvers all loaded, you are going to experience the darker side of the Old American West! In the Wanted Dead or a Wild slot, Hacksaw Gaming has decided to elevate playing experience in more ways than one. So, get ready for a very dynamic but also possibly extremely rewarding gameplay. Hopefully, you’ll get to ride off into the sunset as a very happy and immensely rich man.",
      features: "There are many things to look forward to in the Wanted Dead or a Wild slot. Hacksaw Gaming studio has already had some big hits and proven itself as an innovative force in the industry. This time around they have taken a classical wild west theme and used vivid and dark colors, as well as an immersive soundtrack, to depict the real atmosphere of this famous historical period.\n" +
        "The bet range for players who want to spin these reels goes from £/€0.20 to £/€100 per spin. General volatility is set high but what makes this game unique is the fact that 3 bonus games have their individual volatility levels. The provider has opted for a 5x5 grid layout with 15 fixed paylines and if a player manages to line 5 premium symbols, they cash in 20x the bet. Besides substituting for all regular symbols, Wild is also part of the paytable and brings up to 20x the bet.\n" +
        "Appearing in base and Duel at Dawn bonus game, the VS symbol turns into the expanding multiplier wild that can carry up to 100x and make your balance skyrocket in a matter of seconds. The game also comes with 3 different scatters, each one of them responsible for activating a specific bonus game.\n" +
        "The Great Train Robbery bonus comes with medium volatility, 10 free spins, and sticky wilds. Duel at Dawn brings 10 highly volatile free spins and much more frequent appearance of the VS symbols. Also highly volatile, Dead Man’s Hand bonus game is divided into 2 parts. First players get 3 spins and a grid where only multipliers and wilds can land. Each time that happens the number of spins is reset back to 3. Once that part is over, it’s time for a Showdown! You get 3 spins and the total number of collected wilds and multipliers is added and applied to the reels on each spin.",
      haddzyTake: "One word - Amazing!!! Well, you know that there are 18 million reasons (wink, wink!) why Wanted Dead or a Wild slot has a special place in my heart and casino streaming career. After all, it was the win that broke all the records! But, in all honesty, it's one of the best casino games to ever land on the iGaming market. It’s definitely the BIGGEST positive shock in the iGaming world for the year 2021.\n" +
        "This video slot really has everything a player could wish for - sharp visuals, amped up base game, and 3 different bonus rounds with sticky wilds, special VS symbols and multipliers. With a winning potential set at 12,500x the bet, spinning these reels can certainly pay off big time! And the fact that you can land max win in the base game is why it’s one of my favorites.\n" +
        "However, the downside to this is that you can finish the bonus round with 0x the bet so I don’t recommend buying bonuses here. But spin for it! Sometimes all it takes is one spin in the base game and you can feel the magnificent power of the Wanted Dead or a Wild slot.",
      info: {
        rtp: 95.05,
        volatility: "high",
        maxPotential: 30000,
        minBet: 0.4,
        maxBet: 400,
        payLines: 1241241,
        reels: "6x5",
        released: "2023-09-15T19:00:52Z"
      },
      best: {
        win: "($55) $550.000",
        x: "($55) 10.000x"
      },
      comments: [
        {
          id: 345678,
          date: "2023-11-15T19:00:52Z",
          userId: "58173445-3754-4ec1-a551-747c4f6324cb",
          userImageUrl: "https://lh3.googleusercontent.com/a/ACg8ocKjqZ5hmzseFs1XHrP1hCHX42bLwLou8A8fM_AgZbfn=s96-c",
          displayName: "Андрій Коник",
          likeCount: 5,
          isLiked: true,
          text: "Sometimes all it takes is one spin in the base game and you can feel the magnificent power of the Wanted Dead or a Wild slot.",
          rating: 4
        },
        {
          id: 435354,
          date: "2023-11-27T19:00:52Z",
          userId: "58173445-3754-4ec1-a551-747c4f6324cb",
          userImageUrl: "https://lh3.googleusercontent.com/a/ACg8ocKjqZ5hmzseFs1XHrP1hCHX42bLwLou8A8fM_AgZbfn=s96-c",
          displayName: "Андрій Коник11",
          likeCount: 5,
          isLiked: false,
          text: "Sometimes all it takes is one spin in the base game and you can feel the magnificent power of the Wanted Dead or a Wild slot.",
          rating: 5
        },
        {
          id: 6523134,
          date: "2023-11-28T12:00:52Z",
          userId: "58173445-3754-4ec1-a551-747c4f6324cb",
          userImageUrl: "https://lh3.googleusercontent.com/a/ACg8ocKjqZ5hmzseFs1XHrP1hCHX42bLwLou8A8fM_AgZbfn=s96-c",
          displayName: "Андрій Коник22",
          likeCount: 1,
          isLiked: false,
          text: "Sometimes all it takes is one spin in the base game and you can feel the magnificent power of the Wanted Dead or a Wild slot.",
          rating: 1
        }
      ],
      recommendedSlots: [
        {
          id: 11,
          title: "Wanted Dead or Wild",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        },
        {
          id: 11,
          title: "Gates of Olympus",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        },
        {
          id: 11,
          title: "Angry Fruits",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 4
        },
        {
          id: 11,
          title: "Sugar Rush",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 4
        },
        {
          id: 11,
          title: "Book of Shadows",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        },
        {
          id: 11,
          title: "Wanted Dead or W...",
          subtitle: "Hacksaw Gaming",
          imgUrl: "/assets/images/slot-img.png",
          link: "",
          rating: 5
        }
      ]
    }
  ]
})
import axios from 'network'

import {
  GetLootBoxFullQuery,
  GetLootBoxItemsQuery,
  GetLootBoxesFullQuery,
  LootBox,
  LootBoxItem,
  MutationResponse,
  PaginatedResponse,
  PutItemsToLootBoxDTO
} from '@modules/lootBox'

export const fetchAdminLootBoxesFull = async (payload: GetLootBoxesFullQuery) => {
  const res = await axios.get('/loot-box/full', {
    params: {
      ...payload,
      ...(payload?.text ? { text: payload.text } : {}),
      ...(payload?.tag ? { tag: payload.tag } : {})
    },
    withCredentials: true
  })

  return {
    total: res?.data?.total,
    data: res?.data?.data
  } as PaginatedResponse<LootBox>
}

export const fetchAdminLootBoxFull = async (payload: GetLootBoxFullQuery) => {
  const res = await axios.get(`/loot-box/${payload.id}/full`, {
    params: {
      withItems: payload.withItems
    },
    withCredentials: true
  })

  return {
    data: res?.data?.data ?? null
  }
}

export const fetchAdminLootBoxItems = async (payload: GetLootBoxItemsQuery) => {
  const res = await axios.get('/loot-box/item', {
    params: {
      ...payload,
      ...(payload?.text ? { text: payload.text } : {})
    },
    withCredentials: true
  })

  return {
    total: res?.data?.total,
    data: res?.data?.data
  } as PaginatedResponse<LootBoxItem>
}

export const saveAdminLootBoxCaseItems = async (payload: PutItemsToLootBoxDTO) => {
  const body = {
    items: payload.items
  }
  const res = await axios.post(`/loot-box/${payload.lootBoxId}/item`, body, {
    withCredentials: true
  })

  return {
    success: res?.data?.success
  } as MutationResponse
}

export const restockAdminLootBoxItem = async (lootBoxItemId: number) => {
  const res = await axios.post(`/loot-box/item/${lootBoxItemId}/restock`, undefined, {
    withCredentials: true
  })

  return {
    success: res?.data?.success
  } as MutationResponse
}

export const restockAdminLootBoxAllItems = async () => {
  const res = await axios.post(`/loot-box/item/all/restock`, undefined, {
    withCredentials: true
  })

  return {
    success: res?.data?.success
  } as MutationResponse
}

import { User } from "@hooks/useUser"
import { PointCard } from "@modules/pointsStore"

export enum TABLE_ORDER {
  ASC = "asc",
  DESC = "desc"
}

export enum AdminPointTransactionSort {
  DATE = "createdAt", UPDATE = "updatedAt", QUANTITY = "quantity", PRICE = "price"
}

export enum AdminPointTransactionStatus {
  ALL = "ALL",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
}

export type AdminPointTransactionsFilterPayload = {
  sortBy: AdminPointTransactionSort
  sortOrder: TABLE_ORDER
  status?: AdminPointTransactionStatus
  search?: string
}
export type AdminPointTransaction = {
  id: number,
  userId: string,
  createdAt: Date
  updatedAt: Date
  status: AdminPointTransactionStatus,
  pointsShopItemId: number,
  rejectionReason: null,
  user: User,
  pointsShopItem: PointCard
}

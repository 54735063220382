import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Spinner } from "reactstrap"

import { ReactComponent as StarIcon } from "assets/icons/star.svg"

import { TopSlotCard, useTopSlots } from "@modules/slotsReviews"

type TopPicksProps = {}

const TopPicks: FC<TopPicksProps> = ({}) => {
  const { t } = useTranslation()
  const { topSlotsList, loading } = useTopSlots(5)

  return (
    <section className="slots-reviews-top-picks">
      {/*<div className="slots-reviews-top-picks-search">
        <SearchInput
          onChange={(s) => onFiltered({ search: s })}
          value={filter?.search}
        />
      </div>*/}

      <div className="slots-reviews-top-picks-tilte shared-text text-secondary-100 font-weight-700 text-uppercase">
        <div className="d-flex align-items-center">
          <StarIcon className="text-yellow" />
        </div>

        <div className="d-flex flex-nowrap">
          &nbsp;
          {t("slotsReviews.topPicksSection.title1")}
          &nbsp;
        </div>

        <div className="text-yellow flex-nowrap">
          {t("slotsReviews.topPicksSection.title2")}
        </div>
        &nbsp;{t("slotsReviews.topPicksSection.title3")}
      </div>

      <div className="slots-reviews-top-picks-list">
        {loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          topSlotsList.length === 0 ? (
            <div className="slots-reviews-top-picks-list-stub shared-stub">
              <p className="shared-stub-text">
                {t("slotsReviews.stub")}
              </p>
            </div>
          ) : (
            topSlotsList.slice(0, 5).map((card) => (
              <TopSlotCard
                card={card}
                key={card.id}
              />
            ))
        ))}
      </div>
    </section>
  )
}

export default TopPicks

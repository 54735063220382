import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import axios from "@src/network"
import { Modal, Spinner } from "reactstrap"
import ModalInput from "@src/components/ModalInput"
import ImageUploader from "@src/components/ImageUploader"

import { RaffleCardForm, setupRaffleCardFormSchema } from "@modules/adminRaffles"
import { RaffleCardEntity } from "@modules/raffles"
import classNames from "classnames"
import Switch from "@src/components/Switch"

type CreateNewRaffleModalProps = {
  item?: RaffleCardEntity
  isOpen: boolean
  onClose: () => void
  onApply: () => void
}

const getTruncatedValue = (e: string): string => (e.length === 2 && /^0[0-9]$/.test(e) ? e.slice(1) : e)

const CreateNewRaffleModal: FC<CreateNewRaffleModalProps> = ({
  item,
  isOpen,
  onClose,
  onApply
}) => {
  const { t } = useTranslation()

  const defaultRaffleCard = {
    winnersCount: 1,
    maxEntries: 0,
    maxEntriesPerUser: 0,
    ...item
  }

  const [loading, setLoading] = useState<boolean>(false)
  const [maxEntriesSwitch, setMaxEntriesSwitch] = useState<boolean>(true)
  const [maxEntriesPerUserSwitch, setMaxEntriesPerUserSwitch] = useState<boolean>(true)

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid }
  } = useForm<RaffleCardForm>({
    defaultValues: defaultRaffleCard,
    mode: "onChange",
    resolver: yupResolver(setupRaffleCardFormSchema())
  })

  useEffect(() => {
    if (isOpen) {
      reset(defaultRaffleCard)
      setMaxEntriesSwitch(defaultRaffleCard.maxEntries === 0)
      setMaxEntriesPerUserSwitch(defaultRaffleCard.maxEntriesPerUser === 0)
    }
    if (!item) {
      reset()
    }
  }, [item, isOpen])

  const onSubmit = async (data: RaffleCardForm) => {
    setLoading(true)

    const formData = new FormData()

    formData.append("title", data.title)
    formData.append("description", data.description)
    formData.append("entryCost", data.entryCost ? data.entryCost.toString() : "0")
    formData.append("prizeInDollars", data.prizeInDollars ? data.prizeInDollars.toString() : "0")
    if (typeof data.imageUrl === "object") {
      formData.append("file", data.imageUrl)
    } else {
      formData.append("imageUrl", data.imageUrl)
    }
    formData.append("durationDays", data.durationDays ? data.durationDays.toString() : "0")
    formData.append("durationHours", data.durationHours ? data.durationHours.toString() : "0")
    formData.append("durationMinutes", data.durationMinutes ? data.durationMinutes.toString() : "0")
    formData.append("winnersCount", data.winnersCount.toString())
    formData.append("maxEntries", data.maxEntries.toString())
    formData.append("maxEntriesPerUser", data.maxEntriesPerUser.toString())

    if (item) {
      await axios.put(`/raffles/${item.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
        withCredentials: true
      })
    } else {
      await axios.post("/raffles/create", formData, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
        withCredentials: true
      })
    }
    setLoading(false)

    reset(data)

    onApply()

    onClose()
  }

  const descriptions: {
    key: keyof RaffleCardForm,
    label: string
  }[] = [
    { key: "entryCost", label: t("admin-raffles.createNewRaffleModal.entryCost") },
    { key: "prizeInDollars", label: t("admin-raffles.createNewRaffleModal.priceInDollars") },
    { key: "durationDays", label: t("admin-raffles.createNewRaffleModal.days") },
    { key: "durationHours", label: t("admin-raffles.createNewRaffleModal.hours") },
    { key: "durationMinutes", label: t("admin-raffles.createNewRaffleModal.minutes") }
  ]

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper small"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close rounded" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <h2 className="admin-bonuses-modal-title admin-bonuses-modal-text">
        {!!item ? t("admin-raffles.createNewRaffleModal.headlineEdit") : t("admin-raffles.createNewRaffleModal.headlineCreate")}
      </h2>

      <form className="admin-bonuses-modal-content" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={field.onChange}
              label={t("admin-raffles.createNewRaffleModal.title")}
              className="admin-bonuses-modal-space"
              inputClassNames={classNames("", { "admin-raffles-modal-edit-input": !!item })}
              placeholder={t("admin-raffles.createNewRaffleModal.titlePlaceholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={field.onChange}
              label={t("admin-raffles.createNewRaffleModal.description")}
              className="admin-bonuses-modal-space"
              inputClassNames={classNames("", { "admin-raffles-modal-edit-input": !!item })}
              placeholder={t("admin-raffles.createNewRaffleModal.descriptionPlaceholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="entryCost"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={(e) => field.onChange(getTruncatedValue(e))}
              className="admin-bonuses-modal-space"
              inputClassNames={classNames("admin-raffles-modal-input", { "admin-raffles-modal-edit-input": !!item })}
              label={t("admin-raffles.createNewRaffleModal.entryCost")}
              placeholder="0"
              isDisabled={!!item}
            />
          )}
        />

        <Controller
          control={control}
          name="prizeInDollars"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={(e) => field.onChange(getTruncatedValue(e))}
              className="admin-bonuses-modal-space"
              inputClassNames={classNames("admin-raffles-modal-input", { "admin-raffles-modal-edit-input": !!item })}
              label={t("admin-raffles.createNewRaffleModal.priceInDollars")}
              placeholder="0"
            />
          )}
        />

        <p className="admin-bonuses-modal-text admin-bonuses-modal-space">
          {t("admin-raffles.createNewRaffleModal.logo")}
        </p>

        <Controller
          control={control}
          name="imageUrl"
          render={({ field }) => (
            <ImageUploader
              inputClassNames={classNames("admin-raffles-ed", { "admin-raffles-modal-edit-img-uploader": !!item })}
              className="admin-bonuses-modal-space"
              url={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <div className="admin-bonuses-modal-space admin-raffles-modal-input-time-wrapper ">
          {descriptions.slice(2).map(({ key, label }) => (
            <Controller
              control={control}
              name={key}
              key={key}
              render={({ field }) => (
                <ModalInput
                  value={field.value}
                  onChange={(e) => field.onChange(getTruncatedValue(e))}
                  className="admin-raffles-modal-input"
                  inputClassNames={classNames("admin-raffles-modal-input", { "admin-raffles-modal-edit-input": !!item })}
                  label={label}
                  placeholder="0"
                  isDisabled={!!item}
                />
              )}
            />
          ))}
        </div>

        <Controller
          control={control}
          name="winnersCount"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={(e) => field.onChange(getTruncatedValue(e))}
              className="admin-bonuses-modal-space"
              inputClassNames={classNames("", { "admin-raffles-modal-edit-input": !!item })}
              label={t("admin-raffles.createNewRaffleModal.winnersCount")}
              placeholder="0"
              isDisabled={!!item}
            />
          )}
        />

        <div className="admin-raffles-modal-input-with-switch">
          <Controller
            control={control}
            name="maxEntries"
            render={({ field }) => (
              <>
                <ModalInput
                  value={field.value}
                  onChange={(e) => field.onChange(getTruncatedValue(e))}
                  className={classNames("admin-raffles-modal-input", { "admin-raffles-modal-input-unlimited": maxEntriesSwitch })}
                  inputClassNames={classNames("", { "admin-raffles-modal-edit-input": !!item })}
                  label={t("admin-raffles.createNewRaffleModal.maxEntries")}
                  placeholder="0"
                  isDisabled={!!item || maxEntriesSwitch}
                />

                <Switch
                  id="maxEntries"
                  isOn={maxEntriesSwitch}
                  isDisabled={!!item}
                  handleToggle={() => {
                    setValue("maxEntries", 0)
                    setMaxEntriesSwitch(!maxEntriesSwitch)
                  }}
                />
              </>
            )}
          />
        </div>

        <div className="admin-raffles-modal-input-with-switch">
          <Controller
            control={control}
            name="maxEntriesPerUser"
            render={({ field }) => (
              <>
                <ModalInput
                  value={field.value}
                  onChange={(e) => field.onChange(getTruncatedValue(e))}
                  className={classNames("admin-raffles-modal-input", { "admin-raffles-modal-input-unlimited": maxEntriesPerUserSwitch })}
                  inputClassNames={classNames("", { "admin-raffles-modal-edit-input": !!item })}
                  label={t("admin-raffles.createNewRaffleModal.maxEntriesPerUser")}
                  placeholder="0"
                  isDisabled={!!item || maxEntriesPerUserSwitch}
                />

                <Switch
                  id="maxEntriesPerUser"
                  isOn={maxEntriesPerUserSwitch}
                  isDisabled={!!item}
                  handleToggle={() => {
                    setValue("maxEntriesPerUser", 0)
                    setMaxEntriesPerUserSwitch(!maxEntriesPerUserSwitch)
                  }}
                />
              </>
            )}
          />
        </div>

        <button
          disabled={loading || !isValid}
          className="shared-btn shared-btn-primary w-100 admin-bonuses-modal-submit admin-bonuses-modal-space"
        >
          {loading ? (
            <Spinner color="secondary" size="sm" />
          ) : (
            t("admin-bonuses.modal.save")
          )}
        </button>
      </form>
    </Modal>
  )
}

export default CreateNewRaffleModal

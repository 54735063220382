import { lazy } from 'react'

import '@modules/lootBox'

import './views/admin-loot-boxes.scss'

import en from './locales/en.json'
import ru from './locales/ru.json'

export const AdminLootBoxes = lazy(() => import('./views/AdminLootBoxes'))
export const AdminLootBox = lazy(() => import('./views/AdminLootBox'))
export const AdminLootBoxItems = lazy(() => import('./views/AdminLootBoxItems'))

export * from './requests'

export * from './useAdminLootBoxFull'
export * from './useAdminLootBoxesFull'

export { default as AdminLootBoxCard } from './components/AdminLootBoxCard'
export { default as AdminLootBoxesList } from './components/AdminLootBoxesList'
export { default as AdminLootBoxItemsList } from './components/AdminLootBoxesList'
export { default as ConfirmDeleteModal } from './components/ConfirmDeleteModal'
export const locales = {
  en,
  ru
}

import React, { FC, useState } from "react"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"

import { addCommasForBigNumbers } from "@utils/Strings"
import { JoinRaffleModal, RaffleCardEntity, useUserRaffleEntries, YourTicketsModal } from "@modules/raffles"

import Countdown from "@src/components/Countdown"
import { ReactComponent as HourglassIcon } from "assets/icons/hourglass.svg"
import { User } from "@hooks/useUser"
import classNames from "classnames"
import { Spinner } from "reactstrap"

type SelectedRaffleInfoProps = {
  data: RaffleCardEntity
  dataLoading?: boolean
  user: User
  isRaffleEnded: boolean
  raffleId: number
  setShowProofModal?: any
  refreshSelectedRaffleEntries?: () => void
  refreshRaffleById?: () => void
  triggerLoginModal?: () => void
  showEntryCost?: boolean
}

const SelectedRaffleInfo: FC<SelectedRaffleInfoProps> = ({
  data,
  isRaffleEnded,
  user,
  setShowProofModal,
  raffleId,
  refreshSelectedRaffleEntries,
  triggerLoginModal,
  refreshRaffleById,
  showEntryCost,
  dataLoading
}) => {
  const { t } = useTranslation()
  const { entry, loading: entryLoading, refresh } = useUserRaffleEntries(raffleId, user)

  const [currentCard, setCurrentCard] = useState<RaffleCardEntity | null>(null)
  const [yourTicketsModalIsOpen, setYourTicketsModalIsOpen] = useState(false)
  const handleJoinRaffle = () => {
    setCurrentCard(null)
    setTimeout(() => {
      refresh()
      if (refreshRaffleById && refreshSelectedRaffleEntries) {
        refreshRaffleById()
        refreshSelectedRaffleEntries()
      }
    }, 100)
  }

  if (!data && !dataLoading) return null

  return (
    <div className="d-flex flex-column w-100 align-items-center justify-content-center">
      <div className="selected-raffle-info-headline-wrapper d-flex flex-column w-100 align-items-center">
        <div className="selected-raffle-info-raffle-title">
          <p className="selected-raffle-info-headline">
            {data.title}&nbsp;
            &nbsp;<span
            className="d-flex align-items-center justify-content-center width-fit-content shared-text shared-text--extra-small text-secondary-75">#{data.id}</span>
          </p>
        </div>
        <p className="selected-raffle-info-caption wide-hide laptop-hide mt-2">{data.description}</p>
      </div>

      <div className="selected-raffle-info">
        <div className="selected-raffle-info-img">
          <img src={data.imageUrl} alt="raffle-img" />
        </div>

        <div className="selected-raffle-info-raffle">
          <p className="selected-raffle-info-caption tablet-max-hide">{data.description}</p>

          <div className={classNames("selected-raffle-info-raffle-row", { "mt-auto": showEntryCost })}>
            <div className="raffles-card-entries selected-raffle-info-raffle-cell">
              <div className="raffles-card-entries-title">
                <img src="/assets/icons/users-icon.svg" alt="users-icon" />

                {t("raffles.card.entries")}
              </div>

              <div className="shared-text shared-text--small font-weight-600">
                {data.maxEntries === 0 ?
                  data.entriesCount
                  : (
                    <>
                      {data.entriesCount}
                      <span className="shared-text shared-text--small font-weight-600 text-secondary-40">
                    &nbsp;/&nbsp;{data.maxEntries}
                    </span>
                    </>
                  )}
              </div>
            </div>

            <div
              className="raffles-card-entries selected-raffle-info-raffle-cell shared-text shared-text--small text-secondary-75 text-uppercase font-weight-600"
            >
              <div className="raffles-card-entries-title">
                <img src="/assets/icons/crown-icon.svg" alt="crown-icon" />

                {t("raffles.selectedRaffle.prizePlaces")}
              </div>
              {data.winnersCount}
            </div>
          </div>

          <div className="selected-raffle-info-raffle-row">
            <div
              className="raffles-card-entries selected-raffle-info-raffle-cell shared-text shared-text--small text-secondary-75 text-uppercase font-weight-600"
            >
              <div className="raffles-card-entries-title">
                <HourglassIcon className="text-secondary-75" />

                {isRaffleEnded ? t("raffles.selectedRaffle.endedOn") : t("raffles.modal.time")}
              </div>

              {dataLoading ? <Spinner size={"sm"} /> : isRaffleEnded ? (
                <div>{format(new Date(data?.deadline), "dd/MM/yyyy") || format(new Date(), "dd/MM/yyyy")}</div>
              ) : (
                <Countdown
                  // @ts-ignore
                  date={data?.deadline || new Date().toString()}
                  className="countdown-raffle-card m-0"
                  timeType
                />
              )}
            </div>
            {showEntryCost ? (
              <div
                className="raffles-card-entries selected-raffle-info-raffle-cell flex-row shared-text shared-text--small text-secondary-75 text-uppercase font-weight-600"
              >
                <div className="raffles-card-entries-title">
                  {t("raffles.entryCost")}
                </div>
                <img src="/assets/icons/coin-small.svg" alt="coin" className="raffles-card-btn-img" />&nbsp;
                {addCommasForBigNumbers(data.entryCost)}
              </div>
            ) : (
              <div
                className="raffles-card-entries selected-raffle-info-raffle-cell shared-text shared-text--small text-secondary-75 text-uppercase font-weight-600"
              >
                <div className="raffles-card-entries-title">
                  {t("raffles.selectedRaffle.yourTickets")}
                </div>

                <div className="d-flex w-100 align-items-center justify-content-between">
                  {user && entry.length !== 0 ? (
                    <>
                      <div className="selected-raffle-info-raffle-entry-wrapper">
                        {!entryLoading && entry.slice(0, 3).map((item: number) => (
                          <div
                            className={classNames("selected-raffle-info-raffle-entry", { "selected-raffle-info-raffle-entry-hide-last-child": entry.length >= 3 })}
                            key={item}>
                            <img src="/assets/images/ticket.svg" alt="tiket"
                                 className="selected-raffle-info-raffle-entry-img" />

                            {item}
                          </div>
                        ))}
                      </div>

                      <button
                        className="selected-raffle-info-raffle-entry-btn"
                        onClick={() => setYourTicketsModalIsOpen(true)}
                      >
                        {t("raffles.selectedRaffle.btnSeeAll")}
                      </button>
                    </>
                  ) : (
                    <div className="selected-raffle-info-raffle-entry-wrapper w-100 align-items-center">
                      <span className="d-flex w-100 align-items-center justify-content-center">-</span>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {isRaffleEnded ? (
            <button
              className="raffles-card-btn shared-btn-primary w-100 m-0"
              disabled={data.entriesCount <= data.winnersCount}
              onClick={() => setShowProofModal(true)}
            >
              {t("raffles.actions.verify")}
            </button>
          ) : (showEntryCost ? null
              : <button
                disabled={data.maxEntries > 0 && data.maxEntries === data.entriesCount}
                className="raffles-card-btn shared-btn-primary w-100 m-0" onClick={() => {
                if (user) {
                  setCurrentCard(data)
                } else {
                  if (triggerLoginModal) {
                    triggerLoginModal()
                  }
                }
              }}>
                {data.entryCost === 0
                  ? t("raffles.card.btnJoinForFree")
                  : (
                    <>
                      {t("raffles.card.btnJoin")}&nbsp;
                      <img src="/assets/icons/coin-small.svg" alt="coin" className="raffles-card-btn-img" />&nbsp;
                      {addCommasForBigNumbers(data.entryCost)}
                    </>
                  )
                }
              </button>
          )}
        </div>

        <JoinRaffleModal isOpen={!!currentCard}
                         item={currentCard}
                         onClose={() => setCurrentCard(null)}
                         onApply={handleJoinRaffle} />

        {yourTicketsModalIsOpen && (
          <YourTicketsModal
            entry={entry}
            onClose={() => setYourTicketsModalIsOpen(false)}
            isOpen={yourTicketsModalIsOpen}
            user={user}
          />
        )}
      </div>
    </div>
  )
}

export default SelectedRaffleInfo

import React, { FC, useState } from "react"
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"


type ConfirmDeleteEntityProps = {
  isOpen: boolean
  entityName: string
  onClose: () => void
  onApply: () => Promise<void>
}

const ConfirmDeleteEntity: FC<ConfirmDeleteEntityProps> = ({
  isOpen,
  onClose,
  entityName,
  onApply
}) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)

  const handleOnApply = async () => {
    setLoading(true)
    await onApply()
    setLoading(false)
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <div className="d-flex flex-column align-items-start justify-content-center shared-modal-content">
        <button type="button" className="shared-btn shared-modal-close" onClick={onClose}>
          <img src="/assets/icons/close.svg" alt="close" />
        </button>

        <ModalBody className="shared-modal-body p-b-0">
          <h4 className="shared-modal-title w-100 mb-0 mt-4">
            {t("deleteEntityModal.title", { entity: entityName })}
          </h4>

          <p className="shared-modal-text shared-text mb-0 text-center">
            {t("deleteEntityModal.text", { entity: entityName })}
          </p>
        </ModalBody>

        <ModalFooter color="secondary" className="shared-modal-footer justify-content-between border-0">
          <button
            type="button"
            className="rounded-0 shared-modal-btn shared-btn-secondary"
            onClick={onClose}
            disabled={loading}
          >
            {t("deleteEntityModal.btnCancel")}
          </button>

          <button
            disabled={loading}
            type="button"
            className="rounded-1 shared-modal-btn shared-btn-primary"
            onClick={handleOnApply}
          >
            {loading ? <Spinner size="sm" color="white" /> : t("deleteEntityModal.btnDelete")}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteEntity

import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"

import Pagination from "@src/components/Pagination"

import { RAFFLES_STATUS, RafflesCard, useRaffles } from "@modules/raffles"

type EndedRafflesProps = {}

const EndedRaffles: FC<EndedRafflesProps> = ({}) => {
  const { t } = useTranslation()
  const status = RAFFLES_STATUS.ENDED

  const { list, loading, total, page, perPage, onChangePage } = useRaffles(status)

  const scrollToTop = () => {
    const element = document.getElementById("raffles-ended-list")
    if (!element) return

    const headerOffset = 90
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition + window.scrollY - headerOffset

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    })
  }

  return (
    <section className="raffles-section raffles-section-ended shared-container" id="raffles-ended-list">
      <div className="raffles-section-header">
        <div className="raffles-title-wrapper">
          <p className="raffles-title">{t("raffles.endedRaffles")}</p>
        </div>
      </div>
      <div className="raffles-list-wrapper">
        <div className="raffles-list">
          {loading ? (
            <div className="raffles-list-stub-wrapper">
              <Spinner color="primary" />
            </div>
          ) : (
            list.length === 0 ? (
              <div className="raffles-list-stub">
                <p className="raffles-list-stub-text">
                  {t("raffles.stubEnded")}
                </p>
              </div>
            ) : (list.map(card => (
                <RafflesCard card={card} key={card.id} status={status} />
              ))
            ))}
        </div>

      </div>

      <div className="raffles-section-ended-pagination">
        <Pagination
          rowCount={Math.ceil(total / perPage) || 1}
          currentPage={page}
          onChangePage={(p) => {
            onChangePage(p)

            scrollToTop()
          }}
          rowsPerPage={1}
        />
      </div>
    </section>
  )
}

export default EndedRaffles

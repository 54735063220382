import { forwardRef, useImperativeHandle, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import Flatpickr from 'react-flatpickr'
import classNames from 'classnames'

// ** Styles
import './datepicker.scss'

type DatePickerProps = {
  /**
   * Handle change events on every date selection
   */
  callback: (e: Date | undefined) => void
  /**
   * Sets a className attribute on the outer component
   */
  className?: string
  placeholder?: string
  label: string
  /**
   * Shows time at the bottom of the calendar if the param is 'true'
   */
  showTime?: boolean
  /**
   * The minimum date that a user can start picking from (inclusive).
   */
  minDate?: Date
  /**
   * The maximum date that a user can pick to (inclusive).
   */
  maxDate?: Date
  value?: Date
  /**
   * Is datepicker disabled
   * @defaultValue false
   */
  disabled?: boolean
}

const DatePicker = forwardRef<unknown, DatePickerProps>(
  (
    {
      callback,
      label,
      minDate,
      maxDate,
      className,
      placeholder,
      showTime,
      value,
      disabled = false
    },
    ref
  ) => {
    const { t } = useTranslation()

    const fp = useRef(null)

    const onReset = () => {
      // @ts-ignore
      fp.current.flatpickr.clear()
    }

    useImperativeHandle(ref, () => ({
      triggerReset() {
        onReset()
      }
    }))

    return (
      <div
        className={classNames(
          'custom-datepicker shared-input-wrapper d-flex align-items-start position-relative',
          className
        )}
      >
        <label className="shared-input-label">
          {label}
        </label>

        <Flatpickr
          ref={fp}
          data-enable-time={showTime}
          options={{
            minDate,
            maxDate,
            dateFormat: showTime ? 'd.m.y / H:i' : 'd.m.y',
            time_24hr: true
          }}
          value={value}
          disabled={disabled}
          onChange={(e) => callback(e[0])}
          className='custom-datepicker-timepicker w-100 shared-input'
          placeholder={placeholder ? placeholder : t('activityLogPage.actions.select')}
        />
      </div>
    )
  }
)

export default DatePicker

import { lazy } from "react"

import { RouteEntity } from "@src/router/routes"

import "./views/landing.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

const Landing = lazy(() => import("./views/Landing"))
const FaqPage = lazy(() => import("./views/FaqPage"))
const TermsAndConditionPage = lazy(() => import("./views/TermsAndConditionPage"))
const PrivacyPage = lazy(() => import("./views/PrivacyPage"))
const RedirectPage = lazy(() => import("./views/RedirectPage"))

export * from "./dto"

export * from "./useAllBonuses"

export * from "./requests"

export * as consts from "./consts"

export { default as PointStore } from "./components/PointStore"
export { default as Hero } from "./components/Hero"
export { default as LeaderBoard } from "./components/LeaderBoard"
export { default as Slots } from "./components/Slots"
export { default as VirtualAvatar } from "./components/VirtualAvatar"
export { default as Raffles } from "./components/Raffles"
export { default as Socials } from "./components/Socials"
export { default as Creators } from "./components/Creators"
export { default as About } from "./components/About"
export { default as Faq } from "./components/Faq"

export const router: RouteEntity[] = [
  {
    path: "/faq",
    element: <FaqPage />
  },
  {
    path: "/terms",
    element: <TermsAndConditionPage />
  },
  {
    path: "/privacy",
    element: <PrivacyPage />
  },
  {
    path: "/",
    element: <Landing />
  },
  {
    path: "/*",
    element: <RedirectPage />
  }
]

export const locales = {
  en,
  ru
}

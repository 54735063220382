import { useEffect, useState } from "react"
import Draggable from "react-draggable"
import { useLocation } from "react-router-dom"

import useKickOnlineStatus, {
  STREAM_HADDZY_KICK_LINK,
  STREAM_HADDZY_JR_KICK_LINK
} from "@hooks/useKickOnlineStatus"

import "./styles.scss"

const StreamDraggableBlock = () => {
  const location = useLocation()
  const { isOnline, data } = useKickOnlineStatus()

  const [isStreamVisible, setIsStreamVisible] = useState<boolean>(false)

  useEffect(() => {
    if (isOnline) {
      setIsStreamVisible(true)
    }
  }, [isOnline])

  if (location.pathname.includes("/admin/")) return null

  if (!isStreamVisible) {
    return (
      <div className="stream-draggable-cta-wrapper">
        <button className="stream-draggable-cta" onClick={() => setIsStreamVisible(true)}>
          <img src="/assets/icons/live-button.svg" alt="live" />
        </button>
      </div>
    )
  }

  return (
    <Draggable
      handle="#player-controll"
      bounds={{
        top: 50,
        left: 30,
        right: window.innerWidth - 50 - 350,
        bottom: window.innerHeight - 30 - 225
      }}
      defaultPosition={{ x: window.innerWidth - 410, y: window.innerHeight - 285 }}
    >
      <div className="stream-draggable">
        <div className="position-relative w-100 h-100">
          <div className="stream-draggable-control">
            <div id="player-controll" className="stream-draggable-control-cell stream-draggable-handle">
              <img src="/assets/icons/drag.svg" alt="m" />
            </div>

            <button
              onClick={() => setIsStreamVisible(false)}
              className="stream-draggable-control-cell stream-draggable-close"
            >
              <img src="/assets/icons/close.svg" alt="X" />
            </button>
          </div>

          <iframe
            src={!!data?.haddzyStatus ? STREAM_HADDZY_KICK_LINK : STREAM_HADDZY_JR_KICK_LINK}
            style={{ width: "100%", height: "100%", borderRadius: "10px", aspectRatio: "16/9" }}
          />
        </div>
      </div>
    </Draggable>
  )
}

export default StreamDraggableBlock

import { lazy } from "react"

import "./views/admin-points-store.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export const AdminPointsStore = lazy(() => import("./views/AdminPointsStore"))

export * from "./dto"

export * from "./requests"

export { default as AdminPointsStoreCardReorder } from "./components/AdminPointsStoreCardReorder"
export { default as AdminPointsStoreList } from "./components/AdminPointsStoreList"
export { default as AddPointsCardModal } from "./components/AddPointsCardModal"
export { default as ConfirmPurchaseModal } from "./components/ConfirmPurchaseModal"
export { default as ConfirmDeleteModal } from "./components/ConfirmDeleteModal"
export { default as AdminPointsStoreStatistic } from "./components/AdminPointsStoreStatistic"
export const locales = {
  en,
  ru
}

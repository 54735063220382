import axios from "@src/network"
import { PaginationPayload, usePlainSWRNoMount } from "@hooks/usePlainSWR"
import { useEffect, useState } from "react"

import {
  ITEMS_ORDER,
  POINTS_STORE_PROFILE_TABLE,
  PointStoreProfileTableFilterPayload,
  ProfilePointsStorePurchases
} from "@modules/pointsStore/dto"

export const fetchCurrentUserPointsStorePurchases = async (userId: string, payload: PointStoreProfileTableFilterPayload & PaginationPayload) => {
  const params = {
    ...payload,
    skip: (payload.page - 1) * payload.perPage,
    take: payload.perPage,
    userId
  }

  const res = await axios.get("/point-shop/purchases", {
    params,
    withCredentials: true
  })

  return {
    total: res?.data?.length,
    data: res?.data
  }
}

export const usePointsStorePurchasesById = (userId: string) => {
  const [filter, setFilter] = useState<PointStoreProfileTableFilterPayload>({
    sortOrder: ITEMS_ORDER.DESC,
    sortBy: POINTS_STORE_PROFILE_TABLE.DATE
  })

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(6)

  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWRNoMount(`point-shop/purchases/${userId}`, () => fetchCurrentUserPointsStorePurchases(userId, {
    ...filter,
    page,
    perPage
  }))

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const loading = !data && !error
  const purchase: ProfilePointsStorePurchases[] = data?.items || []
  return {
    loading,
    purchase,
    refresh,
    error,

    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 0,

    onFiltered: (newFilter: Partial<PointStoreProfileTableFilterPayload>) => setFilter((prev) => ({ ...prev, ...newFilter })),
    filter
  }
}
import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchRaffleProof, RaffleProof } from "@modules/raffles"

export const useRaffleProof = (id: number) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`/raffles/proof/${id}`, () => (id ? fetchRaffleProof(id) : null))

  return {
    data: data as RaffleProof,
    error,

    refresh,

    isValidating,
    loading: !data && !error
  }
}

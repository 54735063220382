import { FC, useContext } from "react"
import { mutate } from "swr"

import { RAFFLE } from "constants/swr"

import FireworksContext from "@context/Fireworks"

import useToast from "@hooks/useToast"

import {
  createTemplateSubscription, pointStoreCreateHandle,
  pointStoreDeleteHandle,
  pointStoreEditHandle, pointStorePurchaseHandle,
  pointStoreRestockHandle, raffleCreateHandle, raffleDeleteHandle, raffleNewEntryHandle
} from "@utils/SocketHandle"
import { RaffleCardEntity, RAFFLES_STATUS } from "@modules/raffles"

enum SOCKET_EVENTS {
  POINT_SHOP_ITEM_CREATED = "PointsShopItemCreated",
  POINT_SHOP_ITEM_UPDATED = "PointsShopItemUpdated",
  POINT_SHOP_ITEM_DELETED = "PointsShopItemDeleted",
  POINT_SHOP_ITEM_RESTOCKED = "PointsShopItemsRestocked",
  POINT_SHOP_ITEM_PURCHASED = "PointsShopItemPurchased",
  // --
  NEW_RAFFLE_ENTRY = "NewRaffleEntry",
  DELETE_RAFFLE = "RaffleDeleted",
  RAFFLE_ENDED = "RaffleEnded",
  NEW_RAFFLE = "NewRaffle",
  RAFFLE_ENDS_SOON = "RaffleEndsSoon",
}

type SocketTriggerProps = {}

const SocketTrigger: FC<SocketTriggerProps> = ({}) => {
  const { setShowFireworks } = useContext(FireworksContext)

  const toast = useToast()

  createTemplateSubscription(SOCKET_EVENTS.POINT_SHOP_ITEM_RESTOCKED, pointStoreRestockHandle)
  createTemplateSubscription(SOCKET_EVENTS.POINT_SHOP_ITEM_UPDATED, pointStoreEditHandle)
  createTemplateSubscription(SOCKET_EVENTS.POINT_SHOP_ITEM_DELETED, pointStoreDeleteHandle)
  createTemplateSubscription(SOCKET_EVENTS.POINT_SHOP_ITEM_CREATED, pointStoreCreateHandle)
  createTemplateSubscription(SOCKET_EVENTS.POINT_SHOP_ITEM_PURCHASED, pointStorePurchaseHandle)
  createTemplateSubscription(SOCKET_EVENTS.NEW_RAFFLE_ENTRY, raffleNewEntryHandle)
  createTemplateSubscription(SOCKET_EVENTS.NEW_RAFFLE, raffleCreateHandle)
  createTemplateSubscription(SOCKET_EVENTS.DELETE_RAFFLE, raffleDeleteHandle)

  createTemplateSubscription(SOCKET_EVENTS.RAFFLE_ENDS_SOON, async (event: any) => {
    if (event) {
      toast("Raffle ends soon!")
    }
  })

  createTemplateSubscription(SOCKET_EVENTS.RAFFLE_ENDED, async (event: RaffleCardEntity) => {
    if (event) {
      if (window.location.pathname === `${RAFFLE}/${event.id}`) {
        setShowFireworks(true)
      }

      // Remove ended raffle from active list
      await mutate(`${RAFFLE}-${RAFFLES_STATUS.ACTIVE}`, async (response: any) => {
        if (!response) {
          return { data: undefined }
        }
        const { data } = response

        const items = (data?.items || []).filter((raffle: RaffleCardEntity) => raffle.id !== event.id)

        const res = {
          ...data,
          total: items.length,
          items
        }

        return { data: res }
      }, { revalidate: false })

      // Add ended raffle to ended list
      await mutate(`${RAFFLE}-${RAFFLES_STATUS.ENDED}`, async (response: any) => {
        if (!response) {
          return { data: undefined }
        }
        const { data } = response

        const items: RaffleCardEntity[] = (data?.items || [])

        if (!items.find((raffle) => raffle.id === event.id)) {
          items.unshift(event)
        }

        const res = {
          ...data,
          total: items.length,
          items
        }

        return { data: res }
      }, { revalidate: false })

      await mutate(`${RAFFLE}/${event.id}`, async () => {
        return { data: event }
      }, { revalidate: false })
    }
  })

  return null
}
export default SocketTrigger

import { useEffect, useState } from "react"

import { usePlainSWRNoMount } from "@hooks/usePlainSWR"

import { User } from "@hooks/useUser"

import { AdminUserTablePayload, fetchAdminUsers, TABLE_ORDER, USERS_ORDER_BY } from "@modules/adminUsers"

export const useAdminUsers = () => {
  const [filter, setFilter] = useState<AdminUserTablePayload>({
      order: TABLE_ORDER.DESC,
      orderBy: USERS_ORDER_BY.JOINED
    }
  )

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount("/admin-users", () => fetchAdminUsers({ ...filter, page, perPage }))

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const onFiltered = (newFilter: Partial<AdminUserTablePayload>) => {
    setFilter((prev) => ({ ...prev, ...newFilter }))
    setPage(1)
  }

  return {
    list: (data?.items || []) as User[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.totalCount || 0,

    onFiltered,
    filter
  }
}

import { FC } from "react"

import { InputGroup, InputGroupText } from "reactstrap"
import { useTranslation } from "react-i18next"
import "./styles.scss"

type SearchInputProps = {
  onChange: (e: string) => void
  value?: string
  placeholder?: string
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, onChange, value = "" }) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column search align-items-start justify-content-start">
      <InputGroup className='search-input-wrapper'>
        <InputGroupText>
          <img src="/assets/icons/search.svg" alt="search" />
        </InputGroupText>

        <input
          type="text"
          className="search-input"
          placeholder={placeholder || t("admin-users.filters.searchPlaceholder")}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </InputGroup>
    </div>
  )
}

export default SearchInput

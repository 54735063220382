import React, { FC } from "react"
import SearchInput from "@src/components/SearchInput/SearchInput"
import Select from "react-select"
import { ADMIN_RAFFLE_SORT, RAFFLES_ORDER } from "@modules/raffles"
import { TableCell, TableHeaderCell } from "@src/components/Table"
import { compareAsc, format } from "date-fns"
import { addCommasForBigNumbers } from "@utils/Strings"
import classNames from "classnames"
import Pagination from "@src/components/Pagination"
import { Option } from "@utils/Select"
import { useCurrentUserRafflesEntry } from "@modules/raffles/useCurrentUserRafflesEntry"
import { User } from "@hooks/useUser"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"

type RafflesTabProps = {
  user?: User
}

const RafflesTab: FC<RafflesTabProps> = ({ user }) => {
  const { t } = useTranslation()
  const sortByOptions: Option[] = [
    { label: "wins first", value: ADMIN_RAFFLE_SORT.IS_WINNER },
    { label: "date", value: ADMIN_RAFFLE_SORT.DATE }
  ]
  const {
    rafflesEntry,
    loading,
    page,
    filter,
    onFiltered,
    onChangePage,
    perPage,
    total
  } = useCurrentUserRafflesEntry(user?.id!)

  return (
    <>
      <div className="profile-modal-raffle-header justify-content-start mt-3">
        <div className="points-store-input-admin">
          <SearchInput
            onChange={(s) => onFiltered({ search: s })}
            value={filter?.search}
          />
        </div>

        <div className="profile-modal-raffle-select">
          <Select
            options={sortByOptions}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "transparent",
                primary25: "#E2F1FF1A"
              }
            })}

            isSearchable={false}
            className="profile-select-wrapper"
            classNamePrefix="profile-select"
            components={{ IndicatorSeparator: null }}
            value={sortByOptions.find((o) => o.value === filter.sortBy?.toString()) || null}
            onChange={(o) => {
              if (!o?.value) return
              onFiltered(
                o.value === ADMIN_RAFFLE_SORT.IS_WINNER ? {
                  sortBy: ADMIN_RAFFLE_SORT.IS_WINNER,
                  sortOrder: RAFFLES_ORDER.DESC
                } : {
                  sortBy: ADMIN_RAFFLE_SORT.DATE,
                  sortOrder: RAFFLES_ORDER.DESC
                })
              onChangePage(1)
            }}
          />
        </div>
      </div>

      <div className="profile-modal-table-container">
        {rafflesEntry.length === 0 ? (
          <div className="profile-modal-table-stub">
            <p className="raffles-list-stub-text">{t("profile.rafflesSection.stub")}</p>
          </div>
        ) : (
          <div className="profile-modal-table no-scrollbar">
            <div className="d-flex flex-column w-100 justify-content-end">
              <div className="d-flex flex-row profile-modal-table-header-row">
                <TableHeaderCell
                  text={"Id"}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.rafflesSection.tableTitle.entered")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.rafflesSection.tableTitle.ended")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.rafflesSection.tableTitle.price")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.rafflesSection.tableTitle.result")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.rafflesSection.tableTitle.prize")}
                  className="profile-modal-table-header text-center"
                />
              </div>
            </div>

            <div className="d-flex flex-column w-100 justify-content-end">
              {loading ? (
                <div className="w-100 d-flex justify-content-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                rafflesEntry.map((entry) => {
                  return (
                    <div className="d-flex flex-row profile-modal-table-row"
                         key={entry.id}>
                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell">
                          #{entry.raffle.id}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell">
                          {format(new Date(entry.createdAt), "dd.MM.yyyy")}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell">
                          {format(new Date(entry.raffle.deadline), "dd.MM.yyyy")}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell">
                          <img src="/assets/icons/coin-small.svg" alt="coin" className="admin-users-table-icon" />
                          {addCommasForBigNumbers(entry.raffle.entryCost)}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div
                          className={classNames("profile-modal-table-cell profile-modal-table-cell-result",
                            { "text-green": entry.isWinner },
                            { "text-ban-red": !entry.isWinner },
                            { "text-blue": compareAsc(new Date(), new Date(entry.raffle.deadline)) === -1 }
                          )}
                        >
                          {compareAsc(new Date(), new Date(entry.raffle.deadline)) === -1 ? "LIVE" : entry.isWinner ? "WIN" : "LOST"}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell text-green">
                          ${Math.floor(entry.raffle.prizeInDollars / entry.raffle.winnersCount)}
                        </div>
                      </TableCell>
                    </div>
                  )
                }))}
            </div>
          </div>
        )}

        <div className="d-flex w-100 align-items-center justify-content-center mt-3">
          <Pagination
            rowCount={Math.ceil(total / perPage) || 1}
            currentPage={page}
            onChangePage={onChangePage}
            rowsPerPage={1}
          />
        </div>
      </div>
    </>
  )
}

export default RafflesTab
import { FC } from "react"

import { SlotCard } from "@modules/slotsReviews"
import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"


type TopSlotCardProps = {
  card: SlotCard
  withControl?: boolean
  className?: string
  onDelete?: (id: string) => void
}

const TopSlotCard: FC<TopSlotCardProps> = ({ card, withControl, className, onDelete }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className={classNames("slots-reviews-top-picks-card d-flex flex-column", className)}>
      <img
        className="slots-reviews-top-picks-card-img"
        src={card.imageUrl} alt={card.name}
        onClick={() => navigate(`/slots-reviews/slot/${card.id}`)}
      />

      <div className="slots-reviews-top-picks-card-title">
        <h4
          className="text-truncate cursor-pointer"
          onClick={() => navigate(`/slots-reviews/slot/${card.id}`)}
        >
          {card.name}
        </h4>

        <h5 className="text-truncate text-capitalize">{card.provider.name}</h5>
      </div>

      <div className="slots-reviews-top-picks-card-stars">
        {[...Array(5)].map((star, index) => {
          return (
            <div className="slots-reviews-top-picks-card-star-icon-wrapper" key={`${index}${card.id}`}>
              <StarIcon
                className={classNames("slots-reviews-top-picks-card-star-icon", index <= card.adminRate - 1 ? "text-yellow" : "text-secondary-25")} />
            </div>
          )
        })}
      </div>

      {withControl &&
        <div className="slots-reviews-top-picks-card-cta">
          <button
            className="slots-reviews-top-picks-card-cta-edit shared-text shared-text--extra-small text-secondary-75"
            onClick={() => navigate(`/admin/slots-reviews/slot/${card.id}`)}
          >
            {t("adminSlotsReviews.editBtn")}
          </button>

          <button
            className="slots-reviews-top-picks-card-cta-delete"
            onClick={() => onDelete && onDelete(card.id)}
          >
            <img src="/assets/icons/delete.svg" alt="delete" />
          </button>
        </div>
      }
    </div>
  )
}

export default TopSlotCard
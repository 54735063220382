import axios from "network"
import { SearchPayload } from "@modules/slotsReviews/dto"
import { PaginationPayload } from "@hooks/usePlainSWR"

export const fetchTopSlots = async (payload: SearchPayload) => {
  const { search } = payload

  const params: any = {
    search
  }
  const res = await axios.get("/slots-reviews/top", { params, withCredentials: true })

  return { data: { items: res.data } }
}

export const fetchProviders = async (payload: SearchPayload & PaginationPayload) => {
  const { search, perPage, page } = payload

  const params: any = {
    search,
    page,
    limit: perPage
  }

  const res = await axios.get("/casino/providers", { params, withCredentials: true })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const fetchCurrentProvider = async (id: string, payload: SearchPayload) => {

  const res = await axios.get(`/casino/providers/${id}`, {
    withCredentials: true
  })

  return { data: { item: res.data } }
}

export const fetchSlotById = async (id: string) => {
  const res = await axios.get(`/casino/slots/${id}`, {
    withCredentials: true
  })

  return { data: { item: res.data } }
}

export const fetchAllSlots = async (payload: SearchPayload & PaginationPayload, providerId?: string | undefined) => {
  const { search, perPage, page } = payload

  const params: any = {
    providerId,
    search,
    page,
    limit: perPage
  }

  const res = await axios.get("/casino/slots", { params, withCredentials: true })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const fetchAllCommentsBySlotId = async (id: string, payload: PaginationPayload) => {
  const { perPage, page } = payload

  const params: any = {
    status: "APPROVED",
    page,
    limit: perPage
  }

  const res = await axios.get(`/casino/slots/get-comments/${id}`, { params, withCredentials: true })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const postSlotRateRequest = (id: string, rate: number, comment: string = "") => axios.post(`/casino/slot/${id}/rate`, {
  rate,
  comment
}, { withCredentials: true })

export const updateSlotsOrderRequest = (payload: { id: string, order: number }[]) => {
  const items = {
    items: payload
  }
  void axios.post("/casino/slots/change-order", items, {
    withCredentials: true
  })
}

export const updateProvidersOrderRequest = (payload: { id: string, order: number }[]) => {
  const items = {
    items: payload
  }
  void axios.post("/casino/providers/change-order", items, {
    withCredentials: true
  })
}
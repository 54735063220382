import React, { FC } from "react"
import { Modal } from "reactstrap"
import { useTranslation } from "react-i18next"
import { User } from "@hooks/useUser"
import classNames from "classnames"

type YourTicketsModalProps = {
  entry: []
  isOpen: boolean
  onClose: () => void
  user: User
}

const YourTicketsModal: FC<YourTicketsModalProps> = ({ entry, onClose, isOpen, user }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "tickets-modal" }}
      contentClassName="position-relative shared-modal tickets-modal-content"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close rounded" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <div className="tickets-modal-body">
        <p className="tickets-modal-text">{t("raffles.yourTickets")}</p>

        <div className="d-flex flex-column align-items-center justify-content-center">
          <img src={user.profileImageUrl} alt="avatar" className="tickets-modal-avatar" />
          <p className="tickets-modal-text">{user.displayName}</p>
        </div>

        <div className="d-flex align-items-center justify-content-center w-100">
          <div
            className={classNames("tickets-modal-container", { "tickets-modal-container-few-entries": entry.length <= 5 })}>
            {entry.map((item: number) => (
              <div key={item}>
                <span className="selected-raffle-info-raffle-entry">
                <img src="/assets/images/ticket.svg" alt="tiket"
                     className="selected-raffle-info-raffle-entry-img-modal" />

                  {item}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default YourTicketsModal
import React, { FC, useState } from "react"
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import axios from "@src/network"
import { AdminLeaderboardUsers } from "@modules/adminLeaderboard"

type BanUserInLeaderboardProps = {
  user: AdminLeaderboardUsers | null
  isOpen: boolean
  leaderboardId?: string
  onClose: () => void
  onApply: () => void
}

const UnbanUserInLeaderboard: FC<BanUserInLeaderboardProps> = ({
  user,
  isOpen,
  onClose,
  onApply,
  leaderboardId
}) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState<boolean>(false)
  const onUnban = async () => {
    setLoading(true)

    if (user) {
      await axios.put(`leaderboard/${leaderboardId}/user/${user.id}/unban`, {}, {
        headers: { "x-api-key": 12345 },
        withCredentials: true
      })
    }

    setLoading(false)

    onApply()
  }
  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "admin-leaderboards-modal" }}
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <ModalBody cssModule={{ "modal-body": "admin-leaderboards-modal-body-unban" }}>
        <div
          className="admin-leaderboards-modal-title">{t("admin-leaderboard.current.unbanModal.title")} {user?.displayName}?
        </div>
        <p className="admin-leaderboards-modal-text">
          {t("admin-leaderboard.current.unbanModal.text")}
        </p>
      </ModalBody>

      <ModalFooter color="secondary" cssModule={{ "modal-footer": "admin-leaderboards-modal-footer" }}>
        <button type="button" className="shared-btn-secondary admin-leaderboards-modal-btn"
                onClick={onClose}>
          {t("admin-leaderboard.current.unbanModal.btnCancel")}
        </button>

        <button type="button"
                className="points-confirm-btn shared-btn-primary admin-leaderboards-modal-btn"
                onClick={onUnban}
        >
          {loading && <Spinner size="sm" />}
          {t("admin-leaderboard.current.unbanModal.btnUnban")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default UnbanUserInLeaderboard
import React, { FC, useEffect, useState } from "react"
import axios from "@src/network"

import { useTranslation } from "react-i18next"
import { useForm } from "react-hook-form"
import { addMonths, endOfMonth, isPast } from "date-fns"
import useToast, { TOAST_TYPE } from "@hooks/useToast"
import { yupResolver } from "@hookform/resolvers/yup"

import { Modal, ModalFooter, Spinner } from "reactstrap"

import { Leaderboard } from "@modules/leaderboards"
import {
  LeaderboardForm,
  ManageLeaderboardModalPrizes,
  ManageLeaderboardModalSetup,
  setupLeaderboardFormSchema, useAdminCurrentLeaderboard
} from "@modules/adminLeaderboard"


type ManageLeaderboardModalProps = {
  isOpen: boolean
  onClose: () => void
  onApply: () => void
  leaderboard?: Leaderboard
}

enum FormStep {
  SETUP = "setup",
  PRIZES = "prizes",
}

const ManageLeaderboardModal: FC<ManageLeaderboardModalProps> = ({
  isOpen, onApply, onClose, leaderboard
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<FormStep>(FormStep.SETUP)
  const { data } = useAdminCurrentLeaderboard(leaderboard?.id!)

  const today = new Date()
  const nextMonth = addMonths(new Date(today.getFullYear(), today.getMonth() + 1), 1)

  const defaultLeaderboard = {
    month: leaderboard?.month || nextMonth.getMonth().toString(),
    year: leaderboard?.year || nextMonth.getFullYear().toString(),
    provider: leaderboard?.provider || "STAKE",
    maxPublicUsersCount: leaderboard?.maxPublicUsersCount || 10,
    randomPrizeThreshold: leaderboard?.randomPrizeThreshold || undefined,
    tieredPrizes: data?.leaderboardTieredPrize || [
      { prizeNumber: 1, amount: undefined },
      { prizeNumber: 2, amount: undefined },
      { prizeNumber: 3, amount: undefined }
    ],
    randomPrizes: data?.leaderboardRandomPrize || []
  }

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid, errors }
  } = useForm<LeaderboardForm>({
    defaultValues: defaultLeaderboard,
    mode: "onChange",
    resolver: yupResolver(setupLeaderboardFormSchema())
  })

  useEffect(() => {
    if (isOpen) {
      reset(defaultLeaderboard)
    }
  }, [leaderboard, data])
  const onSubmit = async (data: LeaderboardForm) => {
    setLoading(true)
    const payload = {
      month: parseInt(data.month),
      year: parseInt(data.year),
      provider: data.provider,
      maxPublicUsersCount: data.maxPublicUsersCount,
      randomPrizeThreshold: data.randomPrizeThreshold,
      tieredPrizes: data.tieredPrizes,
      randomPrizes: data.randomPrizes
    }
    if (isPast(endOfMonth(new Date(parseInt(data.year), parseInt(data.month) - 1)))) {
      toast(t("Leaderboard end date cannot be past"), TOAST_TYPE.ERROR)

      setLoading(false)
    } else {

      try {
        if (leaderboard) {
          await axios.patch(`leaderboard/${leaderboard.id}/update`, { ...payload }, {
            headers: { "x-api-key": 12345 },
            withCredentials: true
          })
        } else {
          await axios.post("/leaderboard/create", { ...payload }, {
            headers: { "x-api-key": 12345 },
            withCredentials: true
          })
        }

        reset(data)

        setLoading(false)

        reset()

        setActiveStep(FormStep.SETUP)

        onApply()
      } catch (e: any) {
        const error = e.response.data.message
        toast(error, TOAST_TYPE.ERROR)
      } finally {
        setLoading(false)
      }
    }
  }

  const handleOnClose = () => {
    reset()

    setActiveStep(FormStep.SETUP)

    onClose()
  }

  const month = watch("month")
  const year = watch("year")
  const randomPrizeThreshold = watch("randomPrizeThreshold")
  const randomPrizes = watch("randomPrizes")
  const provider = watch("provider")
  const maxPublicUsersCount = watch("maxPublicUsersCount")

  const isDisabledSubmit = !isValid
    || !!(randomPrizeThreshold && randomPrizeThreshold >= 0 && randomPrizes.length === 0)
    || !!(randomPrizeThreshold && randomPrizeThreshold > 0 && randomPrizes.some((p) => !p.amount))
    || !!(randomPrizeThreshold && +randomPrizeThreshold === 0)
    || !!(!randomPrizeThreshold  && randomPrizes.some((p) => p.amount))
    || Object.keys(errors).length > 0

  return (
    <Modal
      isOpen={isOpen}
      centered
      className={`shared-modal-wrapper ${activeStep === FormStep.SETUP ? "small" : "large"}`}
      contentClassName="position-relative shared-modal"
      toggle={handleOnClose}
    >
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="d-flex flex-column align-items-start justify-content-center shared-modal-content"
      >
        <button type="button" className="shared-btn shared-modal-close" onClick={handleOnClose}>
          <img src="/assets/icons/close.svg" alt="close" />
        </button>


        {activeStep === FormStep.PRIZES && (
          <>
            <ManageLeaderboardModalPrizes isEdit={!!leaderboard} setValue={setValue} control={control} watch={watch} />

            <ModalFooter className="w-100 border-0 ps-0 pe-0 ms-auto me-auto admin-leaderboards-create-content">
              <button
                type="button"
                className="ms-0 rounded-0 shared-modal-btn shared-btn-secondary"
                onClick={() => setActiveStep(FormStep.SETUP)}
              >
                {t("admin-leaderboard.actions.back")}
              </button>

              <button
                type="submit"
                disabled={loading || isDisabledSubmit}
                className="rounded-1 shared-modal-btn shared-btn-primary ms-auto me-0"
              >
                {loading && <Spinner color="primary" />}

                {t("admin-leaderboard.actions.save")}
              </button>
            </ModalFooter>
          </>
        )}


        {activeStep === FormStep.SETUP && (
          <>
            <ManageLeaderboardModalSetup isEdit={!!leaderboard} setValue={setValue} control={control} onApply={onApply} onClose={onClose} />

            <ModalFooter className="w-100 border-0 ps-0 pe-0">
              <button
                type="button" disabled={!month || !year || !provider || !maxPublicUsersCount}
                className="rounded-1 shared-modal-btn shared-btn-primary ms-auto"
                onClick={() => setActiveStep(FormStep.PRIZES)}
              >

                {t("admin-leaderboard.actions.next")}
              </button>
            </ModalFooter>
          </>
        )}
      </form>
    </Modal>
  )
}

export default ManageLeaderboardModal

import { FC, useEffect, useRef, useState } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { Collapse as ReactstrapCollapse } from 'reactstrap'

import LiveIndicator from 'components/LiveIndicator'
import { HeaderUser } from 'components/HeaderUser'

import './styles.scss'
import LoginModal from '@src/components/LoginModal'
import { logout, useCurrentUser } from '@hooks/useUser'
import ProfileModal from 'components/ProfileModal'
import { addCommasForBigNumbers } from '@utils/Strings'
import { useOnClickOutside } from '@hooks/useOnClickOutside'
import UserAvatar from '@src/components/UserAvatar'

type HeaderProps = {
  headerClassName?: string
}

const Header: FC<HeaderProps> = ({ headerClassName }) => {
  const navigate = useNavigate()

  const location = useLocation()

  const { t } = useTranslation()
  const { user, loading } = useCurrentUser()
  const NAV_LINKS: { text: string; url: string }[] = [
    { text: t('header.links.bonuses'), url: '/bonuses' },
    { text: t('header.links.leaderboards'), url: '/leaderboards' },
    { text: t('header.links.pointsStore'), url: '/points-store' },
    { text: t('header.links.raffles'), url: '/raffles' }
  ]

  const NAV_LINKS_MORE: { text: string; url: string }[] = [
    { text: t('header.links.daily-case'), url: '/daily-case' },
    { text: t('header.links.bonusHunt'), url: '/bonus-hunt' },
    { text: t('header.links.slotsReviews'), url: '/slots-reviews' }
  ]

  const dropdownRef = useRef(null)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)
  const [showProfileModal, setShowProfileModal] = useState(false)
  const [dropdownIsOpen, setDropdownIsOpen] = useState<boolean>(false)
  const [openConnectionsTab, setOpenConnectionsTab] = useState(false)

  const url = new URL(window.location.href)

  useEffect(() => {
    if (url.searchParams.get('selectedTab') === 'connections') {
      setShowProfileModal(true)
      setOpenConnectionsTab(true)
      window.history.replaceState(null, '/', window.location.pathname)
      setTimeout(() => setOpenConnectionsTab(false), 2000)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-overflow')
    } else {
      document.body.classList.remove('no-overflow')
    }
  }, [isOpen])

  const toggleNavbar = () => setIsOpen(!isOpen)

  const toggleProfileModal = () => setShowProfileModal(!showProfileModal)

  const onLogin = () => {
    document.body.classList.remove('no-overflow')

    setShowLoginModal(true)
  }

  const toggleMenu = () => setDropdownIsOpen(!dropdownIsOpen)

  useOnClickOutside(dropdownRef, () => {
    if (dropdownRef) setTimeout(() => setDropdownIsOpen(false), 50)
  })

  const handleLogout = async () => {
    await logout()

    window.open('/', '_self')
  }

  return (
    <div className='custom-header-wrapper'>
      <header
        className={classNames('custom-header shared-container wide-max-hide', headerClassName)}
      >
        <div className='custom-header-cell custom-header-cell--logo'>
          <img
            src='/assets/images/logo.svg'
            alt='logo'
            className='custom-header-logo cursor-pointer'
            onClick={() => navigate('/')}
          />

          <LiveIndicator className='' />
        </div>

        {NAV_LINKS.map(({ text, url }) => (
          <div className='custom-header-cell' key={url}>
            <Link
              to={url}
              className={classNames('custom-header-cell-link', {
                active: location.pathname === url
              })}
            >
              {text}
            </Link>
          </div>
        ))}

        <div className='custom-header-cell custom-header-menu-container' ref={dropdownRef}>
          <div
            className='custom-header-cell-link custom-header-cell-link-dropdown cursor-pointer'
            onClick={toggleMenu}
          >
            {t('header.more')}

            <button className='custom-header-menu-btn position-relative' onClick={toggleMenu}>
              <img
                className={classNames('custom-header-menu-btn-img', {
                  'custom-header-menu-btn-img-active': dropdownIsOpen
                })}
                src='/assets/icons/dropdown-arrow.svg'
                alt='arrow'
              />

              <ul
                className={classNames('custom-header-menu', {
                  'custom-header-menu-active': dropdownIsOpen
                })}
              >
                {NAV_LINKS_MORE.map(({ text, url }) => (
                  <li
                    key={url}
                    className={classNames('custom-header-menu-item', {
                      'custom-header-menu-item-active': location.pathname === url
                    })}
                  >
                    <Link
                      to={url}
                      className={classNames('custom-header-menu-item-link', {
                        'custom-header-menu-item-link-active': location.pathname === url
                      })}
                    >
                      {text}
                    </Link>
                  </li>
                ))}
              </ul>
            </button>
          </div>
        </div>

        <HeaderUser />
      </header>

      <div className='w-100 custom-header-mobile flex-column align-items-start justify-content-start wide-hide'>
        <div className='w-100 d-flex justify-content-start align-items-center custom-header-mobile-block'>
          <img
            src='/assets/images/logo.svg'
            alt='logo'
            className='custom-header-logo'
            onClick={() => navigate('/')}
          />

          <LiveIndicator className='' />

          {user ? (
            <div className='d-flex align-items-center'>
              <UserAvatar
                userDisplayName={user.displayName}
                userImageUrl={user.profileImageUrl}
                size={28}
                onClick={toggleProfileModal}
                className={'header-user-avatar header-user-avatar-mobile'}
              />

              <div
                className='header-user-balance header-user-balance-mobile w-100'
                onClick={logout}
              >
                <img src='/assets/icons/coin-small.svg' alt='coin' />

                <p className='m-0 d-inline'>{addCommasForBigNumbers(user.pointsBalance)}</p>
              </div>
            </div>
          ) : null}

          <button onClick={toggleNavbar} type='button' className='custom-header-mobile-toggle'>
            <img src='/assets/icons/burger.svg' alt='burger' />
          </button>
        </div>

        <ReactstrapCollapse isOpen={isOpen} className='custom-header-mobile-content'>
          <div className='custom-header-mobile-content-cell'>
            {!loading && user ? (
              <button
                type='button'
                className='custom-header-cell-link custom-header-cell-link--btn'
                onClick={() => {
                  toggleProfileModal()
                  toggleNavbar()
                }}
              >
                {t('header.links.profile')}
              </button>
            ) : (
              <button
                type='button'
                className='custom-header-cell-link custom-header-cell-link--btn'
                onClick={onLogin}
              >
                {t('auth.login')}
              </button>
            )}

            {showProfileModal && (
              <div className='position-relative'>
                <ProfileModal
                  modal={showProfileModal}
                  setModal={setShowProfileModal}
                  openConnectionsTab={openConnectionsTab}
                />
              </div>
            )}
          </div>

          {NAV_LINKS.map(({ text, url }) => (
            <div className='custom-header-mobile-content-cell' key={url}>
              <Link
                to={url}
                className={classNames('custom-header-cell-link', {
                  active: location.pathname.includes(url)
                })}
              >
                {text}
              </Link>
            </div>
          ))}

          {NAV_LINKS_MORE.map(({ text, url }) => (
            <div className='custom-header-mobile-content-cell' key={url}>
              <Link
                to={url}
                className={classNames('custom-header-cell-link', {
                  active: location.pathname.includes(url)
                })}
              >
                {text}
              </Link>
            </div>
          ))}

          {!loading && user && (
            <div className='custom-header-mobile-content-cell'>
              <button
                type='button'
                className='custom-header-cell-link custom-header-cell-link--btn'
                onClick={handleLogout}
              >
                {t('header.dropdown.item3')}
              </button>
            </div>
          )}
        </ReactstrapCollapse>
      </div>
      <LoginModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)} />
    </div>
  )
}

export default Header

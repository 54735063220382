import { User } from "@hooks/useUser"

export enum ITEMS_ORDER {
  ASC = "asc",
  DESC = "desc"
}

export enum POINTS_CARD_ORDER_BY {
  DEFAULT = "order", PRICE = "price", QUANTITY = "quantity"
}

export enum POINTS_STORE_PROFILE_TABLE {
  DATE = "updatedAt", PRICE = "price"
}

export type PointStoreProfileTableFilterPayload = {
  sortBy: POINTS_STORE_PROFILE_TABLE
  sortOrder: ITEMS_ORDER
  search?: string
}

export type PointCard = {
  createdAt: Date
  createdBy: string
  deleted: boolean
  description: string
  id: number
  imageUrl: string
  order: number
  price: number
  price_dollars: number
  quantity: number
  stockQuantity: number
  title: string
  updatedAt: Date
  userCooldown: number
}

export type PointCardsPayload = {
  search?: string
  orderBy: POINTS_CARD_ORDER_BY
  order: ITEMS_ORDER
}

export type PointsStoreStatisticEntity = {
  "mostPopularItem": {
    "title": string
    "description": string
    "quantity": number
    "price": number
    "imageUrl": string
    "sold": number
  },
  "totalPurchases": number
  "topSpender": {
    "displayName": string,
    "profileImageUrl": string
    "pointsSpent": number
  },
  "totalPointsSpent": number
  "uniqueBuyersCount": number
}

export type ProfilePointsStorePurchases = {
  createdAt: string
  id: number
  pointsShopItem: {
    description: string
    imageUrl: string
    price: number
    title: string
  }
  pointsShopItemId: number
  rejectionReason: string
  status: string
  updatedAt: Date
  user: {
    displayName: string
  }
  userId: string
}
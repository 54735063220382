import React, { FC, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useCurrentUser } from "@hooks/useUser"
import { CommentEntity, Comment } from "@modules/slotsReviews"
import { useOnClickOutside } from "@hooks/useOnClickOutside"
import classNames from "classnames"
import UserAvatar from "@src/components/UserAvatar"

type CommentsSectionProps = {
  comments: CommentEntity[]
  onLeaveComment: (text: string) => void
  isOpen?: boolean
  perPage: number
  setPerPage: (n: number) => void
  isCommented: boolean
  total: number
}

const MAX_REVIEW_COMMENT_LENGTH = 400

const CommentsSection: FC<CommentsSectionProps> = ({
  comments,
  onLeaveComment,
  isOpen,
  perPage,
  setPerPage,
  isCommented,
  total
}) => {
  const { t } = useTranslation()

  const textareaWrapperRef = useRef(null)

  const { user, loading } = useCurrentUser()

  const [textareaValue, setTextareaValue] = useState<string>("")
  const [focus, setFocus] = useState(false)

  useOnClickOutside(textareaWrapperRef, () => {
    if (focus && !isOpen) setTimeout(() => setFocus(false), 10)
  })

  const handleLeaveComment = () => {
    onLeaveComment(textareaValue)

    setTextareaValue("")
  }

  return (
    <section>
      {user && !isCommented &&
        <div className="slots-reviews-slot-comment position-relative" ref={textareaWrapperRef}>
          <textarea
            value={textareaValue}
            maxLength={MAX_REVIEW_COMMENT_LENGTH}
            className={classNames("slots-reviews-slot-comment-input", { "slots-reviews-slot-comment-input-focus": focus })}
            placeholder={t("currentSlotPage.currentSlotInfo.commentsPlaceholder")}
            onChange={(e) => setTextareaValue(e.target.value)}
            onClick={() => setFocus(true)}
          />

          {!loading &&
            <UserAvatar
              className="slots-reviews-slot-comment-input-img"
              userDisplayName={user.displayName}
              userImageUrl={user.profileImageUrl}
              size={30}
            />
          }

          <div
            className={classNames("slots-reviews-slot-comment-input-length shared-text shared-text--extra-small text-secondary-40", {
              "slots-reviews-slot-comment-input-length-focus": focus
            })}
          >
            <span>{textareaValue.length}/</span>

            <span>{MAX_REVIEW_COMMENT_LENGTH}</span>
          </div>

          <div className={classNames("slots-reviews-slot-comment-submit-wrapper", {
            "slots-reviews-slot-comment-submit-wrapper-focus": focus
          })}
          >
            <button
              className="slots-reviews-slot-comment-cancel shared-btn-secondary"
              onClick={() => {
                setFocus(false)
                setTextareaValue("")
              }}
            >
              {t("currentSlotPage.currentSlotInfo.cancelBtn")}
            </button>

            <button
              disabled={textareaValue.length === 0}
              className="slots-reviews-slot-comment-submit shared-btn-primary"
              onClick={handleLeaveComment}
            >
              {t("currentSlotPage.currentSlotInfo.commentBtn")}
            </button>
          </div>
        </div>
      }
      {comments?.length > 0 && (
        <div className="slots-reviews-slot-comments">
          {comments.map((comment) => (
            <Comment comment={comment} key={comment.id} />
          ))}
          {perPage < total && (
            <div className="d-flex w-100 align-items-center justify-content-center">
              <button
                className="slots-reviews-btn shared-btn-secondary"
                onClick={() => setPerPage(perPage + 5)}
              >
                {t("currentSlotPage.showMore")}
              </button>
            </div>
          )}

          {perPage > total && total > 5 && (
            <div className="d-flex w-100 align-items-center justify-content-center">
              <button
                className="slots-reviews-btn shared-btn-secondary"
                onClick={() => {
                  setPerPage(5)
                }}
              >
                {t("currentSlotPage.showLess")}
              </button>
            </div>
          )}
        </div>
      )}

    </section>
  )
}

export default CommentsSection

import React from "react"
import { useTranslation } from "react-i18next"

import SocialCard from "@src/components/SocialCard"
import { HADDZY_KICK_LINK } from "@hooks/useKickOnlineStatus"


const Socials = () => {
  const { t } = useTranslation()

  return (
    <section className="landing-socials shared-container w-100 align-items-center justify-content-between">
      <div className="landing-socials-title">
        <h2 className="mid-headline mid-headline-primary">{t("landing.socials.title1")}</h2>

        <h2 className=" mid-headline d-flex">&nbsp;{t("landing.socials.title2")}</h2>
      </div>

      <p className="landing-socials-description description tablet-max-hide">
        {t("landing.socials.text")}
      </p>

      <div className="landing-socials-section w-100">
        <SocialCard title="Facebook" subtitle="New Video Soon!" link="https://www.facebook.com/Haddzy" />

        <SocialCard title="Kick" subtitle="Stream Soon!" link={HADDZY_KICK_LINK} />

        <SocialCard title="Youtube" subtitle="Stream Soon!" link="https://www.youtube.com/channel/UCOKNEzhIAvZDaP805jUkGEQ" />

        <SocialCard title="Instagram" subtitle="Stream Soon!" link="https://instagram.com/haddzy?igshid=MzRlODBiNWFlZA==" />
      </div>
    </section>
  )
}
export default Socials

import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchUserRaffleEntries } from "@modules/raffles/requests"
import { User } from "@hooks/useUser"
import { useEffect } from "react"

export const useUserRaffleEntries = (raffleId: number, user: User) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`/${raffleId}/my-entries`, () => (user ? fetchUserRaffleEntries(raffleId) : null))

  useEffect(() => {
    if (!!user?.id && !data && !isValidating) {
      refresh()
    }
  }, [user?.id])

  return {
    entry: data || [],
    error,

    refresh,

    isValidating,
    loading: !data && !error
  }
}

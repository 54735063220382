// ** Imports createContext function
import { createContext } from 'react'

// ** Imports createContextualCan function
import { createContextualCan } from '@casl/react'
import { Ability } from '@casl/ability'

export enum ACTIONS {
  READ = 'view',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum SUBJECTS {
  ALL = 'all',
  LEADERBOARD = 'leaderboard'
}

type Actions = 'create' | 'view' | 'update' | 'delete'

type Subjects =
  | 'all'
  | 'leaderboard'

type AppAbility = [Actions, Subjects]

// ** Create Context
export const AbilityContext = createContext(new Ability<AppAbility>())

// ** Init Can Context
export const Can = createContextualCan<any>(AbilityContext.Consumer)

export const buildAbilityFromRole = (ability: Ability, rules: any[] = []): void => {
  ability.update(
    rules.map((rule) => ({
      action: Object.keys(rule.permissions).map((permission) => permission.toLowerCase()),
      subject: rule.routeName.toLowerCase()
    }))
  )
}

import { ChangeEvent, FC, useEffect, useId, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { Input, Label } from "reactstrap"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import "./styles.scss"

type ImageUploaderProps = {
  url?: string | File
  onChange: (url: string | File | undefined) => void
  className?: string
  inputClassNames?: string
}

const ImageUploader: FC<ImageUploaderProps> = ({ className, url, onChange, inputClassNames }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const id = useId()
  const inputRef = useRef(null)

  const [asset, setAsset] = useState<string | File | undefined>(url)
  const [imgSuccessfullyLoaded, setImgSuccessfullyLoaded] = useState<boolean>(true)
  const [linkInput, setLinkInput] = useState<string>("")

  useEffect(() => {
    if (url && !asset) {
      setAsset(url)
    }
  }, [url])

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const fileObj = event.target.files && event.target.files[0]
    if (!fileObj) {
      return
    }
    // @ts-ignore
    event.target.value = null

    setAsset(fileObj)

    setLinkInput("")

    setImgSuccessfullyLoaded(false)
  }

  const handleLoadError = () => {
    setImgSuccessfullyLoaded(false)

    if (url) {
      toast('Something went wrong trying to load asset!', TOAST_TYPE.ERROR)
    }

    onChange(undefined)
  }

  const handleOnDelete = () => {
    setLinkInput("")
    setAsset(undefined)
    onChange(undefined)
  }

  const handleImgLoaded = () => {
    if (!imgSuccessfullyLoaded && asset) {
      setImgSuccessfullyLoaded(true)

      onChange(asset)
    }
  }
  return (
    <div className={classNames("image-uploader d-flex flex-row", className)}>
      <div className="image-uploader-img">
        {asset && (
          <img
            src={typeof (asset) === "object" ? URL.createObjectURL(asset as File) : asset}
            alt=""
            onLoad={handleImgLoaded}
            onError={handleLoadError}
          />
        )}
      </div>

      <div className="image-uploader-content">
        <input
          type="text"
          onChange={(e) => {
            setLinkInput(e.target.value)

            setImgSuccessfullyLoaded(false)
          }}
          value={linkInput}
          className={classNames("image-uploader-content-input", inputClassNames)}
          placeholder={t("admin-bonuses.modal.imgPlaceholder")}
          onBlur={() => setAsset(linkInput)}
        />

        <div className="d-flex align-items-center flex-row justify-content-between w-100">
          <Label for={`uploader${id}`} className="image-uploader-content-upload m-0">
            {t("admin-bonuses.modal.chooseFile")}
          </Label>

          <Input
            id={`uploader${id}`}
            name="file"
            ref={inputRef}
            accept="image/png, image/jpg, image/jpeg"
            cssModule={{ "form-control": " " }}
            type="file"
            onChange={handleFileUpload}
            className={"image-uploader-content-upload d-none"}
          />

          <button type="button" onClick={handleOnDelete} className="image-uploader-content-delete">
            <img src="/assets/icons/delete.svg" alt="delete" />
          </button>
        </div>
      </div>
    </div>
  )
}

export default ImageUploader

import axios from "network"

import { EBonusType, TopBonus } from "@modules/adminBonuses/dto"
import { REGION } from "@context/Region"

export const updateItemOrderRequest = (payload: { id: string, order: number }[]) => {
  const items = {
    items: payload
  }
  void axios.post("/bonuses/change-order", items, {
    withCredentials: true
  })
}


export const getAllBonuses = (region: REGION = REGION.GLOBAL) => axios.get<TopBonus[]>("/bonuses", {
  params: {
    type: EBonusType.REGULAR,
    region
  },
  withCredentials: true
})

import React, { FC } from "react"
import classNames from "classnames"
import { useNavigate } from "react-router-dom"
import { format } from "date-fns"
import { useTranslation } from "react-i18next"

import Countdown from "components/Countdown"
import { addCommasForBigNumbers } from "@utils/Strings"


import "./styles.scss"

type LeaderboardHistoryCardProps = {
  id: string,
  start: string,
  ended: string,
  name: string,
  imgUrl: string,
  isHistoryPage?: boolean,
  isActive?: boolean,
  isAdminPage?: boolean
  prize: number
};

const LeaderboardHistoryCard: FC<LeaderboardHistoryCardProps> = ({
  id,
  start,
  ended,
  name,
  imgUrl,
  isHistoryPage,
  isActive,
  isAdminPage,
  prize
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className={classNames("leaderboard-history-card", {
      "leaderboard-history-card-for-history": isHistoryPage
    })}
    >
      {isAdminPage && isActive ? (
        <div className="leaderboard-history-card-hover">
          <span className={classNames("leaderboard-history-card-hover-date leaderboard-history-card-date", {
            "leaderboard-history-card-hover-date-admin leaderboard-history-card-date-admin": isAdminPage
          })}
          >
            {format(new Date(start), "MMMM yyyy")}
          </span>

          <p className="leaderboard-history-card-hover-title">
            {t("leaderboard-history-card.title")}
          </p>

          <div className="leaderboard-history-card-hover-countdown">
            <div className="leaderboard-history-card-hover-countdown-title">time to end</div>

            <Countdown date={ended} className="countdown-leaderboard-card" timeType />
          </div>

          <button
            className="leaderboard-history-card-hover-btn shared-btn-secondary"
            onClick={() => navigate(`/admin/leaderboards/${id}`)}
          >
            {t("leaderboard-history-card.btn-hover-current")}
          </button>
        </div>
      ) : (
        <div className="leaderboard-history-card-hover">
          <img className="leaderboard-history-card-winner" src="/assets/icons/winner.svg" alt="winner" />

          <p className="leaderboard-history-card-hover-title">
            {t("leaderboard-history-card.title")}
          </p>

            <span className={classNames("leaderboard-history-card-hover-date leaderboard-history-card-date", {
              "leaderboard-history-card-hover-date-admin leaderboard-history-card-date-admin": isAdminPage
            })}
            >
            {format(new Date(start), "MMMM yyyy")}
          </span>


          <button
            className="leaderboard-history-card-hover-btn shared-btn-secondary"
            onClick={() => (isHistoryPage ? navigate(id) : navigate(`history/${id}`))}
          >
            {isAdminPage ? t("leaderboard-history-card.btn-hover-admin") : t("leaderboard-history-card.btn-hover")}
          </button>
        </div>
      )}

      <div className="leaderboard-history-card-date-wrapper leaderboard-history-card-date-wrapper-admin">
        {isAdminPage ? (
          <>
          <span className="leaderboard-history-card-date leaderboard-history-card-date-admin">
            {format(new Date(start), "MMMM yyyy")}
          </span>

            {isActive ? (
              <span
                className="leaderboard-history-card-date leaderboard-history-card-date-admin leaderboard-history-card-date-current"
              >
                {t("leaderboard-history-card.current")}
              </span>
            ) : (
              <span className={classNames("leaderboard-history-card-date", {
                "leaderboard-history-card-date-admin": isAdminPage
              })}
              >
                {isAdminPage && t("leaderboard-history-card.end")}
              </span>
            )}
          </>
        ) : (
          <span className="leaderboard-history-card-date leaderboard-history-card-date-admin">
             {format(new Date(start), "MMMM yyyy")}
          </span>
        )}


      </div>

      <img
        className={classNames("leaderboard-history-card-winner", { "d-none": isAdminPage })}
        src="/assets/icons/winner.svg"
        alt="winner"
      />

      <img src={imgUrl} alt="logo" className="leaderboard-history-card-logo" />

      <h4 className="leaderboard-history-card-name">{name}</h4>

      <div className="leaderboard-history-card-footer position-relative d-flex flex-column align-items-center">
        <p className="leaderboard-history-card-footer-title">{t("leaderboard-history-card.subtitle")}</p>

        <button className="leaderboard-history-card-btn shared-btn-primary"><span>$</span>{addCommasForBigNumbers(prize)}</button>
      </div>
    </div>
  )
}

export default LeaderboardHistoryCard

import { FC, useState } from "react"
import classNames from "classnames"

import { getInitials } from "@utils/Strings"

import './styles.scss'

type UserAvatarProps = {
  userImageUrl: string
  userDisplayName: string
  className?: string;
  size?: number
  onClick?: () => void
};

const UserAvatar: FC<UserAvatarProps> = ({ size = 96, className, onClick, userDisplayName, userImageUrl }) => {
  const [errorInLoading, setErrorInLoading] = useState<boolean>(false)

  const styles = {
    width: size,
    height: size,
    minWidth: size,
    minHeight: size
  }

  if (userImageUrl && !errorInLoading) {
    return (
      <img
        onError={() => setErrorInLoading(true)}
        src={userImageUrl}
        alt="user"
        style={styles}
        className={classNames("user-avatar user-avatar-img", className)}
        onClick={() => onClick && onClick()}
      />
    )
  }

  return (
    <div
      className={classNames("user-avatar user-avatar-initials", className)} style={styles}
      onClick={() => onClick && onClick()}
    >
      {getInitials(userDisplayName)}
    </div>
  )
}

export default UserAvatar

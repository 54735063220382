import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import JsonView from "react18-json-view"

import { Modal } from "reactstrap"

type RaffleInstructionModalProps = {
  isOpen: boolean
  onClose: () => void
}

const verifyJson = {
  method: "generateSignedIntegers",
  hashedApiKey:
    "vltnt+lj1hMUwheEilZsTo5hMYSaSFBE+oYXNYq4YEQZnTJFQ23wAZvNtHoGHvCgSTCaeNc9Z8LP0hlXFTXSPw==",
  n: 1,
  min: 1,
  max: 50,
  replacement: true,
  base: 10,
  pregeneratedRandomization: null,
  data: [37],
  license: {
    type: "developer",
    text: "Random values licensed strictly for development and testing only",
    infoUrl: null
  },
  licenseData: null,
  userData: null,
  ticketData: null,
  completionTime: "2023-08-08 07:07:41Z",
  serialNumber: 37
}

const RaffleInstructionModal: FC<RaffleInstructionModalProps> = ({ onClose, isOpen }) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close rounded" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <div className="instruction-modal-body">
        <h2 className="instruction-modal-title">{t("raffles.instruction.title")}</h2>

        <h3 className="instruction-modal-subtitle">{t("raffles.instruction.h1")}</h3>

        <p className="shared-text text-daylight mb-0">
          {t("raffles.instruction.p1.1")}<br />
          {t("raffles.instruction.p1.2")}<br />
          {t("raffles.instruction.p1.3")}<br />
          {t("raffles.instruction.l1.1")}<br />
          {t("raffles.instruction.l1.2")}<br />
          {t("raffles.instruction.l1.3")}<br />
          {t("raffles.instruction.l1.4")}
        </p>

        <h3 className="instruction-modal-subtitle">{t("raffles.instruction.h2")}</h3>

        <p className="shared-text text-daylight mb-0">
          {t("raffles.instruction.l2.1")}<br />
          {t("raffles.instruction.l2.2")}<br />
          {t("raffles.instruction.l2.3")}<br />
          {t("raffles.instruction.p2.1")}
        </p>

        <h3 className="instruction-modal-subtitle">{t("raffles.instruction.h3")}</h3>

        <p className="shared-text text-daylight mb-0">
          {t("raffles.instruction.l3.1")}&nbsp;<a className="text-daylight"
                                                  href="https://api.random.org/signatures/form" target="_blank">Random.org
          Result Verification Page.</a><br />
          {t("raffles.instruction.l3.2")}
        </p>

        <JsonView collapseStringsAfterLength={40} src={verifyJson} />

        <p className="shared-text text-daylight mb-0">
          {t("raffles.instruction.l3.3")}<br />
          {t("raffles.instruction.l3.4")}
        </p>

        <h3 className="instruction-modal-subtitle">{t("raffles.instruction.h4")}</h3>

        <p className="shared-text text-daylight mb-0">
          {t("raffles.instruction.l4.1")}<br />
          {t("raffles.instruction.l4.2")}<br />
          {t("raffles.instruction.l4.3")}<br />
          {t("raffles.instruction.l4.4")}<br />
          {t("raffles.instruction.l4.5")}
        </p>

        <h3 className="instruction-modal-subtitle">{t("raffles.instruction.h5")}</h3>

        <p className="shared-text text-daylight mb-0">
          {t("raffles.instruction.p5.1")}
        </p>
      </div>
    </Modal>
  )
}

export default RaffleInstructionModal

import { RAFFLES_ORDER } from "@modules/raffles"

export type SearchPayload = {
  search?: string
}

export enum CommentStatus {
  ALL = 'all',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  PENDING = 'PENDING'
}

export enum CommentSortBy {
  CREATED_AT = 'created_at',
  UPDATED_AT = 'updated_at'
}

export type CommentTablePayload = {
  search?: string
  status: CommentStatus
  sortBy: CommentSortBy
  sortOrder: RAFFLES_ORDER
}

export type Comment = {
  id: string,
  createdAt: string,
  updatedAt: string,
  userId: string,
  slotId: string,
  comment: string,
  rate: number,
  status: CommentStatus,
  slot: {
    name: string
  },
  user: {
    id: string,
    displayName: string,
    profileImageUrl: string
  }
}

import { usePlainSWR } from "@hooks/usePlainSWR"

import { DashboardStatsEntity, fetchDashboardStats } from "@modules/adminUsers"

export const useDashboardStats = () => {
  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWR("users/stats/dashboard", () => fetchDashboardStats())

  return {
    data: data as DashboardStatsEntity,
    error,

    refresh,

    loading: !data && !error
  }
}

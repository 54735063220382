import { lazy } from "react"

import { RouteEntity } from "@src/router/routes"

import "./views/leaderboards.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export * from "./dto"

export * from "./requests"
export * from "./helpers"

export * from "./useLeaderboard"
export * from "./useUsersByLeaderboardId"

const LeaderBoards = lazy(() => import("./views/ActiveLeaderboardPage"))
const LeaderboardByIdPage = lazy(() => import("./views/LeaderboardByIdPage"))
const LeaderboardsHistory = lazy(() => import("./views/LeaderboardsHistory"))

export { default as ActiveLeaderboard } from "./components/ActiveLeaderboard"
export { default as HistorySection } from "./components/HistorySection"
export { default as LeaderboardSection } from "./components/LeaderboardSection"
export { default as Leaders } from "./components/Leaders"
export { default as RandomWinners } from "./components/RandomWinners"
export { default as RandomWinnersHistory } from "./components/RandomWinnersHistory"
export { default as SectionTitle } from "./components/SectionTitle"

export const router: RouteEntity[] = [
  {
    path: "/leaderboards/",
    element: <LeaderBoards />
  },
  {
    path: "/leaderboards/history",
    element: <LeaderboardsHistory />
  },
  {
    path: "/leaderboards/history/:id",
    element: <LeaderboardByIdPage />
  }
]

export const locales = {
  en,
  ru
}

export enum TABLE_ORDER {
  ASC = "asc",
  DESC = "desc"
}

export enum USERS_ORDER_BY {
  NAME = "displayName",
  JOINED = "createdAt",
  KICK_USERNAME = "kickUsername",
  POINTS_SPEND = "pointsSpent",
  POINTS_BALANCE = "coreStatus"
}

export type AdminUserTablePayload = {
  search?: string
  orderBy: USERS_ORDER_BY
  order: TABLE_ORDER
}

export type DashboardStatsEntity = {
  totalPointsPool: number
  totalPointsSpent: number
  totalUsers: number
}


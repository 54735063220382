import React, { FC, useEffect, useState } from 'react'
import classNames from 'classnames'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { mutate } from 'swr'

import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap'

import { addCommasForBigNumbers } from '@utils/Strings'

import { useCurrentUser, User, UserWallets, useUser } from '@hooks/useUser'
import useToast, { TOAST_TYPE } from '@hooks/useToast'

import WalletInput from 'components/WalletInput'
import TogglePointsByIdModal from 'components/TogglePointsByIdModal'
import UserAvatar from 'components/UserAvatar'
import RafflesTab from 'components/ProfileModal/RafflesTab'
import PointShopTab from 'components/ProfileModal/PointShopTab'
import ConnectionsTab from 'components/ProfileModal/ConnectionsTab'

import { BanUserModal, updateUserCryptoWalletsRequest } from '@modules/adminUsers'

import './styles.scss'
import ChangeUsernameModal from '@src/components/ChangeUsernameModal'
import CaseItemsTab from './CaseItemsTab'

type ProfileModalProps = {
  modal: boolean
  setModal: (isVisible: boolean) => void
  user?: User
  isAdmin?: boolean
  userRefresh: () => void
  myId?: string
  openConnectionsTab?: boolean
  refreshUsersList?: () => void
}

const ProfileModal: FC<ProfileModalProps> = ({
  isAdmin = false,
  modal,
  setModal,
  user,
  userRefresh,
  myId,
  openConnectionsTab,
  refreshUsersList
}) => {
  const { t } = useTranslation()
  const toast = useToast()

  enum E_MODAL_TAB {
    MAIN = 'main',
    RAFFLES = 'raffles',
    POINTS = 'points shop',
    CONNECTIONS = 'connections',
    CASE_ITEMS = 'case items'
  }

  const [active, setIsActive] = useState(
    openConnectionsTab ? E_MODAL_TAB.CONNECTIONS : E_MODAL_TAB.MAIN
  )
  const [showBanModal, setShowBanModal] = useState(false)
  const [showPointsModal, setShowPointsModal] = useState(false)
  const [showChangeUsernameModal, setShowChangeUsernameModal] = useState(false)
  const [userInfo, setUserInfo] = useState<User>(user!)
  const { user: currentUser } = useCurrentUser()

  const toggleBan = () => setShowBanModal(!showBanModal)

  const togglePoints = () => setShowPointsModal(!showPointsModal)

  const tabHandler = (tab: E_MODAL_TAB) => setIsActive(tab)

  const toggle = () => setModal(!modal)

  useEffect(() => {
    if (user) {
      setUserInfo(user)
    }
  }, [user])

  const handleUpdateUserWallet = async (payload: UserWallets) => {
    try {
      const res = await updateUserCryptoWalletsRequest(userInfo.id, payload)

      const data = {
        btcAddress: res.data.btcAddress,
        createdAt: res.data.createdAt,
        ethAddress: res.data.ethAddress,
        id: res.data.userId,
        updatedAt: res.data.updatedAt,
        usdtErc20Address: res.data.usdtErc20Address
      }
      setUserInfo((prev) => ({ ...prev, ...data }))

      toast('Address successfully saved!', TOAST_TYPE.SUCCESS)
    } catch (e: unknown) {
      // @ts-ignore
      toast(e?.response?.data?.message?.join(',') || '', TOAST_TYPE.ERROR)
    }
  }

  const closeBtn = (
    <button className='profile-modal-close' onClick={toggle} type='button'>
      <img src='/assets/icons/close.svg' alt='close' />
    </button>
  )

  if (!userInfo) return null

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        contentClassName='profile-modal'
        className='profile-modal-container'
        backdrop='static'
        centered={true}
      >
        <ModalHeader
          toggle={toggle}
          close={closeBtn}
          cssModule={{ 'modal-header': 'profile-modal-header' }}
        >
          {t('profile.profile')}
        </ModalHeader>

        <ModalBody className='p-0'>
          <div className='profile-modal-tab-wrapper'>
            {Object.values(E_MODAL_TAB).map((value) => (
              <button
                className={classNames('profile-modal-tab ', {
                  'profile-modal-tab-active': value === active
                })}
                name={value}
                key={value}
                onClick={() => tabHandler(value)}
              >
                {value}
              </button>
            ))}
          </div>

          {active === E_MODAL_TAB.MAIN && (
            <div className='profile-modal-wrapper'>
              <div className='profile-modal-user'>
                <UserAvatar
                  userDisplayName={userInfo.displayName}
                  userImageUrl={userInfo.profileImageUrl}
                />

                <div className='profile-modal-user-info-container'>
                  <div className='profile-modal-user-name-container'>
                    <div className='d-flex align-items-center'>
                      <p className='profile-modal-user-name text-truncate'>
                        {userInfo.displayName}
                      </p>

                      <button
                        className='profile-modal-user-name-edit-btn'
                        onClick={() => setShowChangeUsernameModal(true)}
                      >
                        <img src='/assets/icons/edit-2.svg' alt='edit' />
                      </button>
                    </div>

                    <span className='profile-modal-user-date'>
                      {t('profile.lastLogin')}:{' '}
                      {format(new Date(userInfo.updatedAt || userInfo.createdAt), 'MM.dd.yyyy')}
                    </span>
                  </div>

                  <div className='profile-modal-user-info'>
                    <p>
                      {t('profile.joined')}: {format(new Date(userInfo.createdAt), 'MM.dd.yyyy')}
                    </p>

                    <p>
                      {t('profile.email')}: {userInfo.email}
                    </p>
                  </div>

                  <span className='profile-modal-user-balance'>
                    <img src='/assets/icons/Coin.svg' alt='' />
                    {t('profile.points')}: {addCommasForBigNumbers(userInfo.pointsBalance)}
                    {isAdmin && (
                      <button className='profile-modal-user-balance-btn' onClick={togglePoints}>
                        <img src='/assets/icons/edit.svg' alt='edit' />
                      </button>
                    )}
                  </span>

                  {isAdmin && showPointsModal && user && (
                    <TogglePointsByIdModal
                      onSubmit={() => {
                        const updateInfo =
                          user?.id === currentUser?.id
                            ? () => {
                                mutate('/user')
                                userRefresh()
                                if (refreshUsersList) {
                                  refreshUsersList()
                                }
                              }
                            : () => {
                                userRefresh()
                                if (refreshUsersList) {
                                  refreshUsersList()
                                }
                              }
                        updateInfo()
                        setShowPointsModal(false)
                      }}
                      onClose={() => setShowPointsModal(false)}
                      defaultId={user.id!}
                    />
                  )}
                </div>
              </div>

              <div className='profile-modal-statistics'>
                <div className='profile-modal-statistics-title'>
                  <img src='/assets/icons/statistics.svg' alt='statistics-icon' />

                  <p>{t('profile.statistics')}</p>
                </div>

                <div className='d-flex w-100 align-items-center justify-content-center'>
                  <div className='profile-modal-statistics-container'>
                    <div className='profile-modal-statistics-block'>
                      <p className='profile-modal-statistics-block-title'>
                        {t('profile.raffles-won')}
                      </p>

                      <img src='/assets/icons/Coin.svg' alt='coin' />

                      <p className='profile-modal-statistics-block-balance'>
                        {userInfo.rafflesWon || 0}
                      </p>
                    </div>

                    <div className='profile-modal-statistics-block'>
                      <p className='profile-modal-statistics-block-title'>
                        {t('profile.points-spent')}
                      </p>

                      <img src='/assets/icons/Coin.svg' alt='coin' />

                      <p className='profile-modal-statistics-block-balance'>
                        {addCommasForBigNumbers(userInfo.pointsSpent) || 0}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className='profile-modal-crypto'>
                <div className='profile-modal-crypto-title'>
                  <img src='/assets/icons/dollar.svg' alt='dollar-icon' />

                  <p>{t('profile.my-crypto')}</p>
                </div>

                <WalletInput
                  onSubmit={(address) => handleUpdateUserWallet({ btcAddress: address })}
                  address={userInfo.btcAddress || ''}
                  type='btc'
                  img='/assets/icons/btc.svg'
                  placeholder={t('profile.btcPlaceholder')}
                />

                <WalletInput
                  onSubmit={(address) => handleUpdateUserWallet({ ethAddress: address })}
                  address={userInfo.ethAddress || ''}
                  type='eth'
                  img='/assets/icons/eth.svg'
                  placeholder={t('profile.ethPlaceholder')}
                />

                <WalletInput
                  onSubmit={(address) => handleUpdateUserWallet({ usdtErc20Address: address })}
                  address={userInfo.usdtErc20Address || ''}
                  type='usdt'
                  img='/assets/icons/usdt.svg'
                  placeholder={t('profile.usdtPlaceholder')}
                />
              </div>

              {isAdmin && (
                <Button cssModule={{ btn: 'profile-modal-ban-btn' }} onClick={toggleBan}>
                  {userInfo.isBanned ? t('profile.unban') : t('profile.ban')}
                </Button>
              )}

              <BanUserModal
                user={userInfo}
                onClose={() => setShowBanModal(false)}
                isOpen={showBanModal}
                onApply={() => {
                  mutate('/admin-users')
                  setShowBanModal(false)
                  userRefresh()
                }}
              />

              {showChangeUsernameModal && user && (
                <ChangeUsernameModal
                  onSubmit={() => {
                    mutate('/user')
                    userRefresh()
                    setShowChangeUsernameModal(false)
                  }}
                  onClose={() => setShowChangeUsernameModal(false)}
                  user={user}
                />
              )}
            </div>
          )}

          {active === E_MODAL_TAB.RAFFLES && <RafflesTab user={user} />}

          {active === E_MODAL_TAB.POINTS && <PointShopTab user={user} />}

          {active === E_MODAL_TAB.CONNECTIONS && (
            <ConnectionsTab user={user} refresh={userRefresh} isAdminPage={isAdmin} myId={myId} />
          )}

          {active === E_MODAL_TAB.CASE_ITEMS && <CaseItemsTab user={user} />}
        </ModalBody>
      </Modal>
    </div>
  )
}

type ProfileModalWrapperProps = {
  openConnectionsTab?: boolean
  id?: string
  modal: boolean
  setModal: (isVisible: boolean) => void
  isAdmin?: boolean
  myId?: string
  refreshUsersList?: () => void
}

const ProfileModalWrapper: FC<ProfileModalWrapperProps> = ({
  id,
  modal,
  setModal,
  isAdmin,
  myId,
  openConnectionsTab,
  refreshUsersList
}) => {
  const { user: currentUser } = useCurrentUser(!id)
  const { user, refresh, loading } = useUser(id)

  useEffect(() => {
    if (modal && user && !loading) {
      refresh()
    }
  }, [modal])

  return (
    <ProfileModal
      openConnectionsTab={openConnectionsTab}
      modal={modal}
      setModal={setModal}
      user={id ? user : currentUser}
      isAdmin={isAdmin}
      myId={myId}
      userRefresh={() => (id ? mutate(`/user/${id}`) : mutate('/user'))}
      refreshUsersList={refreshUsersList}
    />
  )
}

export default ProfileModalWrapper

import { useEffect, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import { RAFFLE } from "constants/swr"

import {
  RaffleCardEntity,
  RAFFLES_STATUS,
  RafflesPayload,
  fetchRaffles,
  ADMIN_RAFFLE_SORT,
  RAFFLES_ORDER
} from "@modules/raffles"

export const useRaffles = (status: RAFFLES_STATUS, withPagination = true) => {
  const [filter, setFilter] = useState<RafflesPayload>({
    sortBy: ADMIN_RAFFLE_SORT.ORDER,
    sortOrder: RAFFLES_ORDER.DESC
  })

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(8)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
    // @ts-ignore
  } = usePlainSWR(`${RAFFLE}-${status}`, () => fetchRaffles(withPagination ? {
    ...filter,
    page,
    perPage
  } : filter, status))

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const raffleCards: RaffleCardEntity[] = (data?.items || [])

  return {
    list: raffleCards,
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 0,

    onFiltered: (newFilter: Partial<RafflesPayload>) => setFilter((prev) => ({ ...prev, ...newFilter })),
    filter
  }
}

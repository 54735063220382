import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { SlotInfo, SlotsCard } from "@modules/slotsReviews"
import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"
import { User } from "@hooks/useUser"

type CurrentSlotInfoProps = {
  slot: SlotInfo
  isCommented: boolean
  setShowRateModal: (n: boolean) => void
  setRateModalWithTextarea: (n: boolean) => void
  setLoginModal: (n: boolean) => void
  user: User
}

const CurrentSlotInfo: FC<CurrentSlotInfoProps> = ({ slot, setShowRateModal, isCommented, setRateModalWithTextarea, setLoginModal, user }) => {
  const { t } = useTranslation()

  const handleRateBtn = () => {
      if (user) {
        setShowRateModal(true)
        setRateModalWithTextarea(true)
      } else {
        setLoginModal(true)
      }
  }

  return (
    <section className="slots-reviews-slot-info-wrapper">
      <div className="slots-reviews-slot-info-container">
        <SlotsCard card={slot} key={slot.id} className="slots-reviews-slot-info-description-info-card" />

        <div className="d-flex flex-column justify-content-between">
          <h3 className="slots-reviews-slot-info-title m-0">
            {slot.name}
          </h3>

          <div className="slots-reviews-slot-info-provider">
            <p className="slots-reviews-slot-info-text">
              {t("currentSlotPage.currentSlotInfo.provider")}
            </p>

            <p className="slots-reviews-slot-info-subtitle text-capitalize">
              {slot.provider.name}
            </p>
          </div>

          <div className="slots-reviews-slot-info-footer">
            <div className="slots-reviews-slot-info-rating">
              <div className="slots-reviews-slot-info-rating-stars">
                {[...Array(5)].map((star, index) => {
                  return (
                    <div className="slots-reviews-slot-info-rating-stars-icon" key={`${index}${slot.id}`}>
                      <StarIcon
                        className={classNames("", index <= slot.adminRate - 1 ? "text-yellow" : "text-secondary-25")} />
                    </div>
                  )
                })}
              </div>

              <p className="slots-reviews-slot-info-text">
                {t("currentSlotPage.currentSlotInfo.haddzy")}
              </p>
            </div>

            <div className="slots-reviews-slot-info-rating">
              <div className="slots-reviews-slot-info-rating-stars">
                {[...Array(5)].map((star, index) => {
                  return (
                    <div className="slots-reviews-slot-info-rating-stars-icon" key={`${index}${slot.id}`}>
                      <StarIcon
                        className={classNames("", index <= slot.averageRating - 1 ? "text-yellow" : "text-secondary-25")} />
                    </div>
                  )
                })}
              </div>

              <p className="slots-reviews-slot-info-text">
                {slot.usersRated || 0}&nbsp;{t("currentSlotPage.currentSlotInfo.users")}
              </p>
            </div>

            <button
              className="slots-reviews-btn shared-btn-secondary mobile-hide"
              onClick={handleRateBtn}
              disabled={isCommented}
            >
              {isCommented ? (
                <div className="slots-reviews-rated">
                  {t("currentSlotPage.currentSlotInfo.rated")}
                  <img src="/assets/icons/check-alternative.svg" alt="check" />
                </div>
              ) : (
                t("currentSlotPage.currentSlotInfo.rateSlotBtn")
              )}
            </button>
          </div>
        </div>
      </div>

      <button
        className="slots-reviews-btn slots-reviews-slot-info-description-info-btn shared-btn-secondary mt-4 wide-hide laptop-hide tablet-hide"
        onClick={handleRateBtn}
        disabled={isCommented}
      >
        {isCommented ? (
          <div className="slots-reviews-rated">
            {t("currentSlotPage.currentSlotInfo.rated")}
            <img src="/assets/icons/check-alternative.svg" alt="check" />
          </div>
        ) : (
          t("currentSlotPage.currentSlotInfo.rateSlotBtn")
        )}
      </button>
    </section>
  )
}

export default CurrentSlotInfo

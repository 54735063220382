import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Button } from "reactstrap"

import { getBonusText } from "@modules/bonuses"

import { Bonus, TopBonus } from "@modules/adminBonuses"

type TopBonusCardProps = {
  bonus: TopBonus
  onDeleteItem?: (b: Bonus) => void
  onEditItem?: (b: Bonus) => void
  showControl?: boolean
}

const TopBonusCard: FC<TopBonusCardProps> = ({ bonus, onDeleteItem, onEditItem, showControl }) => {
  const { t } = useTranslation()

  return (
    <div
      className="bonuses-rewards-bonus bonuses-rewards-bonus--bordered overflow-hidden position-relative"
      key={bonus.id}
    >
      {!showControl ? null : (
        <div className="admin-bonuses-btn-group">
          <button className="admin-bonuses-btn " onClick={() => onEditItem && onEditItem(bonus)}>
            <img className="admin-bonuses-btn-edit" src="/assets/icons/edit-2.svg" alt="edit" />
          </button>

          <button className="admin-bonuses-btn admin-bonuses-btn-delete"
                  onClick={() => onDeleteItem && onDeleteItem(bonus)}>
            <img className="admin-bonuses-btn-delete" src="/assets/icons/delete.svg" alt="delete" />
          </button>
        </div>
      )}
      <div className="bonuses-rewards-bonus-img-wrapper full">
        <img
          className="bonuses-rewards-bonus-img cursor-pointer"
          src={bonus.imageUrl || "/assets/images/bonus-logo-mock.png"}
          alt={bonus.id}
          onClick={() => window.open(bonus.refUrl, "_blank")}
        />
      </div>

      <div className="bonuses-rewards-bonus-separator">
        <img src="/assets/icons/gift.svg" alt="gift" />
      </div>

      <div className="bonuses-rewards-bonus-content w-100">
        <p className="bonuses-rewards-bonus-name">{bonus.title}</p>

        <div className="w-100 d-flex flex-row align-items-stretch justify-content-between">
          <div className="bonuses-rewards-bonus-text">
            {getBonusText(bonus.firstLineDescription)}
          </div>

          <div className="bonuses-rewards-bonus-text">
            {getBonusText(bonus.secondLineDescription)}
          </div>
        </div>

        <div className="d-flex align-items-center flex-column position-relative">
          <Button
            onClick={() => window.open(bonus.refUrl, "_blank")}
            color="primary"
            className="bonuses-rewards-bonus-btn cursor-pointer with-caption"
          >
            {t("landing.rewards.cta")}
          </Button>

          <span className="bonuses-rewards-bonus-btn-with-caption">{t("bonuses.bonus.termsApply")}</span>
          {bonus.hoverText === null ? null :
            <span className="bonuses-rewards-bonus-btn-with-caption-tooltip">{bonus.hoverText}</span>}

        </div>
      </div>
    </div>
  )
}

export default TopBonusCard

import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Modal, ModalBody, ModalFooter } from "reactstrap"

import { castToFormData } from "@utils/Strings"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import { RaffleCardEntity } from "@modules/raffles"
import { updateRaffleRequest } from "@modules/adminRaffles"

type ToggleRafflePaidStatusModalProps = {
  onClose: () => void,
  isOpen: boolean,
  raffle?: RaffleCardEntity
  onApply: () => void
}

const ToggleRafflePaidStatusModal: FC<ToggleRafflePaidStatusModalProps> = ({ onApply, onClose, isOpen, raffle }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const onSetPaidRaffle = async () => {
    if (!raffle) return

    const partialRaffle: Partial<RaffleCardEntity> = { ...raffle }

    delete partialRaffle.winners

    await updateRaffleRequest(raffle.id, castToFormData({
      ...partialRaffle as Omit<RaffleCardEntity, "winners">,
      isPaid: true
    }) as never as RaffleCardEntity)

    toast("Paid status successfully updated!", TOAST_TYPE.SUCCESS)

    onApply()
  }
  const onSetUnpaidRaffle = async () => {
    if (!raffle) return

    const partialRaffle: Partial<RaffleCardEntity> = { ...raffle }

    delete partialRaffle.winners

    await updateRaffleRequest(raffle.id, castToFormData({
      ...partialRaffle as Omit<RaffleCardEntity, "winners">,
      isPaid: false
    }) as never as RaffleCardEntity)

    toast("Paid status successfully updated!", TOAST_TYPE.SUCCESS)

    onApply()
  }

  const handleClose = () => {
    onClose()
  }

  if (!raffle) return null

  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "admin-leaderboards-modal" }}
      contentClassName="position-relative shared-modal"
      toggle={handleClose}
    >
      <button type="button" className="shared-btn shared-modal-close" onClick={handleClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      {raffle.isPaid ? (
        <ModalBody className="mt-4" cssModule={{ "modal-body": "admin-leaderboards-modal-body-unban" }}>
          <div
            className="admin-leaderboards-modal-title text-center">{t("admin-raffles.ended.unpaid.title")}
          </div>
        </ModalBody>
      ) : (
        <ModalBody className="mt-4" cssModule={{ "modal-body": "admin-leaderboards-modal-body" }}>
          <div
            className="admin-leaderboards-modal-title text-center">{t("admin-raffles.ended.paid.title")}
          </div>
        </ModalBody>
      )}

      <ModalFooter color="secondary" cssModule={{ "modal-footer": "admin-leaderboards-modal-footer" }}>
        <button
          type="button"
          className="shared-btn-secondary admin-leaderboards-modal-btn"
          onClick={handleClose}
        >
          {t("admin-leaderboard.current.banModal.btnCancel")}
        </button>

        {raffle.isPaid ? (
          <button
            type="button"
            className="points-confirm-btn shared-btn-primary admin-leaderboards-modal-btn"
            onClick={onSetUnpaidRaffle}
          >
            {t("admin-raffles.ended.unpaid.action")}
          </button>
        ) : (
          <button
            type="button"
            className="points-confirm-btn shared-btn-primary admin-leaderboards-modal-btn"
            onClick={onSetPaidRaffle}
          >
            {t("admin-raffles.ended.paid.action")}
          </button>
        )}
      </ModalFooter>
    </Modal>
  )
}

export default ToggleRafflePaidStatusModal

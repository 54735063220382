import React, { FC, useRef, useState } from "react"
import { Modal } from "reactstrap"
import { useTranslation } from "react-i18next"

import RatingSelector from "components/RatingSelector"

import { useOnClickOutside } from "@hooks/useOnClickOutside"
import classNames from "classnames"

type RateSlotModalProps = {
  isOpen: boolean
  handleSubmit: (rating: number) => Promise<void>
  handleClose: () => void
  rateModalWithTextarea?: boolean
  setTextareaValue: (text: string) => void
  textareaValue: string | undefined
}
const MAX_REVIEW_COMMENT_LENGTH = 400
const RateSlotModal: FC<RateSlotModalProps> = ({ isOpen, handleClose, handleSubmit, rateModalWithTextarea, textareaValue, setTextareaValue }) => {
  const { t } = useTranslation()
  const [rating, setRating] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)

  const modalRef = useRef(null)
  const onSubmit = async () => {
    setLoading(true)

    await handleSubmit(rating)

    setLoading(false)

    setRating(0)
  }

  useOnClickOutside(modalRef, () => {
    if (isOpen) setTimeout(() => setRating(0), 10)
  })

  return (
    <Modal
      isOpen={isOpen}
      centered
      contentClassName="slots-reviews-slot-modal position-relative shared-modal"
      toggle={handleClose}
    >
      <button type="button" className="shared-btn shared-modal-close" onClick={() => {
        handleClose()
        setTextareaValue("")
        setRating(0)
      }}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <div className="slots-reviews-slot-modal-body">
        <h3 className="shared-text text-headline-white font-weight-700 text-uppercase m-0">
          {t("currentSlotPage.rateSlotModal.title")}
        </h3>

        <p className="shared-text shared-text--small text-secondary-75 font-weight-600 mb-0 mt-3">
          {t("currentSlotPage.rateSlotModal.subtitle")}
        </p>

        <RatingSelector rating={rating} setRating={setRating} wrapperClasName="mt-3"/>

        <p className="shared-text shared-text--extra-small text-secondary-40 font-weight-600 text-uppercase mb-0 mt-3">
          {t("currentSlotPage.rateSlotModal.description")}
        </p>

        {rateModalWithTextarea && rating > 0 && (
          <div className="d-flex w-100 position-relative">
          <textarea
            value={textareaValue}
            maxLength={MAX_REVIEW_COMMENT_LENGTH}
            className={classNames("slots-reviews-slot-comment-input", { "slots-reviews-slot-comment-input-focus": focus })}
            placeholder={t("currentSlotPage.currentSlotInfo.commentsPlaceholder")}
            onChange={(e) => setTextareaValue(e.target.value)}
          />

            <div
              className="slots-reviews-area-counter slots-reviews-slot-comment-input-length-focus shared-text shared-text--extra-small text-secondary-40"
            >
              <span>{textareaValue ? textareaValue.length : 0}/</span>

              <span>{MAX_REVIEW_COMMENT_LENGTH}</span>
            </div>
          </div>

        )}

        <button
          disabled={loading || (rateModalWithTextarea && !textareaValue) || !rating}
          className="slots-reviews-slot-modal-body-btn shared-btn-primary"
          onClick={onSubmit}
        >
          {t("currentSlotPage.rateSlotModal.btn")}
        </button>
      </div>
    </Modal>
  )
}

export default RateSlotModal

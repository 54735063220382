import { User } from "@hooks/useUser"

export enum RAFFLES_ORDER {
  ASC = "asc",
  DESC = "desc"
}

export enum RAFFLES_STATUS {
  ACTIVE = "active",
  ENDED = "ended"
}

export enum ADMIN_RAFFLE_SORT {
  ORDER = "order",
  DEADLINE = "deadline",
  DATE = "createdAt",
  UPDATE = "updatedAt",
  QUANTITY = "quantity",
  PRICE = "price",
  IS_WINNER = "isWinner",

}

export type RafflesPayload = {
  search?: string
  sortBy?: ADMIN_RAFFLE_SORT
  sortOrder?: RAFFLES_ORDER
}

export type RaffleEntriesPayload = {
  search?: string
}

export type RaffleCardEntity = {
  createdAt: Date
  updatedAt: Date
  startDate: Date
  durationHours: number
  durationMinutes: number
  deadline: number
  entryCost: number
  entriesCount: number
  maxEntries: number
  maxEntriesPerUser: number
  winnersCount: number
  title: string
  description: string
  prizeInDollars: number
  imageUrl: string
  id: number
  isPaid: boolean
  winners: RaffleWinner[]
}

export type RaffleEntryUser = {
  displayName: string
  id: string
  entryNumber: number
  profileImageUrl: string
}

export type RaffleEntryEntity = {
  userId: string;
  createdAt: string;
  updatedAt: string;
  isWinner: boolean;
  raffleId: number;
  entryNumber: number;
  user: RaffleEntryUser;
  id: number;
}

export type RaffleWinner = {
  userId: string;
  createdAt: string;
  updatedAt: string;
  isWinner: boolean;
  raffleId: number;
  entryNumber: number;
  id: number;
  displayName: string;
  email: string;
  offlineImageUrl: string;
  profileImageUrl: string;
  totalEntriesCount: number;
}

export type Winner = {
  entryNumber: number;
  raffleId: number;
  user: User;
}

export type RaffleProof = {
  proofBody: string
  proofSignature: string
}

import { useEffect, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import { POINT_STORE_CARDS } from "constants/swr"

import {
  fetchPointCards,
  ITEMS_ORDER,
  PointCard,
  PointCardsPayload,
  POINTS_CARD_ORDER_BY,
  updatePointCardsOrderRequest
} from "@modules/pointsStore"

export const usePointCards = () => {
  const [filter, setFilter] = useState<PointCardsPayload>({
      order: ITEMS_ORDER.ASC,
      orderBy: POINTS_CARD_ORDER_BY.DEFAULT
    }
  )

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(12)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(POINT_STORE_CARDS, () => fetchPointCards({ ...filter, page, perPage }))

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const pointCards: PointCard[] = (data?.items || [])

  const savePointCards = async (items: PointCard[]) => {
    const oldOrders: number[] = pointCards.map(({ order }) => order)
    const payload: { order: number, id: number }[] = items.map(({ id }, index) => ({ id, order: oldOrders[index] }))

    await updatePointCardsOrderRequest(payload)
  }

  const onFiltered = (newFilter: Partial<PointCardsPayload>) => {
    setFilter((prev) => ({ ...prev, ...newFilter }))
    setPage(1)
  }

  return {
    list: pointCards,
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 0,

    onFiltered,
    filter,

    savePointCards
  }
}

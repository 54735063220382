import { FC, ReactNode } from "react"
import classNames from "classnames"

import { ReactComponent as SortIcon } from "@src/assets/icons/sort.svg"

import { TABLE_ORDER } from "@modules/adminUsers"

import "./styles.scss"

type TableHeaderCellProps = {
  text: string
  withSort?: boolean
  onSort?: (order: TABLE_ORDER) => void
  isActiveSort?: boolean
  sortDirection?: TABLE_ORDER
  className?: string
}

export const TableHeaderCell: FC<TableHeaderCellProps> = ({
  text,
  isActiveSort = false,
  onSort = () => undefined,
  withSort = false,
  className,
  sortDirection = TABLE_ORDER.DESC
}) => (
  <div className={classNames("custom-table-cell custom-table-header-cell", className)}>
    {text}
    {withSort && (
      <button
        className={classNames("custom-table-header-cell-sort", {
          rotated: sortDirection === TABLE_ORDER.ASC,
          active: isActiveSort
        })}
        onClick={() => onSort(isActiveSort ? sortDirection === TABLE_ORDER.ASC ? TABLE_ORDER.DESC : TABLE_ORDER.ASC : TABLE_ORDER.DESC)}
      >
        <SortIcon className="custom-table-header-cell-sort-icon" />
      </button>
    )}
  </div>
)

type TableCellProps = {
  children?: ReactNode,
  className?: string
  extraText?: ReactNode
}

export const TableCell: FC<TableCellProps> = ({
  children,
  className,
  extraText
}) => (
  <div className={classNames("custom-table-cell position-relative custom-table-cell-in-row", className)}>
    {children}
    {extraText && (
      <div className="custom-table-cell-extra">{extraText}</div>
    )}
  </div>
)

import React, { FC, useState } from "react"

import classNames from "classnames"
import "./styles.scss"

import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"

type RatingSelectorProps = {
  rating: number
  setRating: (rating: number) => void
  wrapperClasName?: string
  starSize?: number
}

const RatingSelector: FC<RatingSelectorProps> = ({rating, setRating, wrapperClasName, starSize = 35}) => {
  const [hover, setHover] = useState<number>(0)

  const styles = {
    width: starSize,
    height: starSize,
    minWidth: starSize,
    minHeight: starSize
  }
  return (
    <div className={classNames("rating-selector-wrapper", wrapperClasName)}>
      {[...Array(5)].map((star, index) => {
        const currentRating = index + 1
        return (
          <label
            className="rating-selector"
            key={index}
            onMouseEnter={() => setHover(currentRating)}
            onMouseLeave={() => setHover(0)}
          >
            <input type="radio" name="rating" className="d-none" value={currentRating} onClick={() => setRating(currentRating)} />

            <StarIcon
              className={classNames("rating-selector-star", currentRating <= (hover || rating) ? "rating-selector-star-hover" : "")}
              style={styles}
            />
          </label>
        )
      })}
    </div>
  )
}

export default RatingSelector
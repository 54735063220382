import React, { FC, useContext, useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { Fireworks, FireworksHandlers } from "@fireworks-js/react"

import FireworksContext from "@context/Fireworks"

import { RaffleCardEntity } from "@modules/raffles"

type FireworksModalProps = {
  raffle: RaffleCardEntity
}

const FireworksModal: FC<FireworksModalProps> = ({ raffle }) => {
  const { t } = useTranslation()
  const ref = useRef<FireworksHandlers>(null)

  const { showFireworks, setShowFireworks } = useContext(FireworksContext)

  const onClose = () => {
    setShowFireworks(false)
  }

  useEffect(() => {
    if (showFireworks && raffle) {
      document.body.classList.add("no-overflow")
    } else {
      document.body.classList.remove("no-overflow")
    }
  }, [showFireworks])

  if (!showFireworks || !raffle) return null

  return (
    <>
      <div className="fireworks-modal-wrapper">
        <div className="fireworks-modal">
          <Fireworks
            ref={ref}
            autostart
            options={{
              explosion: 9,
              opacity: 0.5,
              delay: {
                min: 10,
                max: 30
              }
            }}
            style={{
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              position: "absolute",
              background: "transparent",
              zIndex: 1
            }}
          />

          <div className="fireworks-modal-content">
            <h2 className="fireworks-modal-title">{raffle.winners.length > 1 ? t("raffles.winners") : t("raffles.winner")}</h2>

            <div className="fireworks-modal-list">
              {raffle.winners.map((winner) => (
                <div
                  key={winner.id}
                  className="selected-raffle-entries-card-winner-container position-relative fireworks-modal-list-item p-0"
                >
                  <img
                    src="/assets/images/crown-btm-part.png"
                    alt="crown-btm"
                    className="selected-raffle-entries-card-decoration-bottom fireworks-modal-list-item-decoration"
                  />
                  <div
                    className="selected-raffle-entries-card selected-raffle-entries-card-winner position-relative w-100 h-100">
                    <img
                      src="/assets/images/crown-top-part.png"
                      alt="crown-top"
                      className="selected-raffle-entries-card-decoration-top"
                    />

                    <div className="position-relative">
                      <img
                        src={winner.profileImageUrl}
                        alt="profileImage"
                        className="selected-raffle-entries-card-avatar fireworks-modal-list-item-avatar"
                      />

                      <div
                        className="selected-raffle-entries-card-number selected-raffle-entries-card-number-winner shared-text shared-text--small text-main-bg font-weight-600"
                      >
                        {winner.entryNumber}
                      </div>
                    </div>

                    <p
                      className="m-0 selected-raffle-entries-card-name shared-text shared-text--small text-headline-white font-weight-600"
                    >
                      {winner.displayName}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <button
              className="shared-btn shared-btn-primary fireworks-modal-btn"
              onClick={onClose}
              type="button"
            >
              {t("raffles.actions.close")}
            </button>
          </div>
        </div>
      </div>
    </>

  )
}

export default FireworksModal

import { usePlainSWR } from "@hooks/usePlainSWR"

import { BonusHuntStatisticEntity, fetchBonusHuntStatistic } from "@modules/bonusHunt"

export const useBonusHuntStatistic = () => {
  const { data, mutate, error, isValidating } = usePlainSWR(`/bonus-hunt/stats`, fetchBonusHuntStatistic)

  return {
    statistic: data as BonusHuntStatisticEntity,
    mutate,
    loading: !data && !error,
    isValidating,
    error
  }
}

export default useBonusHuntStatistic

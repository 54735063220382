import React, { FC } from "react"
import { Modal, ModalBody, ModalFooter } from "reactstrap"
import { useTranslation } from "react-i18next"


type ExitReviewProps = {
  isOpen: boolean
  onClose: () => void
  onApply: () => void
}

const ExitReview: FC<ExitReviewProps> = ({
  isOpen,
  onClose,
  onApply
}) => {
  const { t } = useTranslation()

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <div className="d-flex flex-column align-items-start justify-content-center shared-modal-content">
        <button type="button" className="shared-btn shared-modal-close" onClick={onClose}>
          <img src="/assets/icons/close.svg" alt="close" />
        </button>

        <ModalBody className="shared-modal-body p-b-0">
          <h4 className="shared-modal-title w-100 mb-0 mt-4">
            {t("exitModal.title")}
          </h4>

          <p className="shared-modal-text shared-text mb-0 text-center">
            {t("exitModal.text")}
          </p>
        </ModalBody>

        <ModalFooter color="secondary" className="shared-modal-footer justify-content-between border-0">
          <button
            type="button"
            className="rounded-0 shared-modal-btn shared-btn-secondary"
            onClick={onClose}
          >
            {t("exitModal.btnCancel")}
          </button>

          <button
            type="button"
            className="rounded-1 shared-modal-btn shared-btn-primary"
            onClick={onApply}
          >
            {t("exitModal.btnExit")}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ExitReview
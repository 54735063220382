import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import { Spinner } from "reactstrap"
import { BonusHuntTopStatisticCard, useBonusHuntTopStatistic } from "@modules/bonusHunt"

type BonusHuntTopStatisticProps = {
  id: string
}

const BonusHuntTopStatistic: FC<BonusHuntTopStatisticProps> = ({ id }) => {
  const { t } = useTranslation()

  const { statistic, loading } = useBonusHuntTopStatistic(id)

  if (loading) return (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <Spinner color="primary" className="mt-2" />
    </div>
  )

  return (
    <section className="bonus-hunt-statistic-top w-100 position-relative zindex-1">
      <BonusHuntTopStatisticCard value={statistic.bonus_count_total} topStatistic>
        <p>{t("bonus-hunt.topStatistic.totalBonuses")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={statistic.info_start_cost || "-"} topStatistic>
        <p>{t("bonus-hunt.topStatistic.startCost")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={statistic.bonus_count_opened} topStatistic>
        <p>{t("bonus-hunt.topStatistic.currentBonus")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={statistic.info_amount_won || "-"} topStatistic>
        <p>{t("bonus-hunt.topStatistic.huntPayout")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={statistic?.date ? format(new Date(statistic.date), "yyyy/MM/dd") : "-"}
                                 topStatistic>
        <p>{t("bonus-hunt.topStatistic.date")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={statistic.x100_wins} topStatistic>
        <p>{t("bonus-hunt.topStatistic.topWinsCount")}</p>
      </BonusHuntTopStatisticCard>
    </section>
  )
}

export default BonusHuntTopStatistic

import { mutate } from "swr"
import useSWRSubscription, { SWRSubscriptionOptions } from "swr/subscription"

import { socket } from "socket"

import { POINT_STORE_CARDS, RAFFLE } from "constants/swr"

import { PointCard } from "@modules/pointsStore"
import { ENTRIES_PER_PAGE, RaffleCardEntity, RaffleEntryUser, RAFFLES_STATUS } from "@modules/raffles"

export const createTemplateSubscription = (socketKey: string, handler: (event: any) => Promise<any>) => useSWRSubscription(socketKey, (key, { next }: SWRSubscriptionOptions<number, Error>) => {
  socket.on(socketKey, async (event) => {
    await handler(event)

    next(null, event.message)
  })

  return () => {
    return socket.off(socketKey)
  }
})

export const pointStoreRestockHandle = async (event: any) => {
  if (event) {
    await mutate(POINT_STORE_CARDS, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response

      const res = {
        ...data,
        items: (data?.items || []).map((item: PointCard) => ({ ...item, quantity: item.stockQuantity }))
      }
      return { data: res }
    }, { revalidate: false })
  }
}
export const pointStoreEditHandle = async (event: PointCard) => {
  if (event) {
    await mutate(POINT_STORE_CARDS, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response

      const res = {
        ...data,
        items: (data?.items || []).map((item: PointCard) => (item.id === event.id ? event : item))
      }
      return { data: res }
    }, { revalidate: false })
  }
}
export const pointStorePurchaseHandle = async (event: PointCard) => {
  if (event) {

    await mutate(POINT_STORE_CARDS, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response

      const res = {
        ...data,
        items: (data?.items || []).map((item: PointCard) => (item.id === event.id ? ({
          ...item,
          quantity: item.quantity - 1
        }) : item))
      }
      return { data: res }
    }, { revalidate: false })
  }
}
export const pointStoreDeleteHandle = async (event: PointCard) => {
  if (event) {

    await mutate(POINT_STORE_CARDS, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response

      const res = {
        ...data,
        total: !!data?.total ? data.total - 1 : 0,
        items: (data?.items || []).filter((item: PointCard) => (item.id !== event.id))
      }
      return { data: res }
    }, { revalidate: false })
  }
}
export const pointStoreCreateHandle = async (event: PointCard) => {
  if (event) {

    await mutate(POINT_STORE_CARDS, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response
      const items = (data?.items || [])
      const isItemAlreadyExist = !!items.find((item: PointCard) => item.id === event.id)

      const res = {
        ...data,
        total: isItemAlreadyExist ? data.total : !!data?.total ? data.total + 1 : 0,
        items: isItemAlreadyExist ? items : items.concat(event)
      }
      return { data: res }
    }, { revalidate: false })
  }
}

export const raffleNewEntryHandle = async (event: { raffleId: number, joinedUser: RaffleEntryUser }) => {
  if (event) {
    await mutate(`${RAFFLE}-${RAFFLES_STATUS.ACTIVE}`, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response

      const res = {
        ...data,
        items: (data?.items || []).map((item: RaffleCardEntity) => (item.id === event.raffleId ? ({
          ...item,
          entriesCount: (item.entriesCount || 0) + 1
        }) : item))
      }
      return { data: res }
    }, { revalidate: false })

    await mutate(`${RAFFLE}/${event.raffleId}`, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response

      const res = {
        ...data,
        entriesCount: (data?.entriesCount || 0) + 1
      }
      return { data: res }
    }, { revalidate: false })

    await mutate(`${RAFFLE}/${event.raffleId}/entries`, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const items = (response.data?.items || [])

      const res = {
        ...response.data,
        total: !!response.data?.total ? response.data.total + 1 : 1,
        items: items.length === ENTRIES_PER_PAGE ? items : items.concat({
          entryNumber: event.joinedUser.entryNumber,
          id: event.joinedUser.id,
          user: event.joinedUser,
          raffleId: event.raffleId
        })
      }

      return { data: res }
    }, { revalidate: false })
  }
}

export const raffleCreateHandle = async (event: RaffleCardEntity) => {
  if (event) {
    await mutate(`${RAFFLE}-${RAFFLES_STATUS.ACTIVE}`, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response
      const items = (data?.items || [])
      const isItemAlreadyExist = !!items.find((item: RaffleCardEntity) => item.id === event.id)
      event.entriesCount = 0
      const res = {
        ...data,
        total: isItemAlreadyExist ? data.total : !!data?.total ? data.total + 1 : 0,
        items: isItemAlreadyExist ? items : items.concat(event)
      }

      return { data: res }
    }, { revalidate: false })
  }
}
export const raffleDeleteHandle = async (event: number) => {
  if (event) {
    await mutate(`${RAFFLE}-${RAFFLES_STATUS.ACTIVE}`, async (response: any) => {
      if (!response) {
        return { data: undefined }
      }

      const { data } = response
      const items = (data?.items || []).filter((item: RaffleCardEntity) => item.id !== event)

      const res = {
        ...data,
        total: items.length,
        items
      }

      return { data: res }
    }, { revalidate: false })
  }
}

import { useEffect, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import { AdminLeaderboardUsers, fetchUsersByLeaderboardId } from "@modules/adminLeaderboard"

export const useAdminUsersByLeaderboardId = (id: string) => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`/admin/leaderboard/${id}/users`, () =>
    fetchUsersByLeaderboardId({ page, perPage }, id)
  )

  useEffect(() => {
    refresh()
  }, [page, perPage])

  return {
    usersList: (data?.items || []) as AdminLeaderboardUsers[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 1
  }
}

import { FC } from "react"
import { useTranslation } from "react-i18next"

type PointsStoreHeaderProps = {
}

const PointsStoreHeader: FC<PointsStoreHeaderProps> = ({}) => {
  const { t } = useTranslation()

  return (
    <section className="points-store-container w-100">
      <div className="points-store-header w-100">
        <img src="/assets/icons/coin-alternative.svg"
             alt="coin"
             className="points-store-header-img" />

        <div className="points-store-header-content">
          <h3 className="points-store-header-content-title m-0">
            {t("pointsStore.header.title")}
          </h3>

          <p className="shared-text shared-text--small points-store-header-content-text">
            {t("pointsStore.header.text")}
          </p>
        </div>
      </div>
    </section>
  )
}

export default PointsStoreHeader

import { FC, ReactElement } from "react"
import classNames from "classnames"

type PointsStoreStatisticCardProps = {
  title: string
  children: ReactElement
  alternative?: boolean
}

const PointsStoreStatisticCard: FC<PointsStoreStatisticCardProps> = ({ title, children, alternative }) => {
  return (
    <div className={classNames("points-store-statistic-card", { "points-store-statistic-card-admin": alternative })}>
      <div className={classNames("points-store-statistic-card-header",
        { "points-store-statistic-card-header-admin": alternative })}
      >
        {title}
      </div>

      <div className="points-store-statistic-card-body" id="pointsStoreContainer">
        {children}
      </div>
    </div>
  )
}

export default PointsStoreStatisticCard

import React, { useState } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"

import { Spinner } from "reactstrap"

import { Option } from "@utils/Select"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import Pagination from "components/Pagination"
import PerPageSelector from "components/PerPageSelector"
import SearchInput from "components/SearchInput/SearchInput"

import {
  ConfirmPurchaseModal,
  ITEMS_ORDER,
  PointCard,
  POINTS_CARD_ORDER_BY,
  PointsStoreCard,
  usePointCards
} from "@modules/pointsStore"
import LoginModal from "@src/components/LoginModal"


const PointsStoreList = () => {
  const toast = useToast()
  const { t } = useTranslation()
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false)

  const {
    list,
    filter,
    onFiltered,
    total,
    page,
    perPage,
    onChangePage,
    onChangePerPage,
    loading
  } = usePointCards()

  const [selectedCard, setSelectedCard] = useState<PointCard>()

  const sortByOptions: Option[] = [
    { label: "default", value: POINTS_CARD_ORDER_BY.DEFAULT },
    { label: POINTS_CARD_ORDER_BY.PRICE, value: POINTS_CARD_ORDER_BY.PRICE },
    { label: "quantity", value: POINTS_CARD_ORDER_BY.QUANTITY }
  ]

  const options: Option[] = [
    { label: "12", value: "12" },
    { label: "24", value: "24" },
    { label: "48", value: "48" },
    { label: "ALL", value: `${total}` }
  ]

  const handleScrollToTop = () => {
    window.location.href = "#pointsStoreContainer"
  }

  const handleOnPurchase = () => {
    toast(t("pointsStore.purchase.successfullyCreated"), TOAST_TYPE.SUCCESS)

    setSelectedCard(undefined)
  }

  return (
    <div
      className="points-store-container w-100 d-flex flex-column align-items-center justify-content-start"
    >
      {loading ? (<Spinner />) : (
        <>
          <div className="points-store-list w-100 d-flex flex-wrap">
            <div className="points-store-list-header">
              <div className="points-store-input">
                <SearchInput
                  onChange={(s) => onFiltered({ search: s })}
                  value={filter?.search}
                />
              </div>

              <div className="points-store-list-header-select">
                <Select
                  options={sortByOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "transparent",
                      primary25: "#E2F1FF1A"
                    }
                  })}
                  isSearchable={false}
                  className="custom-select-wrapper"
                  classNamePrefix="custom-select"
                  components={{ IndicatorSeparator: null }}
                  value={sortByOptions.find((o) => o.value === filter.orderBy.toString()) || null}
                  onChange={(o) => {
                    if (!o?.value) return
                    onFiltered(o.value === POINTS_CARD_ORDER_BY.DEFAULT ? {
                      orderBy: o.value as POINTS_CARD_ORDER_BY,
                      order: ITEMS_ORDER.ASC
                    } : {
                      orderBy: o.value as POINTS_CARD_ORDER_BY, order: ITEMS_ORDER.DESC
                    })

                    onChangePage(1)
                  }}
                />
              </div>
            </div>

            <div className="points-store-list-wrapper">
              {list.length === 0 ? (
                  <div className="points-store-list-wrapper-stub">
                    <p className="points-store-list-wrapper-stub-text">
                      {t("pointsStore.stub")}
                    </p>
                  </div>
                ) :
                list.map((card: PointCard) => (
                  <PointsStoreCard
                    card={card}
                    key={card.id}
                    onSelect={setSelectedCard}
                    setShowLoginModal={setShowLoginModal} />
                ))}
            </div>
          </div>

          <div className="points-store-footer">
            <PerPageSelector
              options={options}
              onChangePerPage={onChangePerPage}
              perPage={perPage}
              setPage={onChangePage}
            />

            <Pagination
              rowCount={Math.ceil(total / perPage) || 1}
              currentPage={page}
              onChangePage={onChangePage}
              rowsPerPage={1}
              scrollToTop={handleScrollToTop}
            />
          </div>
        </>
      )}

      <ConfirmPurchaseModal
        onApply={handleOnPurchase}
        isOpen={!!selectedCard}
        onClose={() => setSelectedCard(undefined)}
        item={selectedCard}
      />

      <LoginModal isOpen={showLoginModal} onClose={() => setShowLoginModal(false)} />
    </div>
  )
}

export default PointsStoreList

import mock from './mock'

// ** Active mocks
// import './queries/users'
// import './queries/bonuses'
import './queries/leaderboard'
import './queries/pointCards'
import './queries/bonusHunt'
import './queries/slotsReviews'

mock.onAny().passThrough()

import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Modal, ModalBody, ModalFooter } from "reactstrap"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import { RaffleCardEntity } from "@modules/raffles"
import { rerollRaffleWinnerRequest } from "@modules/adminRaffles"

type RerollRaffleWinnerModalProps = {
  onClose: () => void,
  isOpen: boolean,
  raffle?: RaffleCardEntity
  onApply: () => void
}

const RerollRaffleWinnerModal: FC<RerollRaffleWinnerModalProps> = ({ onApply, onClose, isOpen, raffle }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const onRerollRaffleWinner = async () => {
    if (!raffle) return

    const partialRaffle: Partial<RaffleCardEntity> = { ...raffle }

    delete partialRaffle.winners

    await rerollRaffleWinnerRequest(raffle.id)

    toast(t('admin-raffles.entries.rerollSuccessMessage'), TOAST_TYPE.SUCCESS)

    onApply()
  }

  const handleClose = () => {
    onClose()
  }

  if (!raffle) return null

  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "admin-leaderboards-modal" }}
      contentClassName="position-relative shared-modal"
      toggle={handleClose}
    >
      <button type="button" className="shared-btn shared-modal-close" onClick={handleClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <ModalBody className="mt-4" cssModule={{ "modal-body": "admin-leaderboards-modal-body-unban" }}>
        <div
          className="admin-leaderboards-modal-title text-center">{t("admin-raffles.entries.rerollModalTitle")}
        </div>
      </ModalBody>

      <ModalFooter color="secondary" cssModule={{ "modal-footer": "admin-leaderboards-modal-footer" }}>
        <button
          type="button"
          className="shared-btn-secondary admin-leaderboards-modal-btn"
          onClick={handleClose}
        >
          {t("admin-leaderboard.current.banModal.btnCancel")}
        </button>

        <button
          type="button"
          className="points-confirm-btn shared-btn-primary admin-leaderboards-modal-btn"
          onClick={onRerollRaffleWinner}
        >
          {t("admin-raffles.entries.reroll")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default RerollRaffleWinnerModal

import { useEffect } from "react"

import axios from "@src/network"

import { usePlainSWRNoMount } from "@hooks/usePlainSWR"

import { Bonus, EBonusType, TopBonus, updateItemOrderRequest } from "@modules/adminBonuses"
import { REGION } from "@context/Region"

const getFeaturedBonuses = (region: REGION = REGION.GLOBAL) => axios.get<TopBonus[]>("/bonuses", {
  params: {
    type: EBonusType.FEATURED,
    region
  },
  withCredentials: true
})

export const useFeaturedBonuses = (region?: REGION) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount("/featured-bonuses", () => (region ? getFeaturedBonuses(region) : null))

  const bonuses: TopBonus[] = (data || [])

  const saveFeaturedBonusesNewOrder = async (items: Bonus[]) => {
    const prevOrders: number[] = bonuses.map(({ order }) => order)
    const payload: { order: number, id: string }[] = items.map(({ id }, index) => ({ id, order: prevOrders[index] }))

    await updateItemOrderRequest(payload)

    // await refresh()
  }

  useEffect(() => {
    refresh()
  }, [region])

  return {
    bonuses,
    loading: !error && !data,
    error,
    refresh,
    isValidating,

    saveFeaturedBonusesNewOrder
  }
}

export default useFeaturedBonuses

import { useEffect, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchFutureLeaderboards } from "@modules/adminLeaderboard"
import { Leaderboard } from "@modules/leaderboards/dto"

export const useFutureLeaderboards = () => {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR("/admin-future-leaderboards", () =>
    fetchFutureLeaderboards({ page, perPage })
  )

  useEffect(() => {
    refresh()
  }, [page, perPage])

  return {
    list: (data || []) as Leaderboard[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.length || 0
  }
}

import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import axios from "network"
import { PointCard } from "@modules/pointsStore"


type ConfirmDeleteModalProps = {
  isOpen: boolean
  item?: PointCard
  onClose: () => void
  onApply: () => void
}

const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  isOpen, onApply, onClose, item
}) => {
  const { t } = useTranslation()

  const [loading, setLoading] = useState<boolean>(false)

  const onDelete = async () => {
    setLoading(true)

    if (item) {
      await axios.delete(`/point-shop/items/${item.id}`, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
        withCredentials: true
      })
    }
    setLoading(false)
    onApply()
  }

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <div className="d-flex flex-column align-items-start justify-content-center shared-modal-content">
        <button type="button" className="shared-btn shared-modal-close" onClick={onClose}>
          <img src="/assets/icons/close.svg" alt="close" />
        </button>

        <ModalBody className="shared-modal-body">
          <h4 className="shared-modal-title w-100 mb-0">{t("admin-points-store.deleteModal.title")}</h4>

          <p className="shared-modal-text shared-text mb-0 text-center">{t("admin-points-store.deleteModal.text")}</p>
        </ModalBody>

        <ModalFooter color="secondary" className="shared-modal-footer justify-content-between border-0">
          <button type="button" disabled={loading} className="rounded-0 shared-modal-btn shared-btn-secondary"
                  onClick={onClose}>
            {t("admin-points-store.deleteModal.cancel")}
          </button>

          <button type="button" disabled={loading} className="rounded-1 shared-modal-btn shared-btn-primary"
                  onClick={onDelete}>
            {loading && <Spinner size="sm" className="me-1" />}

            {t("admin-points-store.deleteModal.delete")}
          </button>
        </ModalFooter>
      </div>
    </Modal>
  )
}

export default ConfirmDeleteModal

import { lazy } from "react"

import "./views/admin-leaderboards.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export const AdminLeaderboard = lazy(() => import("./views/AdminLeaderboard"))
export const AdminFutureLeaderboards = lazy(() => import("./views/AdminFutureLeaderboards"))
export const AdminCurrentLeaderboard = lazy(() => import("./views/AdminCurrentLeaderboard"))

export * from "./dto"

export * from "./useFutureLeaderboards"
export * from "./useAdminLeaderboards"
export * from "./useAdminUsersByLeaderboardId"

export * from "./requests"

export * from "./helpers/form"

export { default as ConfirmDeleteModal } from "./components/ConfirmDeleteModal"
export { default as ManageLeaderboardModal } from "./components/ManageLeaderboardModal"
export { default as ManageLeaderboardModalPrizes } from "./components/ManageLeaderboardModalPrizes"
export { default as ManageLeaderboardModalSetup } from "./components/ManageLeaderboardModalSetup"
export { default as LeaderboardsList } from "./components/LeaderboardsList"
export { default as AdminLeaderboardTable } from "./components/AdminLeaderboardTable"
export { default as BanUserInLeaderboard } from "./components/BanUserInLeaderboard"
export { default as UnbanUserInLeaderboard } from "./components/UnbanUserInLeaderboard"
export { default as RandomWinnersTable } from "./components/RandomWinnersTable"


export const locales = {
  en,
  ru
}

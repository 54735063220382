import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"

import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"

import { createPurchaseRequest, PointCard } from "@modules/pointsStore"
import useToast, { TOAST_TYPE } from "@hooks/useToast"

type ConfirmPurchaseModalProps = {
  isOpen: boolean
  onClose: () => void
  onApply: () => void
  item?: PointCard
}

const ConfirmPurchaseModal: FC<ConfirmPurchaseModalProps> = ({
  isOpen, onApply, onClose, item
}) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [loading, setLoading] = useState<boolean>(false)

  const handleSubmit = async () => {
    if (!item) return

    setLoading(true)
    try {
      await createPurchaseRequest(item.id)

      onApply()
    } catch (e) {
      // @ts-ignore
      toast(e?.response?.data?.message, TOAST_TYPE.ERROR)
    } finally {
      setLoading(false)
    }
  }

  if (!item) return null

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <div className="d-flex flex-column align-items-start justify-content-center shared-modal-content">
        <ModalBody className="shared-modal-body points-store-purchase-body w-100">
          <h4 className="shared-text points-store-purchase-title w-100">{t("pointsStore.purchaseModal.title")}</h4>

          <div className="points-store-purchase-text">
            <span>1</span>

            <p className="shared-modal-text shared-text shared-text--small m-0">
              {t("pointsStore.purchaseModal.text1")}
            </p>
          </div>

          <div className="points-store-purchase-text">
            <span>2</span>

            <p className="shared-modal-text shared-text shared-text--small m-0">
              {t("pointsStore.purchaseModal.text2")}
            </p>
          </div>

          <div className="points-store-purchase-text">
            <span>3</span>

            <p className="shared-modal-text shared-text shared-text--small m-0">
              {t("pointsStore.purchaseModal.text3")}
            </p>
          </div>
        </ModalBody>
      </div>

      <ModalFooter color="secondary" className="points-store-purchase-footer w-100">
        <img src={item.imageUrl} className="points-store-purchase-footer-item-asset" alt="" />

        <p className="points-store-purchase-footer-item-name shared-text">{item.title}</p>

        <div className="points-store-statistic-card-amount d-flex flex-row align-items-center justify-content-center">
          <img src="/assets/icons/coin-alternative.svg" alt="coin" />

          {item.price}
        </div>

        <div
          className="w-100 d-flex flex-row align-items-center justify-content-center points-store-purchase-footer-control">
          <button
            type="button"
            disabled={loading}
            className="rounded-1 shared-modal-btn shared-btn-secondary flex-cell"
            onClick={onClose}
          >
            {t("pointsStore.actions.cancel")}
          </button>

          <button
            type="button"
            disabled={loading}
            className="rounded-1 shared-modal-btn shared-btn-primary flex-cell points-store-purchase-footer-submit"
            onClick={handleSubmit}
          >
            {loading && <Spinner size="sm" className="me-1" />}

            {t("pointsStore.actions.buy")}

            <img src="/assets/icons/coin-alternative.svg" alt="coin" />

            {item.price}
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmPurchaseModal

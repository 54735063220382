import { FC } from "react"

import Select from "react-select"

import { Option } from "@utils/Select"
import "./styles.scss"

type PerPageSelectorProps = {
  options: Option[]
  perPage: number
  onChangePerPage: (n: number) => void
  setPage: (n: number) => void
};

const PerPageSelector: FC<PerPageSelectorProps> = ({
  perPage,
  onChangePerPage,
  setPage,
  options
}) => {

  return (
    <Select
      options={options}
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "transparent",
          primary25: "#1C243B"
        }
      })}
      className="per-page-select-wrapper"
      classNamePrefix="per-page-select"
      isSearchable={false}
      value={options.find((o) => parseInt(o.value) === perPage) || null}
      onChange={(o) => {
        if (!o?.value) return
        onChangePerPage(parseInt(o.value, 10))
        setPage(1)
      }}
      components={{ IndicatorSeparator: null }}
    />
  )
}

export default PerPageSelector

import React, { FC } from "react"

import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

import LeaderboardHistoryCard from "components/LeaderboardHistoryCard"

import { SectionTitle, getImageUrlByIdAndIndex, Leaderboard } from "@modules/leaderboards"
import { endOfMonth } from "date-fns"
import { Spinner } from "reactstrap"

type HistorySectionProps = {
  list: Leaderboard[]
  loading: boolean
}
const HistorySection: FC<HistorySectionProps> = ({list, loading}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <section className="leaderboard-history position-relative">
      <div className="leaderboard-history-title">
        <SectionTitle imgUrl="/assets/icons/clock.svg" text={t("leaderboards.history-section.title")} />
      </div>

      <p className="leaderboard-history-text">
        {t("leaderboards.history-section.text")}
      </p>

      <div className="leaderboard-history-container">
        {loading ? <Spinner color="primary" /> :
          list.map((leaderboard, index) => (
            <LeaderboardHistoryCard
              start={new Date(parseInt(leaderboard.year), parseInt(leaderboard.month) - 1).toString()}
              ended={endOfMonth(new Date(parseInt(leaderboard.year), parseInt(leaderboard.month) - 1)).toString()}
              imgUrl={getImageUrlByIdAndIndex(leaderboard.leaderboardUser[0].id, index)}
              name={leaderboard.leaderboardUser[0].displayName}
              isHistoryPage={false}
              id={leaderboard.id}
              key={leaderboard.id}
              prize={leaderboard.leaderboardTieredPrize[0].amount}
            />

          ))}
      </div>

      <button
        className="leaderboard-history-btn shared-btn-secondary mobile-hide"
        onClick={() => navigate("history")}
      >
        {t("leaderboards.history-section.btn")}
      </button>
    </section>
  )
}
export default HistorySection

import { FC } from "react"
import { useNavigate } from "react-router-dom"

import { SlotCard } from "@modules/slotsReviews"
import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"
import classNames from "classnames"

type SlotsCardProps = {
  card: SlotCard
  className?: string
}

const SlotsCard: FC<SlotsCardProps> = ({ card, className }) => {
  const navigate = useNavigate()

  return (
    <div
      className={`slots-reviews-all-slots-card cursor-pointer ${className}`}
      onClick={() => navigate(`/slots-reviews/slot/${card.id}`)}
    >
      <img className="slots-reviews-all-slots-card-img" src={card.imageUrl} alt="img" />

      <div className={classNames("slots-reviews-all-slots-card-rating",
        { "slots-reviews-all-slots-card-rating-small": className }
      )}
      >
        <StarIcon className={classNames("text-yellow", { "slots-reviews-all-slots-card-star": className })} />
        {card.averageRating.toFixed(1)}
      </div>
    </div>
  )
}

export default SlotsCard

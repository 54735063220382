import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchSlotById } from "@modules/slotsReviews/requests"
import { SlotInfo } from "@modules/slotsReviews"

export const useSlotById = (id: string) => {

  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWR(`/slots-reviews/slot/${id}`, () => fetchSlotById(id))

  const slot: SlotInfo = data?.item

  return {
    slot,

    refresh,

    loading: !data && !error,

    total: data?.total || 0
  }
}

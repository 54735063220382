import axios from "network"

import { RaffleCardEntity } from "@modules/raffles"

export const updateRaffleRequest = (id: number, payload: Partial<RaffleCardEntity>) => axios.put(`/raffles/${id}`, payload, {
  withCredentials: true
})

export const rerollRaffleWinnerRequest = (id: number) => axios.post(`/raffles/${id}/reroll`, {}, {
  withCredentials: true
})

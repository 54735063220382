/**
 * Capitalize first letter of the string
 */
export const capitalize = (str: string = ""): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)?.toLowerCase()
}
/**
 * Formats a number with commas as thousand separators.
 *
 * @param {number} x - The number to format.
 * @returns {string} The formatted number.
 */
export const numberWithCommas = (x: number = 0): string => {
  const numberString: string = x.toString()
  const pattern: RegExp = /(-?\d+)(\d{3})/

  let formattedNumber: string = numberString
  while (pattern.test(formattedNumber)) {
    formattedNumber = formattedNumber.replace(pattern, "$1,$2")
  }

  return formattedNumber
}

export const addCommasForBigNumbers = (value: number | string = 0): string => value.toLocaleString("en-US")
export const getInitials = (str: string = ""): string => {
  const results: string[] = []

  const wordArray = str.split(" ")

  wordArray.forEach((e) => results.push(e[0]?.toUpperCase() || ""))
  return results.join("")
}

export const castToFormData = (payload: { [key: string]: string | number | boolean | Date }): FormData => {
  const formData = new FormData()

  Object.keys(payload).forEach((key) => {
    formData.append(key, payload[key].toString())
  })

  return formData
}

export const hashToInteger = (input: string, digits: number = 6): number => {
  const modulus = Math.pow(10, digits + 1) - 1
  const phi = (Math.pow(10, digits) / 2) - 1
  let result = 0

  for (let i = 0; i < input.length; i++) {
    result = (result + (phi * input.charCodeAt(i))) % modulus
  }

  return result
}

import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import axios from "network"

import ModalInput from "components/ModalInput"
import ImageUploader from "components/ImageUploader"

import {
  Bonus, EBonusType,
  FeaturedBonusForm,
  setupFeaturedBonusFormSchema
} from "@modules/adminBonuses"
import { REGION } from "@context/Region"

type FeaturedBonusModalProps = {
  bonus?: Bonus
  region: REGION
  onApply: () => void
}

const FeaturedBonusModal: FC<FeaturedBonusModalProps> = ({ region, bonus, onApply }) => {
  const { t } = useTranslation()

  const defaultBonus = {
    title: "",
    firstLineDescription: "",
    secondLineDescription: "",
    thirdLineDescription: null,
    fourthLineDescription: null,
    imageUrl: null,
    refUrl: "",
    ...bonus
  }
  const [loading, setLoading] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<FeaturedBonusForm>({
    defaultValues: defaultBonus,
    mode: "onChange",
    resolver: yupResolver(setupFeaturedBonusFormSchema())
  })

  useEffect(() => {
    if (bonus) {
      reset(defaultBonus)
    }
  }, [bonus])

  const onSubmit = async (data: FeaturedBonusForm) => {
    setLoading(true)

    const formData = new FormData()

    formData.append("title", data.title)
    formData.append("firstLineDescription", data.firstLineDescription)
    formData.append("secondLineDescription", data.secondLineDescription)
    if (typeof data.imageUrl === "object") {
      formData.append("imageFile", data.imageUrl)
    } else {
      formData.append("imageUrl", data.imageUrl)
    }
    formData.append("refUrl", data.refUrl)
    formData.append("region", region)
    formData.append("type", EBonusType.FEATURED)

    if (bonus) {
      await axios.patch(`/bonuses/${bonus.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
        withCredentials: true
      })
    } else {
      await axios.post("/bonuses", formData, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
        withCredentials: true
      })
    }
    setLoading(false)

    reset(data)

    onApply()
  }

  const descriptions = ["firstLineDescription", "secondLineDescription"]

  return (
    <>
      <h2 className="admin-bonuses-modal-title admin-bonuses-modal-text">
        {bonus?.id ? t("admin-bonuses.modal.editFeatured") : t("admin-bonuses.modal.addFeatured")}
      </h2>

      <form className="admin-bonuses-modal-content" onSubmit={handleSubmit(onSubmit)}>
        <p className="admin-bonuses-modal-text">{t("admin-bonuses.modal.logo")}</p>

        <Controller
          control={control}
          name="imageUrl"
          render={({ field }) => (
            <ImageUploader
              className="mt-4"
              url={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <p className="admin-bonuses-modal-text">{t("admin-bonuses.modal.info")}</p>

        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={field.onChange}
              label={t("admin-bonuses.modal.title")}
              className="admin-bonuses-modal-space"
            />
          )}
        />

        <Controller
          control={control}
          name="refUrl"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={field.onChange}
              label={t("admin-bonuses.modal.link")}
              className="admin-bonuses-modal-space"
            />
          )}
        />

        {descriptions.map((key, index) => (
          <Controller
            key={key}
            control={control}
            name={key as keyof FeaturedBonusForm}
            render={({ field }) => (
              <ModalInput
                value={field.value}
                onChange={field.onChange}
                className="admin-bonuses-modal-space"
                label={`${t("admin-bonuses.modal.text")} ${index + 1}`}
              />
            )}
          />
        ))}


        <button
          disabled={loading || !isValid}
          className="shared-btn shared-btn-primary w-100 admin-bonuses-modal-submit admin-bonuses-modal-space"
        >
          {t("admin-bonuses.modal.save")}
        </button>
      </form>
    </>
  )
}

export default FeaturedBonusModal

import { createContext } from 'react'

export const AUTH_KEY = 'haddzy-auth'

export const context = {
  auth: Boolean(localStorage.getItem(AUTH_KEY)),
  setAuth(value: boolean) {
    context.auth = value
  }
}

const AuthContext = createContext(context)

export default AuthContext

import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import SectionTitle from "../components/SectionTitle"
import RandomWinnersCard from "components/RandomWinnersCard"
import { useLeaderboard } from "../useLeaderboard"
import { useParams } from "react-router-dom"
import { getImageUrlByIdAndIndex } from "@modules/leaderboards"


const RandomWinnersHistory = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useLeaderboard(id!)
  const [isAll, setIsAll] = useState(false)

  const toggleIsAll = () => {
    setIsAll(!isAll)
  }

  return (
    <section className="current-leaderboard-random-winners w-100 shared-container">
      <div className="tablet-max-hide">
        <SectionTitle imgUrl="/assets/icons/clover.svg"
                      text={loading ? "" : `${data.leaderboardRandomPrize.length} ${t("leaderboards.random-winners-history.title1")} ${data.leaderboardRandomPrize[0].amount} ${t("leaderboards.random-winners-history.title2")}`} />
      </div>

      <div className="current-leaderboard-random-winners-content">
        {!loading &&
          data.leaderboardRandomPrize.slice(0, isAll ? data.leaderboardRandomPrize.length : 5).map((user, index) => {
            return (
              <RandomWinnersCard
                imgUrl={getImageUrlByIdAndIndex(user.id, index)}
                place={index + 1}
                price={data.leaderboardRandomPrize[0].amount}
                name={user.leaderboardUserDisplayName}
                key={user.id + index}
              />
            )
          })}
      </div>

      <button className="custom-leaderboard-btn shared-btn-secondary"
              onClick={() => toggleIsAll()}>
        {isAll ? t("leaderboards.random-winners-history.btn-less") : t("leaderboards.random-winners-history.btn-all")}
      </button>
    </section>
  )
}
export default RandomWinnersHistory

import React from "react"
import { useTranslation } from "react-i18next"

import Header from "components/Header"
import Footer from "components/Footer"

import { Rewards, EarnBonuses } from "@modules/bonuses"

const Bonuses = () => {
  const { t } = useTranslation()

  return (
    <div
      className="landing w-100 min-vh-100 d-flex flex-column align-items-center justify-content-start"
    >
      <Header />

      <Rewards withAllBonuses withPromotions withBg />

      <EarnBonuses title={t("bonuses.earnSection.title")} subtitle={t("bonuses.earnSection.subtitle")} />

      <Footer />
    </div>
  )
}

export default Bonuses

import axios from 'network'

import { GetUserLootBoxItemQuery, PayUserLootBoxItemDTO } from './dto'

export const fetchAdminLootBoxTransactions = async (query: GetUserLootBoxItemQuery) => {
  const params = {
    ...query
  }

  if (!params.status) {
    delete params.status
  }
  const res = await axios.get('/loot-box/users', {
    params,
    withCredentials: true
  })

  return {
    total: res?.data?.total,
    data: res?.data
  }
}

export const fetchUserById = async (id: string) => {
  const res = await axios.get(`/users/${id}`, {
    withCredentials: true,
    params: { id }
  })

  return {
    data: res?.data
  }
}

export const payUserLootBoxItem = (payload: PayUserLootBoxItemDTO) =>
  axios.put(
    `/loot-box/users`,
    {
      ...payload
    },
    { withCredentials: true }
  )

import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import classNames from "classnames"

import { RaffleWinner } from "@modules/raffles"
import UserAvatar from "@src/components/UserAvatar"

type WinnerCardProps = {
  winner: RaffleWinner
  showEntriesCount?: boolean
  className?: string
}

const WinnerCard: FC<WinnerCardProps> = ({ className, winner, showEntriesCount = false }) => {
  const { t } = useTranslation()

  return (
    <div
      className={classNames("selected-raffle-entries-card-winner-container position-relative", className)}
    >
      <img
        src="/assets/images/crown-btm-part.png"
        alt="crown-btm"
        className="selected-raffle-entries-card-decoration-bottom"
      />
      <div className="selected-raffle-entries-card selected-raffle-entries-card-winner position-relative">
        <img
          src="/assets/images/crown-top-part.png"
          alt="crown-top"
          className="selected-raffle-entries-card-decoration-top"
        />

        <div className="position-relative">
          <UserAvatar
            userDisplayName={winner.displayName}
            userImageUrl={winner.profileImageUrl}
            className="selected-raffle-entries-card-avatar"
            size={64}
          />

          <div
            className="selected-raffle-entries-card-number selected-raffle-entries-card-number-winner shared-text shared-text--small text-main-bg font-weight-600"
          >
            {winner.entryNumber}
          </div>
        </div>

        <div
          className="selected-raffle-entries-card-name shared-text shared-text--small text-headline-white font-weight-600"
        >
          {winner.displayName}
        </div>
      </div>

      {winner.totalEntriesCount && showEntriesCount && (
        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="shared-text shared-text--small text-secondary-75 text-uppercase font-weight-600">
            <span className="selected-raffle-entries-card-winner-tickets-text">
              {t("raffles.selectedRaffle.winners.winningTicket")}:
            </span>

            <span className="ms-2">
              <img src="/assets/images/ticket.svg" alt="ticket" className="me-1" />
              {winner.entryNumber}
            </span>
          </div>

          <div className="shared-text shared-text--small text-secondary-75 text-uppercase font-weight-600">
            {t("raffles.selectedRaffle.winners.entriesCount")}:

            <span className="ms-3">
              {winner.totalEntriesCount}
            </span>
          </div>
        </div>
      )}

    </div>
  )
}

export default WinnerCard

import { createContext } from "react"

export enum REGION {
  GLOBAL = "GLOBAL",
  UK = "UK"
}

const RegionContext = createContext<REGION | undefined>(undefined)

export default RegionContext

import * as yup from "yup"

export type RaffleCardForm = {
  title: string
  description: string
  durationDays?: number | undefined | string
  durationHours?: number | undefined | string
  durationMinutes?: number | undefined | string
  entryCost?: number | undefined | string
  maxEntries: number
  maxEntriesPerUser: number
  winnersCount: number
  prizeInDollars?: number | undefined | string
  imageUrl: any
}

export const setupRaffleCardFormSchema = () => yup.object().shape({
  title: yup.string().required(),
  description: yup.string().required(),
  durationDays: yup.mixed(),
  durationHours: yup.mixed(),
  durationMinutes: yup.mixed(),
  entryCost: yup.mixed(),
  maxEntries: yup.number().required(),
  maxEntriesPerUser: yup.number().required(),
  winnersCount: yup.number().required(),
  prizeInDollars: yup.mixed(),
  imageUrl: yup.mixed().required()
})

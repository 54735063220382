import React, { FC } from "react"

import "./styles.scss"


type LeaderCardProps = {
  place: number,
  name: string,
  imgUrl: string,
  price: number,
}

const LeaderCard: FC<LeaderCardProps> = ({ name, imgUrl, price, place }) => {

  return (
    <div className="random-winner-card position-relative d-flex flex-column align-items-center justify-content-end">

      <span className="random-winner-card-place">
        #{place}
      </span>

      <img src={imgUrl} alt="logo" className="random-winner-card-logo" />

      <h4 className="random-winner-card-user">{name}</h4>

      <img src="/assets/icons/present.png" alt="present" className="random-winner-card-icon" />
      <div className="random-winner-card-footer">
        <div className="random-winner-card-btn shared-btn-primary"><span>$</span>{price}</div>
      </div>
    </div>

  )
}

export default LeaderCard

import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Spinner } from "reactstrap"

import { addCommasForBigNumbers } from "@utils/Strings"

import { PointsStoreStatisticCard, usePointsStoreStatistic } from "@modules/pointsStore"

type AdminPointsStoreStatisticProps = {}

const AdminPointsStoreStatistic: FC<AdminPointsStoreStatisticProps> = ({}) => {
  const { t } = useTranslation()

  const { statistic, loading } = usePointsStoreStatistic()

  if (loading || !statistic) {
    return (
      <div className="w-100 points-store-statistic">
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <section className="points-store-container w-100">
      <div className="points-store-statistic w-100 mt-3">
        <PointsStoreStatisticCard title={t("pointsStore.statistic.popularItem")} alternative>
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="d-flex align-items-center justify-content-center flex-row">
              <p
                className="shared-text points-store-statistic-card-text text-center w-100 m-0">{statistic.mostPopularItem?.title || "GOODS COMING SOON"}</p>
            </div>

            {statistic.mostPopularItem && (
              <div
                className="points-store-statistic-card-amount points-store-statistic-card-amount-admin text-secondary-75 d-flex flex-row align-items-center justify-content-center">
                {t("pointsStore.statistic.sold")}&nbsp;
                <span className="text-headline-white">
                {addCommasForBigNumbers(statistic.mostPopularItem?.sold || 0)}
              </span>
              </div>
            )}
          </div>
        </PointsStoreStatisticCard>

        <PointsStoreStatisticCard title={t("pointsStore.statistic.pointsSpent")} alternative>
          <p className="points-store-statistic-card-title m-0">
            {addCommasForBigNumbers(statistic.totalPointsSpent || 0)}
          </p>
        </PointsStoreStatisticCard>

        <PointsStoreStatisticCard title={t("pointsStore.statistic.totalPurchases")} alternative>
          <div>
            <p className="points-store-statistic-card-title m-0">
              {addCommasForBigNumbers(statistic.totalPurchases || 0)}
            </p>

            <div
              className="points-store-statistic-card-amount points-store-statistic-card-amount-admin text-secondary-75 d-flex flex-row align-items-center justify-content-center">

              {t("pointsStore.statistic.by")}&nbsp;
              <span className="text-headline-white">
                {statistic.uniqueBuyersCount || 0}
              </span>
              &nbsp;{t("pointsStore.statistic.users")}
            </div>
          </div>
        </PointsStoreStatisticCard>

        <PointsStoreStatisticCard title={t("pointsStore.statistic.topSpender")} alternative>
          <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
            <div className="w-100 d-flex flex-row align-items-center justify-content-center">
              <p
                className="points-store-statistic-card-username shared-text m-0">{statistic.topSpender?.displayName || "-"}</p>
            </div>

            {statistic.topSpender && (
              <div
                className="points-store-statistic-card-amount points-store-statistic-card-amount-admin d-flex flex-row align-items-center justify-content-center">
                <img src="/assets/icons/coin-alternative.svg" alt="coin" />

                {addCommasForBigNumbers(statistic.topSpender?.pointsSpent || 0)}
              </div>
            )}
          </div>
        </PointsStoreStatisticCard>
      </div>
    </section>
  )
}

export default AdminPointsStoreStatistic

// ** Router imports
import { useEffect, useState } from "react"
import { useRoutes } from "react-router-dom"

import axios from "network"

import RegionContext, { REGION } from "@context/Region"

// ** GetRoutes
import { getRoutes } from "./routes"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import SocketTrigger from "components/SocketTriggers"
import FireworksContext from "@context/Fireworks"
import StreamDraggableBlock from "@src/components/StreamDraggableBlock"

const getUserLocation = () => axios.get("/users/location")

const Router = () => {
  const allRoutes = getRoutes()

  return useRoutes([...allRoutes])
}

const AppHooks = () => {
  const toast = useToast()
  const [showFireworks, setShowFireworks] = useState<boolean>(false)

  const fireworksContextValue = { showFireworks, setShowFireworks }

  const [region, setRegion] = useState<REGION>()
  useEffect(() => {
    if (process.env.REACT_APP_HTTPS_FORCE === "true") {
      if (window.location.protocol === "http:") {
        window.open(window.location.href.replace("http://", "https://"), "_self")
      }
    }
  }, [])

  useEffect(() => {
    const fetch = async () => {
      const res = await getUserLocation()
      if (res.data) {
        setRegion(res.data?.message as REGION)
      } else {
        toast("Can't set region", TOAST_TYPE.ERROR)
      }
    }

    fetch()
  }, [])

  return (
    <FireworksContext.Provider value={fireworksContextValue}>
      <RegionContext.Provider value={region}>
        <SocketTrigger />
        <StreamDraggableBlock />
        <Router />
      </RegionContext.Provider>
    </FireworksContext.Provider>
  )
}

export default AppHooks

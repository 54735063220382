import { usePlainSWR } from '@hooks/usePlainSWR'
import { fetchLootBoxByTag } from './requests'
import { PublicLootBoxWithItems } from '../lootBox'

export const useGetLootBoxByTag = (tag: string) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`/loot-box/tag/${tag}`, () => fetchLootBoxByTag(tag))

  return {
    data: data as PublicLootBoxWithItems,
    error,

    refresh,

    isValidating,
    loading: !data && !error
  }
}

import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Modal } from "reactstrap"
import axios from "network"

import Countdown from "@src/components/Countdown"
import { addCommasForBigNumbers } from "@utils/Strings"
import { ReactComponent as HourglassIcon } from "assets/icons/hourglass.svg"

import { RaffleCardEntity } from "@modules/raffles"
import useToast, { TOAST_TYPE } from "@hooks/useToast"

type JoinRaffleModalProps = {
  isOpen: boolean
  item: RaffleCardEntity | null
  onClose: () => void
  onApply: () => void
}

const JoinRaffleModal: FC<JoinRaffleModalProps> = ({
  isOpen,
  onClose,
  item,
  onApply
}) => {
  const { t } = useTranslation()

  const toast = useToast()

  const joinRaffle = async () => await axios.post(`/raffles/${item?.id}/join`, { id: item?.id }, { withCredentials: true })
    .then(() => {
      toast(t("raffles.modal.toast"), TOAST_TYPE.SUCCESS)
    })
    .catch((e) => {
      toast(t(e.response.data.message), TOAST_TYPE.ERROR)
    })

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close rounded" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <h2 className="admin-bonuses-modal-title admin-bonuses-modal-text">
        {t("raffles.modal.title")}
        &nbsp;<span className="admin-bonuses-modal-text text-secondary-25">#{item?.id}</span>
      </h2>

      <div className="raffles-modal-body">
        <img src={item?.imageUrl} alt="" className="raffles-card-img" />

        <div className="d-flex flex-column align-items-center justify-content-center">
          <p className="raffles-card-title w-100">{item?.title}</p>

          {item?.description ? <p className="raffles-card-description">{item?.description}</p> : null}
        </div>

        <div className="raffles-modal-time">
          <div className="raffles-modal-time-title">
            <HourglassIcon className="text-secondary-75" />
            {t("raffles.modal.time")}
          </div>

          <Countdown date={item?.deadline.toString() || new Date().toString()} className="countdown-raffle-card m-0"
                     timeType />
        </div>
      </div>

      <div className="raffles-modal-footer">
        <button className="raffles-card-btn shared-btn-primary w-100 m-0" onClick={async () => {
          await joinRaffle()
          onApply()
        }}>
          {item?.entryCost === 0 ? t("raffles.card.btnJoinForFree")
            : (
              <>
                {t("raffles.card.btnJoin")}&nbsp;
                <img src="/assets/icons/coin-small.svg" alt="coin" className="raffles-card-btn-img" />&nbsp;
                {addCommasForBigNumbers(item?.entryCost)}
              </>)
          }
        </button>
      </div>
    </Modal>

  )
}

export default JoinRaffleModal

import React from "react"
import { useTranslation } from "react-i18next"

import { Button, Spinner } from "reactstrap"
import { getImageUrlByIdAndIndex, useLeaderboard } from "@modules/leaderboards"
import { useNavigate } from "react-router-dom"
import { numberWithCommas } from "@utils/Strings"

const defaultLeaderboardUsers = [
  {
    banned: false,
    bannedReason: "",
    createdAt: new Date(),
    displayName: "Cu*****j",
    id: "mock-default-id-1",
    leaderboardId: "",
    updatedAt: new Date(),
    wageredTotal: 95535.15
  }, {
    banned: false,
    bannedReason: "",
    createdAt: new Date(),
    displayName: "f2*****h",
    id: "mock-default-id-2",
    leaderboardId: "",
    updatedAt: new Date(),
    wageredTotal: 51637
  }, {
    banned: false,
    bannedReason: "",
    createdAt: new Date(),
    displayName: "Ju****c",
    id: "mock-default-id-3",
    leaderboardId: "",
    updatedAt: new Date(),
    wageredTotal: 35145
  }
]

const defaultLeaderboardTieredPrize = [
  {
    amount: 10000,
    id: "mock-default-tiered-prizeid-1",
    leaderboardId: "",
    prizeNumber: 1
  },
  {
    amount: 7000,
    id: "mock-default-tiered-prizeid-2",
    leaderboardId: "",
    prizeNumber: 2
  },
  {
    amount: 5000,
    id: "mock-default-tiered-prizeid-3",
    leaderboardId: "",
    prizeNumber: 3
  }
]

const LeaderBoard = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const { data, loading } = useLeaderboard()

  const firstPlace = loading || !data ? defaultLeaderboardUsers[0] : data.leaderboardUser[0]
  const secondPlace = loading || !data ? defaultLeaderboardUsers[1] : data.leaderboardUser[1]
  const thirdPlace = loading || !data ? defaultLeaderboardUsers[2] : data.leaderboardUser[2]

  return (
    <div className="landing-leader-wrapper shared-container position-relative d-flex justify-content-between">
      <div className="landing-leader-content">
        <div className="landing-leader-title position-relative">
          <h1 className="big-headline text-primary">{t("landing.leader-board.title1")}</h1>

          <h1 className="big-headline">{t("landing.leader-board.title2")}</h1>

          <img src="/assets/icons/premium-quality.svg" alt="meddal" className="landing-leader-medal tablet-hide" />
        </div>

        <p className="landing-leader-board-description description">{t("landing.leader-board.text")}</p>

        <button className="shared-btn-primary landing-explore-btn" onClick={() => navigate("/leaderboards")}>
          {t("landing.leader-board.btn")}
        </button>
      </div>

      <div className="landing-leader-board position-relative">
        {loading ? (
          <div className="w-100 h-100 d-flex align-items-center justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <>
            <div
              className="landing-leader-board-card landing-leader-board-card-first d-flex flex-column align-items-center justify-content-end"
            >
              <div className="d-flex flex-column position-relative align-items-center justify-content-center">
                <img
                  src="/assets/icons/place-first.svg" alt="first"
                  className="landing-leader-board-card-place-first"
                />

                <img
                  src={getImageUrlByIdAndIndex(firstPlace?.id, 0)}
                  alt="img1"
                  className="landing-leader-board-card-logo"
                />

                <h4 className="landing-leader-board-card-user">{firstPlace?.displayName}</h4>

                <h5>{t("landing.leader-board.cart-title")}</h5>

                <h4 className="landing-leader-board-card-wagered">
                  <span>$</span>{numberWithCommas(firstPlace?.wageredTotal)}
                </h4>
              </div>

              <img src="/assets/icons/present.png" alt="present" className="landing-leader-board-card-icon" />

              <Button
                className="landing-leader-board-card-btn"
                cssModule={{ btn: "landing-leader-board-card-btn" }}
              >
                <span>$</span>{numberWithCommas(!data ? defaultLeaderboardTieredPrize[0]?.amount : data.leaderboardTieredPrize[0]?.amount)}
              </Button>
            </div>

            <img
              src="/assets/images/crown-top-part.png" alt="crown-top-part"
              className="landing-leader-board-crown-top"
            />

            <img
              src="/assets/images/crown-btm-part.png" alt="crown-btm-part"
              className="landing-leader-board-crown-btm"
            />

            <img src="/assets/images/cup.png" alt="cup" className="landing-leader-board-cup" />

            <div
              className="landing-leader-board-card landing-leader-board-card-second d-flex flex-column align-items-center justify-content-end"
            >
              <div className="d-flex flex-column position-relative align-items-center justify-content-center">
                <img
                  src="/assets/icons/place-second.svg"
                  alt="second"
                  className="landing-leader-board-card-place-second"
                />

                <img
                  src={getImageUrlByIdAndIndex(secondPlace?.id, 1)}
                  alt="img1"
                  className="landing-leader-board-card-logo-second"
                />

                <h4
                  className="landing-leader-board-card-user landing-leader-board-card-user-second">{secondPlace?.displayName}</h4>

                <h5>{t("landing.leader-board.cart-title")}</h5>

                <h4 className="landing-leader-board-card-wagered">
                  <span>$</span>{numberWithCommas(secondPlace?.wageredTotal)}</h4>
              </div>

              <img src="/assets/icons/present.png" alt="present" className="landing-leader-board-card-icon" />

              <Button
                className="landing-leader-board-card-btn"
                cssModule={{ btn: "landing-leader-board-card-btn" }}
              >
                <span>$</span>{numberWithCommas(!data ? defaultLeaderboardTieredPrize[1]?.amount : data.leaderboardTieredPrize[1]?.amount)}
              </Button>
            </div>

            <div
              className="landing-leader-board-card landing-leader-board-card-third d-flex flex-column align-items-center justify-content-end"
            >
              <div className="d-flex flex-column position-relative align-items-center justify-content-center">
                <img
                  src="/assets/icons/place-third.svg"
                  alt="third"
                  className="landing-leader-board-card-place-third"
                />

                <img
                  src={getImageUrlByIdAndIndex(thirdPlace?.id, 2)}
                  alt="img1"
                  className="landing-leader-board-card-logo-third"
                />

                <h4
                  className="landing-leader-board-card-user landing-leader-board-card-user-third">{thirdPlace?.displayName}</h4>

                <h5>{t("landing.leader-board.cart-title")}</h5>

                <h4 className="landing-leader-board-card-wagered">
                  <span>$</span>{numberWithCommas(thirdPlace?.wageredTotal)}</h4>
              </div>

              <img src="/assets/icons/present.png" alt="present" className="landing-leader-board-card-icon" />

              <Button
                className="landing-leader-board-card-btn"
                cssModule={{ btn: "landing-leader-board-card-btn" }}
              >
                <span>$</span>{numberWithCommas(!data ? defaultLeaderboardTieredPrize[1]?.amount : data.leaderboardTieredPrize[2]?.amount)}
              </Button>
            </div>

            <img src="/assets/images/dice.svg" alt="dice" className="landing-leader-board-dice" />
          </>
        )}
      </div>
    </div>
  )
}
export default LeaderBoard

import { lazy } from 'react'
import './views/daily-case.scss'

import en from './locales/en.json'
import ru from './locales/ru.json'
import { RouteEntity } from '@src/router/routes'

export const DailyCase = lazy(() => import('./views/DailyCase'))

export * from './useGetLootBoxByTag'

export * from './requests'

// export { default as RafflesCard } from "./components/RafflesCard"

export const router: RouteEntity[] = [
  {
    path: '/daily-case',
    element: <DailyCase />
  }
]

export const locales = {
  en,
  ru
}

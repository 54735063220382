import React, { FC } from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import "./styles.scss"
import { numberWithCommas } from "@utils/Strings"

type LeaderCardProps = {
  place: number,
  name?: string,
  bank?: number,
  imgUrl?: string,
  amount: number
}

const LeaderCard: FC<LeaderCardProps> = ({ name, bank, imgUrl, place, amount }) => {
  const { t } = useTranslation()

  const icons = () => {
    switch (place) {
      case 1:
        return <div className="leader-board-card-place">
          <img className="leader-board-card-place" src="/assets/icons/first.svg" alt="first" />
          <span>{place}</span>
        </div>
      case 2:
        return <div className="leader-board-card-place">
          <img className="leader-board-card-place" src="/assets/icons/second.svg" alt="second" />
          <span>{place}</span>
        </div>

      case 3:
        return <div className="leader-board-card-place">
          <img className="leader-board-card-place" src="/assets/icons/third.svg" alt="third" />
          <span>{place}</span>
        </div>
    }
  }
  return (
    <div
      className={classNames("leader-board-card position-relative d-flex flex-column align-items-center justify-content-end", `leader-board-card-${place}`)}
    >
      {icons()}

      {place === 1 && <img className="leader-board-card-winner" src="/assets/icons/winner.svg" alt="winner" />}

      <img src={imgUrl} alt="logo" className="leader-board-card-logo" />

      <h4 className={classNames("leader-board-card-user", {
        "leader-board-card-user-first": place === 1
      })}
      >
        {name}
      </h4>

      <div className="leader-board-card-footer d-flex flex-column align-items-center">
        <div className="leader-board-card-wagered">
          <h5>{t("leader-card.text")}</h5>

          <h4><span>$</span>{numberWithCommas(bank)}</h4>
        </div>


        <img src="/assets/icons/present.png" alt="present" className="leader-board-card-icon" />

        <div className="leader-board-card-btn"><span>$</span>{numberWithCommas(amount)}</div>
      </div>
    </div>
  )
}

export default LeaderCard

import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import AsideCollapse from 'components/AsideCollapse'

const AsideMenu = () => {
  const par = useParams()
  const keys = Object.keys(par)

  const [activeNav, setActiveNav] = useState(par[keys[0]]?.split('/')[0] || '')

  const navHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
    const link: HTMLAnchorElement = event.currentTarget
    setActiveNav(link.type)
  }

  const MAIN_LINKS: { text: string; url: string }[] = [
    { text: 'users', url: 'users' },
    { text: 'leaderboards', url: 'leaderboards' },
    { text: 'ALL BONUSES', url: 'bonuses' },
    { text: 'raffles', url: 'raffles' }
  ]

  const POINT_STORE_LINKS: { text: string; url: string }[] = [
    { text: 'points store', url: 'points-store' },
    { text: 'transactions', url: 'transactions' }
  ]

  const LOOT_BOX_LINKS: { text: string; url: string }[] = [
    { text: 'loot boxes', url: 'loot-boxes' },
    { text: 'loot box items', url: 'loot-box-items' },
    { text: 'loot box transactions', url: 'loot-box-transactions' }
  ]

  const SLOTS_REVIEWS_LINKS: { text: string; url: string }[] = [
    { text: 'slots', url: 'slots-reviews' },
    { text: 'providers', url: 'providers' },
    { text: 'comments', url: 'comments' }
  ]
  return (
    <aside className='admin-aside position-relative'>
      <AsideCollapse
        title='main'
        links={MAIN_LINKS}
        active={activeNav}
        navHandler={navHandler}
      />

      <AsideCollapse
        title='points store'
        links={POINT_STORE_LINKS}
        active={activeNav}
        navHandler={navHandler}
      />

      <AsideCollapse
        title='loot box'
        links={LOOT_BOX_LINKS}
        active={activeNav}
        navHandler={navHandler}
      />

      <AsideCollapse
        title='slots reviews'
        links={SLOTS_REVIEWS_LINKS}
        active={activeNav}
        navHandler={navHandler}
      />
    </aside>
  )
}
export default AsideMenu

import React from "react"
import { useTranslation } from "react-i18next"

const VirtualAvatar = () => {
  const { t } = useTranslation()

  return (
    <section className="landing-virtual-avatar-wrapper position-relative tablet-max-hide">
      <img
        src="/assets/images/bg-virtual-avatar.svg"
        alt="bg-virtual-avatar"
        className="landing-virtual-avatar-bg tablet-max-hide"
      />

      <div className="shared-container w-100 d-flex align-items-start tablet-max-hide">
        <div className="landing-virtual-avatar">
          <span className="landing-virtual-avatar-label">
            {t("landing.virtual-avatar.new")}
          </span>

          <h3 className="small-headline">
            {t("landing.virtual-avatar.title1")}
          </h3>

          <h1 className="landing-virtual-avatar-title big-headline">
            {t("landing.virtual-avatar.title2")}
          </h1>

          <h1 className="landing-virtual-avatar-title big-headline-gold">
            {t("landing.virtual-avatar.title3")}
          </h1>

          <p className="landing-virtual-avatar-description description">
            {t("landing.virtual-avatar.text")}
          </p>

          <button className="shared-btn-primary" disabled>
            {t("landing.virtual-avatar.coming-soon")}
          </button>
        </div>

        <div className="position-relative">
          <img
            src="/assets/icons/main-s4-decor.png"
            alt="landing-virtual-avatar-decor"
            className="landing-virtual-avatar-decor wide-max-hide"
          />

          <img
            src="/assets/icons/main-s4-img.png"
            alt="landing-virtual-avatar-img"
            className="landing-virtual-avatar-img"
          />
        </div>
      </div>
    </section>
  )
}
export default VirtualAvatar

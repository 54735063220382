import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { ProvidersCard, useFeaturedProviders } from "@modules/slotsReviews"
import { Spinner } from "reactstrap"
import { useNavigate } from "react-router-dom"

type FeaturedProvidersProps = {}

const FeaturedProviders: FC<FeaturedProvidersProps> = ({}) => {
  const navigate = useNavigate()

  const { t } = useTranslation()
  const { list, loading } = useFeaturedProviders()

  return (
    <section className="slots-reviews-featured-providers">
      <div className="w-100 d-flex align-items-center justify-content-between">
        <p className="shared-text text-secondary-100 font-weight-700 text-uppercase m-0">
          {t("slotsReviews.featuredProviders.title")}
        </p>

        <button className="slots-reviews-btn--default shared-btn-secondary slots-reviews-featured-providers-list-btn" onClick={() => navigate("/slots-reviews/providers")}>
          {t("slotsReviews.featuredProviders.btn")}
        </button>
      </div>

      <div className="slots-reviews-featured-providers-list">
        {loading ? (
          <div className="d-flex w-100 align-items-center justify-content-center">
            <Spinner color="primary" />
          </div>
        ) : (
          list.length === 0 ? (
            <div className="slots-reviews-providers-list-stub shared-stub">
              <p className="shared-stub-text">
                {t("currentProvider.stub")}
              </p>
            </div>
          ) : (
            list.map((card) => (
              <ProvidersCard card={card} key={card.id} />
            ))))}
      </div>
    </section>
  )
}

export default FeaturedProviders

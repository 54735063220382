import React, { FC } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { SlotCard, TopSlotCard } from "@modules/slotsReviews"

type SlotsReorderProps = {
  onDelete: (b: string) => void
  card: SlotCard
}

const SlotsReorder: FC<SlotsReorderProps> = ({ onDelete, card }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: card.id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  }
  return (
    <div ref={setNodeRef}
         style={style}
         {...attributes}
         {...listeners}
    >
      <TopSlotCard
        onDelete={onDelete}
        card={card}
        className={"admin-slots-reviews-card"}
        withControl
      />
    </div>
  )
}

export default SlotsReorder
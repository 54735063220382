import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { mutate } from 'swr'
import classNames from 'classnames'

import { Button, Modal, ModalBody, ModalHeader, Spinner } from 'reactstrap'

import UserAvatar from 'components/UserAvatar'

import {
  useUserById,
  RejectTransactionModal,
  ApproveTransactionModal,
  UserLootBoxItem
} from '@modules/adminLootBoxTransactions'

import { addCommasForBigNumbers } from '@utils/Strings'
import { UserLootBoxItemStatus } from '@src/modules/lootBox'

type ReviewTransactionProps = {
  transaction?: UserLootBoxItem
  isOpen: boolean
  onClose: () => void
  onApply: () => void
}

const ReviewTransaction: FC<ReviewTransactionProps> = ({ onClose, transaction, isOpen }) => {
  const { t } = useTranslation()

  const { user: userInfo } = useUserById(transaction?.user.id!)

  const [showRejectModal, setShowRejectModal] = useState(false)
  const [showApproveModal, setShowApproveModal] = useState(false)

  const ApplyButton = () => (
    <Button
      cssModule={{ btn: 'profile-modal-ban-btn' }}
      onClick={() => setShowApproveModal(true)}
      className='flex-cell admin-loot-box-transactions-modal-cta-approve'
    >
      {t('admin-loot-box-transactions.actions.approve')}
    </Button>
  )
  const RejectButton = () => (
    <Button
      cssModule={{ btn: 'profile-modal-ban-btn' }}
      onClick={() => setShowRejectModal(true)}
      className='flex-cell admin-loot-box-transactions-modal-cta-reject'
    >
      {t('admin-loot-box-transactions.actions.reject')}
    </Button>
  )

  const getCtaButtons = () => {
    switch (transaction?.status) {
      case UserLootBoxItemStatus.PENDING:
        return (
          <div className='admin-loot-box-transactions-modal-cta-wrapper'>
            <RejectButton />

            <ApplyButton />
          </div>
        )
      case UserLootBoxItemStatus.APPROVED:
        return <RejectButton />
      case UserLootBoxItemStatus.REJECTED:
        return <ApplyButton />
    }
  }

  const closeBtn = (
    <button className='profile-modal-close' onClick={onClose} type='button'>
      <img src='/assets/icons/close.svg' alt='close' />
    </button>
  )

  if (!userInfo)
    return (
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        contentClassName='profile-modal'
        className='profile-modal-container'
        backdrop='static'
        centered
      >
        <div className='w-100 d-flex align-items-center justify-content-center'>
          <Spinner className='text-primary' />
        </div>
      </Modal>
    )

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        contentClassName='profile-modal'
        className='profile-modal-container'
        backdrop='static'
        centered
      >
        <ModalHeader close={closeBtn} cssModule={{ 'modal-header': 'profile-modal-header' }}>
          {t('profile.profile')}
        </ModalHeader>

        <ModalBody className='p-0'>
          <div className='profile-modal-wrapper mt-4'>
            <div className='profile-modal-user'>
              <UserAvatar
                userDisplayName={userInfo.displayName}
                userImageUrl={userInfo.profileImageUrl}
              />

              <div className='d-flex flex-column admin-loot-box-transactions-modal-content'>
                <div className='profile-modal-user-name-container align-items-center'>
                  <p className='admin-loot-box-transactions-modal-content-name shared-text m-0'>
                    {userInfo.displayName}
                  </p>

                  <span className='d-flex align-items-center justify-content-between shared-text faded-text ms-auto'>
                    Status:
                    <span
                      className={classNames('profile-modal-user-status shared-text', {
                        'profile-modal-user-status-pending':
                          transaction?.status === UserLootBoxItemStatus.PENDING,
                        'profile-modal-user-status-approved':
                          transaction?.status === UserLootBoxItemStatus.APPROVED,
                        'profile-modal-user-status-rejected':
                          transaction?.status === UserLootBoxItemStatus.REJECTED
                      })}
                    >
                      {transaction?.status}
                    </span>
                  </span>
                </div>

                <div className='profile-modal-user-info'>
                  <p>
                    {t('profile.joined')}: {format(new Date(userInfo.createdAt), 'MM.dd.yyyy')}
                  </p>

                  <p>
                    {t('profile.email')}: {userInfo.email}
                  </p>
                </div>

                <span className='profile-modal-user-balance text-nowrap width-fit-content'>
                  <img src='/assets/icons/Coin.svg' alt='' />
                  {t('profile.points')}: {userInfo.pointsBalance || 0}
                </span>
              </div>
            </div>

            <div className='profile-modal-statistics'>
              <div className='profile-modal-statistics-title'>
                <img src='/assets/icons/statistics.svg' alt='statistics-icon' />

                <p>{t('profile.statistics')}</p>
              </div>

              <div className='d-flex w-100 align-items-center justify-content-center'>
                <div className='profile-modal-statistics-container w-100'>
                  <div className='profile-modal-statistics-block w-100'>
                    <p className='profile-modal-statistics-block-title'>
                      {t('profile.raffles-won')}
                    </p>

                    <img src='/assets/icons/Coin.svg' alt='coin' />

                    <p className='profile-modal-statistics-block-balance'>
                      {userInfo.rafflesWon || 0}
                    </p>
                  </div>

                  <div className='profile-modal-statistics-block w-100'>
                    <p className='profile-modal-statistics-block-title'>
                      {t('profile.points-spent')}
                    </p>

                    <img src='/assets/icons/Coin.svg' alt='coin' />

                    <p className='profile-modal-statistics-block-balance'>
                      {userInfo.pointsSpent || 0}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='admin-loot-box-transactions-modal-item d-flex flex-row align-items-center justify-content-start'>
              <div className='admin-loot-box-transactions-modal-item-image-wrapper'>
                <img
                  src={transaction?.lootBoxItem.imageUrl}
                  alt='item'
                  className='admin-loot-box-transactions-modal-item-image'
                />
              </div>

              <div className='admin-loot-box-transactions-modal-item-content d-flex flex-column align-items-start justify-content-start'>
                <h6 className='admin-loot-box-transactions-modal-item-title m-0'>
                  {transaction?.lootBoxItem.title}
                </h6>

                <p className='admin-loot-box-transactions-modal-item-description text-secondary-75 shared-text m-0'>
                  {transaction?.lootBoxItem.description}
                </p>

                <div className='admin-loot-box-transactions-modal-item-price w-100 d-flex flex-row align-items-center justify-content-start'>
                  <span className='text-secondary-75 shared-text'>
                    {t('admin-loot-box-transactions.modal.price')}:
                  </span>

                  <div className='admin-loot-box-transactions-modal-item-price-cell'>
                    $ {addCommasForBigNumbers(transaction?.lootBoxItem.priceDollars)}
                  </div>
                </div>
              </div>
            </div>
            <div className='admin-loot-box-transactions-modal-item d-flex flex-row align-items-center justify-content-start'>
              <div className='admin-loot-box-transactions-modal-item-image-wrapper'>
                <img
                  src={transaction?.lootBox.imageUrl}
                  alt='item'
                  className='admin-loot-box-transactions-modal-item-image'
                />
              </div>

              <div className='admin-loot-box-transactions-modal-item-content d-flex flex-column align-items-start justify-content-start'>
                <h6 className='admin-loot-box-transactions-modal-item-title m-0'>
                  {transaction?.lootBox.title}
                </h6>

                <p className='admin-loot-box-transactions-modal-item-description text-secondary-75 shared-text m-0'>
                  {transaction?.lootBox.description}
                </p>
              </div>
            </div>

            {transaction?.status === UserLootBoxItemStatus.REJECTED && (
              <div className='admin-loot-box-transactions-modal-reason'>
                <p>{t('admin-loot-box-transactions.modal.rejectReason')}</p>

                <div className='admin-loot-box-transactions-modal-reason-block'>
                  {transaction.rejectionReason}
                </div>
              </div>
            )}

            {getCtaButtons()}

            <RejectTransactionModal
              transaction={transaction}
              onClose={() => setShowRejectModal(false)}
              isOpen={showRejectModal}
              onApply={() => {
                mutate('/loot-box/users')

                setShowRejectModal(false)
              }}
            />

            <ApproveTransactionModal
              transaction={transaction}
              onClose={() => setShowApproveModal(false)}
              isOpen={showApproveModal}
              onApply={() => {
                mutate('/loot-box/users')

                setShowApproveModal(false)
              }}
            />
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ReviewTransaction

import { useEffect } from "react"

import axios from "@src/network"

import { usePlainSWRNoMount } from "@hooks/usePlainSWR"

import { EBonusType, TopBonus } from "@modules/adminBonuses"
import { REGION } from "@context/Region"

const getTopBonuses = (region: REGION = REGION.GLOBAL) => axios.get<TopBonus[]>("/bonuses", {
  params: {
    type: EBonusType.TOP,
    region
  },
  withCredentials: true
})

export const useTopBonuses = (region?: REGION) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount("/top-bonuses", () => (region ? getTopBonuses(region) : null))

  useEffect(() => {
    refresh()
  }, [region, refresh])

  const bonuses: TopBonus[] = (data || [])

  return {
    bonuses,
    loading: !error && !data,
    error,
    refresh,
    isValidating
  }
}

export default useTopBonuses

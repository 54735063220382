export type IdParam = {
  id: number
}

export type PaginationQuery = {
  skip: number
  take: number
}

export type GetLootBoxItemsQuery = PaginationQuery & {
  text?: string
}

export type GetLootBoxesFullQuery = PaginationQuery & {
  text?: string
  enabled?: boolean
  tag?: string
}

export type GetLootBoxFullQuery = IdParam & {
  withItems: boolean
}

export type LootBoxItemDTO = {
  title: string
  description: string
  imageUrl: string
  quantity: number
  stockQuantity: number
  unlimited: boolean
  priceDollars: number
  price: number
  type: LootBoxItemType
}

export type LootBoxDTO = {
  title: string
  description: string
  imageUrl: string
  settings: LootBoxSettings
  enabled: boolean
  tags: string[]
}

export type PutItemsToLootBoxItemDTO = {
  itemId: number
  dropProbability: number
}

export type PutItemsToLootBoxDTO = {
  lootBoxId: number
  items: PutItemsToLootBoxItemDTO[]
}

export type LootBoxSettings = {
  openCondition: {
    frequencyHours: number | null | undefined
    pointPrice: number | null | undefined
  }
}

export type PublicLootBoxSettings = Pick<LootBoxSettings, 'openCondition'>

export type PublicLootBox = {
  id: number
  title: string
  description: string
  imageUrl: string
  settings: PublicLootBoxSettings
}

export type PublicLootBoxItem = {
  id: number
  title: string
  description: string
  imageUrl: string
  priceDollars: number
  price: number
}

export type PublicWonItem = PublicLootBoxItem & {
  type: LootBoxItemType
}

export type PublicLootBoxItemWithProbability = PublicLootBoxItem & {
  dropChance: string
  dropProbability: number
}

export type PublicLootBoxWithItems = PublicLootBox & {
  items: PublicLootBoxItemWithProbability[]
}

export type OpenLootBoxResponse = {
  items: PublicLootBoxItemWithProbability[]
  wonItem: PublicWonItem
}

export enum UserLootBoxItemStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED'
}

export enum LootBoxItemType {
  EMPTY = 'EMPTY',
  POINTS = 'POINTS',
  ITEM = 'ITEM'
}

export type PublicUserLootBoxItem = {
  id: number
  status: UserLootBoxItemStatus
  rejectionReason: string | null
  createdAt: Date
  updatedAt: Date
  lootBoxItem: PublicLootBoxItem
}

export type LootBoxItem = {
  id: number
  title: string
  description: string
  imageUrl: string
  quantity: number
  stockQuantity: number
  unlimited: boolean
  createdAt: Date
  updatedAt: Date
  priceDollars: number
  price: number
  type: LootBoxItemType
  usersId: string
  deleted: boolean
}

export type LootBoxItemInLootBoxWithItem = {
  id: number
  createdAt: Date
  updatedAt: Date
  lootBoxItemId: number
  lootBoxId: number
  dropProbability: number
  usersId: string
  item: LootBoxItem
}

export type LootBoxItemInLootBoxWithItemInput = Pick<
  LootBoxItemInLootBoxWithItem,
  'item' | 'dropProbability'
>

export type LootBoxItemInLootBoxWithItemInputView = LootBoxItemInLootBoxWithItemInput & {
  chanceInPercent: string
}

export type LootBox = {
  id: number
  title: string
  description: string
  imageUrl: string
  createdAt: Date
  updatedAt: Date
  usersId: string
  enabled: boolean
  settings: LootBoxSettings
  deleted: boolean
  tags: string[]
}

export type LootBoxWithItems = LootBox & {
  lootBoxItemInLootBox: LootBoxItemInLootBoxWithItem[]
}

export type PaginatedResponse<T> = {
  data: T[]
  total: number
}

export type MutationResponse = {
  success: boolean
}

export type ErrorResponse = {
  message: string
  statusCode: number
  error: string
}

import { Slide, toast, ToastPosition } from "react-toastify"

export const TOAST_TYPE = {
  SUCCESS: "success",
  ERROR: "error",
  INFO: "info"
}

const toastOptions = {
  transition: Slide,
  position: "top-right" as ToastPosition,
  autoClose: 2500,
  closeOnClick: true,
  rtl: false,
  pauseOnFocusLoss: true,
  draggable: true,
  pauseOnHover: true,
  className: "toast-wrapper"
}

const useToast = () => {
  const showInfoToast = (text: string) => {
    toast.info(text, toastOptions)
  }

  const showSuccessToast = (text: string) => {
    toast.success(text, toastOptions)
  }

  const showErrorToast = (text: string) => {
    toast.error(text, toastOptions)
  }

  return (text: string, type = TOAST_TYPE.SUCCESS) => {
    switch (type) {
      case TOAST_TYPE.INFO:
        return showInfoToast(text)
      case TOAST_TYPE.SUCCESS:
        return showSuccessToast(text)
      case TOAST_TYPE.ERROR:
        return showErrorToast(text)
      default:
        return showErrorToast(text)
    }
  }
}

export default useToast

import { usePlainSWR } from '@hooks/usePlainSWR'
import { PublicLootBoxTransactionsFilterPayload } from '../adminLootBoxTransactions'
import { useEffect, useState } from 'react'
import { fetchUserLootBoxItems } from './requests'
import { PublicUserLootBoxItem } from '../lootBox'

export const useGetUserLootBoxItems = () => {
  const [filter, setFilter] = useState<PublicLootBoxTransactionsFilterPayload>({
    status: undefined
  })
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR('/loot-box/my-items', () =>
    fetchUserLootBoxItems({ take: perPage, skip: (page - 1) * perPage, ...filter })
  )

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  const onFiltered = (newFilter: Partial<PublicLootBoxTransactionsFilterPayload>) =>
    setFilter((prev) => ({ ...prev, ...newFilter }))

  return {
    list: (data?.data || []) as PublicUserLootBoxItem[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 0,

    filter,
    onFiltered
  }
}

import React, { FC } from "react"
import { useTranslation } from "react-i18next"

import { Spinner } from "reactstrap"

import { addCommasForBigNumbers } from "@utils/Strings"

import { PointsStoreStatisticCard, usePointsStoreStatistic } from "@modules/pointsStore"

type PointsStoreStatisticProps = {}

const PointsStoreStatistic: FC<PointsStoreStatisticProps> = ({}) => {
  const { t } = useTranslation()

  const { statistic, loading } = usePointsStoreStatistic()

  if (loading || !statistic) {
    return (
      <div className="w-100 points-store-statistic">
        <Spinner color="primary" />
      </div>
    )
  }

  return (
    <section className="points-store-container w-100">
      <div className="points-store-statistic w-100">
        <PointsStoreStatisticCard title={t("pointsStore.statistic.popularItem")}>
          <div className="d-flex align-items-center justify-content-center flex-row">
            <img
              src={statistic.mostPopularItem?.imageUrl || "/assets/icons/coin-alternative.svg"}
              className="points-store-statistic-card-img"
              alt=""
            />

            <p className="shared-text points-store-statistic-card-text">
              {statistic.mostPopularItem?.title || "GOODS COMING SOON"}
            </p>
          </div>
        </PointsStoreStatisticCard>

        <PointsStoreStatisticCard title={t("pointsStore.statistic.topSpender")}>
          <div className="w-100 h-100 d-flex flex-column align-items-center justify-content-center">
            <div className="w-100 d-flex flex-row align-items-center justify-content-center">
              <img className="points-store-statistic-card-avatar" src={statistic.topSpender?.profileImageUrl || '/assets/images/image1.png'} alt="" />

              <p className="points-store-statistic-card-username text-truncate shared-text m-0">{statistic.topSpender?.displayName || 'CAN BE YOU'}</p>
            </div>

            {statistic.topSpender && (
              <div
                className="points-store-statistic-card-amount d-flex flex-row align-items-center justify-content-center">
                <img src="/assets/icons/coin-alternative.svg" alt="coin" />

                {statistic.topSpender?.pointsSpent || 0}
              </div>
            )}
          </div>
        </PointsStoreStatisticCard>

        <PointsStoreStatisticCard title={t("pointsStore.statistic.pointsSpent")}>
          <p className="points-store-statistic-card-title m-0">
            {addCommasForBigNumbers(statistic.totalPointsSpent || 0)}
          </p>
        </PointsStoreStatisticCard>

        <PointsStoreStatisticCard title={t("pointsStore.statistic.totalPurchases")}>
          <p className="points-store-statistic-card-title m-0">
            {addCommasForBigNumbers(statistic.totalPurchases || 0)}
          </p>
        </PointsStoreStatisticCard>
      </div>
    </section>
  )
}

export default PointsStoreStatistic

import { lazy } from "react"
import "./views/admin-raffles.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export const AdminActiveRaffles = lazy(() => import("./views/AdminActiveRaffles"))
export const AdminEndedRaffles = lazy(() => import("./views/AdminEndedRaffles"))

export const AdminCurrentRaffle = lazy(() => import("./views/AdminCurrentRaffle"))

export * from "./dto"
export * from "./helpers/form"
export * from "./requests"

export { default as RerollRaffleWinnerModal } from "./components/RerollRaffleWinnerModal"
export { default as AdminCurrentRaffles } from "./components/AdminCurrentRaffles"
export { default as CreateNewRaffleModal } from "./components/CreateNewRaffleModal"
export { default as ToggleRafflePaidStatusModal } from "./components/ToggleRafflePaidStatusModal"
export { default as WinnersCollapseCell } from "./components/WinnersCollapseCell"
export { default as AdminRaffleEntries } from "./components/AdminRaffleEntries"

export const locales = {
  en,
  ru
}

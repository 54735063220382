import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"

import { RaffleCardEntity, RAFFLES_STATUS, RafflesCard, useRaffles } from "@modules/raffles"
import { useCurrentUser } from "@hooks/useUser"
import Pagination from "@src/components/Pagination"
import SearchInput from "@src/components/SearchInput/SearchInput"
import { CreateNewRaffleModal } from "@modules/adminRaffles"
import { useNavigate } from "react-router-dom"


type AdminCurrentRafflesProps = {}

const AdminCurrentRaffles: FC<AdminCurrentRafflesProps> = ({}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const status = RAFFLES_STATUS.ACTIVE

  const { user } = useCurrentUser()
  const { list, loading, page, refresh, perPage, total, filter, onFiltered, onChangePage } = useRaffles(status)

  const [itemToEdit, setItemToEdit] = useState<RaffleCardEntity | null>(null)
  const [showCreateNewRaffleModal, setCreateNewRaffleModal] = useState(false)

  const onApplyCreateNewRaffleModal = () => {
    setCreateNewRaffleModal(false)
    refresh()
  }
  const closeAddPointCardModal = () => {
    setCreateNewRaffleModal(false)
    setItemToEdit(null)
  }

  return (
    <section className="admin-raffles">
      <div className="raffles-title-wrapper">
        <img src="/assets/images/tickets.png" alt="tickets" className="raffles-title-img" />

        <div className="raffles-title-container">
          <p className="raffles-title">
            {t("raffles.currentRaffles")}
          </p>

          <p className="shared-text shared-text--small font-weight-600 text-secondary-75 m-0">
            {t("raffles.currentRafflesDescription")}
          </p>
        </div>
      </div>


      <div className="admin-raffles-search-wrapper">
        <div className="w-100">
          <SearchInput
            onChange={(s) => onFiltered({ search: s })}
            value={filter?.search}
          />
        </div>

        <div className="admin-raffles-cta-wrapper">
          <button
            onClick={() => navigate('/admin/raffles/ended')}
            className="admin-raffles-cta-btn-history shared-btn-secondary"
          >
            <img src="/assets/icons/clock.svg" alt="refresh" />

            {t("admin-raffles.btnShowHistory")}
          </button>

          <button
            className="admin-raffles-cta-btn-add shared-btn-primary"
            onClick={() => setCreateNewRaffleModal(true)}
          >
            <img src="/assets/icons/plus.svg" alt="plus" className="admin-leaderboards-icon-add" />

            {t("admin-raffles.btnCreateRaffle")}
          </button>
        </div>
      </div>

      <div className="admin-raffles-list-wrapper">
        <div className="raffles-list mt-4">
          {loading ? (<Spinner color="primary" />
          ) : (
            list.length === 0 ? (
              <div className="raffles-list-stub">
                <p className="raffles-list-stub-text">
                  {t("admin-raffles.noActiveRaffles")}
                </p>
              </div>
            ) : (
              list.map(card => (
                <RafflesCard
                  card={card}
                  key={card.id}
                  status={status}
                  user={user}
                  onEdit={setItemToEdit}
                  toggleModal={() => setCreateNewRaffleModal(true)}
                  showControl
                />
              )))
          )}
        </div>
      </div>

      <div className="admin-raffles-pagination mb-5">
        <Pagination
          rowCount={Math.ceil(total / perPage) || 1}
          currentPage={page}
          onChangePage={onChangePage}
          rowsPerPage={1}
        />
      </div>

      <CreateNewRaffleModal
        onApply={onApplyCreateNewRaffleModal}
        onClose={closeAddPointCardModal}
        isOpen={showCreateNewRaffleModal}
        item={itemToEdit || undefined}
      />
    </section>
  )
}

export default AdminCurrentRaffles

import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Spinner } from 'reactstrap'

import useToast from '@hooks/useToast'

import Pagination from 'components/Pagination'
import SearchInput from 'components/SearchInput/SearchInput'

import {
  AdminLootBoxCard,
  ConfirmDeleteModal,
  useAdminLootBoxesFull
} from '@modules/adminLootBox'
import { LootBox } from '@modules/lootBox'
import { useNavigate } from 'react-router-dom'
import axios from 'network'

const AdminLootBoxesList = () => {
  const toast = useToast()
  const navigate = useNavigate()
  const { t } = useTranslation()

  const {
    list,
    error,
    refresh,
    isValidating,
    loading,
    page,
    onChangePage,
    perPage,
    onChangePerPage,
    tag,
    setTag,
    searchText,
    setSearchText,
    total
  } = useAdminLootBoxesFull()

  const [itemToDelete, setItemToDelete] = useState<LootBox | null>(null)

  const deleteAction = async () => {
    await axios.delete(`/loot-box/${itemToDelete?.id}`, {
      withCredentials: true
    })
  }

  const onDeleted = () => {
    setItemToDelete(null)
    refresh()
  }

  //   const sortByOptions: Option[] = [
  //     { label: 'default', value: POINTS_CARD_ORDER_BY.DEFAULT },
  //     { label: POINTS_CARD_ORDER_BY.PRICE, value: POINTS_CARD_ORDER_BY.PRICE },
  //     { label: 'quantity', value: POINTS_CARD_ORDER_BY.QUANTITY }
  //   ]

  const handleScrollToTop = () => {
    window.location.href = '#lootBoxesContainer'
  }

  return (
    <div className='shared-container w-100 d-flex flex-column align-items-center justify-content-start'>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <div className='loot-boxes-list w-100 d-flex flex-wrap admin-loot-boxes-list-padding'>
            <div
              className='loot-boxes-list-header justify-content-between'
              id='lootBoxesContainer'
            >
              <div className='loot-boxes-input-admin'>
                <SearchInput
                  onChange={(text) =>
                    setSearchText(text.length > 0 ? text : undefined)
                  }
                  value={searchText ?? ''}
                />
              </div>
              <button
                onClick={() => navigate('/admin/loot-boxes/new')}
                className='admin-loot-boxes-btn shared-btn-primary'
              >
                <img
                  src='/assets/icons/plus.svg'
                  alt='plus'
                  className='admin-loot-boxes-icon-add'
                />

                {t('admin-loot-boxes.btn.addItem')}
              </button>
            </div>
          </div>

          <div className='loot-boxes-list-wrapper-admin admin-loot-boxes-list-padding'>
            {list?.map((card: LootBox) => (
              <AdminLootBoxCard
                card={card}
                key={card.id}
                onDelete={setItemToDelete}
                onEdit={() => navigate(`/admin/loot-boxes/${card.id}`)}
              />
            ))}
          </div>

          <div className='loot-boxes-footer'>
            <Pagination
              rowCount={Math.ceil(total / perPage)}
              currentPage={page}
              onChangePage={onChangePage}
              rowsPerPage={1}
              scrollToTop={handleScrollToTop}
            />
          </div>
        </>
      )}
      <ConfirmDeleteModal
        isOpen={!!itemToDelete}
        action={deleteAction}
        onClose={() => setItemToDelete(null)}
        onApply={onDeleted}
      />
    </div>
  )
}

export default AdminLootBoxesList

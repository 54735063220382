import React, { FC, useState } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"
import { mutate } from "swr"
import classNames from "classnames"

import { Button, Modal, ModalBody, ModalHeader, Spinner } from "reactstrap"

import { ReactComponent as StarIcon } from "@src/assets/icons/star.svg"

import UserAvatar from "components/UserAvatar"

import { useUserById } from "@modules/adminTransactions"

import { Comment, CommentStatus, updateCommentStatusRequest } from "@modules/adminSlotsReviews"

type ReviewCommentProps = {
  comment?: Comment
  isOpen: boolean
  onClose: () => void
  onApply: () => void
}

const ReviewComment: FC<ReviewCommentProps> = ({ onClose, comment, isOpen, onApply }) => {
  const { t } = useTranslation()

  const { user: userInfo } = useUserById(comment?.userId!)

  const [loading, setLoading] = useState<boolean>(false)

  const handleRejectComment = async () => {
    setLoading(true)

    await updateCommentStatusRequest(comment?.id!, CommentStatus.REJECTED)

    await mutate("/slots-reviews/comments")

    setLoading(false)

    onApply()
  }
  const handleApproveComment = async () => {
    setLoading(true)

    await updateCommentStatusRequest(comment?.id!, CommentStatus.APPROVED)

    await mutate("/slots-reviews/comments")

    setLoading(false)

    onApply()
  }
  const ApplyButton = () => (
    <Button
      cssModule={{ btn: "profile-modal-ban-btn" }}
      onClick={handleApproveComment}
      disabled={loading}
      className="flex-cell admin-transactions-modal-cta-approve"
    >
      {t("admin-transactions.actions.approve")}
    </Button>
  )
  const RejectButton = () => (
    <Button
      cssModule={{ btn: "profile-modal-ban-btn" }}
      onClick={handleRejectComment}
      disabled={loading}
      className="flex-cell admin-transactions-modal-cta-reject"
    >
      {t("admin-transactions.actions.reject")}
    </Button>
  )

  const getCtaButtons = () => {
    switch (comment?.status) {
      case CommentStatus.PENDING:
        return (
          <div className="admin-transactions-modal-cta-wrapper">
            <RejectButton />

            <ApplyButton />
          </div>
        )
      case CommentStatus.APPROVED:
        return <RejectButton />
      case CommentStatus.REJECTED:
        return <ApplyButton />
    }
  }


  const closeBtn = (
    <button className="profile-modal-close" onClick={onClose} type="button">
      <img src="/assets/icons/close.svg" alt="close" />
    </button>
  )

  if (!userInfo || !comment) return (
    <Modal
      isOpen={isOpen}
      toggle={onClose}
      contentClassName="profile-modal"
      className="profile-modal-container"
      backdrop="static"
      centered
    >
      <div className="w-100 d-flex align-items-center justify-content-center">
        <Spinner className="text-primary" />
      </div>
    </Modal>
  )

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={onClose}
        contentClassName="profile-modal"
        className="profile-modal-container"
        backdrop="static"
        centered
      >
        <ModalHeader
          close={closeBtn}
          cssModule={{ "modal-header": "profile-modal-header" }}
        >
          {t("adminCommentsReview.modal.title")}
        </ModalHeader>

        <ModalBody className="p-0">
          <div className="profile-modal-wrapper mt-4">
            <div className="profile-modal-user">
              <UserAvatar userDisplayName={userInfo.displayName} userImageUrl={userInfo.profileImageUrl} />

              <div className="d-flex flex-column admin-transactions-modal-content">
                <div className="profile-modal-user-name-container align-items-center">
                  <p className="admin-transactions-modal-content-name shared-text m-0">{userInfo.displayName}</p>

                  <span className="d-flex align-items-center justify-content-between shared-text faded-text ms-auto">
                    Status:
                    <span className={classNames("profile-modal-user-status shared-text", {
                      "profile-modal-user-status-pending": comment?.status === CommentStatus.PENDING,
                      "profile-modal-user-status-approved": comment?.status === CommentStatus.APPROVED,
                      "profile-modal-user-status-rejected": comment?.status === CommentStatus.REJECTED
                    })}>
                      {comment?.status}
                    </span>
                  </span>
                </div>

                <div className="profile-modal-user-info">
                  <p>{t("profile.joined")}: {format(new Date(userInfo.createdAt), "MM.dd.yyyy")}</p>

                  <p>{t("profile.email")}: {userInfo.email}</p>
                </div>

                <span className="profile-modal-user-balance text-nowrap width-fit-content">
                  <img src="/assets/icons/Coin.svg" alt="" />

                  {t("profile.points")}: {userInfo.pointsBalance || 0}
                </span>
              </div>
            </div>

            <div className="w-100 d-flex flex-column align-items-start justify-content-start">
              <div className="d-flex flex-row align-items-center justify-content-start w-100">
                <p className="text-uppercase shared-text text-secondary-75 m-0">{t("adminCommentsReview.modal.rating")}</p>

                <div className="slots-reviews-slot-info-rating-stars m-l-4">
                  {[...Array(5)].map((star, index) => {
                    return (
                      <div className="slots-reviews-slot-info-rating-stars-icon" key={`${index}${comment.id}`}>
                        <StarIcon
                          className={classNames("", index <= comment?.rate - 1 ? "text-yellow" : "text-secondary-25")} />
                      </div>
                    )
                  })}
                </div>
              </div>

              <div className="m-t-4 w-100 admin-comment-review-modal-comment">
                {comment.comment}
              </div>
            </div>
            {/*<div className="admin-transactions-modal-item d-flex flex-row align-items-center justify-content-start">
              <div className="admin-transactions-modal-item-image-wrapper">
                <img
                  src={comment?.pointsShopItem.imageUrl}
                  alt="item"
                  className="admin-transactions-modal-item-image"
                />
              </div>

              <div
                className="admin-transactions-modal-item-content d-flex flex-column align-items-start justify-content-start"
              >
                <h6 className="admin-transactions-modal-item-title m-0">{comment?.pointsShopItem.title}</h6>

                <p className="admin-transactions-modal-item-description text-secondary-75 shared-text m-0">
                  {comment?.pointsShopItem.description}
                </p>

                <div
                  className="admin-transactions-modal-item-price w-100 d-flex flex-row align-items-center justify-content-start"
                >
                  <span className="text-secondary-75 shared-text">{t("admin-transactions.modal.price")}:</span>

                  <div className="admin-transactions-modal-item-price-cell">
                    <img src="/assets/icons/coin-alternative.svg" alt="coin" />

                    {addCommasForBigNumbers(comment?.pointsShopItem.price)}
                  </div>
                </div>
              </div>
            </div>*/}

            {getCtaButtons()}
          </div>
        </ModalBody>
      </Modal>
    </div>
  )
}

export default ReviewComment

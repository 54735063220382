import axios from "network"
import { SearchPayload } from "@modules/slotsReviews/dto"
import { CommentStatus, CommentTablePayload } from "@modules/adminSlotsReviews/dto"
import { PaginationPayload } from "@hooks/usePlainSWR"

export const fetchTopSlots = async (payload: SearchPayload) => {
  const { search } = payload

  const params: any = {
    search
  }
  const res = await axios.get("/slots-reviews/top", { params, withCredentials: true })

  return { data: { items: res.data } }
}

export const fetchProviders = async (payload: SearchPayload) => {
  const { search } = payload

  const params: any = {
    search
  }

  const res = await axios.get("/slots-reviews/featured-providers", { params, withCredentials: true })

  return { data: { items: res.data } }
}

export const fetchCurrentProvider = async (id: string, payload: SearchPayload) => {

  const res = await axios.get(`/slots-reviews/providers/${id}`, {
    params: { id },
    withCredentials: true
  })

  return { data: { item: res.data } }
}

export const fetchSlotById = async (id: string) => {
  const res = await axios.get(`/slots-reviews/slot/${id}`, {
    params: { id },
    withCredentials: true
  })

  return { data: { item: res.data } }
}

export const fetchAllSlots = async (payload: SearchPayload) => {
  const { search } = payload

  const params: any = {
    search
  }
  const res = await axios.get("/slots-reviews/all-slots", { params, withCredentials: true })

  return { data: { items: res.data } }
}
export const fetchCommentsForReview = async (payload: CommentTablePayload & PaginationPayload) => {
  const params: any = {
    page: payload.page,
    limit: payload.perPage
  }

  if (payload.status !== CommentStatus.ALL) {
    params.status = payload.status
  }

  const res = await axios.get("casino/slot-comments", {
    params,
    withCredentials: true
  })

  return { data: res.data }
}

export const updateCommentStatusRequest = (id: string, status: CommentStatus) => axios.post(`casino/slot/comment/${id}/review`, { status }, { withCredentials: true })

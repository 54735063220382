import axios from 'network'
import MockAdapter from 'axios-mock-adapter'

const mock = new MockAdapter(axios)

export const throttling = async (ms: number = 1000) => {
  await new Promise<void>((res) =>
    setTimeout(() => {
      res()
    }, ms)
  )
}

export default mock

import { useEffect, useState } from 'react'
import { usePlainSWRNoMount } from '@hooks/usePlainSWR'
import { fetchAdminLootBoxesFull } from './requests'
import { LootBox } from '@modules/lootBox'

export const useAdminLootBoxesFull = () => {
  const [searchText, setSearchText] = useState<string | undefined>(undefined)
  const [tag, setTag] = useState<string | undefined>(undefined)
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(8)

  const {
    data,
    total,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount('/loot-box/full', () =>
    fetchAdminLootBoxesFull({
      take: perPage,
      skip: (page - 1) * perPage,
      text: searchText,
      tag
    })
  )

  useEffect(() => {
    refresh()
  }, [page, perPage, searchText, tag])

  return {
    list: (data || []) as LootBox[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    tag,
    setTag,

    searchText,
    setSearchText,

    total
  }
}

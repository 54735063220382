import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { Control, Controller } from "react-hook-form"

import RatingSelector from "components/RatingSelector"
import ImageUploader from "components/ImageUploader"
import SelectInput from "components/SelectInput"
import DatePicker from "components/DatePicker"
import ModalInput from "components/ModalInput"

import { SlotReviewForm } from "@modules/adminSlotsReviews"
import { Option } from "@utils/Select"
import { InputType } from "reactstrap/types/lib/Input"

type SlotReviewBaseFormProps = {
  control: Control<SlotReviewForm, any>
  options: Option[]
}

const SlotReviewBaseForm: FC<SlotReviewBaseFormProps> = ({
  control,
  options
}) => {
  const { t } = useTranslation()

  const descriptions: { key: keyof SlotReviewForm, label: string, placeholder: string, type: InputType }[] = [
    {
      key: "rtp",
      label: t("adminSlotsReviews.addSlotReview.rtp"),
      placeholder: t("adminSlotsReviews.addSlotReview.rtpPlaceholder"),
      type: "text"
    },
    {
      key: "volatility",
      label: t("adminSlotsReviews.addSlotReview.volatility"),
      placeholder: t("adminSlotsReviews.addSlotReview.volatilityPlaceholder"),
      type: "text"
    },
    {
      key: "maxPotential",
      label: t("adminSlotsReviews.addSlotReview.maxPotential"),
      placeholder: t("adminSlotsReviews.addSlotReview.maxPotentialPlaceholder"),
      type: "text"
    },
    {
      key: "mixMaxBet",
      label: t("adminSlotsReviews.addSlotReview.betSize"),
      placeholder: t("adminSlotsReviews.addSlotReview.betSizePlaceholder"),
      type: "text"
    },
    {
      key: "paylines",
      label: t("adminSlotsReviews.addSlotReview.payLines"),
      placeholder: t("adminSlotsReviews.addSlotReview.payLinesPlaceholder"),
      type: "text"
    },
    {
      key: "reels",
      label: t("adminSlotsReviews.addSlotReview.reels"),
      placeholder: t("adminSlotsReviews.addSlotReview.reelsPlaceholder"),
      type: "text"
    }
  ]

  return (
    <>
      <div className="admin-slots-reviews-add-slot-form-content">
        <div className="admin-slots-reviews-add-slot-form-content-block">
          <h2 className="shared-text shared-text--small text-headline-white font-weight-600 text-uppercase">
            {t("adminSlotsReviews.addSlotReview.mainInfo")}
          </h2>

          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <ModalInput
                value={field.value}
                onChange={field.onChange}
                label={t("adminSlotsReviews.addSlotReview.name")}
                className="admin-slots-reviews-add-slot-form-space"
                placeholder={t("adminSlotsReviews.addSlotReview.namePlaceholder")}
              />
            )}
          />

          <Controller
            control={control}
            name="providerId"
            render={({ field }) => (
              <SelectInput
                onChange={(s) => field.onChange(s)}
                value={field.value}
                className="admin-slots-reviews-add-slot-form-space"
                title={t("admin-raffles.createNewRaffleModal.slotProvider")}
                options={options}
              />
            )}
          />

          <p className="admin-bonuses-modal-text admin-bonuses-modal-space">
            {t("adminSlotsReviews.addSlotReview.logo")}
          </p>

          <Controller
            control={control}
            name="imageUrl"
            render={({ field }) => (
              <ImageUploader
                className="admin-slots-reviews-add-slot-form-space"
                url={field.value}
                onChange={field.onChange}
              />
            )}
          />

          <p className="admin-bonuses-modal-text admin-bonuses-modal-space">
            {t("adminSlotsReviews.addSlotReview.rating")}
          </p>

          <Controller
            control={control}
            name="adminRate"
            render={({ field }) => (
              <RatingSelector
                rating={field.value}
                setRating={field.onChange}
                wrapperClasName="m-t-4"
                starSize={24}
              />
            )}
          />
        </div>

        <div className="admin-slots-reviews-add-slot-form-content-block">
          <h2 className="shared-text shared-text--small text-headline-white font-weight-600 text-uppercase">
            {t("adminSlotsReviews.addSlotReview.slotsInfo")}
          </h2>

          {descriptions.map(({ key, label, placeholder }) => (
            <Controller
              control={control}
              name={key}
              key={key}
              render={({ field }) => (
                <ModalInput
                  value={field.value}
                  onChange={field.onChange}
                  label={label}
                  className="admin-slots-reviews-add-slot-form-space"
                  placeholder={placeholder}
                />
              )}
            />
          ))}

          <Controller
            control={control}
            name="releaseDate"
            render={({ field }) => (
              <DatePicker
                className="admin-slots-reviews-add-slot-form-space"
                placeholder={t("adminSlotsReviews.addSlotReview.releasedPlaceholder")}
                label={t("adminSlotsReviews.addSlotReview.released")}
                callback={(date) => {
                  field.onChange(date)
                }}
                value={new Date(field.value)}
              />
            )}
          />
        </div>

        <div className="admin-slots-reviews-add-slot-form-content-block">
          <h2 className="shared-text shared-text--small text-headline-white font-weight-600 text-uppercase">
            {t("adminSlotsReviews.addSlotReview.personalBest")}
          </h2>

          <Controller
            control={control}
            name="personalWin"
            render={({ field }) => (
              <ModalInput
                value={field.value}
                onChange={field.onChange}
                label={t("adminSlotsReviews.addSlotReview.win")}
                className="admin-slots-reviews-add-slot-form-space"
                placeholder={t("adminSlotsReviews.addSlotReview.winPlaceholder")}
              />
            )}
          />

          <Controller
            control={control}
            name="personalMulti"
            render={({ field }) => (
              <ModalInput
                value={field.value}
                onChange={field.onChange}
                label={t("adminSlotsReviews.addSlotReview.x")}
                className="admin-slots-reviews-add-slot-form-space"
                placeholder={t("adminSlotsReviews.addSlotReview.xPlaceholder")}
              />
            )}
          />
        </div>
      </div>
    </>
  )
}

export default SlotReviewBaseForm

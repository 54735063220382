import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useTranslation } from "react-i18next"

import axios from "network"

import { Spinner } from "reactstrap"

import { Option } from "@utils/Select"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import Pagination from "components/Pagination"
import PerPageSelector from "components/PerPageSelector"
import SearchInput from "components/SearchInput/SearchInput"

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  MouseSensor,
  PointerSensor,
  TouchSensor,
  useSensor,
  useSensors
} from "@dnd-kit/core"

import {
  arrayMove,
  rectSortingStrategy,
  SortableContext
} from "@dnd-kit/sortable"

import { AdminPointsStoreCardReorder, ConfirmDeleteModal, AddPointsCardModal } from "@modules/adminPointsStore"

import {
  ITEMS_ORDER,
  PointCard,
  POINTS_CARD_ORDER_BY,
  usePointCards
} from "@modules/pointsStore"

const AdminPointsStoreList = () => {
  const toast = useToast()
  const { t } = useTranslation()

  const {
    list,
    filter,
    onFiltered,
    total,
    page,
    perPage,
    onChangePage,
    onChangePerPage,
    loading,
    refresh,
    savePointCards
  } = usePointCards()

  const [selectedCard, setSelectedCard] = useState<PointCard>()
  const [allPointCards, setAllPointCards] = useState<PointCard[]>(list)
  const [showAddPointCardModal, setAddPointCardModal] = useState(false)
  const [itemToDelete, setItemToDelete] = useState<PointCard | null>(null)
  const [itemToEdit, setItemToEdit] = useState<PointCard | null>(null)

  useEffect(() => {
    if (!loading) {
      setAllPointCards(list)
    }
  }, [list])
  const handleDeleteItem = () => {
    setItemToDelete(null)
    refresh()
  }

  const sortByOptions: Option[] = [
    { label: "default", value: POINTS_CARD_ORDER_BY.DEFAULT },
    { label: POINTS_CARD_ORDER_BY.PRICE, value: POINTS_CARD_ORDER_BY.PRICE },
    { label: "quantity", value: POINTS_CARD_ORDER_BY.QUANTITY }
  ]

  const options: Option[] = [
    { label: "12", value: "12" },
    { label: "24", value: "24" },
    { label: "48", value: "48" },
    { label: "ALL", value: `${total}` }
  ]

  const handleScrollToTop = () => {
    window.location.href = "#pointsStoreContainer"
  }
  const onApplyAddPointCardModal = () => {
    setAddPointCardModal(false)
    if (showAddPointCardModal) {
      refresh()
    }
  }
  const closeAddPointCardModal = () => {
    setAddPointCardModal(false)
    setItemToEdit(null)
  }

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8
      }
    })
  )
  const restockItems = async () => await axios.post("/point-shop/restock-items", {}, { withCredentials: true })
    .then(() => {
      toast(t("admin-points-store.toastRestock"), TOAST_TYPE.SUCCESS)
    })
    .catch(() => {
      toast(t("admin-points-store.toastError"), TOAST_TYPE.ERROR)
    })

  const handlePointCardDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (active.id !== over?.id) {
      setAllPointCards((prev: PointCard[]) => {
        const oldIndex = prev.findIndex((item: PointCard) => item.id === active.id)
        const newIndex = prev.findIndex((item: PointCard) => item.id === over!.id)

        savePointCards(arrayMove(prev, oldIndex, newIndex))

        return arrayMove(prev, oldIndex, newIndex)
      })
    }
  }
  return (
    <div
      className="points-store-container-admin shared-container w-100 d-flex flex-column align-items-center justify-content-start"
    >
      {loading ? (<Spinner />) : (
        <>
          <div className="points-store-list w-100 d-flex flex-wrap admin-points-store-list-padding">
            <div className="points-store-list-header justify-content-between" id="pointsStoreContainer">
              <div className="points-store-input-admin">
                <SearchInput
                  onChange={(s) => onFiltered({ search: s })}
                  value={filter?.search}
                />
              </div>

              <div className="admin-points-store-select">
                <Select
                  options={sortByOptions}
                  theme={(theme) => ({
                    ...theme,
                    colors: {
                      ...theme.colors,
                      primary: "transparent",
                      primary25: "#E2F1FF1A"
                    }
                  })}
                  isSearchable={false}
                  className="custom-select-wrapper"
                  classNamePrefix="custom-select"
                  components={{ IndicatorSeparator: null }}
                  value={sortByOptions.find((o) => o.value === filter.orderBy.toString()) || null}
                  onChange={(o) => {
                    if (!o?.value) return
                    onFiltered(o.value === POINTS_CARD_ORDER_BY.DEFAULT ? {
                      orderBy: o.value as POINTS_CARD_ORDER_BY,
                      order: ITEMS_ORDER.ASC
                    } : {
                      orderBy: o.value as POINTS_CARD_ORDER_BY, order: ITEMS_ORDER.DESC
                    })

                    onChangePage(1)
                  }}
                />

                <button
                  onClick={restockItems}
                  className="admin-points-store-restock shared-text shared-text--small text-headline-white"
                >
                  <img src="/assets/icons/refresh.svg" alt="refresh" />

                  {t("admin-points-store.btn.restock")}
                </button>

                <button
                  onClick={() => setAddPointCardModal(true)}
                  className="admin-points-store-btn  shared-btn-primary"
                >
                  <img src="/assets/icons/plus.svg" alt="plus" className="admin-leaderboards-icon-add" />

                  {t("admin-points-store.btn.addItem")}
                </button>
              </div>
            </div>

            <div className="points-store-list-wrapper-admin">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handlePointCardDragEnd}
              >
                <SortableContext
                  items={allPointCards}
                  strategy={rectSortingStrategy}
                >
                  {allPointCards.map((card: PointCard) => (
                    <AdminPointsStoreCardReorder
                      card={card}
                      key={card.id}
                      onSelect={setSelectedCard}
                      onDeleteItem={setItemToDelete}
                      onEditItem={setItemToEdit}
                      toggleModal={() => setAddPointCardModal(true)}
                      isDisabled={filter.orderBy !== "order"}
                    />
                  ))}
                </SortableContext>
              </DndContext>
            </div>


          </div>

          <div className="points-store-footer">
            <PerPageSelector
              options={options}
              onChangePerPage={onChangePerPage}
              perPage={perPage}
              setPage={onChangePage}
            />

            <Pagination
              rowCount={Math.ceil(total / perPage)}
              currentPage={page}
              onChangePage={onChangePage}
              rowsPerPage={1}
              scrollToTop={handleScrollToTop}
            />
          </div>
        </>
      )}
      <ConfirmDeleteModal
        isOpen={!!itemToDelete}
        item={itemToDelete || undefined}
        onClose={() => setItemToDelete(null)}
        onApply={handleDeleteItem}
      />

      <AddPointsCardModal
        onApply={onApplyAddPointCardModal}
        onClose={closeAddPointCardModal}
        isOpen={showAddPointCardModal}
        item={itemToEdit || undefined}
      />
    </div>
  )
}

export default AdminPointsStoreList

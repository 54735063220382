import { useEffect, useState } from "react"

import { usePlainSWRNoMount } from "@hooks/usePlainSWR"

import { RAFFLES_ORDER } from "@modules/raffles"

import {
  Comment,
  CommentSortBy,
  CommentStatus,
  CommentTablePayload,
  fetchCommentsForReview
} from "@modules/adminSlotsReviews"

export const useComments = () => {
  const [filter, setFilter] = useState<CommentTablePayload>({
    sortBy: CommentSortBy.CREATED_AT,
    sortOrder: RAFFLES_ORDER.DESC,
    status: CommentStatus.ALL
  })

  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWRNoMount("/slots-reviews/comments", () => fetchCommentsForReview({ ...filter, page, perPage }))

  const commentList: Comment[] = (data?.items || [])

  useEffect(() => {
    refresh()
  }, [filter, page])

  const onFiltered = (newFilter: Partial<CommentTablePayload>) => {
    setFilter((prev) => ({ ...prev, ...newFilter }))
    setPage(1)
  }

  return {
    list: commentList,

    refresh,

    loading: !data && !error,

    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.totalCount || 0,

    onFiltered,
    filter
  }
}

import React, { FC } from "react"
import Pagination from "@src/components/Pagination"

import { useTranslation } from "react-i18next"
import { BonusHunt, BonusHuntStatBonus, BonusHuntTablePayload } from "@modules/bonusHunt"
import SearchInput from "@src/components/SearchInput/SearchInput"
import { useNavigate } from "react-router-dom"

type BonusHuntTableProps = {
  list?: BonusHunt[]
  bonuses?: BonusHuntStatBonus[]
  filter: BonusHuntTablePayload
  page: number
  total: number
  onChangePage: (n: number) => void
  onChangeFilter: (s: Partial<BonusHuntTablePayload>) => void
  isCurrent?: boolean
}

const BonusHuntTable: FC<BonusHuntTableProps> = ({
  page,
  total,
  onChangePage,
  list,
  filter,
  onChangeFilter,
  isCurrent,
  bonuses
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div className="bonus-hunt-table shared-container w-100 d-flex flex-column align-items-center">
      <div className="bonus-hunt-table-search">
        <span className="shared-text shared-text--small text-headline-white text-uppercase text-left w-100">
          {isCurrent ? t("bonus-hunt.tableTitleSingle") : t("bonus-hunt.tableTitle")}
        </span>

        <SearchInput
          onChange={(s) => onChangeFilter({ search: s })}
          value={filter?.search}
        />
      </div>

      <div className="bonus-hunt-table-container no-scrollbar">
        {isCurrent ? (
          <div className="bonus-hunt-table-header">
            <div className="bonus-hunt-table-cell bonus-hunt-table-cell--name position-relative border-none">
              <div className="bonus-hunt-table-cell--name-text">{t("bonus-hunt.tableHeader.name")}</div>
            </div>

            <div className="bonus-hunt-table-cell bonus-hunt-table-cell--provider border-none">
              {t("bonus-hunt.tableHeader.providerName")}
            </div>

            <div className="bonus-hunt-table-cell bonus-hunt-table-cell--balance border-none">
              {t("bonus-hunt.tableHeader.bestSize")}
            </div>

            <div className="bonus-hunt-table-cell border-none bonus-hunt-table-cell--multiplier-current">
              {t("bonus-hunt.tableHeader.multiplier")}
            </div>

            <div className="bonus-hunt-table-cell bonus-hunt-table-cell--payout border-none">
              {t("bonus-hunt.tableHeader.payout")}
            </div>
          </div>
        ) : (
          <div className="bonus-hunt-table-header">
            <div className="bonus-hunt-table-cell bonus-hunt-table-cell--name position-relative border-none">
              <div className="bonus-hunt-table-cell--name-text">{t("bonus-hunt.tableHeader.name")}</div>
            </div>

            <div className="bonus-hunt-table-cell bonus-hunt-table-cell--balance border-none">
              {t("bonus-hunt.tableHeader.startingBalance")}
            </div>

            <div className="bonus-hunt-table-cell border-none bonus-hunt-table-cell--multiplier">
              {t("bonus-hunt.tableHeader.biggestMultiplier")}
            </div>

            <div className="bonus-hunt-table-cell bonus-hunt-table-cell--payout border-none">
              {t("bonus-hunt.tableHeader.biggestPayout")}
            </div>
          </div>
        )}

        {isCurrent ? (
          <div className="bonus-hunt-table-wrapper">
            {bonuses?.map((bonusHunt) => {

              return (
                <div className="bonus-hunt-table-row" key={bonusHunt.id}>
                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--name d-flex position-relative">
                    <div className="bonus-hunt-table-cell-place">{bonusHunt.id}</div>

                    <div
                      className="bonus-hunt-table-cell--name-text cursor-pointer"
                      onClick={() => navigate(`/bonus-hunt/${bonusHunt.id}`)}
                    >
                      {bonusHunt.name}
                    </div>
                  </div>

                  <div className="bonus-hunt-table-cell text-center bonus-hunt-table-cell--provider">
                    {bonusHunt.provider_name}
                  </div>

                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--balance">
                    {bonusHunt.bet_size}
                  </div>

                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--multiplier-current">
                    {bonusHunt.multiplier}
                  </div>

                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--payout">
                    {bonusHunt.payout}
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className="bonus-hunt-table-wrapper">
            {list?.map((bonusHunt) => {

              return (
                <div className="bonus-hunt-table-row" key={bonusHunt.id}>
                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--name d-flex position-relative">
                    <div className="bonus-hunt-table-cell-place">{bonusHunt.id}</div>

                    <div
                      className="bonus-hunt-table-cell--name-text cursor-pointer"
                      onClick={() => navigate(`/bonus-hunt/${bonusHunt.id}`)}
                    >
                      {bonusHunt.name}
                    </div>
                  </div>

                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--balance">
                    {bonusHunt.info_start_cost}
                  </div>

                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--multiplier">
                    {bonusHunt.info_highest_multi}
                  </div>

                  <div className="bonus-hunt-table-cell bonus-hunt-table-cell--payout">
                    {bonusHunt.info_highest_payout}
                  </div>
                </div>
              )
            })}
          </div>
        )}

      </div>


      <div className="admin-current-leaderboard-table-pagination">
        <Pagination
          rowCount={total}
          currentPage={page}
          onChangePage={onChangePage}
          rowsPerPage={1}
        />
      </div>
    </div>
  )
}

export default BonusHuntTable

import { lazy } from "react"

import "./views/admin-users.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export const AdminUsers = lazy(() => import("./views/AdminUsers"))

export * from "./dto"

export * from "./useAdminUsers"
export * from "./useDashboardStats"

export * from "./requests"

export { default as SearchInput } from "./components/SearchInput"
export { default as BanUserModal } from "./components/BanUserModal"

export const locales = {
  en,
  ru
}

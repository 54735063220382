import mock, { throttling } from "../mock"

mock.onGet("/points-store/statistic").reply(async () => {
  return [
    200,
    {
      data: {
        popularItem: {
          id: "1",
          title: "Nomad Knife CS:GO Skin",
          description: "Free HypeDrop Box priced at $450.00",
          price: 2000,
          quantity: 100,
          quantityRemaining: 0,
          imageUrl: "/assets/images/knife-img.png"
        },
        soldCount: 14320,
        topSpender: {
          id: "123041",
          displayName: "Southside",
          balance: 53210,
          pointsSpent: 53210,
          isAdmin: true,
          avatar: "/assets/images/mock-avatar.png",
          joined: new Date(),
          email: "808mafia@gmail.com",
          btcAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          ethAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          tetherAddress: "0x71C7656EC7ab88b098defB751B7401B5f6d8976F",
          isBanned: false
        },
        pointsSpend: 123456,
        totalPurchases: {
          count: 51321,
          usersCount: 2413
        }
      }
    }
  ]
})
mock.onGet("/point-cards").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: "1",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 0,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "2",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 15,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "3",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 0,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "4",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 55,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "5",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 50,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "6",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 115,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "7",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 50,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "8",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 50,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "9",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 50,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "10",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 5,
        imgUrl: "/assets/images/knife-img.png"
      },
      {
        id: "11",
        title: "title",
        name: "Free HypeDrop Box priced at $450.00",
        caption: "18+ | Gamble Responsibly | BeGambleAware. Most people gamble for fun and enjoyment. Do not think of gambling as a way to make money. ",
        price: 2000,
        quantity: 100,
        quantityRemaining: 5,
        imgUrl: "/assets/images/knife-img.png"
      }
    ]
  ]
})

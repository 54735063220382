import axios from "network"

import { usePlainSWR, usePlainSWRNoMount } from "@hooks/usePlainSWR"

export type User = {
  id: string
  displayName: string
  pointsBalance: number
  pointsSpent: number
  rafflesWon: number
  leaderboardWins: number
  isAdmin: boolean
  profileImageUrl: string
  createdAt: Date
  updatedAt: Date
  email: string
  isBanned: boolean
  banReason: string
  discordId: string
  discordUsername: string
  hasVerifiedRoleOnDiscordGuild: boolean
  kickUsername: string
  stakeUsername: string
} & UserWallets

export type UserWallets = {
  btcAddress?: string | null,
  ethAddress?: string | null,
  usdtErc20Address?: string | null
}

export const defaultUserWallets: UserWallets = {
  btcAddress: '',
  ethAddress: '',
  usdtErc20Address: ''
}

export const logout = () => axios.get("/auth/logout", { withCredentials: true })

const fetchUser = () => axios.get("/auth/me?withStats=true&withCrypto=true", { withCredentials: true })
const fetchUserById = (id: string) => axios.get(`/users/${id}?withStats=true&withCrypto=true`, { withCredentials: true })

export const useCurrentUser = (shouldFetch = true) => {
  const { data, mutate, error } = usePlainSWR("/user", async () => (shouldFetch ? fetchUser() : null))

  const loading = !data && !error

  return {
    loading,
    user: data as User,
    refresh: mutate,
    error,
    isLoginFailed: !!error && error?.response?.status === 401
  }
}

export const useUser = (id?: string) => {
  const { data, mutate, error } = usePlainSWRNoMount(`/user/${id}`, async () => {
    return (id ? fetchUserById(id) : null)
  })

  const loading = !data && !error

  return {
    loading,
    user: data as User,
    refresh: mutate,
    error,
    isLoginFailed: !!error && error?.response?.status === 401
  }
}


import {
  LootBox,
  LootBoxItem,
  PaginationQuery,
  PublicLootBoxItem,
  UserLootBoxItemStatus
} from '../lootBox'
import { User } from '@hooks/useUser'

export enum TABLE_ORDER {
  ASC = 'asc',
  DESC = 'desc'
}

export enum AdminLootBoxTransactionSort {
  DATE = 'createdAt',
  UPDATE = 'updatedAt'
}

export type AdminLootBoxTransactionsFilterPayload = {
  sortBy: AdminLootBoxTransactionSort
  sortOrder: TABLE_ORDER
  status?: UserLootBoxItemStatus
  search?: string
}

export type PublicLootBoxTransactionsFilterPayload = {
  status?: UserLootBoxItemStatus
}

export type GetUserLootBoxItemQuery = PaginationQuery & AdminLootBoxTransactionsFilterPayload

export type GetPublicUserLootBoxItemQuery = PaginationQuery & PublicLootBoxTransactionsFilterPayload

export type PayUserLootBoxItemDTO = {
  userLootBoxItemId: number
  rejectionReason?: string
  status: UserLootBoxItemStatus
}

export type UserLootBoxItem = {
  id: number
  createdAt: Date
  updatedAt: Date
  usersId: string
  lootBox: LootBox
  lootBoxItem: LootBoxItem
  status: UserLootBoxItemStatus
  rejectionReason: string
  purchasePrice: number
  user: User
}

export type PublicUserLootBoxItem = Pick<
  UserLootBoxItem,
  'id' | 'status' | 'rejectionReason' | 'createdAt' | 'updatedAt'
> & {
  lootBoxItem: PublicLootBoxItem
}

import { FC } from "react"
import classnames from "classnames"

import { ChevronLeft, ChevronRight } from "react-feather"

import { usePagination, DOTS } from "./usePagination"

import "./pagination.scss"

type PaginationProps = {
  rowsPerPage: number
  rowCount: number
  siblingCount?: number
  onChangePage: (n: number) => void
  currentPage: number
  className?: string
  wrapperClassName?: string
  scrollToTop?: () => void
}

const Pagination: FC<PaginationProps> = ({
  onChangePage,
  rowCount,
  siblingCount = 1,
  currentPage,
  rowsPerPage,
  className,
  wrapperClassName,
  scrollToTop
}) => {

  const paginationRange =
    usePagination({
      currentPage,
      totalCount: rowCount,
      siblingCount,
      pageSize: rowsPerPage
    }) || []

  const onNext = () => {
    onChangePage(currentPage + 1)
  }

  const onPrevious = () => {
    onChangePage(currentPage - 1)
  }

  const lastPage = paginationRange[paginationRange.length - 1]

  return (
    <div className={classnames("d-flex justify-content-start align-items-center p-0", wrapperClassName)}>
      <ul className={classnames("pagination-container m-0", className)}>
        <li
          className={classnames("pagination-item pagination-arrow", {
            disabled: currentPage === 1
          })}
          onClick={() => {
            onPrevious()
            if (scrollToTop) {
              scrollToTop()
            }
          }}
        >
          <ChevronLeft className="pagination-arrow-icon" size={16} />
        </li>

        <div className="pagination-content position-relative">
          <div className="pagination-bg" />

          {paginationRange.map((pageNumber, index) => {
            if (pageNumber === DOTS) {
              return (
                <li key={`${pageNumber}-${index}`} className="pagination-item dots">
                  &#8230;
                </li>
              )
            }

            return (
              <li
                className={classnames("pagination-item", {
                  selected: pageNumber === currentPage
                })}
                key={`${pageNumber}-${index}`}
                onClick={() => {
                  onChangePage(pageNumber as number)
                  if (scrollToTop) {
                    scrollToTop()
                  }
                }}
              >
                {pageNumber}
              </li>
            )
          })}
        </div>

        <li
          className={classnames("pagination-item pagination-arrow", {
            disabled: currentPage === lastPage
          })}
          onClick={() => {
            onNext()
            if (scrollToTop) {
              scrollToTop()
            }
          }}
        >
          <ChevronRight size={16} className="pagination-arrow-icon" />
        </li>
      </ul>
    </div>
  )
}

export default Pagination

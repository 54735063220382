import * as yup from "yup"

export type FormPrize = {
  amount: number,
  prizeNumber: number
}

export type LeaderboardForm = {
  month: string,
  year: string,
  provider: string,
  maxPublicUsersCount: number,
  randomPrizeThreshold?: number,
  tieredPrizes: FormPrize[],
  randomPrizes: FormPrize[]
}

export const setupLeaderboardFormSchema = () => yup.object().shape({
  month: yup.string().required(),
  year: yup.string().required(),
  provider: yup.string().required(),
  maxPublicUsersCount: yup.number().required(),
  randomPrizeThreshold: yup.number(),
  tieredPrizes: yup.array(yup.object({
    prizeNumber: yup.number().required(),
    amount: yup.number().required()
  })).default([]),
  randomPrizes: yup.array(yup.object({
    prizeNumber: yup.number().required(),
    amount: yup.number().required()
  })).default([])
})

import { lazy } from "react"
import { RouteEntity } from "@src/router/routes"

import "./views/points-store.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

const PointsStore = lazy(() => import("./views/PointsStore"))

export * from "./dto"

export * from "./usePointsStoreStatistic"
export * from "./usePointCards"

export * from "./requests"

export { default as PointsStoreCard } from "./components/PointsStoreCard"
export { default as PointsStoreList } from "./components/PointsStoreList"
export { default as AgreementSection } from "./components/AgreementSection"
export { default as PointsStoreHeader } from "./components/PointsStoreHeader"
export { default as PointsStoreStatistic } from "./components/PointsStoreStatistic"
export { default as PointsStoreStatisticCard } from "./components/PointsStoreStatisticCard"
export { default as ConfirmPurchaseModal } from "./components/ConfirmPurchaseModal"


export const router: RouteEntity[] = [
  {
    path: "/points-store",
    element: <PointsStore />
  }
]

export const locales = {
  en,
  ru
}

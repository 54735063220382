import mock, { throttling } from "../mock"

mock.onGet("/bonus-hunt/top-statistic").reply(async () => {
  await throttling()

  return [
    200,
    {
      totalBonuses: 54,
      startCost: 54,
      currentBonus: 1,
      huntPayout: 290000,
      date: "2023-02-20T19:00:52Z",
      topWinsCount: 15
    }
  ]
})

mock.onGet("/bonus-hunt/statistic").reply(async () => {
  await throttling()

  return [
    200,
    {
      averageBet: 39.50,
      averagePayout: 5375.50,
      huntStarted: "2023-02-20T12:33:52Z",
      averageMulti: 183.30,
      averageRequired: "-"
    }
  ]
})

mock.onGet("/bonus-hunt").reply(async () => {
  await throttling()

  return [
    200,
    [
      {
        id: "1111",
        name: "Dog House Dice",
        startingBalance: 2300323.11,
        biggestMultiplier: 3500,
        biggestPayout: 5304.39
      },
      {
        id: "2222",
        name: "Fear The Dark",
        startingBalance: 2300323.11,
        biggestMultiplier: 3500,
        biggestPayout: 5304.39
      },
      {
        id: "3333",
        name: "RIP City",
        startingBalance: 2300323.11,
        biggestMultiplier: 3500,
        biggestPayout: 5304.39
      },
      {
        id: "4444",
        name: 5375.50,
        startingBalance: 2300323.11,
        biggestMultiplier: 3500,
        biggestPayout: 5304.39
      },
      {
        id: "5555",
        name: 5375.50,
        startingBalance: 2300323.11,
        biggestMultiplier: 3500,
        biggestPayout: 5304.39
      },
      {
        id: "6666",
        name: 5375.50,
        startingBalance: 2300323.11,
        biggestMultiplier: 3500,
        biggestPayout: 5304.39
      },
      {
        id: "7777",
        name: 5375.50,
        startingBalance: 1234567,
        biggestMultiplier: 3500,
        biggestPayout: 3500
      },
      {
        id: "8888",
        name: 5375.50,
        startingBalance: 1234567,
        biggestMultiplier: 3500,
        biggestPayout: 3500
      },
      {
        id: "9999",
        name: 5375.50,
        startingBalance: 1234567,
        biggestMultiplier: 3500,
        biggestPayout: 3500
      },
      {
        id: "10101010",
        name: 5375.50,
        startingBalance: 1234567,
        biggestMultiplier: 3500,
        biggestPayout: 3500
      },
      {
        id: "11111111",
        name: 5375.50,
        startingBalance: 1234567,
        biggestMultiplier: 3500,
        biggestPayout: 3500
      },
      {
        id: "12121212",
        name: 5375.50,
        startingBalance: 1234567,
        biggestMultiplier: 3500,
        biggestPayout: 3500
      }
    ]
  ]
})
import React, { FC, useState } from "react"
import classNames from "classnames"

import { Button, Collapse as ReactstrapCollapse } from "reactstrap"

import { RaffleCardEntity, RaffleWinner } from "@modules/raffles"

type WinnersCollapseCellProps = {
  raffle: RaffleCardEntity
}

const WinnersCollapseCell: FC<WinnersCollapseCellProps> = ({ raffle }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggle = () => setIsOpen(!isOpen)

  const entries = (raffle.winners || []) as never as { user: RaffleWinner, entryNumber: number }[]

  return (
    <div className="w-100 d-flex justify-content-between align-items-start admin-raffles-ended-table-collapse">
      <div className="admin-raffles-ended-table-collapse-content">
        <p className="admin-raffles-ended-table-collapse-trigger">{entries[0]?.user?.displayName}</p>

        {entries.length > 1 && (
          <ReactstrapCollapse isOpen={isOpen} className="custom-collapse-content p-0">
            {entries.slice(1).map((winner) => (
              <p key={winner.entryNumber}>{winner?.user?.displayName}</p>
            ))}
          </ReactstrapCollapse>
        )}

      </div>

      {entries.length > 1 && (
        <Button
          onClick={toggle}
          className={classNames("custom-collapse-trigger admin-raffles-ended-table-collapse-trigger", {
            active: isOpen
          })}
          color="transparent"
          cssModule={{ btn: "custom-collapse-trigger" }}
        >
          <img src="/assets/icons/dropdown-arrow.svg" alt="icon" />
        </Button>
      )}
    </div>
  )
}

export default WinnersCollapseCell

import React, { FC, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { Modal, Spinner } from "reactstrap"

import axios from "network"

import ModalInput from "components/ModalInput"
import ImageUploader from "components/ImageUploader"

import {
  PointsStoreCardForm, setupPointsStoreCardFormSchema
} from "@modules/adminBonuses"
import { PointCard } from "@modules/pointsStore"

type AddPointsCardModalProps = {
  item?: PointCard
  isOpen: boolean
  onClose: () => void
  onApply: () => void
}

const AddPointsCardModal: FC<AddPointsCardModalProps> = ({ item, onApply, onClose, isOpen }) => {
  const { t } = useTranslation()

  const defaultPointCard = {
    ...item
  }

  const [loading, setLoading] = useState<boolean>(false)

  const {
    control,
    handleSubmit,
    reset,
    formState: { isValid }
  } = useForm<PointsStoreCardForm>({
    defaultValues: defaultPointCard,
    mode: "onChange",
    resolver: yupResolver(setupPointsStoreCardFormSchema())
  })

  useEffect(() => {
    if (isOpen) {
      reset(defaultPointCard)
    }
    if (!item) {
      reset()
    }
  }, [item, isOpen])

  const onSubmit = async (data: PointsStoreCardForm) => {
    setLoading(true)

    const formData = new FormData()

    formData.append("title", data.title)
    formData.append("description", data.description)
    formData.append("quantity", JSON.stringify(data.quantity))
    if (typeof data.imageUrl === "object") {
      formData.append("file", data.imageUrl)
    } else {
      formData.append("imageUrl", data.imageUrl)
    }
    formData.append("stockQuantity", JSON.stringify(data.stockQuantity))
    formData.append("price", JSON.stringify(data.price))
    formData.append("price_dollars", JSON.stringify(data.price_dollars))
    formData.append("userCooldown", JSON.stringify(data.userCooldown))


    if (item) {
      await axios.put(`/point-shop/items/${item.id}`, formData, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
        withCredentials: true
      })
    } else {
      await axios.post("/point-shop/items", formData, {
        headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
        withCredentials: true
      })
    }
    setLoading(false)

    reset(data)

    onApply()

    onClose()
  }

  const descriptions: { key: keyof PointsStoreCardForm, label: string }[] = [
    { key: "price", label: t("admin-points-store.addItemModal.priceInPoints") },
    { key: "price_dollars", label: t("admin-points-store.addItemModal.priceInDollar") },
    { key: "quantity", label: t("admin-points-store.addItemModal.itemQuantity") },
    { key: "stockQuantity", label: t("admin-points-store.addItemModal.stockQuantity") },
    { key: "userCooldown", label: t("admin-points-store.addItemModal.userCooldown") }
  ]

  return (
    <Modal
      isOpen={isOpen}
      centered
      className="shared-modal-wrapper small"
      contentClassName="position-relative shared-modal"
      toggle={onClose}
    >
      <button type="button" className="shared-btn shared-modal-close rounded" onClick={onClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <h2 className="admin-bonuses-modal-title admin-bonuses-modal-text">
        {!!item ? t("admin-points-store.addItemModal.titleEdit") : t("admin-points-store.addItemModal.titleAdd")}
      </h2>

      <form className="admin-bonuses-modal-content" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={field.onChange}
              label={t("admin-points-store.addItemModal.name")}
              className="admin-bonuses-modal-space"
              placeholder={t("admin-points-store.addItemModal.namePlaceholder")}
            />
          )}
        />

        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <ModalInput
              value={field.value}
              onChange={field.onChange}
              label={t("admin-points-store.addItemModal.caption")}
              className="admin-bonuses-modal-space"
              placeholder={t("admin-points-store.addItemModal.captionPlaceholder")}
            />
          )}
        />
        {descriptions.slice(0, 2).map(({ key, label }) => (
          <Controller
            key={key}
            control={control}
            name={key}
            render={({ field }) => (
              <ModalInput
                value={field.value}
                onChange={field.onChange}
                className="admin-bonuses-modal-space"
                label={label}
                placeholder="0"
              />
            )}
          />
        ))}

        <p className="admin-bonuses-modal-text">{t("admin-bonuses.modal.logo")}</p>

        <Controller
          control={control}
          name="imageUrl"
          render={({ field }) => (
            <ImageUploader
              className="mt-4"
              url={field.value}
              onChange={field.onChange}
            />
          )}
        />

        {descriptions.slice(2).map(({ key, label }) => (
          <Controller
            key={key}
            control={control}
            name={key}
            render={({ field }) => (
              <ModalInput
                value={field.value}
                onChange={field.onChange}
                className="admin-bonuses-modal-space"
                label={label}
                placeholder="0"
              />
            )}
          />
        ))}


        <button
          disabled={loading || !isValid}
          className="shared-btn shared-btn-primary w-100 admin-bonuses-modal-submit admin-bonuses-modal-space"
        >
          {loading ? (
            <Spinner color="secondary" size="sm" />
          ) : (
            t("admin-bonuses.modal.save")
          )}
        </button>
      </form>
    </Modal>
  )
}

export default AddPointsCardModal

import { FC, useEffect, useState } from "react"

import { intervalToDuration } from "date-fns"

import { useTranslation } from "react-i18next"

import "./styles.scss"
import classNames from "classnames"


type CountdownProps = {
  date?: string,
  timeType?: boolean
  className?: string
};

const Countdown: FC<CountdownProps> = ({ date, timeType, className}) => {
  const { t } = useTranslation()
  const [isExpired, setIsExpired] = useState(false)
  const [, setTimestamp] = useState<number>(Date.now())

  useEffect(() => {
    const interval = setInterval(() => {
      setTimestamp(Date.now())
      const delta = intervalToDuration({
        start: date ? new Date(date) : new Date(),
        end: new Date()
      })

      if (delta.days === 0 && delta.hours === 0 && delta.minutes === 0 && delta.seconds === 0) {
        clearInterval(interval)
        setIsExpired(true)
      }
    }, 1000)
    return () => clearInterval(interval)
  }, [date])

  const delta = intervalToDuration({
    start: date ? new Date(date) : new Date(),
    end: new Date()
  })

  const zeroPad = (num: number | undefined) => String(num).padStart(2, '0')

  return (
    <div className={classNames("", className)}>
      <div>
          <span>
            {timeType ? t("countdown.leaderboard-card.day") : t("countdown.dates.day")}
          </span>
        {isExpired ? "00" : zeroPad(delta.days)}
      </div>

      <div>
          <span>
            {timeType ? t("countdown.leaderboard-card.hour") : t("countdown.dates.hour")}
          </span>
        {isExpired ? "00" : zeroPad(delta.hours)}
      </div>

      <div>
          <span>
            {timeType ? t("countdown.leaderboard-card.minutes") : t("countdown.dates.minutes")}
          </span>
        {isExpired ? "00" : zeroPad(delta.minutes)}
      </div>

      <div>
          <span>
            {timeType ? t("countdown.leaderboard-card.seconds") : t("countdown.dates.seconds")}
          </span>
        {isExpired ? "00" : zeroPad(delta.seconds)}
      </div>
    </div>
  )
}

export default Countdown

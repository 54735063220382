import React, { FC, useRef, useState } from "react"
import { Input, InputGroupText } from "reactstrap"
import { useTranslation } from "react-i18next"
import axios from "@src/network"

import classNames from "classnames"

import { useOnClickOutside } from "@hooks/useOnClickOutside"
import useToast, { TOAST_TYPE } from "@hooks/useToast"
import { useCurrentUser, User } from "@hooks/useUser"

import { ReactComponent as DiscordIcon } from "assets/icons/discord.svg"
import { ReactComponent as KickIcon } from "assets/icons/kick.svg"
import ConnectKickModal from "@src/components/ConnectKickModal"


type RafflesTabProps = {
  user?: User
  refresh: () => void
  isAdminPage: boolean
  myId?: string
}

const ConnectionsTab: FC<RafflesTabProps> = ({ user, refresh, isAdminPage, myId }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const { user: currentUser } = useCurrentUser()
  const [isEdit, setEditBtn] = useState(false)
  const [inputValue, setInputValue] = useState<string | undefined>(user?.stakeUsername)
  const [showConnectKickModal, setShowConnectKickModal] = useState(false)

  const inputGroupRef = useRef(null)

  const connectDiscord = async () => {
    window.open(`${process.env.REACT_APP_API_URL}/auth/discord`, "_self")
  }

  const unlinkDiscord = async () => {
    await axios.patch(`/users/${user?.id}/unlink-discord`, {}, {
      headers: { "Content-Type": "multipart/form-data", "x-api-key": 12345 },
      withCredentials: true
    })
  }

  const unlinkKick = async () => {
    await axios.patch(`/users/${user?.id}`, { kickUsername: null }, {
      headers: { "x-api-key": 12345 },
      withCredentials: true
    })
  }

  const onSubmitStakeUsername = async (stakeUsername: string | null) => {
    await axios.patch(`/users/${user?.id}`, { stakeUsername }, {
      headers: { "x-api-key": 12345 },
      withCredentials: true
    })
      .then(() => {
        toast(t("profile.connectionSection.stakeToastSuccess"), TOAST_TYPE.SUCCESS)
      })
      .catch(() => {
        toast(t("profile.connectionSection.stakeToastError"), TOAST_TYPE.ERROR)
      })
  }

  const handleEdit = () => {
    setEditBtn(!isEdit)
  }

  useOnClickOutside(inputGroupRef, () => {
    if (isEdit) {
      setTimeout(() => {
        setEditBtn(false)
      }, 10)
    }
  })

  return (
    <div className="profile-modal-connections-wrapper">
      <div className="profile-modal-connections-input-container">
        <div ref={inputGroupRef} className="profile-modal-connections-stake" onClick={handleEdit}>
          <div
            className={classNames("profile-modal-connections-stake-input-container d-flex", { "profile-modal-connections-stake-input-container-edit": isEdit })}
          >
            <InputGroupText cssModule={{ "input-group-text": "profile-modal-connections-stake-text" }}>
              <img src="/assets/images/stake-img-small.png" alt="img" />
            </InputGroupText>

            <Input
              value={inputValue}
              onChange={e => setInputValue(e.target.value)}
              cssModule={{ "form-control": "profile-modal-connections-stake-input w-100" }}
              placeholder={t("profile.connectionSection.stakePlaceholder")}
            />
          </div>

          <button
            className={classNames("profile-modal-connections-stake-btn-edit", { "d-flex": isEdit })}
            onClick={() => onSubmitStakeUsername(inputValue ? inputValue : null)}
          >
            SAVE
          </button>
        </div>

        <div className="d-flex w-100 justify-content-start">
          <div className="profile-modal-connections-item me-5">
            <div>
              <button
                className={classNames("profile-modal-connections-input-button-connect position-relative",
                  { "cursor-not-allowed": isAdminPage && myId !== user?.id }
                )}
                onClick={() => connectDiscord()}
                disabled={!!user?.discordUsername || (isAdminPage && myId !== user?.id)}
              >
                <div
                  className="profile-modal-connections-input-button-label">{t("profile.connectionSection.discord")}</div>

                <DiscordIcon className="profile-modal-connections-discord" />

                <div className="profile-modal-connections-input-button-text">
                  {user?.discordUsername ? (
                    <span className="text-inherit">{user.discordUsername}</span>
                  ) : t("profile.connectionSection.linkDiscord")}

                  {user?.discordUsername ? <img src="/assets/icons/check-alternative.svg" alt="check" /> : null}
                </div>
              </button>
            </div>

            {user?.discordUsername ? (
              <>
                <div className="d-flex w-100 align-items-center justify-content-between mt-2">
                  <button className="profile-modal-connections-input-button" onClick={() =>
                    unlinkDiscord().then(() => refresh())}
                  >
                    {t("profile.connectionSection.unlink")}
                  </button>

                  <button className="profile-modal-connections-input-button" onClick={() => {
                    refresh()
                    toast(t("profile.connectionSection.refresh"), TOAST_TYPE.SUCCESS)
                  }}>
                    {t("profile.connectionSection.recheck")}
                  </button>
                </div>

                <div className="d-flex w-100 align-items-start justify-content-start mt-4">
                  <img
                    src="/assets/icons/check-green.svg"
                    alt="cross"
                    className="profile-modal-connections-input-status-check"
                  />

                  <p className="profile-modal-connections-input-status">
                    {t("profile.connectionSection.joinServer")}
                  </p>
                </div>
              </>
            ) : (
              <div className="d-flex w-100 align-items-start justify-content-start mt-4">
                <img
                  src="/assets/icons/cross-red-small.svg"
                  alt="cross"
                  className="profile-modal-connections-input-status-cross"
                />

                <p className="profile-modal-connections-input-status">
                  {t("profile.connectionSection.joinServer")}
                </p>
              </div>)}
          </div>

          <div className="profile-modal-connections-item">
            <div>
              <button
                className="profile-modal-connections-input-button-connect position-relative"
                onClick={() => setShowConnectKickModal(true)}
                disabled={!!user?.kickUsername}
              >
                <div className="profile-modal-connections-input-button-label">
                  {t("profile.connectionSection.kick")}
                </div>

                <KickIcon className="profile-modal-connections-kick" />

                <div className="profile-modal-connections-input-button-text">
                  {user?.kickUsername ? (
                    <span className="text-inherit">{user.kickUsername}</span>
                  ) : t("profile.connectionSection.linkKick")}

                  {user?.kickUsername ? <img src="/assets/icons/check-alternative.svg" alt="check" /> : null}
                </div>
              </button>
            </div>

            {user?.kickUsername ? (
              <div className="d-flex w-100 align-items-center justify-content-center mt-2">
                {currentUser.isAdmin &&
                  <button className="profile-modal-connections-input-button" onClick={() =>
                    unlinkKick().then(() => refresh())}
                  >
                    {t("profile.connectionSection.unlink")}
                  </button>
                }
              </div>
            ) : (
              <div className="d-flex w-100 align-items-center justify-content-center mt-2">
                <button className="profile-modal-connections-input-button" onClick={() => {
                  refresh()
                  toast(t("profile.connectionSection.refresh"), TOAST_TYPE.SUCCESS)
                }}>
                  {t("profile.connectionSection.recheck")}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <ConnectKickModal isOpen={showConnectKickModal} handleClose={() => setShowConnectKickModal(false)} />
    </div>
  )
}

export default ConnectionsTab

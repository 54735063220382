import axios from 'network'
import { OpenLootBoxResponse, PublicLootBoxWithItems, PublicUserLootBoxItem } from '../lootBox'
import { GetPublicUserLootBoxItemQuery } from '../adminLootBoxTransactions'

export const fetchLootBoxByTag = async (tag: string) => {
  try {
    const res = await axios.get(`/loot-box/tag/${tag}`, {
      withCredentials: true
    })

    return { data: res?.data?.data as PublicLootBoxWithItems }
  } catch (e) {
    return {
      data: {
        id: -1,
        title: 'There is no active daily free case',
        description: '',
        imageUrl: '/assets/images/sad-smile.png',
        settings: {
          openCondition: {}
        },
        items: []
      }
    }
  }
}

export const openLootBox = async (lootBoxId: number) => {
  const res = await axios.post(`/loot-box/${lootBoxId}/open`, undefined, {
    withCredentials: true
  })

  return { data: res?.data?.data as OpenLootBoxResponse }
}

export const fetchUserLootBoxItems = async (payload: GetPublicUserLootBoxItemQuery) => {
  const res = await axios.get(`/loot-box/my-items`, {
    params: { ...payload },
    withCredentials: true
  })

  return {
    total: res?.data?.total,
    data: res?.data as PublicUserLootBoxItem[]
  }
}

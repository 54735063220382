import { FC } from "react"
import Select from "react-select"
import { Option } from "@utils/Select"

import "./styles.scss"
import classNames from "classnames"

type SelectInputProps = {
  options: Option[]
  onChange: (e: string) => void;
  value?: string;
  title: string;
  className?: string;
}

const SelectInput: FC<SelectInputProps> = ({options, value, onChange, title, className }) => {
  return (
    <div className={classNames("d-flex flex-column w-100 position-relative", className)}>
      <span className="select-input-title">
        {title}
      </span>
      <Select
        options={options}
        placeholder=""
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: "transparent",
            primary25: "#1C243B"
          }
        })}
        className="select-wrapper"
        classNamePrefix="select"
        isSearchable={false}
        value={options.find((o) => o.value === value) || null}
        onChange={(o) => {
          if (!o?.value) return
          onChange(o.value)
        }}
        components={{ IndicatorSeparator: null }}
      />
    </div>
  )
}

export default SelectInput
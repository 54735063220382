import { createContext } from "react"

type FireworksContext = {
  showFireworks: boolean,
  setShowFireworks: (v:boolean) => void
}
const FireworksContext = createContext<FireworksContext>({
  showFireworks: false,
  setShowFireworks: () => undefined
})

export default FireworksContext

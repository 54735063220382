import { FC, useState } from "react"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

import useToast, { TOAST_TYPE } from "@hooks/useToast"

import "./styles.scss"

type CopyButtonProps = {
  className?: string
  copyText: string
}

export const CopyButton: FC<CopyButtonProps> = ({ copyText, className }) => {
  const { t } = useTranslation()
  const toast = useToast()

  const [showSuccessState, setShowSuccessState] = useState<boolean>(false)

  const handleOnCopy = () => {
    setShowSuccessState(true)

    navigator.clipboard.writeText(copyText)

    toast(t("copy.success"), TOAST_TYPE.SUCCESS)

    setTimeout(() => setShowSuccessState(false), 3000)
  }

  return (
    <button className={classNames("copy-button", className)} onClick={handleOnCopy}>
      {showSuccessState ? (
        <>
          <img src="/assets/icons/check.svg" alt="check" className="copy-button-icon copy-button-icon--check" />
          {t("actions.copied")}
        </>
      ) : (
        <>
          <img src="/assets/icons/copy.svg" alt="copy" className="copy-button-icon copy-button-icon--copy" />
          {t("actions.copy")}
        </>
      )}
    </button>
  )
}

export default CopyButton

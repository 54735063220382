import { usePlainSWR } from "@hooks/usePlainSWR"

import { RAFFLE } from "constants/swr"

import { fetchRaffleById, RaffleCardEntity } from "@modules/raffles"

export const useRaffleById = (id: number) => {

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`${RAFFLE}/${id}`, () => fetchRaffleById(id))

  return {
    data: data as RaffleCardEntity,
    error,

    refresh,

    isValidating,
    loading: !data && !error
  }
}

import React from "react"
import { useTranslation } from "react-i18next"
import { endOfMonth } from "date-fns"

import Countdown from "components/Countdown"
import LeaderboardTable from "components/LeaderboardTable"

import { useLeaderboard, SectionTitle } from "modules/leaderboards"
import { useParams } from "react-router-dom"

const ActiveLeaderboard = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useLeaderboard(id!)

  return (
    <section className="leaderboard-active-liderboard position-relative">
      <div className="leaderboard-active-liderboard-title">
        <SectionTitle imgUrl="/assets/icons/hourglass.svg" text={t("leaderboards.active-leaderboard.title")} />
      </div>

      <div className="leaderboard-active-liderboard-countdown">

        {!loading && (
          <Countdown
            // @ts-ignore
            date={endOfMonth(new Date(data.year, data.month - 1) || new Date().toString()).toString()  || new Date().toString()}
            className="countdown"
          />
        )}
      </div>

      <div className="leaderboard-active-liderboard-table-wrapper">
        <LeaderboardTable />
      </div>
    </section>
  )
}
export default ActiveLeaderboard

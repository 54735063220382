import React from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"

import LeaderboardTableRow from "components/LeaderboardTableRow"

import { useUsersByLeaderboardId, useLeaderboard } from "@modules/leaderboards"

import "./styles.scss"
import { useParams } from "react-router-dom"

const LeaderboardTable = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useLeaderboard(id!)
  const { list, loading: usersLoading, onChangePerPage, perPage } = useUsersByLeaderboardId(data?.id)

  return (
    <section className="custom-leaderboard-table-wrapper w-100 d-flex flex-column align-items-center">
      <div className="custom-leaderboard-table">
        <div className="custom-leaderboard-table-title">
          <h3>{t("leaderboard-table.user")}</h3>

          <h3>{t("leaderboard-table.wagered")}</h3>

          <h3>{t("leaderboard-table.prize")}</h3>
        </div>

        {(loading || usersLoading) ? (
            <div className="w-100 d-flex align-items-center justify-content-center height-100">
              <Spinner color="primary" />
            </div>
          ) :
          list
            .slice(3, perPage)
            .map((user, index) => {
              const prize = data?.leaderboardTieredPrize[index + 3]

              return (
                <LeaderboardTableRow
                  place={index + 4}
                  name={user.displayName}
                  total={user.wageredTotal}
                  current={prize?.amount || 0}
                  key={user.id + index} />
              )
            })}
      </div>

      {!loading && perPage < data.maxPublicUsersCount ? (
        <button
          className="custom-leaderboard-btn shared-btn-secondary"
          onClick={() => onChangePerPage(data.maxPublicUsersCount)}
        >
          {t("leaderboard-table.btn-more")}
        </button>
      ) : (
        !loading && data.maxPublicUsersCount > 10  &&
        <button
          className="custom-leaderboard-btn shared-btn-secondary"
          onClick={() => onChangePerPage(10)}
        >
          {t("leaderboard-table.btn-less")}
        </button>
      )}
    </section>
  )
}
export default LeaderboardTable

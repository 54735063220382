import { useEffect, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import { Leaderboard } from "@modules/leaderboards"
import { fetchAdminCurrentLeaderboards, fetchAdminLeaderboards } from "@modules/adminLeaderboard"

export const useAdminLeaderboard = () => {
  const [page, setPage] = useState(0)
  const [perPage, setPerPage] = useState(9)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR("/admin-leaderboard", () =>
    fetchAdminLeaderboards({ page, perPage })
  )

  useEffect(() => {
    refresh()
  }, [page, perPage])

  return {
    list: (data?.items || []) as Leaderboard[],
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: data?.total || 1

  }
}

export const useAdminCurrentLeaderboard = (id: string) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`/admin-leaderboards/${id}`, () =>
    fetchAdminCurrentLeaderboards(id)
  )

  const currentLeaderboard = data && data as Leaderboard
  return {
    data: currentLeaderboard,
    error,

    refresh,
    isValidating,
    loading: !data && !error
  }
}


import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

type PromotionsProps = {}

const Promotions: FC<PromotionsProps> = ({}) => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <div
      className="bonuses-rewards-promotion-wrapper w-100 d-flex flex-column justify-content-center align-items-center"
    >
      <div className="bonuses-earn-title">
        <h3 className="small-headline">{t("bonuses.promotion.title")}</h3>

        <h2 className="bonuses-earn-title-subtitle">{t("bonuses.promotion.subtitle")}</h2>
      </div>

      <div
        className="shared-block bonuses-rewards-promotion w-100 d-flex flex-column justify-content-center align-items-center"
      >
        <img
          className="bonuses-rewards-promotion-img"
          src="/assets/images/bonuses-promotion-crown.png"
          alt="crown"
        />

        <h6 className="bonuses-rewards-promotion-title">{t("bonuses.promotion.crown.title")}</h6>

        <p className="bonuses-rewards-promotion-text">{t("bonuses.promotion.crown.text")}</p>

        <button className="w-100 shared-btn-primary bonuses-rewards-promotion-btn" onClick={() => navigate('/leaderboards')}>
          {t("bonuses.promotion.exploreLeaderboard")}
        </button>
      </div>

      <div className="w-100 bonuses-rewards-promotion-content">
        <div
          className="shared-block bonuses-rewards-promotion bonuses-rewards-promotion--small d-flex flex-column justify-content-start align-items-center"
        >
          <img
            className="bonuses-rewards-promotion-img"
            src="/assets/images/bonuses-promotion-coin.png"
            alt="crown"
          />

          <h6 className="bonuses-rewards-promotion-title">{t("bonuses.promotion.coin.title")}</h6>

          <p className="bonuses-rewards-promotion-text">{t("bonuses.promotion.coin.text")}</p>

          <button className="w-100 shared-btn-primary bonuses-rewards-promotion-btn" onClick={() => navigate('/points-store')}>
            {t("bonuses.promotion.explorePointsShop")}
          </button>
        </div>

        <div
          className="shared-block bonuses-rewards-promotion bonuses-rewards-promotion--small d-flex flex-column justify-content-start align-items-center"
        >
          <img
            className="bonuses-rewards-promotion-img"
            src="/assets/images/bonuses-promotion-tickets.png"
            alt="crown"
          />

          <h6 className="bonuses-rewards-promotion-title">{t("bonuses.promotion.tickets.title")}</h6>

          <p className="bonuses-rewards-promotion-text">{t("bonuses.promotion.tickets.text")}</p>

          <button className="w-100 shared-btn-primary bonuses-rewards-promotion-btn" onClick={() => navigate('/raffles')}>
            {t("bonuses.promotion.exploreRaffles")}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Promotions

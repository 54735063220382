import React, { FC } from 'react'
import classNames from 'classnames'

import { Input } from 'reactstrap'
import { InputType } from 'reactstrap/types/lib/Input'
import { FieldError } from 'react-hook-form'

type ModalInputProps = {
  value: any
  onChange?: (v: any) => void
  placeholder?: string
  label: string
  className?: string
  isDisabled?: boolean
  inputClassNames?: string
  onFocus?: (v: any) => void
  type?: InputType
  onKeyDown?: (e: any) => void
  error?: FieldError
}

const ModalInput: FC<ModalInputProps> = ({
  value,
  className,
  label,
  placeholder = '',
  onChange,
  isDisabled,
  inputClassNames,
  type,
  onKeyDown,
  error
}) => {
  return (
    <div className={classNames('shared-input-wrapper ', className)}>
      <label className='shared-input-label'>
        {label}
        {error && (
          <span className='shared-input-label-error'>{error.message}</span>
        )}
      </label>

      <Input
        value={value}
        type={type ? type : 'text'}
        onChange={(e) => onChange && onChange(e.target.value)}
        className={classNames('shared-input', inputClassNames, {
          'shared-input-error': !!error
        })}
        placeholder={placeholder}
        cssModule={{ 'form-control': ' ' }}
        disabled={isDisabled}
        onKeyDown={onKeyDown}
      />
    </div>
  )
}

export default ModalInput

import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import SearchInput from "@src/components/SearchInput/SearchInput"
import { Spinner } from "reactstrap"
import { SearchPayload, SlotCard, SlotsCard } from "@modules/slotsReviews"

type AllSlotsSectionProps = {
  perPage: number
  title: string
  list: SlotCard[]
  loading: boolean
  className?: string
  onFiltered?: (s: {
    search: string
  }) => void
  filter?: SearchPayload
  setPerPage: (n: number) => void
  total: number,
  isSlotsReviewsPage?: boolean
}

const AllSlotsSection: FC<AllSlotsSectionProps> = ({
  title,
  perPage,
  list,
  loading,
  className,
  filter,
  onFiltered,
  setPerPage,
  total,
  isSlotsReviewsPage
}) => {
  const { t } = useTranslation()

  const basePerPage = isSlotsReviewsPage ? 10 : 5

  return (
    <section className={`slots-reviews-all-slots ${className}`}>
      <div className="slots-reviews-all-slots-title">
        <p className="shared-text text-secondary-100 font-weight-700 text-uppercase m-0">
          {title}
        </p>

        <div className="slots-reviews-all-slots-search">
          <SearchInput
            onChange={(s) => onFiltered && onFiltered({ search: s })}
            value={filter?.search}
          />
        </div>
      </div>

      {list.length === 0 ? (
        <div className="slots-reviews-all-slots-list-stub shared-stub">
          <p className="shared-stub-text">
            {t("slotsReviews.stub")}
          </p>
        </div>
      ) : (
        <>
          <div className="slots-reviews-all-slots-list-wrapper">
            <div className="slots-reviews-all-slots-list">
              {loading ? (
                <div className="d-flex w-100 align-items-center justify-content-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                list.map((card) => (
                  <SlotsCard card={card} key={card.id} />
                )))}
            </div>
          </div>

          <div className="slots-reviews-all-slots-btn">
            {perPage < total && (
              <button
                className="slots-reviews-btn--default shared-btn-secondary"
                onClick={() => setPerPage(perPage + basePerPage)}
              >
                {t("slotsReviews.allSlotsSection.btn")}
              </button>
            )}

            {perPage > total && total > basePerPage && (
              <button
                className="slots-reviews-btn--default shared-btn-secondary"
                onClick={() => {
                  setPerPage(basePerPage)
                }}
              >
                {t("slotsReviews.allSlotsSection.btnShowLess")}
              </button>
            )}
          </div>
        </>
      )}

    </section>
  )
}

export default AllSlotsSection
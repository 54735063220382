import { RouteEntity } from "@src/router/routes"
import { lazy } from "react"
import "./views/raffles.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export const Raffles = lazy(() => import("./views/Raffles"))
export const SelectedRaffle = lazy(() => import("./views/SelectedRaffle"))

export * from "./dto"

export * from "./useRaffles"
export * from "./useRaffleById"
export * from "./useUserRaffleEntries"
export * from "./useSelectedRaffleEntries"
export * from "./useRaffleProof"

export * from "./requests"

export * from "./const"

export { default as RafflesCard } from "./components/RafflesCard"
export { default as CurrentRaffles } from "./components/CurrentRaffles"
export { default as EndedRaffles } from "./components/EndedRaffles"
export { default as JoinRaffleModal } from "./components/JoinRaffleModal"
export { default as RaffleWinners } from "./components/RaffleWinners"
export { default as YourTicketsModal } from "./components/YourTicketsModal"
export { default as RaffleProofModal } from "./components/RaffleProofModal"
export { default as RaffleInstructionModal } from "./components/RaffleInstructionModal"
export { default as SelectedRaffleInfo } from "./components/SelectedRaffleInfo"
export { default as FireworksModal } from "./components/FireworksModal"
export { default as WinnerCard } from "./components/WinnerCard"

export const router: RouteEntity[] = [
  {
    path: "/raffles/:id",
    element: <SelectedRaffle />
  },
  {
    path: "/raffles",
    element: <Raffles />
  }
]

export const locales = {
  en,
  ru
}

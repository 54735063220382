import React, { FC } from "react"

import { TopBonusCard } from "@modules/bonuses"

import { Bonus } from "@modules/adminBonuses"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"

type TopBonusCardReorderProps = {
  item: Bonus
  onDeleteItem: (b: Bonus) => void
  onEditItem: (b: Bonus) => void
}

const TopBonusCardReorder: FC<TopBonusCardReorderProps> = ({ item, onEditItem, onDeleteItem }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  }

  return (
    <div ref={setNodeRef}
         style={style}
         {...attributes}
         {...listeners}>
      <TopBonusCard bonus={item} onDeleteItem={onDeleteItem} onEditItem={onEditItem} showControl />
    </div>
  )
}

export default TopBonusCardReorder

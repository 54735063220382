import axios from "network"

import { PaginationPayload } from "@hooks/usePlainSWR"

import { AdminUserTablePayload } from "@modules/adminUsers"
import { UserWallets } from "@hooks/useUser"

export const fetchAdminUsers = async (payload: AdminUserTablePayload & PaginationPayload) => {
  const { order, orderBy, page, perPage, search } = payload

  const res = await axios.get("/users/all", {
    params: {
      sortBy: orderBy,
      sortOrder: order,
      limit: perPage,
      page,
      search
    },
    withCredentials: true
  })

  return {
    total: res?.data?.length,
    data: res?.data
  }
}

export const updateUserCryptoWalletsRequest = (id: string, wallets: UserWallets) => axios.patch(`users/${id}/crypto-addresses`, wallets, { withCredentials: true})

export const banUserRequest = (id: string, reason: string) => axios.patch(`users/${id}/ban`, {
  banReason: reason
}, { withCredentials: true})

export const unbanUserRequest = (id: string) => axios.patch(`users/${id}/unban`, {}, { withCredentials: true})

export const getUserWalletsRequest = (id: string) => axios.get(`/users/${id}?withCrypto=true`, { withCredentials: true})

export const fetchDashboardStats = () => axios.get(`/users/stats/dashboard`, { withCredentials: true})

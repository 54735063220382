import React, { FC } from 'react'
import SearchInput from '@src/components/SearchInput/SearchInput'
import Select from 'react-select'

import { TableCell, TableHeaderCell } from '@src/components/Table'

import { Option } from '@utils/Select'
import { User } from '@hooks/useUser'
import { useTranslation } from 'react-i18next'
import Pagination from '@src/components/Pagination'
import { Spinner } from 'reactstrap'

import classNames from 'classnames'

import { usePointsStorePurchasesById } from '@modules/pointsStore/usePointsStorePurchasesById'
import { ITEMS_ORDER, POINTS_STORE_PROFILE_TABLE } from '@modules/pointsStore'

import { addCommasForBigNumbers } from '@utils/Strings'
import { format } from 'date-fns'
import { useGetUserLootBoxItems } from '@src/modules/dailyCase/useGetUserLootBoxItems'

type Props = {
  user?: User
}

const CaseItemsTab: FC<Props> = ({ user }) => {
  const { t } = useTranslation()
  const { list, loading, page, filter, onFiltered, onChangePage, perPage, total } =
    useGetUserLootBoxItems()

  return (
    <div className='profile-modal-table-container'>
      {list.length === 0 ? (
        <div className='profile-modal-table-stub'>
          <p className='raffles-list-stub-text'>{t('NO ITEMS')}</p>
        </div>
      ) : (
        <div className='profile-modal-table no-scrollbar'>
          <div className='d-flex flex-column w-100 justify-content-end'>
            <div className='d-flex flex-row profile-modal-table-header-row'>
              <TableHeaderCell
                text={t('profile.pointsShopSection.tableTitle.id')}
                className='profile-modal-table-header'
              />

              <TableHeaderCell
                text={t('profile.pointsShopSection.tableTitle.itemName')}
                className='profile-modal-table-header'
              />

              <TableHeaderCell
                text={t('profile.pointsShopSection.tableTitle.price')}
                className='profile-modal-table-header'
              />

              <TableHeaderCell
                text={t('profile.pointsShopSection.tableTitle.status')}
                className='profile-modal-table-header'
              />

              <TableHeaderCell
                text={t('profile.pointsShopSection.tableTitle.completed')}
                className='profile-modal-table-header'
              />
            </div>
          </div>

          <div className='d-flex flex-column w-100 justify-content-end'>
            {loading ? (
              <div className='w-100 d-flex justify-content-center'>
                <Spinner color='primary' />
              </div>
            ) : (
              list.map((item) => {
                return (
                  <div className='d-flex flex-row profile-modal-table-row' key={item.id}>
                    <TableCell className='admin-ended-table-cell-wrapper profile-modal-table-cell-id border-0'>
                      <div className='profile-modal-table-cell'>#{item.id}</div>
                    </TableCell>

                    <TableCell className='admin-ended-table-cell-wrapper border-0'>
                      <div className='profile-modal-table-cell'>
                        <img src={item.lootBoxItem.imageUrl} alt='' />
                        <p className='profile-modal-table-cell-text'>{item.lootBoxItem.title}</p>
                      </div>
                    </TableCell>

                    <TableCell className='admin-ended-table-cell-wrapper border-0'>
                      <div className='profile-modal-table-cell'>
                        $ {addCommasForBigNumbers(item.lootBoxItem.priceDollars)}
                      </div>
                    </TableCell>

                    <TableCell className='admin-ended-table-cell-wrapper border-0'>
                      <div
                        className={classNames(
                          'profile-modal-table-cell',
                          { 'text-green': item.status === 'APPROVED' },
                          { 'text-ban-red': item.status === 'REJECTED' },
                          { 'text-blue': item.status === 'PENDING' }
                        )}
                      >
                        {item.status}
                      </div>
                    </TableCell>

                    <TableCell className='admin-ended-table-cell-wrapper border-0'>
                      <div className='profile-modal-table-cell'>
                        {format(new Date(item.createdAt), 'dd.MM.yyyy')}
                      </div>
                    </TableCell>
                  </div>
                )
              })
            )}
          </div>
        </div>
      )}

      <div className='d-flex w-100 align-items-center justify-content-center mt-3'>
        <Pagination
          rowCount={Math.ceil(total / perPage) || 1}
          currentPage={page}
          onChangePage={onChangePage}
          rowsPerPage={1}
        />
      </div>
    </div>
  )
}

export default CaseItemsTab

import React, { FC, useState } from "react"
import { Modal, ModalBody, ModalFooter, Spinner } from "reactstrap"
import { useTranslation } from "react-i18next"
import axios from "@src/network"
import { AdminLeaderboardUsers } from "@modules/adminLeaderboard"
import useToast, { TOAST_TYPE } from "@hooks/useToast"

type BanUserInLeaderboardProps = {
  user: AdminLeaderboardUsers | null
  isOpen: boolean
  leaderboardId?: string
  onClose: () => void
  onApply: () => void
}

const BanUserInLeaderboard: FC<BanUserInLeaderboardProps> = ({
  user,
  isOpen,
  onClose,
  onApply,
  leaderboardId
}) => {
  const { t } = useTranslation()
  const toast = useToast()
  const [loading, setLoading] = useState<boolean>(false)
  const [textareaValue, setTextareaValue] = useState<string>("")
  const onBan = async () => {
    setLoading(true)

    if (textareaValue === "") {
      toast(t("admin-leaderboard.current.banModal.error"), TOAST_TYPE.ERROR)

      setLoading(false)
      return
    }

    if (user) {
      await axios.put(`leaderboard/${leaderboardId}/user/${user.id}/ban`, { bannedReason: textareaValue }, {
        headers: { "x-api-key": 12345 },
        withCredentials: true
      })
    }

    setLoading(false)

    setTextareaValue("")

    onApply()
  }

  const handleClose = () => {
    setTextareaValue("")
    onClose()
  }
  return (
    <Modal
      isOpen={isOpen}
      centered
      cssModule={{ "modal-dialog": "admin-leaderboards-modal" }}
      contentClassName="position-relative shared-modal"
      toggle={handleClose}
    >
      <button type="button" className="shared-btn shared-modal-close" onClick={handleClose}>
        <img src="/assets/icons/close.svg" alt="close" />
      </button>

      <ModalBody cssModule={{ "modal-body": "admin-leaderboards-modal-body" }}>
        <div
          className="admin-leaderboards-modal-title">{t("admin-leaderboard.current.banModal.title")} {user?.displayName}?
        </div>

        <p className="admin-leaderboards-modal-text">
          {t("admin-leaderboard.current.banModal.text")}
        </p>

        <div className="admin-leaderboards-modal-title-area">
          {t("admin-leaderboard.current.banModal.areaTitle")}
        </div>
        <textarea
          value={textareaValue}
          onChange={(e) => setTextareaValue(e.target.value)}
          placeholder={t("admin-leaderboard.current.banModal.areaPlaceholder")}
        />
      </ModalBody>

      <ModalFooter color="secondary" cssModule={{ "modal-footer": "admin-leaderboards-modal-footer" }}>
        <button type="button" className="shared-btn-secondary admin-leaderboards-modal-btn"
                onClick={handleClose}>
          {t("admin-leaderboard.current.banModal.btnCancel")}
        </button>

        <button
          type="button"
          disabled={textareaValue === "" || loading}
          className="points-confirm-btn shared-btn-primary admin-leaderboards-modal-btn"
          onClick={onBan}
        >
          {loading && <Spinner size="sm" />}
          {t("admin-leaderboard.current.banModal.btnBan")}
        </button>
      </ModalFooter>
    </Modal>
  )
}

export default BanUserInLeaderboard
import axios from "network"

import { PaginationPayload } from "@hooks/usePlainSWR"

import { RaffleEntriesPayload, RAFFLES_STATUS, RafflesPayload } from "@modules/raffles/dto"

export const fetchRaffles = async (payload: RafflesPayload & PaginationPayload, status: RAFFLES_STATUS) => {
  const { search, sortBy, sortOrder } = payload

  const params: any = {
    sortOrder,
    sortBy,
    search,
    status
  }

  if (payload.page) {
    params.take = payload.perPage
    params.skip = payload.perPage * (payload.page - 1)
  }

  const res = await axios.get("/raffles", {
    params,
    withCredentials: true
  })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const fetchEntries = async (payload: RaffleEntriesPayload & PaginationPayload, id: number) => {
  const { page, perPage, search } = payload

  const res = await axios.get(`/raffles/${id}/entries`, {
    params: {
      take: perPage,
      skip: perPage * (page - 1),
      search
    },
    withCredentials: true
  })

  return { data: { items: res.data.items, total: res.data.totalCount } }
}

export const fetchRaffleById = async (id: number) => {
  const res = await axios.get(`/raffles/${id}`, {
    params: {
      withWinner: true
    },
    withCredentials: true
  })

  return {
    data: res?.data
  }
}

export const fetchUserRaffleEntries = async (raffleId: number) => {
  const res = await axios.get(`/raffles/${raffleId}/my-entries`, {
    withCredentials: true
  })

  return {
    data: res?.data.entries
  }
}
export const fetchRaffleProof = async (raffleId: number) => {
  const res = await axios.get(`/raffles/${raffleId}/proof`)

  return {
    data: res?.data
  }
}

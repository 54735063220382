import { FC } from "react"

import { InputGroup, InputGroupText, Label } from "reactstrap"
import { useTranslation } from "react-i18next"

type SearchInputProps = {
  label?: string
  onChange: (e: string) => void
  value?: string
  placeholder?: string
}

const SearchInput: FC<SearchInputProps> = ({ placeholder, label, onChange, value = "" }) => {
  const { t } = useTranslation()

  return (
    <div className="d-flex flex-column admin-users-search align-items-start justify-content-start">
      {label && <Label className="admin-users-search-label" for="search">{label}</Label>}

      <InputGroup className='admin-users-search-input-wrapper'>
        <InputGroupText>
          <img src="/assets/icons/search.svg" alt="search" />
        </InputGroupText>

        <input
          type="text"
          className="admin-users-search-input"
          placeholder={placeholder || t("admin-users.filters.searchPlaceholder")}
          value={value}
          onChange={e => onChange(e.target.value)}
        />
      </InputGroup>
    </div>
  )
}

export default SearchInput

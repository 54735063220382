import React, { FC } from "react"
import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { ProviderCard, ProvidersCard } from "@modules/slotsReviews"

type ProvidersReorderProps = {
  onDelete: (b: string) => void
  card: ProviderCard
}

const ProvidersReorder: FC<ProvidersReorderProps> = ({
  onDelete, card
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: card.id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  }
  return (
    <div ref={setNodeRef}
         style={style}
         {...attributes}
         {...listeners}
    >
      <ProvidersCard card={card} onDelete={onDelete} withFooter withControl />
    </div>
  )
}

export default ProvidersReorder
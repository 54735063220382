import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchUserById } from "@modules/adminTransactions/requests"

export const useUserById = (id: string) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR(`/admin-user/${id}`, () => (id ? fetchUserById(id) : null))


  return {
    user: data,
    error,

    refresh,

    isValidating,
    loading: !data && !error
  }
}

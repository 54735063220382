import { lazy } from "react"

import "./views/admin-transactions.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export * from './useAdminPointTransactions'
export * from './useUserById'

export const AdminTransactions = lazy(() => import("./views/AdminTransactions"))

export { default as ReviewTransaction } from "./components/ReviewTransaction"
export { default as RejectTransactionModal } from "./components/RejectTransactionModal"
export { default as ApproveTransactionModal } from "./components/ApproveTransactionModal"

export * from "./dto"

export * from "./requests"


export const locales = {
  en,
  ru
}

import { usePlainSWR } from "@hooks/usePlainSWR"

import {
  BonusHuntStat,
  BonusHuntStatBonus,
  BonusHuntTablePayload,
  fetchBonusHuntTopStatistic
} from "@modules/bonusHunt"
import { useMemo, useState } from "react"
import { TABLE_ORDER } from "@modules/adminUsers"

export const useBonusHuntTopStatistic = (id: string) => {
  const [filter, setFilter] = useState<BonusHuntTablePayload>({
      order: TABLE_ORDER.DESC
    }
  )
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate,
    error,
    isValidating
  } = usePlainSWR(`/bonus-hunt/hunts/${id}`, () => fetchBonusHuntTopStatistic(id))


  const filteredList: BonusHuntStatBonus[] = useMemo(() => {
    const list = (data?.bonuses || [])

    return filter?.search ? list.filter((hunt: BonusHuntStatBonus) => hunt.name.toLowerCase().includes((filter.search || "").toLowerCase())) : list
  }, [filter.search, data])
  const onChangeFilter = (newFilter: Partial<BonusHuntTablePayload>) => {
    setFilter((prev) => ({ ...prev, ...newFilter }))

    setPage(1)
  }

  return {
    statistic: data as BonusHuntStat,

    mutate,
    loading: !data && !error,
    isValidating,
    error,

    page,
    onChangePage: setPage,
    list: filteredList.slice((page - 1) * perPage, page * perPage),

    total: data?.bonuses?.length ? Math.ceil((data?.bonuses.length / perPage)) : 1,

    perPage,
    onChangePerPage: setPerPage,

    filter,
    onChangeFilter
  }
}

export default useBonusHuntTopStatistic

import { lazy } from "react"

import "./views/admin-bonuses.scss"

import en from "./locales/en.json"
import ru from "./locales/ru.json"

export const AdminBonuses = lazy(() => import("./views/AdminBonuses"))

export * from "./dto"
export * from "./helpers/form"

export * from "./useAllBonuses"
export * from "./useTopBonuses"
export * from "./useFeaturedBonuses"

export * from "./requests"

export { default as BonusCardReorder } from "./components/BonusCardReorder"
export { default as ManageBonusModal } from "./components/ManageBonusModal"
export { default as StakeBonusModal } from "./components/StakeBonusModal"
export { default as FeaturedBonusModal } from "./components/FeaturedBonusModal"
export { default as RegularBonusModal } from "./components/RegularBonusModal"
export { default as ConfirmDeleteModal } from "./components/ConfirmDeleteModal"
export { default as TopBonusCardReorder } from "./components/TopBonusCardReorder"

export const locales = {
  en,
  ru
}

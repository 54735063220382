import React, { FC } from "react"
import { useTranslation } from "react-i18next"
import { format } from "date-fns"

import { BonusHuntStatisticEntity, BonusHuntTopStatisticCard } from "@modules/bonusHunt"

type BonusHuntStatisticProps = {
  data: BonusHuntStatisticEntity
}

const BonusHuntStatistic: FC<BonusHuntStatisticProps> = ({ data }) => {
  const { t } = useTranslation()

  return (
    <section className="bonus-hunt-statistic w-100 position-relative zindex-1">
      <BonusHuntTopStatisticCard value={data.avg_betsize || "-"}>
        <p>{t("bonus-hunt.statistic.averageBet")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={data.avg_payout || "-"}>
        <p>{t("bonus-hunt.statistic.averagePayout")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={format(new Date(data.date), "HH:mm") || "-"}>
        <p>{t("bonus-hunt.statistic.huntStarted")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={data.avg_multi || "-"}>
        <p>{t("bonus-hunt.statistic.averageMulti")}</p>
      </BonusHuntTopStatisticCard>

      <BonusHuntTopStatisticCard value={data.info_required_average || "-"}>
        <p>{t("bonus-hunt.statistic.averageRequired")}</p>
      </BonusHuntTopStatisticCard>
    </section>
  )
}

export default BonusHuntStatistic

import { usePlainSWR } from "@hooks/usePlainSWR"

import { fetchProviders, updateProvidersOrderRequest } from "@modules/slotsReviews/requests"
import { ProviderCard, SearchPayload } from "@modules/slotsReviews"
import { useEffect, useState } from "react"

export const useFeaturedProviders = (maxItems?: number) => {
  const [filter, setFilter] = useState<SearchPayload>({})
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(maxItems || 5)

  const {
    data,
    mutate: refresh,
    error
  } = usePlainSWR("/casino/slots", () => fetchProviders({
    ...filter,
    page,
    perPage
  }))

  const featuredProviders: ProviderCard[] = data?.items || []

  const saveAllProvidersNewOrder = async (items: ProviderCard[]) => {
    const prevOrders: number[] = featuredProviders.map(({ order }) => order)
    const payload: { order: number, id: string }[] = items.map(({ id }, index) => ({ id, order: prevOrders[index] }))

    await updateProvidersOrderRequest(payload)
  }

  useEffect(() => {
    refresh()
  }, [page, perPage, filter])

  return {
    list: featuredProviders,

    refresh,

    loading: !data && !error,

    total: data?.total || 0,

    perPage,
    setPerPage,

    page,
    setPage,

    saveAllProvidersNewOrder,

    onFiltered: (newFilter: Partial<SearchPayload>) => setFilter((prev) => ({ ...prev, ...newFilter })),
    filter
  }
}

import { FC } from "react"
import { useTranslation } from "react-i18next"
import { useLocalStorage } from "@hooks/useLocalStorage"

type AgreementSectionProps = {}

const AgreementSection: FC<AgreementSectionProps> = ({}) => {
  const { t } = useTranslation()
  const [hideAgreement, setHideAgreement] = useLocalStorage("haddzy-point-store-agreement", false)

  if (hideAgreement) return null

  return (
    <section className="w-100 points-store-container">
      <div className="w-100 points-store-agreement">
        <div className="points-store-agreement-content">
          <h3 className="points-store-agreement-content-title m-0">
            {t("pointsStore.agreement.title")}
          </h3>

          <p className="shared-text shared-text--small points-store-agreement-content-text">
            {t("pointsStore.agreement.text")}
          </p>
        </div>

        <button
          type="button"
          onClick={() => setHideAgreement(true)}
          className="shared-btn shared-btn-primary points-store-agreement-content-btn"
        >
          {t("pointsStore.agreement.agree")}
        </button>
      </div>
    </section>
  )
}

export default AgreementSection

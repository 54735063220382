import useSWR from 'swr'

export type PaginationPayload = {
  page: number
  perPage: number
}

export const usePlainSWR = (
  key: string,
  apiCall: (payload?: any) => null | Promise<any>,
  payload?: any
) => {
  const { data, mutate, error, isValidating } = useSWR(
    key,
    () => apiCall(payload),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      errorRetryCount: 0
    }
  )

  return {
    data: data?.data,
    total: data?.total || data?.data?.length,
    mutate,
    error,
    isValidating
  }
}

export const usePlainSWRNoMount = (
  key: string,
  apiCall: (payload?: any) => Promise<any> | null,
  payload?: any
) => {
  const { data, mutate, error, isValidating } = useSWR(
    key,
    () => apiCall(payload),
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: false,
      revalidateOnReconnect: false
    }
  )

  return {
    data: data?.data,
    total: data?.total || data?.data?.length,
    mutate,
    error,
    isValidating
  }
}

import React from "react"
import { useTranslation } from "react-i18next"
import { Spinner } from "reactstrap"

import { ReactComponent as StakeImg } from "assets/image/stake.svg"

import LeaderCard from "components/LeaderCard"

import { getImageUrlByIdAndIndex, useLeaderboard } from "modules/leaderboards"
import { useParams } from "react-router-dom"

const Leaders = () => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { data, loading } = useLeaderboard(id!)

  if (loading) {
    return <Spinner color="primary" />
  }

  return (
    <section className="leaderboard-leaders-wrapper position-relative d-flex justify-content-between">
      <img className="leaderboard-leaders-decoration wide-only" src="/assets/images/leaderboard-bg.png" alt="bg" />
      <img className="leaderboard-leaders-decoration laptop-only" src="/assets/images/leaderboard-bg-laptop.png" alt="bg" />
      <img className="leaderboard-leaders-decoration tablet-only" src="/assets/images/leaderboard-bg-tablet.png" alt="bg" />
      <img className="leaderboard-leaders-decoration mobile-only" src="/assets/images/leaderboard-bg-tablet.png" alt="bg" />

      <div className="leaderboard-leaders shared-container position-relative">
        <StakeImg className="leaderboard-leaders-title-stake" />

        <div className="leaderboard-leaders-title position-relative">
          <h1 className="text-primary">{t("leaderboards.leaders.title1")}</h1>

          <h1 className="position-relative">
            <img
              src="/assets/icons/premium-quality.svg"
              alt="meddal"
              className="leaderboard-leaders-medal"
            />

            {t("leaderboards.leaders.title2")}
          </h1>
        </div>


        {loading ? (
          <Spinner color="primary" />
        ) : (
          !data ? (
            <div className="leaderboard-stub">
              <p className="shared-stub-text">
                {t("leaderboards.stubActive")}
              </p>
            </div>
          ) : (
            <div className="leaderboard-leaders-container">
              {data.leaderboardUser.map((user, index) => {
                return (
                  <LeaderCard
                    bank={user?.wageredTotal}
                    name={user?.displayName}
                    imgUrl={getImageUrlByIdAndIndex(user.id, index)}
                    place={index + 1}
                    amount={data.leaderboardTieredPrize[index]?.amount}
                    key={user.id}
                  />
                )
              })}
            </div>
          ))}
      </div>
    </section>
  )
}
export default Leaders

import { FC } from "react"
import { ProviderCard } from "@modules/slotsReviews"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

type ProvidersCardProps = {
  card: ProviderCard
  onDelete?: (id: string) => void
  withFooter?: boolean
  withControl?: boolean
}

const ProvidersCard: FC<ProvidersCardProps> = ({ card, withFooter, withControl, onDelete }) => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <div
      className={withFooter ? "slots-reviews-providers-card-with-footer cursor-pointer" : "slots-reviews-providers-card cursor-pointer"}
    >
      <div
        className={withFooter ? "slots-reviews-providers-card-img-with-footer" : "slots-reviews-providers-card-img"}
        onClick={() => navigate(`/slots-reviews/providers/${card.id}`)}
      >
        <img src={card.imageUrl} alt="logo" />
      </div>

      {withFooter &&
        <div
          className="slots-reviews-providers-card-footer shared-text shared-text--small text-secondary-75 font-weight-600"
        >
          <span
            className="slots-reviews-providers-card-footer-name text-truncate"
            onClick={() => navigate(`/slots-reviews/providers/${card.id}`)}
          >
          {card.name}
          </span>

          {withControl &&
            <div className="slots-reviews-top-picks-card-cta-provider">
              <button
                className="slots-reviews-top-picks-card-cta-edit shared-text shared-text--extra-small text-secondary-75"
                onClick={() => navigate(`provider/${card.id}`)}
              >
                {t("adminSlotsReviews.editBtn")}
              </button>

              <button
                className="slots-reviews-top-picks-card-cta-delete"
                onClick={() => onDelete && onDelete(card.id)}
              >
                <img src="/assets/icons/delete.svg" alt="delete" />
              </button>
            </div>
          }
        </div>}
    </div>
  )
}

export default ProvidersCard
import React, { FC } from "react"
import { CSS } from "@dnd-kit/utilities"

import { BonusCard } from "@modules/bonuses"

import { Bonus } from "@modules/adminBonuses"
import { useSortable } from "@dnd-kit/sortable"

type BonusCardReorderProps = {
  item: Bonus
  onDeleteItem: (b: Bonus) => void
  onEditItem: (b: Bonus) => void
}

const BonusCardReorder: FC<BonusCardReorderProps> = ({ item, onEditItem, onDeleteItem }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: item.id
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  }

  return (
    <div className="w-100 d-flex justify-content-center" ref={setNodeRef}
         style={style}
         {...attributes}
         {...listeners}>
      <BonusCard bonus={item} onDeleteItem={onDeleteItem} onEditItem={onEditItem} showControl />
    </div>
  )
}

export default BonusCardReorder

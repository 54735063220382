import React, { FC } from "react"
import SearchInput from "@src/components/SearchInput/SearchInput"
import Select from "react-select"

import { TableCell, TableHeaderCell } from "@src/components/Table"

import { Option } from "@utils/Select"
import { User } from "@hooks/useUser"
import { useTranslation } from "react-i18next"
import Pagination from "@src/components/Pagination"
import { Spinner } from "reactstrap"

import classNames from "classnames"

import { usePointsStorePurchasesById } from "@modules/pointsStore/usePointsStorePurchasesById"
import { ITEMS_ORDER, POINTS_STORE_PROFILE_TABLE } from "@modules/pointsStore"

import { addCommasForBigNumbers } from "@utils/Strings"
import { format } from "date-fns"


type RafflesTabProps = {
  user?: User
}

const PointShopTab: FC<RafflesTabProps> = ({
  user
}) => {
  const { t } = useTranslation()
  const sortByOptions: Option[] = [
    { label: "price", value: POINTS_STORE_PROFILE_TABLE.PRICE },
    { label: "date", value: POINTS_STORE_PROFILE_TABLE.DATE }
  ]
  const {
    purchase,
    loading,
    page,
    filter,
    onFiltered,
    onChangePage,
    perPage,
    total
  } = usePointsStorePurchasesById(user?.id!)

  return (
    <>
      <div className="profile-modal-raffle-header justify-content-between mt-3">
        <div className="points-store-input-admin">
          <SearchInput
            onChange={(s) => {
              onFiltered({ search: s })
              onChangePage(1)
            }}
            value={filter?.search}
          />
        </div>

        <div className="profile-modal-raffle-select">
          <Select
            options={sortByOptions}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary: "transparent",
                primary25: "#E2F1FF1A"
              }
            })}

            isSearchable={false}
            className="profile-select-wrapper"
            classNamePrefix="profile-select"
            components={{ IndicatorSeparator: null }}
            value={sortByOptions.find((o) => o.value === filter.sortBy?.toString()) || null}
            onChange={(o) => {
              if (!o?.value) return
              onFiltered(
                o.value === POINTS_STORE_PROFILE_TABLE.PRICE ? {
                  sortBy: POINTS_STORE_PROFILE_TABLE.PRICE,
                  sortOrder: ITEMS_ORDER.DESC
                } : {
                  sortBy: POINTS_STORE_PROFILE_TABLE.DATE,
                  sortOrder: ITEMS_ORDER.DESC
                })
              onChangePage(1)
            }}
          />
        </div>
      </div>

      <div className="profile-modal-table-container">
        {purchase.length === 0 ? (
          <div className="profile-modal-table-stub">
            <p className="raffles-list-stub-text">{t("NO ITEMS")}</p>
          </div>
        ) : (
          <div className="profile-modal-table no-scrollbar">
            <div className="d-flex flex-column w-100 justify-content-end">
              <div className="d-flex flex-row profile-modal-table-header-row">
                <TableHeaderCell
                  text={t("profile.pointsShopSection.tableTitle.id")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.pointsShopSection.tableTitle.itemName")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.pointsShopSection.tableTitle.price")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.pointsShopSection.tableTitle.status")}
                  className="profile-modal-table-header"
                />

                <TableHeaderCell
                  text={t("profile.pointsShopSection.tableTitle.completed")}
                  className="profile-modal-table-header"
                />
              </div>
            </div>

            <div className="d-flex flex-column w-100 justify-content-end">
              {loading ? (
                <div className="w-100 d-flex justify-content-center">
                  <Spinner color="primary" />
                </div>
              ) : (
                purchase.map((purchase) => {
                  return (
                    <div className="d-flex flex-row profile-modal-table-row" key={purchase.id}>
                      <TableCell className="admin-ended-table-cell-wrapper profile-modal-table-cell-id border-0">
                        <div className="profile-modal-table-cell">
                          #{purchase.id}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell">
                          <p className="profile-modal-table-cell-text">
                            {purchase.pointsShopItem.title}
                          </p>
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell">
                          <img src="/assets/icons/coin-small.svg" alt="coin" className="admin-users-table-icon" />
                          {addCommasForBigNumbers(purchase.pointsShopItem.price)}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div
                          className={classNames("profile-modal-table-cell",
                            { "text-green": purchase.status === "APPROVED" },
                            { "text-ban-red": purchase.status === "REJECTED" },
                            { "text-blue": purchase.status === "PENDING" })}
                        >
                          {purchase.status}
                        </div>
                      </TableCell>

                      <TableCell className="admin-ended-table-cell-wrapper border-0">
                        <div className="profile-modal-table-cell">
                          {format(new Date(purchase.updatedAt), "dd.MM.yyyy")}
                        </div>
                      </TableCell>
                    </div>
                  )
                }))}
            </div>
          </div>
        )}

        <div className="d-flex w-100 align-items-center justify-content-center mt-3">
          <Pagination
            rowCount={Math.ceil(total / perPage) || 1}
            currentPage={page}
            onChangePage={onChangePage}
            rowsPerPage={1}
          />
        </div>
      </div>
    </>
  )
}

export default PointShopTab
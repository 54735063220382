import { useEffect } from 'react'
import { usePlainSWRNoMount } from '@hooks/usePlainSWR'
import { fetchAdminLootBoxFull } from './requests'
import { LootBoxWithItems } from '@modules/lootBox'

export const useAdminLootBoxFull = (id: number | null, withItems: boolean) => {
  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWRNoMount('/loot-box/:id/full', () => {
    if (id) {
      return fetchAdminLootBoxFull({
        id,
        withItems
      })
    }
    return null
  })

  useEffect(() => {
    refresh()
  }, [id])

  return {
    data: data as LootBoxWithItems | null,
    error,

    refresh,

    isValidating,
    loading: id !== null && (!data || error)
  }
}

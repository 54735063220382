import { forwardRef, ReactNode, useImperativeHandle, useState } from "react"
import { Button, Collapse as ReactstrapCollapse } from "reactstrap"
import classNames from "classnames"

import "./styles.scss"

type CollapseProps = {
  title: string
  children?: ReactNode
  onOpen?: () => void
}

export type CollapseRef = {
  setIsOpen: (b: boolean) => void
}

const Collapse = forwardRef<CollapseRef, CollapseProps>(({ title, children, onOpen }, ref) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    setIsOpen(b: boolean) {
      setIsOpen(b)
    }
  }))

  const toggle = () => {
    setIsOpen(!isOpen)

    if (!isOpen && onOpen) {
      onOpen()
    }
  }

  return (
    <div className="custom-collapse w-100">
      <div className={classNames("custom-collapse-header w-100 d-flex align-items-center justify-content-between", {
        active: isOpen
      })}>
        <h4 className={classNames("custom-collapse-title d-inline m-0", {
          active: isOpen
        })}>
          {title}
        </h4>

        <Button
          onClick={toggle}
          className={classNames("custom-collapse-trigger", {
            active: isOpen
          })}
          color="transparent"
          cssModule={{ btn: "custom-collapse-trigger" }}
        >
          <img src="/assets/icons/dropdown-arrow.svg" alt="icon" />
        </Button>
      </div>

      <ReactstrapCollapse isOpen={isOpen} className="custom-collapse-content">
        {children}
      </ReactstrapCollapse>
    </div>
  )
})

export default Collapse

import { useMemo, useState } from "react"

import { usePlainSWR } from "@hooks/usePlainSWR"

import {
  BonusHunt,
  BonusHuntTablePayload,
  fetchBonusHunt
} from "@modules/bonusHunt"
import { TABLE_ORDER } from "@modules/adminUsers"

export const useBonusHunt = (reverseList: boolean = false) => {
  const [filter, setFilter] = useState<BonusHuntTablePayload>({
      order: TABLE_ORDER.DESC
    }
  )
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)

  const {
    data,
    mutate: refresh,
    error,
    isValidating
  } = usePlainSWR("/bonus-hunt", () =>
    fetchBonusHunt()
  )

  const actualHunt = useMemo(() => (!!data ? data[0] : null), [data])

  const filteredList: BonusHunt[] = useMemo(() => {
    const list = (data || [])

    return filter?.search ? list.filter((hunt: BonusHunt) => hunt.name.toLowerCase().includes((filter.search || "").toLowerCase())) : list
  }, [filter.search, data])

  const reversedData = [...filteredList]

  if (reverseList) {
    reversedData.reverse()
  }
  const onChangeFilter = (newFilter: Partial<BonusHuntTablePayload>) => {
    setFilter((prev) => ({ ...prev, ...newFilter }))

    setPage(1)
  }

  return {
    list: reversedData.slice((page - 1) * perPage, page * perPage),
    error,

    refresh,

    isValidating,
    loading: !data && !error,
    page,
    onChangePage: setPage,

    perPage,
    onChangePerPage: setPerPage,

    total: filteredList?.length ? Math.ceil((filteredList.length / perPage)) : 1,

    actualHunt,
    filter,
    onChangeFilter
  }
}


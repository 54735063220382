import React, { FC } from "react"

import { useSortable } from "@dnd-kit/sortable"
import { CSS } from "@dnd-kit/utilities"
import { PointCard, PointsStoreCard } from "@modules/pointsStore"

type AdminPointsStoreCardReorderProps = {
  card: PointCard
  onDeleteItem?: (b: PointCard) => void
  onEditItem?: (b: PointCard) => void
  onSelect: (c: PointCard) => void
  toggleModal?: () => void
  isDisabled?: boolean
}

const AdminPointsStoreCardReorder: FC<AdminPointsStoreCardReorderProps> = ({
  card,
  onEditItem,
  onDeleteItem,
  onSelect,
  toggleModal,
  isDisabled
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
    id: card.id,
    disabled: isDisabled
  })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition: transition || undefined
  }

  return (
    <div ref={setNodeRef}
         style={style}
         {...attributes}
         {...listeners}>

      <PointsStoreCard card={card}
                       onSelect={onSelect}
                       onDelete={onDeleteItem}
                       onEdit={onEditItem}
                       toggleModal={toggleModal}
                       showControl
      />
    </div>
  )
}

export default AdminPointsStoreCardReorder
